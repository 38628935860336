import React, { ReactElement, useEffect, useState } from "react";
import { getBrands } from "../../../../api/Brand";

import styled from "styled-components";
import AddButton from "../../../../components/other/buttons/add";
import { deleteBrand } from "../../../../api/Admin";
import InputRow from "./inputRow";
import { DateTime } from "luxon";
import { AxiosError } from "axios";
import InfoRow from "../../Reusables/infoRow";
import useInfo from "../../Hooks/useInfo";
import EditButton from "../../../../components/other/buttons/edit";
import DeleteButton from "../../../../components/other/buttons/delete";
import DelRow from "./delRow";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";

export type Brand = {
  id: number;
  name: string;
  description: string;
  url: string;
  addDate: string;
  updateDate: string;
  imageUrl: string;
  status: string;
  productPublishedCount: number;
};

const Brands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brandToEdit, setBrandToEdit] = useState<Brand | null>(null);
  const [brandToDelete, setBrandToDelete] = useState<Brand | null>(null);
  const [showAddRow, setShowAddRow] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();
  const { breakpoint } = useBreakpoint();
  const MOBILE_BREAKPOINT = 900;

  useEffect(() => {
    if (brandToEdit) {
      setBrandToDelete(null);
    }
  }, [brandToEdit]);

  useEffect(() => {
    if (brandToDelete) {
      setBrandToEdit(null);
    }
  }, [brandToDelete]);

  const loadBrands = async () => {
    getBrands().then((res) => {
      if (res.ok) {
        setBrands(res.data.objects);
        setLoading(false);
      }
    });
  };

  const handleRowClick = (data: Brand) => {
    setBrandToEdit(data);
  };

  const handleCloseAddRow = () => {
    setShowAddRow(false);
  };

  const handleCancelEdit = () => {
    setBrandToEdit(null);
  };

  const refreshData = () => {
    setLoading(true);
  };

  const handleDel = async (index?: number) => {
    if (brandToDelete) {
      try {
        setSubmitting(true);
        await deleteBrand(brandToDelete.id);
        refreshData();
        setSubmitting(false);
        updateInfo(
          `Brand ${
            brandToDelete.name ? brandToDelete.name : brandToDelete.id
          } deleted successfully`,
          brandToDelete.id,
          false,
          index
        );
      } catch (e: any | AxiosError) {
        updateInfo(
          `${
            e.message ? e.message : "Something went wrong, please try again"
          } `,
          brandToDelete.id ? brandToDelete.id : null,
          true
        );
      } finally {
        setSubmitting(false);
        setBrandToEdit(null);
        setBrandToDelete(null);
      }
    }
  };

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Name",
        accessor: "name",
        sortBy: "name",
        columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Description",
        accessor: "description",
        sortBy: "description",
        columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Url",
        accessor: "url",
        columnSizing: { min: "120px", max: "1fr" },
      },

      {
        text: "Product Count",
        accessor: "productCount",
        sortBy: "productCount",
        cb: (data: any, value: any) => {
          return data.productCount ? data.productCount : "-";
        },
      },
      {
        text: "Add Date",
        accessor: "addDate",
        sortBy: "addDate",
        cb: (data: any, value: any) => {
          return DateTime.fromISO(value).toLocaleString();
        },
      },
      {
        text: "",
        columnSizing: { min: "120px", max: "120px" },
        cb: (data: any) => {
          return (
            <ButtonWrap>
              <EditButton
                onClick={() => {
                  handleRowClick(data);
                }}
                disabled={
                  showAddRow ||
                  (showInfo && fail) ||
                  brandToEdit != null ||
                  (brandToDelete != null && brandToDelete.id !== data.id)
                }
              />
              <DeleteButton
                iconType={"Row"}
                onClick={() => setBrandToDelete(data)}
                disabled={
                  showAddRow ||
                  (showInfo && fail) ||
                  brandToEdit != null ||
                  (brandToDelete != null && brandToDelete.id !== data.id)
                }
              />
            </ButtonWrap>
          );
        },
      },
    ],
    [showAddRow, brandToDelete, brandToEdit, showInfo]
  );

  useEffect(() => {
    if (loading) {
      loadBrands();
    }
  }, [loading]);

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setShowAddRow(true);
            setBrandToEdit(null);
            setBrandToDelete(null);
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  const infoRow = React.useMemo<React.ReactNode>(
    () => <InfoRow message={info} hasFailed={fail} resetInfo={resetInfo} />,
    [fail, info, infoId, index]
  );

  const getInputRow = (setData?: (newData: any) => void): ReactElement => {
    return (
      <InputRow
        mobile={breakpoint < MOBILE_BREAKPOINT}
        data={brandToEdit && brandToEdit}
        close={brandToEdit ? handleCancelEdit : handleCloseAddRow}
        refresh={refreshData}
        updateInfo={updateInfo}
        buttonsDisabled={showInfo && fail}
        updateData={setData}
      />
    );
  };

  const getDelRow = (setData?: (newData: any) => void): ReactElement => {
    return (
      <DelRow
        mobile={breakpoint < MOBILE_BREAKPOINT}
        data={brandToDelete}
        handleDel={handleDel}
        close={() => setBrandToDelete(null)}
        refresh={refreshData}
        updateInfo={updateInfo}
        buttonsDisabled={showInfo && fail}
        updateData={setData}
      />
    );
  };

  return (
    <Wrapper>
      {breakpoint > MOBILE_BREAKPOINT ? (
        <TableV2
          data={brands}
          headerRow={setupTable}
          showAddRow={showAddRow}
          infoRow={showInfo && infoRow}
          tableHeader={tableHeader}
          loading={loading}
          editId={brandToEdit && brandToEdit.id}
          delId={brandToDelete && brandToDelete.id}
          infoId={infoId}
          getInputRow={getInputRow}
          getDelRow={getDelRow}
        />
      ) : (
        <MobileTable
          padding={40}
          data={brands}
          headerRow={setupTable}
          showAddRow={showAddRow}
          infoRow={showInfo && infoRow}
          tableHeader={tableHeader}
          loading={loading}
          editId={brandToEdit && brandToEdit.id}
          delId={brandToDelete && brandToDelete.id}
          infoId={infoId}
          paginate={false}
          getInputRow={getInputRow}
          getDelRow={getDelRow}
        />
      )}
    </Wrapper>
  );
};
export default Brands;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
