import styled from "styled-components";
import { Link } from "react-router-dom";
import LatestCarousel from "../../../components/carousel/latestCarousel";
import ContentWrapper from "../../../components/reusables/ContentWrapper";

const CartEmpty = () => {
  return (
    <ContentWrapper>
      <Wrapper>
        <CartIcon className="ri-shopping-cart-2-line"></CartIcon>
        <h1>Your cart is currently empty</h1>
        <p>Looks like you haven't added anything to your cart yet</p>
        <p>
          follow this <Link to={"/"}>link</Link> to continue browsing
        </p>
        <LatestCarousel hideHeading={true} />
      </Wrapper>
    </ContentWrapper>
  );
};

export default CartEmpty;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: row;
  align-content: center;
  grid-gap: 20px;
  padding-top: 2em;
  padding-bottom: 2em;
  h1 {
    font-weight: 600;
    font-size: 1.2em;
  }

  a {
    text-decoration: none;
    color: var(--orange-color);
  }
  img {
    height: 6em;
  }
`;

const CartIcon = styled.i`
  font-size: 8em;
  color: var(--orange-color);
`;
