import { Outlet } from "react-router-dom";

const AppWrapper = () => {
  return (
    <div className={"content-wrapper"}>
      <Outlet />
    </div>
  );
};

export default AppWrapper;
