import React, { useContext, useEffect, useState } from "react";
import { getCountries } from "../api/Countries";
import { Country } from "../components/form/types";
import { ErrorContext } from "./ErrorContext";
import { useLocation } from "react-router-dom";

interface CountryContextInterface {
  countries: Country[];
  countriesLoading: boolean;
}

const initialState = {
  countries: [],
  countriesLoading: true,
};

const CountryContext =
  React.createContext<CountryContextInterface>(initialState);

const CountryContextProvider = (props: { children: JSX.Element }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesLoading, setCountriesLoading] = useState<boolean>(true);
  const [loadFailed, setLoadFailed] = useState(false);
  const { pagePaths } = useContext(ErrorContext);
  const location = useLocation();

  useEffect(() => {
    if (loadFailed && !pagePaths.includes(location.pathname)) {
      setCountriesLoading(true);
    }
  }, [location]);

  useEffect(() => {
    const fetchCountries = async () => {
      //TODO handle error
      try {
        const countries = await getCountries();
        setCountries(countries.data);
        setLoadFailed(false);
      } catch (e: any) {
        //updateCode(e.response.status);
        setLoadFailed(true);
      }
    };
    if (countriesLoading) {
      fetchCountries().then(() => {
        setCountriesLoading(false);
      });
    }
  }, [countriesLoading]);

  return (
    <CountryContext.Provider value={{ countries, countriesLoading }}>
      {props.children}
    </CountryContext.Provider>
  );
};

export { CountryContext, CountryContextProvider };
