export const capitaliseString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const parseSearch = (s: string) => {
  return s
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/[^a-z0-9+]-/gi, "");
};

export const emptyStringify = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined) {
        obj[key] = "";
      }
    }
  }
  return obj;
};

export const getDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day} 00:00:00`;
};

export const resolvePath = (object: any, path: string): any =>
  path.split(".").reduce((o, p) => (o && o[p] !== null ? o[p] : "-"), object);

export const naturalCompare = (
  a: string | number,
  b: string | number
): number => {
  const aValue = typeof a === "number" ? a.toString() : a;
  const bValue = typeof b === "number" ? b.toString() : b;

  const regex = /(\d+)/g;

  const aMatches = aValue.toLowerCase().match(regex) || [];
  const bMatches = bValue.toLowerCase().match(regex) || [];

  const aParts = aValue.toLowerCase().split(regex);
  const bParts = bValue.toLowerCase().split(regex);

  let aIndex = 0;
  let bIndex = 0;

  while (aIndex < aParts.length && bIndex < bParts.length) {
    const aPart = aParts[aIndex];
    const bPart = bParts[bIndex];

    const aNumber = aMatches.shift();
    const bNumber = bMatches.shift();

    const aIsDigit = /^\d+$/.test(aPart);
    const bIsDigit = /^\d+$/.test(bPart);

    if (aIsDigit !== bIsDigit) {
      if (aIndex === 0 && bIndex === 0) {
        return aIsDigit ? 1 : -1;
      }
      return aIndex - bIndex;
    }
    if (aPart !== bPart) {
      return aPart.localeCompare(bPart);
    }
    if (aNumber && bNumber) {
      const numDiff = parseInt(aNumber, 10) - parseInt(bNumber, 10);
      if (numDiff !== 0) {
        return numDiff;
      }
    }
    aIndex++;
    bIndex++;
  }
  return aParts.length - bParts.length;
};

export const capitalizeWords = (str: string): string => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
