import React, { useState } from "react";
import { Product } from "../index";
import styled from "styled-components";
import ProductDetailsSection from "./details";
import Window from "../../../Reusables/window";
import { Platform } from "../../Platforms";
import { Brand } from "../../Brands";
import { Category } from "../../Categories";
import TabBar from "./tabBar";
import ProductCostSection from "./cost";
import useUpdateProductForm from "../../../../../components/form/hooks/admin/useUpdateProductForm";
import ProductLinkingSection from "./linking";
import ProductMediaSection from "./media";
import useInfo from "../../../Hooks/useInfo";
import Subheading from "../../../../../components/subheading";
import ModalButtons from "../../../Reusables/modalButtons";
import { emptyStringify } from "../../../../../utils/utils";
import { MediaItem } from "../../../../../globaltypes";
import ProductDemoSection from "./demo";

interface FormProps {
  close: () => void;
  product: Product;
  platforms: Platform[];
  brands: Brand[];
  categories: Category[];
  updateTable: (data: any) => void;
}

const UpdateProductsFormAdmin = (props: FormProps): JSX.Element => {
  const [showEditProduct, setShowEditProduct] = useState(
    props.product.id === null || props.product.id.toString() === ""
  );
  const [tab, setTab] = useState("Information");
  const [loading, setLoading] = useState<boolean>(false);
  const [showDemoProduct, setShowDemoProduct] = useState(false);

  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();
  const [
    productState,
    handleInputChange,
    handleDropdownInputChange,
    removeCategory,
    handleSubmit,
    setEditState,
    updateMedia,
    validationState,
    handleUpdateValidation,
  ] = useUpdateProductForm(emptyStringify(props.product));

  const resetState = () => {
    setEditState(JSON.parse(JSON.stringify(emptyStringify(props.product))));
  };

  const toggleEdit = () => {
    setShowEditProduct((prev) => {
      return !prev;
    });
  };

  const toggleDemo = () => {
    setShowDemoProduct((prev) => {
      return !prev;
    });
  };

  const changeTab = (e: React.MouseEvent<HTMLDivElement>) => {
    const input = e.target as HTMLElement;
    setTab(input.textContent ?? "Information");
    if (showDemoProduct) {
      setShowDemoProduct(false);
    }
  };

  const updateProductMedia = async (media: MediaItem, type: string) => {
    let newState = await updateMedia(media, type);
    props.updateTable({ ...props.product, ...newState });
    toggleEdit();
  };

  const saveProduct = async () => {
    try {
      if (!loading) {
        setLoading(true);
        if (!handleUpdateValidation()) {
          updateInfo(
            "Brand, Driver Item and Categories need to be assigned (Linking tab)",
            props.product.id,
            true
          );
          return;
        }
        const res = await handleSubmit();
        if (res) {
          updateInfo(
            `${res.data.name ? res.data.name : "Product"}` +
              " updated successfully",
            res.data.id,
            false
          );
          props.updateTable(res.data);
        }
      }
    } catch (e: any) {
      updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "Product update failed"
        }`,
        props.product.id,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const renderTab = () => {
    switch (tab) {
      case "Information":
        return (
          <TabWrapper>
            <Heading>
              <Subheading
                h3={`${
                  productState.id?.toString() === "" ? "New" : "Update"
                } Product`}
                h2={tab}
              />
              <ButtonWrapper>
                <EyeWrapper>
                  {showDemoProduct ? (
                    <i
                      className="ri-eye-2-line"
                      onClick={() => {
                        toggleDemo();
                      }}
                    ></i>
                  ) : (
                    <i
                      className="ri-eye-close-line"
                      onClick={() => {
                        toggleDemo();
                      }}
                    ></i>
                  )}
                </EyeWrapper>
                {!showDemoProduct && (
                  <ModalButtons
                    editMode={showEditProduct}
                    loading={loading}
                    handleSave={saveProduct}
                    setEditMode={toggleEdit}
                    resetState={resetState}
                    resetInfo={resetInfo}
                    disabled={false}
                  />
                )}
              </ButtonWrapper>
            </Heading>
            {showDemoProduct ? (
              <ProductDemoSection product={productState as Product} />
            ) : (
              <ProductDetailsSection
                product={productState}
                handleInputChange={handleInputChange}
                handleDropdownInputChange={handleDropdownInputChange}
                toggleEdit={toggleEdit}
                edit={showEditProduct}
              />
            )}
          </TabWrapper>
        );
      case "Total":
        return (
          <TabWrapper>
            <Heading>
              <Subheading
                h3={`${
                  productState.id?.toString() === "" ? "New" : "Update"
                } Product`}
                h2={tab}
              />
              <ModalButtons
                editMode={showEditProduct}
                loading={loading}
                handleSave={saveProduct}
                setEditMode={toggleEdit}
                resetState={resetState}
                resetInfo={resetInfo}
                disabled={false}
              />
            </Heading>
            <ProductCostSection
              product={productState}
              handleInputChange={handleInputChange}
              toggleEdit={toggleEdit}
              edit={showEditProduct}
            />
          </TabWrapper>
        );
      case "Linking":
        return (
          <TabWrapper>
            <Heading>
              <Subheading
                h3={`${
                  productState.id?.toString() === "" ? "New" : "Update"
                } Product`}
                h2={tab}
              />
              <ModalButtons
                editMode={showEditProduct}
                loading={loading}
                handleSave={saveProduct}
                setEditMode={toggleEdit}
                resetState={resetState}
                resetInfo={resetInfo}
                disabled={false}
              />
            </Heading>
            <ProductLinkingSection
              product={productState}
              handleInputChange={handleInputChange}
              handleDropdownInputChange={handleDropdownInputChange}
              toggleEdit={toggleEdit}
              edit={showEditProduct}
              brands={props.brands}
              platforms={props.platforms}
              categories={props.categories}
              removeCategory={removeCategory}
            />
          </TabWrapper>
        );
      case "Media":
        return (
          <ProductMediaSection
            product={productState}
            handleInputChange={handleInputChange}
            toggleEdit={toggleEdit}
            edit={showEditProduct}
            updateInfo={updateInfo}
            resetInfo={resetInfo}
            updateProductMedia={updateProductMedia}
            hide={productState.id?.toString() === ""}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={
        showDemoProduct
          ? () => {
              setShowDemoProduct(false);
            }
          : props.close
      }
      showInfo={showInfo}
      noPlaceholder={true}
    >
      <Wrapper>
        <>
          <TabBar changeTab={changeTab} current={tab} />
          {renderTab()}
        </>
      </Wrapper>
    </Window>
  );
};

export default UpdateProductsFormAdmin;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  min-height: 30em;
  grid-template-rows: 0.2fr auto;
  height: 70vh;
  overflow-y: scroll;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    height: unset;
    width: unset;
    min-height: unset;
    overflow-y: unset;
  }
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 2em;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
`;

const EyeWrapper = styled.div`
  margin-right: 12px;
  font-size: 1.5em;
  color: var(--orange-color);
  align-items: center;
  display: flex;
  i {
    cursor: pointer;
  }
`;

const TabWrapper = styled.div`
  display: grid;
  align-self: center;
  height: 100%;
  position: relative;
  margin: 0.5em 2em 2em 2em;
  grid-template-rows: max-content;
`;
