import styled from "styled-components";
import useShowMenu from "../../../../hooks/useShowMenu";

interface Props {
  heading: string;

  editMode: boolean;
  menuItems?: MenuItem[];
  children?: JSX.Element[];
  handleClick: (data?: any) => void;
}

export interface MenuItem {
  text: string;
  data: any;
}
const Picker = (props: Props) => {
  const [showMenu, toggleOn, toggleOff] = useShowMenu();

  return (
    <DetailsWrap>
      <DetailHeading>{props.heading}</DetailHeading>
      <Detail>
        {props.editMode && (
          <i
            data-menu={true}
            onClick={(e) => {
              if (!showMenu && props.editMode) {
                toggleOn(e);
                e.stopPropagation();
              } else {
                if (showMenu) {
                  toggleOff();
                  e.stopPropagation();
                }
              }
            }}
            className={!showMenu ? "ri-add-fill" : "ri-subtract-fill"}
          ></i>
        )}
        {props.children}
      </Detail>
      {showMenu && (
        <DetailMenu data-menu={true}>
          {props.menuItems?.map((item: MenuItem) => {
            return (
              <p
                data-menu={true}
                onClick={() => {
                  props.handleClick(item.data);
                }}
              >
                {item.text}
              </p>
            );
          })}
        </DetailMenu>
      )}
    </DetailsWrap>
  );
};

export default Picker;

const DetailsWrap = styled.div`
  border: var(--border-main);
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  grid-gap: 4px;
  padding-left: 12px;
  grid-column: 1/-1;
  position: relative;
`;

const Detail = styled.div`
  font-size: 0.8em;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  grid-auto-columns: fit-content();
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 5px;
  min-height: 2em;
  i {
    justify-self: start;
    padding-left: 2px;
    font-size: 1.2em;
    align-self: center;
    cursor: pointer;
  }
`;

const DetailHeading = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;

const DetailMenu = styled.div`
  top: 99%;
  border: var(--border-main);
  border-top: none;
  background-color: white;
  width: min-content;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  max-height: 8em;
  overflow-y: scroll;
  font-size: 0.8em;
  z-index: 5;
  p {
    padding: 8px;
    cursor: pointer;
    background-color: white;

    :hover {
      background-color: rgba(255, 159, 0, 0.22);
    }
  }
`;
