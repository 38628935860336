import styled from "styled-components";
import UserDetailsSection from "./details";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import useInfo from "../../../Admin/Hooks/useInfo";
import Window from "../../../Admin/Reusables/window";
import { User } from "../../../Admin/Views/Users";
import AddressPicker from "../../../../components/addressPicker";
import { getAddresses } from "../../../../api/Addresses";
import { MyResponse } from "../../../../api/Request";
import { initialAddressState } from "../../../../components/form/setup/address";
import { AddressResponse } from "../../../../components/form/types";

interface FormProps {
  close: () => void;
  user: User;
  updateTable: (data: any) => void;
}

const UpdateUserModal = (props: FormProps): JSX.Element => {
  const [fail, info, , showInfo, resetInfo, updateInfo] = useInfo();
  const [user, setUser] = useState<User>(props.user);
  const [loading, setLoading] = useState(false);
  const [editAddress, setEditAddress] = useState({
    editing: false,
    type: "new",
    data: initialAddressState as AddressResponse,
  });
  const [addresses, setAddresses] = useState([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (addresses.length === 0) {
      getAddresses()
        .then((res: MyResponse) => {
          if (res.ok) {
            setAddresses(res.data);
          } else {
            console.error("get addresses request failed");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [addresses.length]);

  // const editAddress = (type: string) => {};

  const theme = {
    padding: showInfo ? 0 : "20px",
  };

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={
        editAddress.editing
          ? () => {
              setEditAddress({
                editing: false,
                type: "new",
                data: initialAddressState as AddressResponse,
              });
            }
          : props.close
      }
      showInfo={showInfo}
      noPlaceholder={true}
    >
      {loading ? (
        <Loader>
          <ClipLoader size={30} color={"var(--orange-color)"} />
        </Loader>
      ) : (
        <Wrapper>
          {!editAddress.editing && (
            <UserDetailsSection
              padding={theme.padding}
              user={user}
              resetInfo={resetInfo}
              updateInfo={updateInfo}
              updateTable={props.updateTable}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          <AddressPicker
            editAddress={editAddress}
            setEditAddress={setEditAddress}
            addresses={addresses}
            editing={editMode}
          />
        </Wrapper>
      )}
    </Window>
  );
};

export default UpdateUserModal;
const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  text-align: start;
  max-height: 85vh;
  max-width: 85vw;
`;

const Loader = styled.div`
  height: 30em;
  width: 50em;
  background-color: white;
  display: grid;
  align-items: center;
  justify-items: center;
`;
