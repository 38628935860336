import { useEffect, useState } from "react";
type Validator = () => void;

const useInput = (validator?: Validator) => {
  const [value, setValue] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    // takes an optional custom callback to validate,
    // else default behaviour is to validate by length
    if (validator) {
      validator();
    } else {
      setIsValid(value.length > 0);
    }
  }, [value]);

  const reset = (): void => {
    setValue("");
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ): void => {
    setValue(e.target.value);
  };

  return [value, handleChange, reset, isValid] as const;
};
export default useInput;
