import {
  Document,
  DocumentHeader,
  DocumentIndent,
} from "../../../../styles/styledComponents/document";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const CloudPrivacy = () => {
  return (
    <ContentWrapper withLoc={true}>
      <Document>
        <DocumentHeader>INTRINSIC CLOUD SUPPORT AND LICENSING</DocumentHeader>
        <p>
          Many Intrinsic Drivers utilise cloud services for licensing and
          support. We call this cloud Intrinsic(Athena). Drivers that utilise
          this technology pass information between a local system and the
          Intrinsic(Athena) cloud.
        </p>
        <DocumentHeader>1. What data do we collect?</DocumentHeader>
        <p>
          1.1 In order to work effectively drivers utilising Intrinsic(Athena)
          technology transmit personal and identifiable information to the
          Intrinsic cloud.
        </p>
        <p>
          1.2 This data is transmitted securely via SSL and is only accessible
          by Intrinsic Dev.
        </p>
        <p>1.3 Data that is transmitted may include:</p>
        <DocumentIndent>
          1.3.1 <b>Identity Data:</b> including first name, last name, username,
          password
        </DocumentIndent>
        <DocumentIndent>
          1.3.2 <b>Contact Data:</b> including email address
        </DocumentIndent>
        <DocumentIndent>
          1.3.3 <b>Technical Data:</b> including internet protocol (IP) address,
          system MAC address, login account and security data of services
          accessed by driver, time zone settings
        </DocumentIndent>
        <DocumentIndent>
          1.3.4 <b>Usage Data:</b> including information about how you use the
          driver
        </DocumentIndent>
        <DocumentHeader>2. How do we collect this data?</DocumentHeader>
        <p>
          2.1 You provide this information by entering it into the driver upon
          installation or through its use.
        </p>
        <p>
          2.2 Information is accessed through commands issued by the driver in
          order that the driver can carry out its purpose.
        </p>
        <DocumentHeader>3. How do we use this data?</DocumentHeader>
        <p>
          3.1 Data is transmitted securely to the Intrinsic(Athena) cloud but is
          only stored for the duration of a support request. All log files
          relating to a support request are purged upon completion of support.
        </p>
        <p>
          3.2 We only use this data to the extent permitted by law. Most
          commonly it is used in the following circumstances:
        </p>
        <DocumentIndent>
          3.2.1 To ensure the correct functioning of a driver you have installed
          from us.
        </DocumentIndent>
        <DocumentIndent>
          3.2.2 To provide support pertaining to the specific driver you have
          chosen to install
        </DocumentIndent>
        <p>
          3.3 We will only use this data for the purposes for which we collected
          it, unless we reasonably consider that we need to use it for another
          reason and that reason is compatible with the original purpose.
        </p>
        <p>4. Where we store this data</p>
        <p>
          4.1 As per 3.1 data is only stored for the duration of a support
          enquiry.
        </p>
        <p>4.2 Data is stored on industry leading external secure servers.</p>
        <p>
          4.3 We have put in place all reasonable and appropriate security
          measures to prevent personal data from being accidently lost, used or
          accessed in an unauthorised manner. Whilst taking every effort to
          ensure data security, neither Intrinsic Group Limited or its
          subsidiaries are liable for any breach or misuse of data that may
          occur, howsoever such breach or misuse should occur.
        </p>
        <p>
          4.4 Access to data is limited to employees and contractors who have a
          business need to know.
        </p>
        <DocumentHeader>5. Your Obligation as an Installer</DocumentHeader>
        <p>
          5.1 By installing a driver that utilisers our Intrinsic(Athena)
          technology you are consenting to the use and transmission of data as
          outlined in sections 1-4 above.
        </p>
        <p>
          5.2 By installing this driver you are acknowledging that you have
          gained all appropriate consents from your client, as well as all end
          users of the driver, pertaining to the transmission, processing and
          storing of their data as outlined above.
        </p>
        <p>
          5.3 By installing this driver you confirm your responsibility for
          ensuring compliance with relevant data protection notifications for
          your jurisdiction.
        </p>
        <DocumentHeader>6. Legal Rights</DocumentHeader>
        <p>
          6.1 This is a supplementary Privacy Policy for our Intrinsic(Athena)
          drivers that applies in addition to our standard Terms and Privacy
          Policy. If you disagree with this or any of our standard Terms we
          would ask you not to install our drivers.
        </p>
        <p>
          6.2 You can request a copy of any or the records stored by Intrinsic
          Dev at any time by emailing gdpr@intrinsicdev.com
        </p>
        <p>
          6.3 This supplementary Privacy Policy was last updated 1st May 2020
        </p>
      </Document>
    </ContentWrapper>
  );
};

export default CloudPrivacy;
