import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/app/App";
import "remixicon/fonts/remixicon.css";
import { BrowserRouter } from "react-router-dom";
import { ErrorModalContextProvider } from "./context/ErrorModalContext";
import ErrorBoundary from "./utils/errorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <ErrorModalContextProvider>
          <App />
        </ErrorModalContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
