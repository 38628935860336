import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/mainheader";
import Footer from "../footer";
import CookieBanner from "../cookieBanner";
import { useContext, useEffect, useState } from "react";
import { CookieConsentContext } from "../../context/CookiesConsentContext";

const HeaderWrapper = () => {
  const {
    cookieConsent,
    isCookieConsentLoading,
    acceptCookies,
    rejectCookies,
  } = useContext(CookieConsentContext);

  const [showBanner, setShowBanner] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isCookieConsentLoading) {
      return;
    }
    if (cookieConsent === null) {
      setShowBanner(true);
    }
    if (cookieConsent !== null) {
      if (!cookieConsent) {
        setShowBanner(false);
      }
      if (cookieConsent) {
        setShowBanner(false);
      }
    }
  }, [cookieConsent, location, isCookieConsentLoading]);

  //TODO check this logic, decide if we need a place in the website to change the cookie settings

  return (
    <>
      {showBanner && !isCookieConsentLoading && (
        <CookieBanner decline={rejectCookies} accept={acceptCookies} />
      )}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default HeaderWrapper;
