import advert from "../../../../assets/images/about/intrinsic-advert.jpg";
import styled from "styled-components";
import Subheading from "../../../../components/subheading";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const AboutIntro = () => {
  return (
    <ContentWrapper withLoc={true}>
      <Intro>
        <Left>
          <Header>
            <Subheading h3={"WELCOME TO"} h2={"INTRINSIC DEV"} />
            <SubHeader>
              {" "}
              Specialist to the Custom Installation Industry
            </SubHeader>
          </Header>
          <p className={"spaced-text"}>
            {" "}
            Intrinsic Dev write, sell and support drivers to enable the
            integration of equipment. Our full range of drivers are available
            for instant download on this website, many of which are offered free
            of charge to support your installations. Our drivers are all
            developed in-house, in the UK so you can be sure of the highest
            reliability and responsiveness when updates are required. We offer
            full technical support for our drivers and are on hand to help and
            advise the best solution for your next project. Intrinsic Dev also
            provide training services for dealers and distributors, alongside
            white label support, all backed up with years of experience.
            Intrinsic Dev was founded and is run by people with first hand
            knowledge of the Custom Install industry. We aim to provide
            integration solutions and support that is cost effective, easy to
            deploy and reliable. All of our team, whether programmers or support
            technicians, are required to have real world experience of the
            Custom Install industry. Our full team are subject to advanced
            training to ensure the consistent high standards you have come to
            expect from Intrinsic are maintained. It’s our aim to ensure that we
            deliver you solutions, not headaches!
          </p>
        </Left>
        <Right>
          <img src={advert} alt={"company drivers advert"} />
        </Right>
      </Intro>
    </ContentWrapper>
  );
};

export default AboutIntro;

const Left = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  align-content: start;
  align-items: start;
`;

const Right = styled.div`
  justify-self: start;
  img {
    max-height: 40em;
    width: auto;
    border-radius: 4px;
  }
`;

const Header = styled.div`
  display: grid;
  justify-items: start;
  margin-bottom: 1em;
  grid-gap: 10px;
`;

const SubHeader = styled.h2`
  grid-row: 2;
  grid-column: 1/-1;
  font-weight: 600;
  font-size: 1.8em;
`;

const Intro = styled.div`
  grid-template-columns: auto auto;
  display: grid;
  text-align: left;
  margin-bottom: 2em;
  grid-gap: 2em;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    justify-items: center;
    justify-content: center;
    justify-self: center;
    ${Left} {
      grid-template-columns: 1fr;
      justify-self: center;
      margin-bottom: 2em;
    }
    ${Right} {
      justify-self: center;
      img {
        max-height: 30em;
        justify-self: center;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    ${Right} {
      img {
        max-height: 25em;
        justify-self: center;
      }
    }
  }
`;
