import { Product } from "../../../../globaltypes";
import styled, { ThemeProvider } from "styled-components";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CreateOrder } from "../../Orders/utils";
import { OpenOrdersContext } from "../../../../context/OpenOrdersContext";

interface ProductProps {
  product: Product;
}

const ProductCard = (props: ProductProps) => {
  const { user } = useContext(AuthContext);
  const nav = useNavigate();
  const { openOrders, updateOrders } = useContext(OpenOrdersContext);
  const handleClick = () => {
    const url = `/downloads/${props.product.productUrl}`;
    nav(url);
  };
  const platform = props.product.platformName
    ? props.product.platformName.toLowerCase()
    : "";

  const handleDownload = () => {
    window.open(props.product.downloadLink);
  };

  //TODO default product image if no featured image

  const cardTheme = {
    buttonColumns: user ? "1fr 1fr" : "1fr",
    downloadButtonRadius: user ? "" : "4px",
  };

  return (
    <ThemeProvider theme={cardTheme}>
      <Card>
        {props.product.media.length > 0 ? (
          <img
            src={props.product.media[0].imageUrl}
            alt={props.product.media[0].description}
            style={{ cursor: "pointer" }}
            onClick={handleClick}
          />
        ) : (
          <Dummy />
        )}
        <ProductName onClick={handleClick}>{props.product.name}</ProductName>
        {user && (
          <>
            <Price>£{props.product.gbpPrice}</Price>
            <Tax>(Excluding 20% tax)</Tax>
          </>
        )}
        <Buttons style={!user ? { marginTop: "20px" } : {}}>
          {props.product.downloadLink && (
            <DownloadButton
              onClick={() => {
                if (!user) {
                  nav("/login");
                  return;
                }
                handleDownload();
              }}
              style={{ backgroundColor: "var(--grey-color)" }}
            >
              {!user
                ? "Login to Download"
                : `Download ${
                    platform === "control4" ||
                    platform === "elan" ||
                    platform === "rti"
                      ? "Driver"
                      : platform === "crestron"
                      ? "Module"
                      : platform === "savant"
                      ? "Profile"
                      : ""
                  }`}
            </DownloadButton>
          )}
          {user && (
            <BuyButton
              onClick={() => {
                CreateOrder(
                  props.product,
                  [{ symbol: "£", currency: "GBP" }],
                  0,
                  openOrders,
                  updateOrders
                );
              }}
            >
              Buy Licence Key
            </BuyButton>
          )}
        </Buttons>
      </Card>
    </ThemeProvider>
  );
};

export default ProductCard;

const Card = styled.div`
  width: 18em;

  display: grid;
  background-color: #ffffff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding-top: 30px;
  border-radius: 4px;

  img {
    width: auto;
    height: 14em;
    justify-self: center;
  }

  @media only screen and (max-width: 920px) {
    img {
      height: 12em;
    }
  }
  @media only screen and (max-width: 350px) {
    width: 14em;
    img {
      height: 8em;
    }
  }
`;

const Price = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--header-color);
`;

const Tax = styled.p`
  margin-bottom: 20px;
  font-size: 0.8em;
`;

const Dummy = styled.div`
  width: 100%;
  height: 18em;
`;

const Buttons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => props.theme.buttonColumns};
  align-self: end;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  button {
    height: 3em;
    cursor: pointer;
    min-width: 125px;
    a {
      text-decoration: none;
      color: var(--white-color);
    }
  }
  @media only screen and (max-width: 350px) {
    button {
      min-width: 75px;
    }
  }
`;

const DownloadButton = styled.button`
  background-color: var(--grey-color);
  color: var(--white-color);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: ${(props) => props.theme.downloadButtonRadius};
`;

const BuyButton = styled.button`
  background-color: var(--orange-color);
  color: var(--white-color);
  border-bottom-right-radius: 4px;
`;

const ProductName = styled.p`
  color: var(--grey-color);
  :hover {
    color: var(--orange-color);
  }
  cursor: pointer;
  font-weight: 450;
  margin-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
`;
