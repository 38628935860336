import React, { useEffect, useState } from "react";
import { ErrorDetails } from "../types";
import generateErrorObject from "../utils/generateErrorObject";
import { useNavigate } from "react-router-dom";

interface ErrorContextInterface {
  saveError: (err: any, fallback?: string) => void;
  errorDetails: ErrorDetails | null;
  clearError: () => void;
}

const properties: ErrorContextInterface = {
  saveError: (err: any, fallback) => {},
  errorDetails: null,
  clearError: () => {},
};

const ErrorModalContext =
  React.createContext<ErrorContextInterface>(properties);

const ErrorModalContextProvider = (props: { children: JSX.Element }) => {
  const [errorDetails, setErrorDetails] = useState<ErrorDetails | null>(null);
  const [fallback, setFallback] = useState<string | null>(null);
  const nav = useNavigate();
  const saveError = (err: any, fallback?: string) => {
    setErrorDetails(generateErrorObject(err));
    if (fallback) {
      setFallback(fallback);
    }
  };

  const clearError = () => {
    setErrorDetails(null);
    if (fallback) {
      nav(fallback);
      setFallback(null);
    }
  };

  useEffect(() => {
    if (errorDetails) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [errorDetails]);

  return (
    <ErrorModalContext.Provider value={{ saveError, errorDetails, clearError }}>
      {props.children}
    </ErrorModalContext.Provider>
  );
};

export { ErrorModalContext, ErrorModalContextProvider };
