import { ClipLoader } from "react-spinners";

type Props = {
  color?: string;
};
const ButtonSpinner = (props: Props) => {
  return <ClipLoader color={props.color ? props.color : "white"} size={20} />;
};

export default ButtonSpinner;
