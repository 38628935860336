import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Subheading from "../../../../components/subheading";
import { SupportTicket } from "../types";
import { useParams } from "react-router-dom";
import { FormButton } from "../../../../components/form/styledComponents";
import { v4 as uuidv4 } from "uuid";
import { SupportContext } from "../../../../context/SupportContext";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import { useForm, Controller } from "react-hook-form";
import LabeledInput from "../../../../components/form/components/labeledInput";
import TextAreaInput from "../../../../components/form/components/textareainput";
import DropdownInput from "../../../../components/form/components/dropdownInput";

type Props = {
  support?: SupportTicket;
  modal?: boolean;
  editing?: boolean;
};

const SupportForm = (props: Props) => {
  const { handleSubmit, control, watch, setValue } = useForm();

  const { categories } = useContext(SupportContext);
  const { number } = useParams();
  const watchedCategoryId = watch("categoryId");
  const watchedProjectId = watch("projectId");
  const watchedDriverName = watch("driverName");

  const onSubmit = (data: any) => {
    console.log(data);
  };
  useEffect(() => {
    if (number) {
      setValue("categoryId", number);
    }
  }, [number, setValue]);

  useEffect(() => {
    const selectedCategory = categories.find(
      (c) => c.id.toString() === watchedCategoryId
    );
    if (selectedCategory) {
      setValue("fields", selectedCategory.fields);
    }
  }, [watchedCategoryId, categories, setValue]);

  const isDriverSupport = watchedCategoryId > 6;
  const isBilling = watchedCategoryId === "2";

  return (
    <Wrapper>
      <Heading>
        <Subheading h2={"Support Ticket"} h3={"Create"} />
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Inner>
          {categories.length > 0 && (
            <>
              <Controller
                name="categoryId"
                control={control}
                defaultValue={number ? number : null}
                render={({ field }) => (
                  <SearchableDropdown
                    placeholder={"Search for a category..."}
                    id={uuidv4()}
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    items={categories.map((category) => {
                      return {
                        displayValue: category.name,
                        value: category.id.toString(),
                      };
                    })}
                  />
                )}
              />
              {isDriverSupport ||
                (isBilling && (
                  <Controller
                    name="projectId"
                    control={control}
                    render={({ field }) => (
                      <SearchableDropdown
                        placeholder="Select a project..."
                        id={uuidv4()}
                        value={field.value}
                        handlechange={field.onChange}
                        items={[{ displayValue: "Project 1", value: "1" }]}
                      />
                    )}
                  />
                ))}
              {watchedProjectId && (isDriverSupport || isBilling) && (
                <>
                  <Controller
                    name="macAddress"
                    control={control}
                    defaultValue={""}
                    disabled
                    render={({ field }) => (
                      <LabeledInput
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        label={{
                          text: "MAC Address",
                          required: false,
                          for: "macAddress",
                        }}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="driverName"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <DropdownInput
                        height={"200px"}
                        id={uuidv4()}
                        value={field.value}
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        items={[
                          { value: "", displayValue: "Select Driver name" },
                          { displayValue: "Elan", value: "ELAN" },
                          { displayValue: "Crestron", value: "CRESTRON" },
                        ]}
                      />
                    )}
                  />
                </>
              )}
              {watchedDriverName && (
                <>
                  <Controller
                    name="subject"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <LabeledInput
                        name={"subject"}
                        value={field.value}
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        label={{
                          for: "subject",
                          required: false,
                          text: "Subject",
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="orderNumber"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <LabeledInput
                        name={"orderNumber"}
                        value={field.value}
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        label={{
                          for: "orderNumber",
                          required: false,
                          text: "Order Number",
                        }}
                      />
                    )}
                  />
                  {watchedDriverName === "ELAN" && (
                    <>
                      <Controller
                        name="controllerName"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <LabeledInput
                            name={"controllerName"}
                            value={field.value}
                            handlechange={(e) => {
                              field.onChange(e.target.value);
                            }}
                            label={{
                              for: "controllerName",
                              required: false,
                              text: "Controller Name",
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="controllerPassword"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <LabeledInput
                            type={"password"}
                            name={"controllerPassword"}
                            value={field.value}
                            handlechange={(e) => {
                              field.onChange(e.target.value);
                            }}
                            label={{
                              for: "controllerPassword",
                              required: false,
                              text: "Controller Password",
                            }}
                          />
                        )}
                      />
                    </>
                  )}
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <LongTextArea>
                        <TextAreaInput
                          name={"description"}
                          value={field.value}
                          handlechange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          label={{
                            for: "description",
                            required: false,
                            text: "Description",
                          }}
                        />
                      </LongTextArea>
                    )}
                  />
                </>
              )}
            </>
          )}
          <FormButton type="submit">Submit</FormButton>
        </Inner>
      </form>
    </Wrapper>
  );
};

export default SupportForm;

const Wrapper = styled.div`
  display: grid;
  position: relative;
  min-width: 35em;
  text-align: start;
  align-content: start;
  align-self: start;
  @media only screen and (max-width: 600px) {
    min-width: 0px;
  }
`;

const ModalWrapper = styled.div`
  display: grid;
  position: relative;
  min-width: 17em;
  margin: 0 1.5em 2em 1.5em;
  max-height: 35em;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  grid-template-rows: auto 1fr;
  padding-right: 1em;
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.8em;

  justify-self: start;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  text-align: start;
  //padding: 10px;
  button {
    justify-self: center;
    width: 100%;
    max-width: 305px;
  }
`;

const LongTextArea = styled.div`
  grid-column: 1/-1;
`;
