import { useMemo } from "react";
import { naturalCompare, resolvePath } from "../utils/utils";

type HeaderRowItem = {
  accessor?: string;
};

type DataItem = {
  id: any;
};

type Props = {
  data: DataItem[];
  headerRow: HeaderRowItem[];
  searchValue: string | null;
  sortBy: string | null;
  sortIndex: number;
  searchCallback?: (searchValue: string) => DataItem[];
};

export const useSearchAndFilter = ({
  data,
  headerRow,
  searchValue,
  sortBy,
  sortIndex,
  searchCallback,
}: Props) => {
  return useMemo(() => {
    let results = [...data];

    if (searchValue) {
      results = results.filter((dataItem: any) => {
        return headerRow.some((item) => {
          if (item.accessor) {
            const searchRes = resolvePath(dataItem, item.accessor);
            return (
              typeof searchRes === "string" &&
              searchRes.toLowerCase().includes(searchValue.toLowerCase())
            );
          }
          return false;
        });
      });

      if (searchCallback) {
        const callbackResults = searchCallback(searchValue);
        const uniqueIds = new Set(
          results
            .map((item) => item.id)
            .concat(callbackResults.map((item: { id: any }) => item.id))
        );
        results = data.filter((item: { id: any }) => uniqueIds.has(item.id));
      }
    }

    if (sortBy) {
      results.sort((a: any, b: any) => {
        const itemA = resolvePath(a, sortBy);
        const itemB = resolvePath(b, sortBy);

        if (itemA === itemB) return 0;

        const itemAType = typeof itemA;
        const itemBType = typeof itemB;

        if (itemAType === "number" && itemBType === "number") {
          return sortIndex === 1 ? itemA - itemB : itemB - itemA;
        } else if (itemAType === "boolean" && itemBType === "boolean") {
          return sortIndex === 1
            ? Number(itemA) - Number(itemB)
            : Number(itemB) - Number(itemA);
        } else {
          return sortIndex === 1
            ? naturalCompare(itemB.toString(), itemA.toString())
            : naturalCompare(itemA.toString(), itemB.toString());
        }
      });
    }

    return results;
  }, [searchValue, sortIndex, sortBy, data, headerRow, searchCallback]);
};
