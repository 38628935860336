import Card from "../../../../Reusables/card";
import React from "react";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";

const Details = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });
  return (
    <Wrapper>
      <Controller
        name="firstName"
        control={control}
        defaultValue=""
        rules={{ required: "Name is required" }}
        render={({ field }) => (
          <Card
            heading={"Name"}
            text={field.value}
            inputSetup={{
              value: field.value,
              onChange: (e) => field.onChange(e.target.value),
              name: "name",
            }}
            editing={true}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Card
            heading={"Last Name"}
            text={field.value}
            inputSetup={{
              value: field.value,
              onChange: (e) => field.onChange(e.target.value),
              name: "lastName",
            }}
            editing={true}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{ required: "Email is required" }}
        render={({ field }) => (
          <Card
            heading={"Email"}
            text={field.value}
            inputSetup={{
              value: field.value,
              onChange: (e) => field.onChange(e.target.value),
              name: "email",
            }}
            editing={true}
          />
        )}
      />
    </Wrapper>
  );
};

export default Details;

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  grid-gap: 20px;
  display: grid;
  padding-bottom: 40px;
`;
