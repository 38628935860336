import { Link, useNavigate } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const LoginBox = () => {
  const nav = useNavigate();
  const handleLogin = () => {
    nav("/login");
  };
  return (
    <Wrapper>
      <LinkWrap>
        Not yet registered? Click <Link to={"/register"}>here</Link> to create
        an account.
      </LinkWrap>
      <Button onClick={handleLogin}>Log in</Button>
    </Wrapper>
  );
};

export default LoginBox;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid 1px rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 9px;
  padding-left: 10%;
  padding-right: 10%;
  justify-items: center;
  align-items: center;
  align-content: center;
  grid-gap: 20px;
  font-size: 0.8em;
  margin-left: 2em;
  @media only screen and (max-width: 992px) {
    max-width: 100%;
    width: fit-content;
  }
`;

const Button = styled.button`
  //margin-top: 15px;
  margin-bottom: 10px;
  color: var(--grey-color);
  width: 120px;
  height: 40px;

  text-decoration: none;
  padding: 7px 20px 7px 20px;
  display: grid;
  align-content: center;
  border: solid 1px rgba(100, 97, 97, 0.23);
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
  background-color: rgba(238, 238, 238, 0.51);
  font-size: 1.25em;
  cursor: pointer;

  a:hover {
    background-color: #eeeeee;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

const LinkWrap = styled.div`
  font-size: 14px;
  a {
    color: var(--orange-color);
    text-decoration: none;
  }

  @media only screen and (max-width: 992px) {
    font-size: 1em;
  }
`;
