import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { NavContext } from "../../../context/NavContext";
import { Product } from "../../../globaltypes";
import {
  getAllBundleProductsByPlatform,
  getAllFeaturedProductsByPlatform,
  getAllProductsByPlatform,
  getAllSupportProductsByPlatform,
  getProductsByBrand,
  getProductsBySearch,
  getProductsForPlatformAndBrand,
  getProductsForPlatformAndCategory,
} from "../../../api/Product";

import ProductCard from "./productCard";
import { parseSearch } from "../../../utils/utils";
import { DataItem } from "../../../components/header/types";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import PageController from "./pageController";
import usePaginate from "../../../hooks/usePaginate";
import { MyResponse } from "../../../api/Request";
import Location from "../../../components/location";

const Results = () => {
  const { platformName, searchType, query } = useParams();
  const nav = useNavigate();
  const [loadingResults, setLoadingResults] = useState<boolean>(true);
  const [results, setResults] = useState<Product[]>([]);

  const { loading, getPlatform, brands } = useContext(NavContext);
  const location = useLocation();
  const [currentPage, pages, updateCurrentPageTest, toDisplay] = usePaginate(
    results,
    // set page size to results size to remove pagination
    results.length - 1
  );
  const searchResults = toDisplay.map((product) => {
    return <ProductCard product={product} />;
  });

  useEffect(() => {
    setLoadingResults(true);
  }, [location]);
  const getId = (arr: DataItem[]): number => {
    return arr.filter((item) => {
      return parseSearch(item.name) === query;
    })[0].id;
  };

  const response = (res: MyResponse) => {
    if (res.data.objects.length === 1) {
      nav(`/downloads/${res.data.objects[0].productUrl}`);
    }
    setResults(res.data.objects);
    setLoadingResults(false);
  };

  const loadWithPlatformName = (platformName: string) => {
    const platform = getPlatform(platformName);
    switch (searchType) {
      case "category":
        if (query) {
          getProductsForPlatformAndCategory(
            platform.id,
            getId(platform.categories)
          ).then((res) => {
            response(res);
          });
        } else {
          getAllProductsByPlatform(platform.id).then((res) => {
            response(res);
            return;
          });
        }
        break;
      case "featured":
        if (query) {
          getProductsForPlatformAndBrand(
            platform.id,
            getId(platform.featuredBrands)
          ).then((res) => {
            response(res);
          });
        } else {
          getAllFeaturedProductsByPlatform(platform.id).then((res) => {
            response(res);
            return;
          });
        }
        break;
      case "support":
        getAllSupportProductsByPlatform(platform.id).then((res) => {
          if (query) {
            let queryResults = res.data.objects.filter((support: DataItem) =>
              support.shortName
                ? parseSearch(support.shortName) === query
                : true
            );
            setResults(queryResults);
          } else {
            setResults(res.data.objects);
          }
          setLoadingResults(false);
          return;
        });
        break;
      case "value":
        getAllBundleProductsByPlatform(platform.id).then((res) => {
          setResults(res.data.objects);
          setLoadingResults(false);
          return;
        });
        break;
    }
  };

  const loadWithBrandName = () => {
    getProductsByBrand(getId(brands)).then((res) => {
      response(res);
    });
  };

  useEffect(() => {
    const startLoad = async (): Promise<void> => {
      try {
        if (platformName) {
          loadWithPlatformName(platformName);
        } else {
          let pathname = location.pathname;
          if (pathname.split("/")[1] === "products") {
            if (query) {
              getProductsBySearch(query.replaceAll("-", " "))
                .then((res) => {
                  response(res);
                })
                .catch(() => {
                  setResults([]);
                  setLoadingResults(false);
                });
            } else {
              setResults([]);
              setLoadingResults(false);
            }
          } else {
            loadWithBrandName();
          }
        }
      } catch (e) {
        //TODO handle err
        console.error("error caught in search results");
      }
    };

    if (loadingResults && !loading) {
      startLoad().then(() => {
        setTimeout(() => {}, 1000);
      });
    }
  }, [location, loadingResults, loading, platformName]);

  return (
    <>
      {loadingResults ? (
        <SpinnerWrap>
          {" "}
          <PulseLoader size={7} color={"var(--grey-color)"} />
        </SpinnerWrap>
      ) : (
        <Wrapper>
          <LocationWrap>
            <Location />
          </LocationWrap>
          {searchResults.length > 0 ? (
            <>
              {searchResults}
              {pages > 1 && (
                <PageController
                  pages={pages}
                  currentPage={currentPage}
                  updateCurrentPage={updateCurrentPageTest}
                />
              )}
            </>
          ) : (
            <NoProductsText>No Products Found</NoProductsText>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Results;

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
`;

const NoProductsText = styled.div`
  grid-column: 1/-1;
  font-weight: 500;
`;

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  justify-self: center;
  margin-bottom: 4em;

  @media only screen and (max-width: 1320px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const LocationWrap = styled.div`
  margin-top: 30px;
  grid-column: 1/-1;
  justify-self: start;
`;
