import { Controller } from "react-hook-form";
import LabeledInput from "../form/components/labeledInput";
import styled from "styled-components";

type AddressProps = {
  name: string;
  control: any;
  errors: any;
  required?: boolean;
  defaultValues: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
  };
};

const AddressInputNew = (props: AddressProps) => {
  const { name, control, errors, defaultValues } = props;

  const generateName = (fieldName: string) => `${name}.${fieldName}`;
  const getNestedError = (fieldName: string) => {
    return errors[name] && errors[name][fieldName];
  };

  const fieldNames = [
    "line1",
    "line2",
    "city",
    "state",
    "postcode",
    "country",
  ] as const;
  return (
    <AddressWrap>
      {fieldNames.map((field, index) => (
        <Controller
          key={index}
          name={generateName(field)}
          control={control}
          defaultValue={defaultValues[field as keyof typeof defaultValues]}
          rules={
            props.required
              ? {
                  required: `${
                    field.charAt(0).toUpperCase() + field.slice(1)
                  } is required`,
                }
              : {}
          }
          render={({ field: renderedField }) => (
            <LabeledInput
              name={generateName(field)}
              value={renderedField.value}
              handlechange={(e) => renderedField.onChange(e.target.value)}
              error={getNestedError(field)}
              label={{
                for: generateName(field),
                required: true,
                text: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
              }}
            />
          )}
        />
      ))}
    </AddressWrap>
  );
};

export default AddressInputNew;

const AddressWrap = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;

  h3 {
    justify-self: start;
    font-weight: 700;
    align-self: end;
    text-align: left;
  }
`;
