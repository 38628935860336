import React, { useContext, useEffect, useState } from "react";
import {
  defaultPlatforms,
  DataItem,
  Platforms,
} from "../components/header/types";
import { getNavBar } from "../api/NavBar";
import { parseSearch } from "../utils/utils";
import { ErrorContext } from "./ErrorContext";
import { useLocation } from "react-router-dom";

interface NavInterface {
  loading: boolean;
  platforms: Platforms[];
  brands: DataItem[];
  getPlatform: (platformName: string) => Platforms;
  loadFailed: boolean;
  getName: (id: number) => string;
}

const properties: NavInterface = {
  loading: true,
  platforms: [],
  brands: [],
  getPlatform: () => {
    return defaultPlatforms;
  },
  loadFailed: false,
  getName: () => "",
};

const NavContext = React.createContext<NavInterface>(properties);

const NavContextProvider = (props: { children: JSX.Element }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [platforms, setPlatforms] = useState<Platforms[]>([]);
  const [loadFailed, setLoadFailed] = useState(false);
  const [brands, setBrands] = useState<DataItem[]>([]);
  const { updateCode, pagePaths } = useContext(ErrorContext);
  const location = useLocation();

  const getPlatform = (platformName: string): Platforms => {
    return platforms.filter((item) => {
      return parseSearch(item.name) === platformName;
    })[0];
  };

  const getName = (id: string | number): string => {
    const stringId = id.toString();
    const brand = brands.find((element) => element.id.toString() === stringId);
    return brand ? brand.name : stringId;
  };

  useEffect(() => {
    if (loadFailed && !pagePaths.includes(location.pathname)) {
      setLoading(true);
    }
  }, [location, loadFailed, pagePaths]);

  useEffect(() => {
    const fetchNavbar = async (): Promise<void> => {
      try {
        const navBar = await getNavBar();
        setBrands(navBar.data.brands);
        setPlatforms(navBar.data.navBarComponents);
        setLoadFailed(false);
      } catch (e: any) {
        //updateCode(e.response.status);
        setLoadFailed(true);
      }
    };
    if (loading) {
      fetchNavbar().then(() => {
        setLoading(false);
      });
    }
  }, [loading, updateCode]);
  return (
    <NavContext.Provider
      value={{ loading, platforms, brands, getPlatform, loadFailed, getName }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

export { NavContext, NavContextProvider };
