import { useReducer } from "react";
import { InputHandler, ValidationFunction } from "../../types";
import {
  initialProductValidityState,
  ProductValidityReducer,
  UpdateProductReducer,
} from "../../setup/admin/product";
import {
  UpdateProductActionCase,
  UpdateProductFormState,
  UpdateProductValidationCase,
} from "../../setup/admin/product/updateProductTypes";
import { Platform } from "../../../../views/Admin/Views/Platforms";
import { Category } from "../../../../views/Admin/Views/Categories";
import { Brand } from "../../../../views/Admin/Views/Brands";
import { MediaItem } from "../../../../globaltypes";
import { addProductAdmin, editProductAdmin } from "../../../../api/Admin";

const useUpdateProductForm = (initState?: UpdateProductFormState) => {
  let [state, dispatch] = useReducer(
    UpdateProductReducer,
    JSON.parse(JSON.stringify(initState as UpdateProductFormState))
  );
  const [validationState, dispatchValidity] = useReducer(
    ProductValidityReducer,
    initialProductValidityState
  );

  const removeCategory = (category: Category): void => {
    dispatch({
      value: category,
      case: UpdateProductActionCase.REMOVE_CATEGORY,
    });
  };

  const handleValidation: ValidationFunction = (e): void => {
    let validationCase = e.target.dataset
      .validationtype as keyof typeof UpdateProductValidationCase;
    let field = e.target.dataset.field as string;
    dispatchValidity({
      field: field,
      value: e.target.value,
      case: UpdateProductValidationCase[validationCase],
      state: state,
    });
  };

  const handleUpdateValidation = (): boolean => {
    const brandCheck = state.brand !== undefined;
    const platformCheck = state.driverPlatform !== undefined;
    const categoriesCheck = state.categories.length > 0;
    if (brandCheck && platformCheck && categoriesCheck) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange: InputHandler = (e): void => {
    let selectedCase: UpdateProductActionCase;
    switch (e.target.dataset.case) {
      case UpdateProductActionCase.UPDATE_COST:
        selectedCase = UpdateProductActionCase.UPDATE_COST;
        if (!isNaN(Number(e.target.value))) {
          dispatch({
            field: e.target.name,
            value: Number(e.target.value),
            case: selectedCase,
          });
        }
        return;
      case UpdateProductActionCase.UPDATE_NUMBER:
        selectedCase = UpdateProductActionCase.UPDATE_NUMBER;
        if (!isNaN(Number(e.target.value))) {
          dispatch({
            field: e.target.name,
            value: Number(e.target.value),
            case: selectedCase,
          });
        }
        return;
      default:
        selectedCase = UpdateProductActionCase.GENERAL;
    }
    dispatch({
      field: e.target.name,
      value: e.target.value,
      case: selectedCase,
    });
  };

  const handleDropdownInputChange = (
    value: string | Platform | Brand | Category,
    productCase?: UpdateProductActionCase
  ): void => {
    let selectedCase: UpdateProductActionCase;
    switch (productCase) {
      case UpdateProductActionCase.UPDATE_DRIVER_PLATFORM:
        selectedCase = UpdateProductActionCase.UPDATE_DRIVER_PLATFORM;
        dispatch({
          value: value as Platform,
          case: selectedCase,
        });
        break;
      case UpdateProductActionCase.UPDATE_BRAND:
        selectedCase = UpdateProductActionCase.UPDATE_BRAND;
        dispatch({
          value: value as Brand,
          case: selectedCase,
        });
        break;
      case UpdateProductActionCase.UPDATE_CATEGORY:
        selectedCase = UpdateProductActionCase.UPDATE_CATEGORY;
        dispatch({
          value: value as Category,
          case: selectedCase,
        });
        break;
      case UpdateProductActionCase.UPDATE_TYPE:
        selectedCase = UpdateProductActionCase.UPDATE_TYPE;
        dispatch({
          value: value as string,
          case: selectedCase,
        });
        break;
      case UpdateProductActionCase.UPDATE_STATUS:
        selectedCase = UpdateProductActionCase.UPDATE_STATUS;
        dispatch({
          value: value as string,
          case: selectedCase,
        });
        break;
      default:
        selectedCase = UpdateProductActionCase.GENERAL;
        dispatch({
          field: "",
          value: value as string,
          case: selectedCase,
        });
    }
  };

  const updateMedia = async (media: MediaItem, type: string) => {
    let newState = { ...state };
    switch (type) {
      case "ADD FILE":
        newState.media.push(media);
        dispatch({
          value: newState,
          case: UpdateProductActionCase.EDIT,
        });
        return newState;
      case "ADD URL":
        newState.media.push(media);
        dispatch({
          value: newState,
          case: UpdateProductActionCase.EDIT,
        });
        return newState;
      case "DELETE":
        newState = {
          ...state,
          media: newState.media.filter(
            (stateMedia) => JSON.stringify(stateMedia) !== JSON.stringify(media)
          ),
        };
        dispatch({
          value: newState,
          case: UpdateProductActionCase.EDIT,
        });
        return newState;
      case "EDIT":
        newState.media.forEach((stateMedia, index) => {
          if (stateMedia.id === media.id) {
            newState.media[index] = media;
          }
        });
        dispatch({
          value: newState,
          case: UpdateProductActionCase.EDIT,
        });
        return newState;
    }
  };

  const handleSubmit = async () => {
    if (state.id) {
      //TODO: Fix originalDateCreated since currently it returns that it cannot be converted.
      let newState = {
        ...state,
        driverPlatform: (state.driverPlatform as Platform).id,
        brand: (state.brand as Brand).id,
        categories: state.categories.map(
          (category) => (category as Category).id
        ),
        originalDateCreated: "",
      };
      return await editProductAdmin(newState);
    } else {
      let newState = {
        ...state,
        id: null,
        driverPlatform: (state.driverPlatform as Platform).id,
        brand: (state.brand as Brand).id,
        categories: state.categories.map(
          (category) => (category as Category).id
        ),
      };
      return await addProductAdmin({ ...newState });
    }
  };

  const setEditState = (editState: UpdateProductFormState) => {
    dispatch({
      value: editState,
      case: UpdateProductActionCase.EDIT,
    });
  };

  return [
    state,
    handleInputChange,
    handleDropdownInputChange,
    removeCategory,
    handleSubmit,
    setEditState,
    updateMedia,
    validationState,
    handleUpdateValidation,
  ] as const;
};

export default useUpdateProductForm;
