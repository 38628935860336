import React, { ReactElement, useEffect } from "react";
import useShowMenu from "../../../../hooks/useShowMenu";
import SubMenu from "../submenu";
import { Platforms } from "../../types";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

interface SubheaderPlatformProps {
  platform: Platforms;
  index: number;
}

const NavBarComponent = ({
  platform,
  index,
}: SubheaderPlatformProps): ReactElement => {
  const [showMenu, toggleOn, toggleOff] = useShowMenu();
  let currentLocation = useLocation();
  let pageStr = currentLocation.pathname.split("/")[1].toUpperCase();
  const activePage = pageStr === platform.name.toUpperCase();
  if (activePage) {
    document.getElementById(platform.name)?.classList.add("active");
  }

  useEffect(() => {
    toggleOff();
    if (showMenu) {
      document.getElementById(platform.name)?.classList.add("active");
    } else {
      document.getElementById(platform.name)?.classList.remove("active");
    }
  }, [currentLocation]);

  return (
    <Wrapper
      onMouseOver={(e: React.MouseEvent<HTMLElement>) => {
        toggleOn(e);
        document.getElementById(platform.name)?.classList.add("active");
      }}
      onMouseOut={(e: React.MouseEvent<HTMLElement>) => {
        toggleOff();
        if (!activePage) {
          document.getElementById(platform.name)?.classList.remove("active");
        }
      }}
      onClick={(e) => {
        setTimeout(() => {
          document.getElementById(platform.name)?.classList.add("active");
        }, 500);
      }}
    >
      <Link
        id={platform.name}
        className="NavPlatName"
        to={`/${platform.name.toLowerCase()}/category`}
      >
        <p>{platform.name}</p>
      </Link>

      {showMenu && <Border></Border>}
      {showMenu && (
        <SubMenu data={platform} name={platform.name} index={index} />
      )}
    </Wrapper>
  );
};
export default NavBarComponent;

const Border = styled.div`
  height: 3px;
  position: absolute;
  top: 98%;
  width: 100%;
  background-color: #ff9f00;
`;

const Wrapper = styled.div`
  font-weight: bold;
  position: relative;
  height: 100%;
  display: grid;
  align-content: center;
  align-items: center;
  cursor: default;
  .active {
    color: var(--orange-color);
  }
  .NavPlatName {
    height: 100%;
  }
`;
