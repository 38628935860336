import React from "react";
import "../../styles/form.css";
import { CountryItemsProps } from "../../types";
import styled from "styled-components";

const CountryItem = (props: CountryItemsProps) => {
  return (
    <Wrapper
      data-menu={true}
      className={"country-item"}
      onClick={() => {
        props.handleCountry(props.country, props.name ? props.name : "country");
        props.toggleOff();
      }}
    >
      {props.country.flagCode}
      {props.country.isoCode}
    </Wrapper>
  );
};

export default CountryItem;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: start;
  cursor: pointer;
  padding: 8px 12px;
  :hover {
    background-color: rgba(75, 73, 73, 0.07);
  }
`;
