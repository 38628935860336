import useInput from "../../../../../../hooks/useInput";
import styled from "styled-components";
import Forminput from "../../../../../../components/form/components/forminput";

const Promo = () => {
  const [code, setCode] = useInput();
  return (
    <Wrapper>
      <Inner>
        <p>Got a promo/discount code? Enter it here:</p>
        <Forminput
          isvalid={true}
          placeholder={"code"}
          name={"promo"}
          handlechange={setCode}
          value={code}
        />
      </Inner>
    </Wrapper>
  );
};
export default Promo;

const Wrapper = styled.div`
  display: grid;
`;

const Inner = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-content: center;
  align-items: center;
  justify-self: end;
  @media only screen and (max-width: 920px) {
    justify-self: start;
    text-align: left;
    width: 100%;
  }
`;
