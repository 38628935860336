import styled from "styled-components";

export const FormInputWrap = styled.div`
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  grid-gap: 2px;
  position: relative;
  border-radius: 4px;
  :focus {
    border-color: var(--border-active-color);
  }
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    height: 35px;
    color: var(--grey-color);
  }
  input[type="password"] {
    padding-right: 2em;
    @media only screen and (max-width: 992px) {
      padding-right: 0;
      font-size: 11px;
    }
  }
  input[type="text"] {
    height: 100%;

    @media only screen and (max-width: 992px) {
      padding-right: 0;
      font-size: 11px;
    }
  }
  input[type="text"]:focus {
    border-color: var(--border-active-color);
  }
`;

export const LabeledInputWrap = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-items: start;
  width: 100%;
  position: relative;
  background-color: white;
  label {
    position: absolute;
    top: 10px;
    left: 8px;
    background-color: white;
    font-size: 0.9em;
    transition: all ease-in-out 0.2s;
    transform: ${(props) => props.theme.transform};
    opacity: ${(props) => props.theme.opacity};
    z-index: ${(props) => props.theme.index};
    padding-left: 2px;
    padding-right: 2px;
  }
`;

export const PhoneInputWrap = styled.div`
  display: grid;
  grid-template-columns: 4em 1fr;
  position: relative;
  width: 100%;
  grid-gap: 2px;
  border-radius: 4px;
  ${FormInputWrap} {
    border: solid 1px rgba(0, 0, 0, 0.23);
  }
`;

export const AddressFormWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  min-width: 50vw;
  grid-gap: 20px;
  margin-bottom: 1em;

  ${FormInputWrap} {
    grid-row: 1;
  }

  h3 {
    justify-self: start;
    font-weight: 700;
    align-self: end;
    text-align: left;
  }
`;

export const AddressLineWrap = styled.div``;

export const CountryInput = styled.input`
  border: solid 1px var(--border-active-color);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  justify-items: center;
  justify-content: center;
  padding-left: 12px;
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  ::-ms-input-placeholder,
  ::-moz-placeholder {
    font-size: 0.9em;
  }
  height: 38px;

  @media only screen and (max-width: 992px) {
    height: 34px;
  }
`;

export const CountryInputHidden = styled.p`
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 38px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 0.9em;

  @media only screen and (max-width: 992px) {
    height: 34px;
  }
`;

export const CheckBoxWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: left;
  align-items: start;
  grid-gap: 5px;
  line-height: 1.3;
  max-width: 35em;
  font-weight: 500;
  input[type="checkbox"] {
    margin-top: 3px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: #ff9f00;
    text-decoration: underline;
  }

  @media only screen and (max-width: 992px) {
    a {
      color: #ff9f00;
    }
  }
`;

export const FormButton = styled.button`
  width: 5em;
  height: 2.5em;
  padding: 12px;
  background-color: rgba(238, 238, 238, 0.51);
  display: grid;
  align-content: center;
  border: solid 1px rgba(100, 97, 97, 0.23);
  font-size: 1em;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
  :disabled {
    background-color: rgba(238, 238, 238, 0.51);
    border: solid 1px rgba(100, 97, 97, 0.23);
    box-shadow: none;
  }
  :active {
    background-color: rgba(238, 238, 238, 0.51);
    border: solid 1px rgba(100, 97, 97, 0.23);
    box-shadow: none;
  }
  :hover {
    background-color: #eeeeee;
  }
`;
