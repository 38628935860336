import { useEffect, useState } from "react";
import styled from "styled-components";
import { AddressResponse } from "../form/types";
import AddressCard from "./addressCard";
import Subheading from "../subheading";
import AddButton from "../other/buttons/add";
import AddAddressForm from "./form";
import { initialAddressState } from "../form/setup/address";
import CancelButton from "../other/buttons/cancel";

interface Props {
  addresses?: AddressResponse[];
  // padding?: string | number;
  editAddress?: { editing: boolean; type: string; data: AddressResponse };
  setEditAddress?: (editing: {
    editing: boolean;
    type: string;
    data: AddressResponse;
  }) => void;
  editing?: boolean;
}

const AddressPicker = (props: Props) => {
  // pull from props
  const [addresses, setAddresses] = useState<AddressResponse[]>(
    props.addresses ? props.addresses : []
  );

  useEffect(() => {
    if (props.addresses) {
      setAddresses(props.addresses);
    }
  }, [props.addresses]);

  const addAddressOnClick = () => {
    if (props.setEditAddress) {
      props.setEditAddress({
        editing: true,
        type: "new",
        data: initialAddressState as AddressResponse,
      });
    }
  };

  return (
    <>
      <AddressPickerWrap>
        <Heading>
          <Subheading
            h3={
              props.editAddress?.editing
                ? props.editAddress?.type === "new"
                  ? "Create"
                  : "Edit"
                : "Manage"
            }
            h2={props.editAddress?.type === "new" ? "New Address" : "Addresses"}
          />
          {props.editing && (
            <>
              {!props.editAddress?.editing ? (
                <AddButton
                  onClick={() => {
                    addAddressOnClick();
                  }}
                  color={"var(--orange-color)"}
                />
              ) : (
                <CancelButton
                  onClick={() => {
                    if (props.setEditAddress) {
                      props.setEditAddress({
                        editing: false,
                        type: "new",
                        data: initialAddressState as AddressResponse,
                      });
                    }
                  }}
                />
              )}
            </>
          )}
        </Heading>
        {props.editAddress?.editing ? (
          <AddAddressForm
            initState={props.editAddress?.data}
            submitCallback={(
              passed: boolean,
              msg: string,
              data: AddressResponse
            ) => {
              //Submitted
              let tempAddresses = [...addresses];
              tempAddresses.push(data);
              setAddresses(tempAddresses);
            }}
            type={props.editAddress.type}
          />
        ) : (
          <CardList>
            {addresses ? (
              addresses.reverse().map((val: AddressResponse) => {
                return (
                  <AddressCard
                    key={val.addressName}
                    address={val}
                    editable={props.editing}
                    setEditAddress={props.setEditAddress}
                  />
                );
              })
            ) : (
              <span>No Addresses</span>
            )}
          </CardList>
        )}
      </AddressPickerWrap>
    </>
  );
};

export default AddressPicker;

const CardList = styled.div`
  //max-height: 800px;
  display: grid;
  align-items: start;
  align-content: start;
  align-self: start;
  grid-gap: 10px;
  padding: 10px;
  @media only screen and (max-width: 920px) {
    grid-auto-flow: column;
    justify-items: start;
    justify-content: start;
    overflow-x: scroll;
  }
`;

const AddressPickerWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  border-left: var(--border-main);
  position: relative;
  max-height: 100%;
  overflow-y: scroll;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 1em;
  @media only screen and (max-width: 920px) {
    padding-top: 20px;
    grid-auto-flow: column;
    grid-column: 1/-1;
  }
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  margin-bottom: 2em;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;
