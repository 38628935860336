import { Link } from "react-router-dom";

import "../error.css";
import ErrorWrapper from "../ErrorWrapper";

const Error403 = () => {
  return (
    <ErrorWrapper>
      <h2>403</h2>
      <div className="return-link">
        <p>Something went wrong, click the link to return</p>
        <Link to={"/"}>Home</Link>
      </div>
    </ErrorWrapper>
  );
};
export default Error403;
