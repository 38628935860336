import { MyResponse, RequestConfig, requestMiddleware } from "./Request";
import { Country } from "../components/form/types";

export async function login(data: {
  username: string;
  password: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/auth/login",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

//auth/register

export async function registerUser(data: {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: { number: string; country: Country };
  website: string;
  password: string;
  companyName: string;
  address: {
    id?: number;
    firstLine: string;
    secondLine: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
  };
  controlPlatforms: {
    Control4: string;
    Crestron: string;
    Elan: string;
    RTI: string;
    Savant: string;
  };
  newsletter: boolean;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/auth/register",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

//	"{{baseUrl}}/auth/forgottenPassword?email=sebastian.smith@intrinsicgrouplimited.com"

export async function forgotPassword(email: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/auth/forgottenPassword?email=${email}`,
    method: "POST",
  };
  return requestMiddleware(config);
}

export async function resetPassword(data: {
  email: string;
  authCode: string;
  newPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/auth/resetPassword`,
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function changePassword(data: {
  currentPassword: string;
  newPassword: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/users/changePassword",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function completeRegistration(data: {
  email: string;
  authCode: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/auth/completeRegistration",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}
