import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import EditButton from "../../../../components/other/buttons/edit";
import styled from "styled-components";
import AddButton from "../../../../components/other/buttons/add";
import { getVouchersAdmin } from "../../../../api/Admin";
import UpdateVoucherFormAdmin from "./modal";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import { useLocation } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";
import { getDate } from "../../../../utils/utils";

export type Voucher = {
  id?: number;
  code: string;
  name: string;
  description: string;
  status: string;
  voucherType: string;
  voucherScope: string;
  amountType: string;
  amount: number;
  usesUser: number;
  maxUses: number;
  totalUseCount: number;
  startDate: string;
  expiry: string;
  products: number[];
};

const initialVoucherState: Voucher = {
  code: "",
  name: "",
  description: "",
  status: "INACTIVE",
  voucherType: "DISCOUNT",
  voucherScope: "GLOBAL",
  amountType: "FLAT",
  amount: 0,
  usesUser: 1,
  maxUses: 0,
  totalUseCount: 0,
  startDate: getDate(new Date()),
  expiry: "",
  products: [],
};

const initialVoucher = initialVoucherState;

const Vouchers = () => {
  const [vouchers, setVouchers] = useState([]);
  const [voucherToEdit, setVoucherToEdit] = useState<{
    data: Voucher;
    index: number;
  } | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    getVouchersAdmin().then((res) => {
      if (res.ok) {
        setVouchers(res.data.objects.flat(1));
        setLoading(false);
      }
    });
  }, []);

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Code",
        accessor: "code",
      },
      {
        text: "Name",
        accessor: "name",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "status",
        accessor: "status",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        //TODO: Change to amount + suffix
        text: "Total",
        accessor: "amount",
        cb: (data: any, value: any, index: number | undefined) => {
          return `£${value}`;
        },
      },
      {
        text: "Total Uses",
        accessor: "totalUses",
        sortBy: "totalUses",
      },
      {
        text: "Expiry",
        accessor: "expiry",
        sortBy: "expiry",
        cb: (data: any, value: any) => {
          return value ? DateTime.fromISO(value).toLocaleString() : "-";
        },
      },
      {
        text: "",
        cb: (
          data: any,
          value: any,
          index: number | undefined,
          cb?: (data: any) => void
        ) => {
          return (
            <EditButton
              onClick={() => {
                handleRowClick(data, index);
              }}
            />
          );
        },
        columnSizing: { min: "60px", max: "60px" },
      },
    ],
    []
  );

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setVoucherToEdit({
              data: initialVoucher,
              index: vouchers.length + 1,
            });
            setShowForm(true);
            // setPlatformToDelete(null);
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  const handleRowClick = (data: Voucher, index?: number) => {
    if (index !== undefined) {
      setVoucherToEdit({ data: data, index: index });
      setShowForm(true);
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <Wrapper>
      {voucherToEdit !== null && showForm && (
        <UpdateVoucherFormAdmin
          voucher={voucherToEdit.data}
          close={handleClose}
        />
      )}
      {breakpoint > 920 ? (
        <TableV2
          data={vouchers}
          headerRow={setupTable}
          loading={loading}
          tableHeader={tableHeader}
        />
      ) : (
        <MobileTable
          padding={40}
          data={vouchers}
          headerRow={setupTable}
          loading={loading}
        />
      )}
    </Wrapper>
  );
};

export default Vouchers;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;
