import styled, { ThemeProvider } from "styled-components";
import { useContext, useEffect, useState } from "react";
import Tab from "./tab";
import Panel from "./panel";
import { Product } from "../../../../globaltypes";
import CurrencySelector from "../currency";
import { AuthContext } from "../../../../context/AuthContext";
import { CreateOrder } from "../../Orders/utils";
import { OpenOrdersContext } from "../../../../context/OpenOrdersContext";
import { v4 as uuidv4 } from "uuid";

interface DescriptionProps {
  product: Product;
  disabled?: boolean;
}
const Description = (props: DescriptionProps) => {
  const [activeTab, setActiveTab] = useState<string>("description");
  const [currencyIndex, setCurrencyIndex] = useState<number>(0);
  const { user } = useContext(AuthContext);
  const { openOrders, updateOrders } = useContext(OpenOrdersContext);
  const [inOrder, setInOrder] = useState(false);
  const platform = props.product.platformName.toLowerCase();

  useEffect(() => {
    containsProduct();
  }, []);
  const containsProduct = () => {
    if (openOrders.length > 0) {
      openOrders[0].orderLines.forEach((orderLine) => {
        if (orderLine.productName === props.product.name) {
          setInOrder(true);
          return true;
        }
      });
    }
    return false;
  };

  const updateTab = (name: string): void => {
    setActiveTab(name);
  };

  const updateCurrency = (index: number): void => {
    setCurrencyIndex(index);
  };

  const handleDownload = () => {
    window.open(props.product.downloadLink);
  };

  const theme = {
    backgroundColor: !user
      ? "var(--orange-color)"
      : props.disabled || inOrder
      ? "var(--grey-button)"
      : "var(--orange-color)",
    hoverColor:
      props.disabled || inOrder
        ? "var(--grey-button)"
        : "var(--orange-color-hover)",
  };

  const symbols = [
    { symbol: "£", currency: "GBP" },
    { symbol: "$", currency: "AUD" },
    { symbol: "$", currency: "CAD" },
    { symbol: "€", currency: "EUR" },
    { symbol: "$", currency: "USD" },
  ];
  return (
    <DescriptionWrap>
      <Title>{props.product.name}</Title>
      {user && (
        <>
          <CurrencySelector
            updateCurrency={updateCurrency}
            currentIndex={currencyIndex}
          />
          <Price>
            {symbols[currencyIndex].symbol +
              props.product.costs[currencyIndex].currencyValue}
          </Price>
        </>
      )}
      <ButtonWrap>
        <ThemeProvider key={uuidv4()} theme={theme}>
          {props.product.downloadLink && (
            <DownloadButton onClick={handleDownload} disabled={props.disabled}>
              {`Download ${
                platform === "control4" ||
                platform === "elan" ||
                platform === "rti"
                  ? "Driver"
                  : platform === "crestron"
                  ? "Module"
                  : platform === "savant"
                  ? "Profile"
                  : ""
              }`}
            </DownloadButton>
          )}
          {user && (
            <BuyButton
              onClick={() => {
                CreateOrder(
                  props.product,
                  symbols,
                  currencyIndex,
                  openOrders,
                  updateOrders
                );
              }}
              disabled={props.disabled || inOrder}
            >
              {inOrder ? "Already in order" : "Buy Licence Key"}
            </BuyButton>
          )}
        </ThemeProvider>
      </ButtonWrap>
      <Menu>
        <Tab
          activeTab={activeTab}
          name={"description"}
          updateTab={updateTab}
          header={"Description"}
        />
        <Tab
          activeTab={activeTab}
          name={"supported"}
          updateTab={updateTab}
          header={"Supported Models"}
        />
        <Tab
          activeTab={activeTab}
          name={"intrinsic"}
          updateTab={updateTab}
          header={"Intrinsic Connected"}
        />
        <Panel product={props.product} activeTab={activeTab} />
      </Menu>
    </DescriptionWrap>
  );
};

export default Description;

const Title = styled.h2`
  font-weight: bold;
  font-size: 1.9em;
  text-align: left;
`;

const Price = styled.h2`
  font-weight: bold;
  font-size: 2em;
  text-align: left;
`;
const Menu = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-template-rows: auto 1fr;
`;

const DownloadButton = styled.button`
  background-color: var(--grey-color);
  :hover {
    background-color: var(--grey-button-hover);
  }
`;

const BuyButton = styled.button`
  background-color: ${(props) => props.theme.backgroundColor};
  :hover {
    background-color: ${(props) => props.theme.hoverColor};
  }
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  grid-gap: 10px;
  justify-items: start;
  justify-content: start;
  button {
    width: 9em;
    height: 2.5em;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    font-size: 1em;
    box-sizing: content-box;
    border-radius: 4px;
  }
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const DescriptionWrap = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto auto 1fr;
  grid-gap: 1em;
  button {
    border-radius: 4px;
  }

  @media only screen and (max-width: 920px) {
    ${Menu} {
      grid-template-columns: 1fr;
    }
    ${ButtonWrap} {
      justify-content: center;
      justify-items: center;
      margin-bottom: 10px;
    }
    ${Price} {
      justify-content: center;
      justify-items: center;
      justify-self: center;
      font-size: 1.4em;
    }
    ${Title} {
      font-size: 1.6em;
      justify-self: center;
      text-align: center;
    }
  }
`;
