import { Link } from "react-router-dom";
import { DataItem } from "../../../types";
import { parseSearch } from "../../../../../utils/utils";
import styled from "styled-components";
import MenuLink from "../../link";

type SubMenuElementProps = {
  elements: DataItem[];
  header?: string;
  name: string;
};

const SubMenuElements = (props: SubMenuElementProps) => {
  const elements = props.elements.map((e) => {
    return <MenuLink dataItem={e} name={props.name} header={props.header} />;
  });
  return (
    <MenuItem data-menu={true}>
      {props.header && (
        <SubTitle>
          <Link
            data-menu={true}
            to={`${parseSearch(props.name)}/${parseSearch(
              props.header === "Enhanced Support" ? "Support" : props.header
            )}`}
          >
            {props.header}
          </Link>
        </SubTitle>
      )}
      <Elements data-menu={true}> {elements}</Elements>
    </MenuItem>
  );
};
export default SubMenuElements;

export const SubTitle = styled.div`
  text-align: start;
  border-bottom: solid white 2px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-size: 1.1em;
  white-space: nowrap;
`;

const MenuItem = styled.div`
  width: 100%;
  p {
    word-break: keep-all;
    word-wrap: normal;
    white-space: nowrap;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const Elements = styled.div`
  display: grid;
  text-align: left;
  width: max-content;
  margin: 10px;
  grid-template-columns: ${(props) => props.theme.cols && props.theme.cols};
  overflow-y: ${(props) => props.theme.scroll && props.theme.scroll};
  padding-right: 10px;
  grid-gap: 5px;
  };

a {
    color: white;
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 10px;
  word-break: keep-all;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
`;
