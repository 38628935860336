import logo from "../../assets/images/footer/cedia.png";
import { Link } from "react-router-dom";
import packageInfo from "../../../package.json";
import { useEffect, useState } from "react";
import { getVersion } from "../../api/Utils";
import styled from "styled-components";
const version = packageInfo.version;

const Footer = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [apiVersion, setApiVersion] = useState<string>("");
  useEffect(() => {
    const loadData = async () => {
      const data = await getVersion();
      setApiVersion(data.data.message);
    };
    if (loading) {
      try {
        loadData().then(() => {
          setLoading(false);
        });
      } catch (e) {
        //TODO handle error
        setLoading(false);
        console.error(e);
      }
    }
  }, [loading]);
  return (
    <FooterWrap>
      <FooterLeft>
        {" "}
        <Link to={"/terms-conditions"}>Terms & Conditions</Link>
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
        <Link to={"/cloud-privacy-policy"}>Cloud Licensing Privacy Policy</Link>
      </FooterLeft>
      <FooterCenter>
        <SocialMediaWrapper>
          <Link to={"https://uk.linkedin.com/company/intrinsic-dev-limited"}>
            <i className="ri-linkedin-box-fill"></i>
          </Link>
          <Link to={"https://www.instagram.com/intrinsicgroup"}>
            <i className="ri-instagram-fill"></i>
          </Link>
          <Link to={"https://www.facebook.com/Intrinsicdev"}>
            <i className="ri-facebook-circle-fill"></i>
          </Link>
          <Link to={"https://www.youtube.com/c/IntrinsicDev"}>
            <i className="ri-youtube-fill"></i>
          </Link>
        </SocialMediaWrapper>
        <Links>
          <Link to={"/about"}>About</Link>
          <CenterLink to={"/support/form"}>Support</CenterLink>
          <Link to={"/contact"}>Contact</Link>
        </Links>
        <CopyRight> © Copyright - {new Date().getFullYear()} | Intrinsic Group Limited </CopyRight>{" "}
      </FooterCenter>
      <FooterRight>
        <img src={logo} alt={"cedia logo"} />
      </FooterRight>
      <Version>
        <p> {"UI: " + version + "-" + process.env.REACT_APP_GIT_SHA}</p>
        <p> {"API: " + apiVersion}</p>
      </Version>
    </FooterWrap>
  );
};

export default Footer;

const FooterLeft = styled.div`
  position: relative;
  display: grid;
  text-align: center;
  grid-gap: 2px;
  grid-template-rows: 1fr 1fr 1fr;
  a {
    align-self: center;
  }
`;

const FooterCenter = styled.div`
  align-self: center;
  display: grid;
  grid-gap: 2px;
  grid-template-rows: 1fr 1fr 1fr;
  position: relative;
  a {
    align-self: center;
  }
  p {
    align-self: center;
  }
`;

const SocialMediaWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 2em);
  justify-content: center;
  grid-gap: 0.8em;
  i {
    font-size: 1.8em;
  }
  a {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none !important;
  }
`;

const FooterRight = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
const Links = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  width: min-content;
  justify-self: center;
  align-self: center;
`;

const CopyRight = styled.p`
  grid-row: 3;
`;

const Version = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  font-size: 0.5em;
  color: rgba(255, 255, 255, 0.77);
  text-align: left;
  bottom: 2em;
  left: 50%;
  transform: translate(-50%, 0);
  @media only screen and (max-width: 350px) {
    bottom: 1em;
    left: 45%;
    transform: translate(-45%, 0);
  }
`;

const CenterLink = styled(Link)`
  border-left: solid 1px white;
  border-right: solid 1px white;
  padding-left: 5px;
  padding-right: 5px;
`;

const FooterWrap = styled.div`
  height: 10em;
  position: relative;
  background-color: #191919;
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: white;
  align-content: center;
  padding: 1em;
  grid-template-rows: auto auto;

  a {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;

    ${FooterLeft} {
      display: none;
    }
    ${FooterRight} {
      display: none;
    }
  }
`;
