import { ClipLoader } from "react-spinners";
import React from "react";
import styled, { ThemeProvider } from "styled-components";

interface Props {
  editMode: boolean;
  loading: boolean;
  handleSave(): any;
  setEditMode: (state: boolean) => void;
  resetState?: () => void;
  resetInfo: () => void;
  disabled: boolean;
}

const ModalButtons = (props: Props) => {
  const { editMode, loading, handleSave, setEditMode, resetState, resetInfo } =
    props;
  return (
    <ThemeProvider
      theme={{ color: props.disabled ? "grey" : "var(--orange-color)" }}
    >
      <SaveWrap>
        {editMode ? (
          <>
            {loading ? (
              <ClipLoader color={"#FF9F00FF"} size={15} />
            ) : (
              <SaveButton
                onClick={handleSave}
                className="ri-save-fill"
              ></SaveButton>
            )}
            <i
              onClick={() => {
                setEditMode(false);
                resetState && resetState();
                resetInfo();
              }}
              className="ri-arrow-go-back-line"
            ></i>
          </>
        ) : (
          <i
            onClick={() => {
              setEditMode(true);
              resetInfo();
            }}
            className="ri-file-edit-fill"
          ></i>
        )}
      </SaveWrap>
    </ThemeProvider>
  );
};

export default ModalButtons;

const SaveWrap = styled.div`
  color: var(--orange-color);
  i {
    cursor: pointer;
    font-size: 20px;
  }
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
`;

const SaveButton = styled.i`
  color: ${(props) => props.theme.color};
`;
