import styled from "styled-components";

interface Props {
  email: string;
}

const SuccessScreen = (props: Props) => {
  return (
    <SuccessWrap>
      <i className="ri-checkbox-circle-line"></i>
      <TextWrap>
        <h2>Registration submission successful</h2>
        <p>
          A confirmation email has been sent to:
          <OrangeText>{" " + props.email}</OrangeText>
        </p>
        <p>
          Your account is currently pending verification by Intrinsic Dev, which
          can take up to 2 working days.
        </p>
        <LoginText>
          Once your account verification is complete an email will be sent.
          Please follow the instructions in the email to Login.
        </LoginText>
        <p>
          If you require a driver urgently please email info@intrinsicdev.com
        </p>
      </TextWrap>
    </SuccessWrap>
  );
};

export default SuccessScreen;

const SuccessWrap = styled.div`
  margin-top: 10%;
  i {
    color: var(--orange-color);
    font-size: 6em;
  }
`;

const TextWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  h2 {
    font-size: 1.6em;
    font-weight: 500;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: var(--orange-color);
  }
`;

const LoginText = styled.p`
  margin-bottom: 20px;
`;

const OrangeText = styled.text`
  color: var(--orange-color);
`;
