import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Product } from "../Views/Products";
import { getProductsAdmin } from "../../../api/Admin";

interface AdminProductContextInterface {
  products: Product[];
  loadingProducts: boolean;
}

const initialState = {
  products: [],
  loadingProducts: true,
};

const AdminProductContext =
  React.createContext<AdminProductContextInterface>(initialState);

const AdminProductContextProvider = (props: { children: JSX.Element }) => {
  const { isAdmin } = useContext(AuthContext);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    if (loadingProducts && isAdmin) {
      loadProducts().then(() => {
        setLoadingProducts(false);
      });
    }
  }, [loadingProducts, isAdmin]);

  const loadProducts = async () => {
    //TODO ERROR
    try {
      getProductsAdmin().then((res) => {
        if (res.ok) {
          setProducts(res.data.objects);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AdminProductContext.Provider
      value={{
        products: products,
        loadingProducts: loadingProducts,
      }}
    >
      {props.children}
    </AdminProductContext.Provider>
  );
};

export { AdminProductContextProvider, AdminProductContext };
