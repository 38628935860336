import { useState } from "react";
import Carousel from "react-simply-carousel";

import styled from "styled-components";
import { MediaItem } from "../../../globaltypes";

interface CarouselProps {
  images: MediaItem[];
  handleClick: (item: MediaItem) => void;
  extractId: (url: string) => string;
  deleteMedia?: (media: MediaItem) => void;
  editing?: boolean;
}

const MediaCarousel = (props: CarouselProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const images = props.images.map((img, index) => {
    if (img.mediaType === "IMAGE") {
      return (
        <ImageWrap
          key={index}
          onClick={(e) => {
            const selectedElement = e.target as HTMLElement;
            handleUpdate(selectedElement.dataset.index);
            if (
              selectedElement.dataset.index !== undefined &&
              !isNaN(Number(selectedElement.dataset.index))
            ) {
              setActiveSlideIndex(Number(selectedElement.dataset.index));
            }
          }}
        >
          {props.editing && activeSlideIndex === index && (
            <DeleteButton
              onClick={(e) => {
                if (props.deleteMedia) {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this media item?"
                    )
                  ) {
                    props.deleteMedia(props.images[activeSlideIndex]);
                  }
                }
              }}
            >
              <i className="ri-close-circle-line"></i>
            </DeleteButton>
          )}
          <img
            src={img.imageUrl}
            alt={img.description}
            key={index}
            data-index={index}
          />
        </ImageWrap>
      );
    } else {
      return (
        <ImageWrap
          key={index}
          onClick={(e) => {
            const selectedElement = e.target as HTMLElement;
            handleUpdate(selectedElement.dataset.index);
            if (
              selectedElement.dataset.index !== undefined &&
              !isNaN(Number(selectedElement.dataset.index))
            ) {
              setActiveSlideIndex(Number(selectedElement.dataset.index));
            }
          }}
        >
          {props.editing && activeSlideIndex === index && (
            <DeleteButton
              onClick={(e) => {
                if (props.deleteMedia) {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this media item?"
                    )
                  ) {
                    props.deleteMedia(props.images[activeSlideIndex]);
                  }
                }
              }}
            >
              <i className="ri-close-circle-line"></i>
            </DeleteButton>
          )}
          <img
            key={index}
            src={`https://img.youtube.com/vi/${props.extractId(
              img.imageUrl
            )}/hqdefault.jpg`}
            alt={img.description}
            data-index={index}
          />
        </ImageWrap>
      );
    }
  });

  const handleUpdate = (index: string | undefined) => {
    if (index !== undefined && !isNaN(Number(index))) {
      props.handleClick(props.images[Number(index)]);
    } else {
      props.handleClick(props.images[activeSlideIndex]);
    }
  };

  return (
    <div>
      <Carousel
        // onAfterChange={handleUpdate}
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={3}
        itemsToScroll={1}
        updateOnItemClick={true}
        forwardBtnProps={{
          style: {
            alignSelf: "center",
            color: "#9e9e9e",
            fontSize: "1.5em",
            cursor: "pointer",
          },
          children: <i className="ri-arrow-right-circle-fill" />,
        }}
        backwardBtnProps={{
          style: {
            alignSelf: "center",
            color: "#9e9e9e",
            fontSize: "1.5em",
            cursor: "pointer",
          },
          children: <i className="ri-arrow-left-circle-fill" />,
        }}
        dotsNav={{
          show: true,
          itemBtnProps: {
            style: {
              height: 16,
              width: 16,
              borderRadius: "50%",
              border: 0,
            },
          },
        }}
        speed={400}
        easing="linear"
        infinite={true}
        centerMode={true}
      >
        {images}
      </Carousel>
    </div>
  );
};

export default MediaCarousel;

const ImageWrap = styled.div`
  position: relative;
  display: grid;
  img {
    width: 7em;
    height: auto;
    align-self: center;
    margin: 5px;
  }
`;

const DeleteButton = styled.div`
  position: absolute;
  right: 2px;
  top: 0;
  i {
    cursor: pointer;
    font-size: 1.2em;
  }
  :hover {
    color: red;
  }
`;
