import styled from "styled-components";
type Props = {
  subtotal: number;
  tax: number;
};
const Subtotal = (props: Props) => {
  return (
    <Wrapper>
      <div>
        <p>Subtotal:</p>
        <p> £{props.subtotal}</p>
      </div>
      <div>
        <p>Tax(20%): </p>
        <p> £{props.tax}</p>
      </div>
    </Wrapper>
  );
};
export default Subtotal;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-self: end;
  width: 100%;
  justify-items: end;
  grid-gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  div {
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: left;
    width: 200px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 920px) {
    div {
      justify-self: start;
    }
  }
`;
