import styled from "styled-components";

const CustomerDetails = () => {
  return <Wrapper>Not yet implemented</Wrapper>;
};
export default CustomerDetails;

const Wrapper = styled.div`
  min-height: 300px;
  display: grid;
  align-content: center;
`;
