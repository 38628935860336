import { useState } from "react";
import styled from "styled-components";
import { AddressResponse } from "../../form/types";
import EditButton from "../../other/buttons/edit";

export interface AddressCardProps {
  editable?: boolean;
  address: AddressResponse;
  setEditAddress?: (editing: {
    editing: boolean;
    type: string;
    data: AddressResponse;
  }) => void;
}

const AddressCard = (props: AddressCardProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const [address, setAddress] = useState(props.address);
  /*
  const onEdit = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setIsEditable(!isEditable);
  };

  const onAddressSave = (
    success?: string,
    message?: string,
    data?: AddressResponse
  ) => {
    if (data && success) {
      setAddress(data);
      setIsEditable(false);
      return;
    }
  };

  const onDefault = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    // do
  };

  const onDelete = (id: number) => {
    deleteAddressById(id)
      .then((res: MyResponse) => {
        if (res.ok) {
        }
      })
      .catch((err) => {
        console.error(err);
      });
    //do
  }; */

  return (
    <>
      <DetailsWrap>
        <HeaderWrapper>
          <DetailHeading>
            {props.address.addressName
              ? props.address.addressName
              : "No name found"}
          </DetailHeading>
          {props.editable && (
            <EditButton
              onClick={() => {
                // toggleEdit(true);
                if (props.setEditAddress) {
                  props.setEditAddress({
                    editing: true,
                    type: "edit",
                    data: address,
                  });
                }
              }}
            />
          )}
        </HeaderWrapper>
        <DetailSubHeading>
          Address Type:{" "}
          {props.address.addressType ? props.address.addressType : ""}
        </DetailSubHeading>
        <Detail>
          {props.address && props.address.firstLine
            ? props.address.firstLine
            : "-"}
        </Detail>
        <Detail>
          {props.address && props.address.secondLine
            ? props.address.secondLine
            : "-"}
        </Detail>
        <Detail>
          {props.address && props.address.city ? props.address.city : "-"}
        </Detail>
        <Detail>
          {props.address && props.address.state ? props.address.state : "-"}
        </Detail>
      </DetailsWrap>
    </>
  );
};

export default AddressCard;

const DetailsWrap = styled.div`
  border: var(--border-main);
  width: 12em;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  grid-gap: 4px;
  padding-left: 12px;
`;

const Detail = styled.p`
  font-size: 0.8em;
`;

const HeaderWrapper = styled.div`
  font-size: 0.6em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailHeading = styled.p`
  font-weight: 600;
  font-size: 1.5em;
  text-align: left;
  flex: 1;
`;

const DetailSubHeading = styled.p`
  font-weight: 600;
  font-size: 0.8em;
`;
