import styled from "styled-components";

const CheckoutComplete = () => {
  return (
    <Wrapper>
      <Circle>
        <i className="ri-check-line"></i>
      </Circle>
      <h1>Thank you for your purchase</h1>
      <h2>Your order number is: xxxxxxx</h2>
      <p>
        Need a driver for another project? click the link below to return home:
      </p>
      <button>Continue Shopping</button>
    </Wrapper>
  );
};
export default CheckoutComplete;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-gap: 20px;
  justify-self: center;
  align-self: center;
  margin-bottom: ${(props) => props.theme.marginBottom};

  i {
    font-size: 9em;
    color: var(--orange-color);
  }
  h1 {
    font-weight: 1000;
    font-size: 1.8em;
  }
  h2 {
    font-size: 1.3em;
    font-weight: 500;
  }

  p {
    font-size: 1em;
  }
  width: 100%;
  button {
    padding: 15px;
    background-color: var(--orange-color);
    color: white;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Circle = styled.div`
  border: solid 10px var(--orange-color);
  width: min-content;
  border-radius: 50%;
`;
