import { ErrorDetails } from "../../types";
import { ReactElement, useEffect, useState } from "react";
import CustomButton from "../other/buttons/submit";
import styled from "styled-components";

type Props = {
  err?: ErrorDetails | null;
  fallback?: () => void;
};

export function ErrorModal({ err, fallback }: Props): ReactElement {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (err) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [err]);

  function handleReport(): void {
    if (err) {
      setLoading(true);
      //post error
      console.log(err);
    }
    close();
  }

  function close(): void {
    if (fallback) {
      fallback();
    }
  }

  return (
    <Main style={{ display: err?.displayedMsg ? "flex" : "none" }}>
      {err?.displayedMsg && (
        <Card>
          <CardHeader>
            <ImageContainer>
              <ImagePadding></ImagePadding>
            </ImageContainer>
          </CardHeader>
          <Content>
            <ErrorInfo>{err.displayedMsg ?? "Something went wrong"}</ErrorInfo>
            <ButtonsWrapper>
              {err.status !== 418 ? (
                <CustomButton
                  spinnerColor={"white"}
                  text={"Send Report"}
                  loading={loading}
                  onClick={handleReport}
                >
                  Send Report
                </CustomButton>
              ) : (
                <CustomButton text={"Close"} onClick={close}>
                  Close
                </CustomButton>
              )}
            </ButtonsWrapper>
          </Content>
        </Card>
      )}
    </Main>
  );
}

const Main = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  overflow: auto;
`;

const Card = styled.div`
  position: relative;
  width: 460px;
  min-width: 460px;
  height: fit-content;
  min-height: 200px;
  display: grid;
  grid-auto-flow: row;
  overflow: hidden;
  background: white;
  border-radius: 10px;
  border: 1px solid #56565a;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin: 10px 0;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 10px 0 !important;
    width: 300px;
    background-color: #ff9f00;
    border-radius: 4px;
    color: white;
    border: none;

    :hover {
      background-color: #ff9f00e2;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 76px;
`;

const ImagePadding = styled.div`
  padding: 8px;
  border-radius: 50%;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  background: white;
  overflow: hidden;
`;

const Logo = styled.img`
  border-radius: 50%;
  background: skyblue;
  width: 100px;
  height: 100px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Content = styled.div`
  position: relative;
  padding: 0 20px;
  height: fit-content;
`;

const CardHeader = styled.div`
  position: relative;
  height: 30px;
  background-color: #ff9f00;
  width: 100%;
`;

const ErrorInfo = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
`;
