import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAdminProductById, getProductsAdmin } from "../../../../api/Admin";
import EditButton from "../../../../components/other/buttons/edit";
import UpdateProductsFormAdmin from "./modal";
import { MediaItem, ProductCost } from "../../../../globaltypes";
import { Brand } from "../Brands";
import { Platform } from "../Platforms";
import { Category } from "../Categories";
import { getPlatforms } from "../../../../api/Platform";
import { getBrands } from "../../../../api/Brand";
import { getCategories } from "../../../../api/Category";
import AddButton from "../../../../components/other/buttons/add";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import { useLocation } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";

export type Product = {
  id: number | null;
  name: string;
  description: string;
  addDate: string;
  updateDate: string;
  gbpPrice: number;
  shortName: string;
  productUrl: string;
  supportedModels: string;
  costs: ProductCost[];
  type: string;
  seoTitle: string;
  seoDescription: string;
  media: MediaItem[];
  productId: string;
  licenceKey: string;
  downloadLink: string;
  integrationNoteLink: string;
  totalOrders: number;
  totalRevenue: number;
  totalActiveDrivers: number;
  driverPlatform: Platform | undefined;
  brand: Brand | undefined;
  categories: Category[];
  tags: string[];
  alternateId: number;
  excerpt: string;
  status: string;
  fileDownloadLimit: number;
  sku: string;
  notes: string;
  sales: number;
  earnings: number;
  originalCategories: string;
  originalTags: string;
  originalDateCreated: string;
  originalAuthor: string;
  platformName: string;
};

const initialProduct: Product = {
  addDate: "",
  alternateId: 0,
  brand: undefined,
  categories: [],
  costs: [
    { currencyType: "GBP", currencyValue: 0 },
    { currencyType: "AUD", currencyValue: 0 },
    { currencyType: "CAD", currencyValue: 0 },
    { currencyType: "EUR", currencyValue: 0 },
    { currencyType: "USD", currencyValue: 0 },
  ],
  description: "",
  downloadLink: "",
  driverPlatform: undefined,
  earnings: 0,
  excerpt: "",
  fileDownloadLimit: 0,
  gbpPrice: 0,
  id: null,
  integrationNoteLink: "",
  licenceKey: "",
  media: [],
  name: "",
  notes: "",
  originalAuthor: "",
  originalCategories: "",
  originalDateCreated: "",
  originalTags: "",
  productId: "",
  productUrl: "",
  sales: 0,
  seoDescription: "",
  seoTitle: "",
  shortName: "",
  sku: "",
  status: "DRAFT",
  supportedModels: "",
  tags: [],
  totalActiveDrivers: 0,
  totalOrders: 0,
  totalRevenue: 0,
  type: "PRODUCT",
  updateDate: "",
  platformName: "",
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [productToEdit, setProductToEdit] = useState<{
    data: Product;
    index: number;
  } | null>(null);
  const [showForm, setShowForm] = useState(false);
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (loading) {
      loadProducts();
    }
  }, [loading]);

  const loadProducts = async () => {
    getProductsAdmin().then((res) => {
      if (res.ok) {
        setProducts(res.data.objects);
        setLoading(false);
        loadPlatforms();
        loadBrands();
        loadCategories();
      }
    });
  };

  const loadPlatforms = async () => {
    getPlatforms().then((res) => {
      if (res.ok) {
        setPlatforms(res.data.objects);
      }
    });
  };

  const loadBrands = async () => {
    getBrands().then((res) => {
      if (res.ok) {
        setBrands(res.data.objects);
      }
    });
  };

  const loadCategories = async () => {
    getCategories().then((res) => {
      if (res.ok) {
        setCategories(res.data.objects);
      }
    });
  };

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Name",
        accessor: "name",
        sortBy: "name",
        columnSizing: { min: "400px", max: "400px" },
      },
      {
        text: "Status",
        accessor: "status",
        sortBy: "status",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Type",
        accessor: "type",
        sortBy: "type",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Platform",
        accessor: "platform",
        sortBy: "platform",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Brand",
        accessor: "brand",
        sortBy: "brand",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "GBP Price",
        accessor: "gbpPrice",
        sortBy: "gbpPrice",
        columnSizing: { min: "150px", max: "1fr" },
        cb: (data: any, value: any) => {
          return `£${value}`;
        },
      },
      {
        text: "Sales",
        accessor: "totalSales",
        sortBy: "totalSales",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Earnings",
        accessor: "totalEarnings",
        sortBy: "totalEarnings",
        columnSizing: { min: "150px", max: "1fr" },
        cb: (data: any, value: any) => {
          return `£${value}`;
        },
      },
      {
        text: "",
        columnSizing: { min: "60px", max: "60px" },
        cb: (data: any, value: any, index: number | undefined) => {
          return (
            <EditButton
              onClick={() => {
                handleRowClick(data, index);
              }}
              disabled={showForm === true}
            />
          );
        },
      },
    ],
    []
  );

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setProductToEdit({
              data: initialProduct,
              index: products.length + 1,
            });
            setShowForm(true);
            // setPlatformToDelete(null);
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  const handleRowClick = (data: Product, index?: number) => {
    if (index !== undefined && data.id !== undefined && data.id !== null) {
      getAdminProductById(data.id).then((res) => {
        if (res.ok) {
          if (res.data.objects.length > 0) {
            setProductToEdit({
              data: res.data.objects[0],
              index: index,
            });
            setShowForm(true);
          }
        }
      });
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <Wrapper>
      {productToEdit !== null && showForm && (
        <UpdateProductsFormAdmin
          product={productToEdit.data}
          close={handleClose}
          platforms={platforms}
          brands={brands}
          categories={categories}
          updateTable={() => {}}
        />
      )}
      {breakpoint > 920 ? (
        <TableV2
          data={products}
          headerRow={setupTable}
          loading={loading}
          tableHeader={tableHeader}
        />
      ) : (
        <MobileTable
          padding={40}
          data={products}
          headerRow={setupTable}
          loading={loading}
          tableHeader={tableHeader}
        />
      )}
    </Wrapper>
  );
};

export default Products;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;
