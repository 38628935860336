import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Platforms from "./Views/Platforms";
import Brands from "./Views/Brands";
import Categories from "./Views/Categories";
import Users from "./Views/Users";
import Orders from "./Views/Orders";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import Products from "./Views/Products";
import TransferLicence from "./Views/Requests/transferLicence";
import Vouchers from "./Views/Vouchers";
import { UserAdminContextProvider } from "./Context/AdminUserContext";
import { AdminProductContextProvider } from "./Context/AdminProductContext";
import useBreakpoint from "../../hooks/useBreakpoint";
import { Overlay } from "../../components/header/mainheader";
import Sidebar from "../../components/header/Sidebar";
import { AuthContext } from "../../context/AuthContext";

const Admin = () => {
  const nav = useNavigate();
  const location = useLocation();
  const [showHamburger, setShowHamburger] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { breakpoint } = useBreakpoint();
  const { isAdmin, loading } = useContext(AuthContext);

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  useEffect(() => {
    if (!isAdmin && !loading) {
      nav("/");
    }
    if (location.pathname === "/admin") {
      nav("/admin/Orders");
    }
  }, [nav, loading, isAdmin]);
  useEffect(() => {
    if (breakpoint < 900) {
      setShowHamburger(true);
    } else {
      setShowHamburger(false);
      setSidebarOpen(false);
    }
  }, [breakpoint]);
  return (
    <UserAdminContextProvider>
      <AdminProductContextProvider>
        <AdminWrap>
          <Header />
          {showHamburger && (
            <>
              <MenuIcon onClick={() => setSidebarOpen(true)}>
                <i className="ri-menu-unfold-line"></i>
              </MenuIcon>
            </>
          )}
          {sidebarOpen && (
            <MenuIcon
              onClick={() => {
                setSidebarOpen(false);
              }}
            >
              <i className="ri-menu-fold-line"></i>
            </MenuIcon>
          )}
          {sidebarOpen && (
            <Overlay
              isExpanded={sidebarOpen}
              onClick={() => setSidebarOpen(false)}
            />
          )}
          <SidebarWrapper isExpanded={sidebarOpen}>
            <Sidebar adminMode={true} isExpanded={sidebarOpen} />
          </SidebarWrapper>
          <Routes>
            <Route path="platforms" element={<Platforms />} />
            <Route path="brands" element={<Brands />} />
            <Route path="categories" element={<Categories />} />
            <Route path="users" element={<Users />} />
            <Route path="products" element={<Products />} />
            <Route path="orders" element={<Orders />} />
            <Route path="vouchers" element={<Vouchers />} />
            <Route path="licenceTransfer" element={<TransferLicence />} />
            <Route path="*" element={<Orders />} />
          </Routes>
        </AdminWrap>
      </AdminProductContextProvider>
    </UserAdminContextProvider>
  );
};

export default Admin;

const AdminWrap = styled.div`
  text-align: left;
  display: grid;
  grid-gap: 1em;
  max-height: 100vh;
  position: relative;
`;

const MenuIcon = styled.div`
  background-color: var(--grey-color);
  cursor: pointer;
  color: white;
  z-index: 10;
  display: grid;
  align-items: center;
  font-size: 1.3em;
  padding: 4px;
  border-radius: 4px;
  position: fixed;
  top: 5px;
  right: 5px;

  @media only screen and (max-width: 400px) {
    font-size: 1.1em;
  }
`;

const SidebarWrapper = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: ${(props) =>
    props.isExpanded ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-out;
`;
