import LabeledInput from "../../../../components/form/components/labeledInput";
import PhoneInput from "../../../../components/form/components/phoneinput";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Label from "../../../../components/form/components/label";
import Captcha from "../../../../components/captcha";
import { Link } from "react-router-dom";
import TextAreaInput from "../../../../components/form/components/textareainput";
import { Controller, useForm } from "react-hook-form";
import { Country } from "../../../../components/form/types";
import { CountryContext } from "../../../../context/CountryContext";
import CustomButton from "../../../../components/other/buttons/submit";

const ContactForm = () => {
  const { countries, countriesLoading } = useContext(CountryContext);
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const watchedCountry = watch("country", countries[233]);

  const handleCountryNew = (country: Country) => {
    setValue("country", country);
  };

  //TODO submit

  const onSubmit = (data: any) => {
    setSubmitting(true);
    console.log(data);
    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContactFormWrapper>
        <Controller
          name="name"
          control={control}
          defaultValue={""}
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <LabeledInput
              name={"name"}
              value={field.value}
              handlechange={(e) => {
                field.onChange(e.target.value);
              }}
              error={errors.name}
              label={{
                for: "name",
                required: true,
                text: "Your Name",
              }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue={""}
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <LabeledInput
              name={"email"}
              value={field.value}
              handlechange={(e) => {
                field.onChange(e.target.value);
              }}
              error={errors.email}
              label={{
                for: "email",
                required: true,
                text: "Your Email",
              }}
            />
          )}
        />
        {!countriesLoading && (
          <Controller
            name="phone"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <PhoneInput
                name={"phone"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                countries={countries}
                currentCountry={watchedCountry}
                handleCountry={handleCountryNew}
              />
            )}
          />
        )}
        <Controller
          name="subject"
          control={control}
          defaultValue={""}
          rules={{ required: "Subject is required" }}
          render={({ field }) => (
            <LabeledInput
              name={"subject"}
              value={field.value}
              handlechange={(e) => {
                field.onChange(e.target.value);
              }}
              error={errors.subject}
              label={{
                for: "subject",
                required: true,
                text: "Subject",
              }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          defaultValue={""}
          render={({ field }) => (
            <TextAreaWrap>
              <TextAreaInput
                name={"message"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                label={{
                  for: "message",
                  required: false,
                  text: "Message",
                }}
                style={{
                  minHeight: "100px",
                  maxHeight: "500px",
                  fontSize: "14px",
                }}
              />
            </TextAreaWrap>
          )}
        />
        <LabelWrap>
          <Label text={"Support"} for={"support"} required={true} />
        </LabelWrap>
        <Controller
          name="support"
          control={control}
          defaultValue={""}
          rules={{ required: "You must confirm this to proceed." }}
          render={({ field }) => (
            <CheckBoxWrap>
              <input
                type={"checkbox"}
                name={"support"}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
              />
              <ConfirmationText>
                <p>
                  I confirm this query is not related to support, billing or
                  payment (these queries should be submitted via{" "}
                  <Link to={"/support/form"}>www.intrinsicdev.com/support</Link>{" "}
                  - queries sent using this Contact Form will not be
                  automatically forwarded).
                </p>
              </ConfirmationText>
              {errors.support?.message && (
                <span>{errors.support.message as string}</span>
              )}
            </CheckBoxWrap>
          )}
        />
        <CaptchaWrap>
          <Captcha resultCallback={setCaptchaVerified} />
        </CaptchaWrap>
        <ContactFormBTNWrap>
          <CustomButton
            text={"Submit"}
            disabled={!captchaVerified}
            type="submit"
          />
        </ContactFormBTNWrap>
      </ContactFormWrapper>
    </form>
  );
};
export default ContactForm;

const LabelWrap = styled.div`
  display: grid;
  justify-content: start;
  justify-self: start;
`;

const ContactFormWrapper = styled.div`
  padding-top: 0.5em;
  display: grid;
  align-items: center;
  align-content: center;
  grid-column: 2/4;
  margin-left: 20px;
  grid-gap: 20px;
  input {
    width: 100%;
  }

  @media only screen and (max-width: 992px) {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    margin-right: 20px;
  }
`;

const ContactFormBTNWrap = styled.div`
  display: grid;
  justify-items: start;
  button {
    justify-self: center;
    width: 100%;
    max-width: 305px;
  }
`;

const ConfirmationText = styled.div`
  display: grid;
  justify-content: start;
  margin-right: 20px;
  text-align: left;
  justify-items: start;

  a {
    color: black;
    text-decoration: none;
  }

  a:hover {
    color: var(--orange-color);
    text-decoration: underline;
  }

  @media only screen and (max-width: 992px) {
    margin-right: 0;
  }
`;

const CaptchaWrap = styled.div`
  display: grid;
  justify-self: center;
`;

const TextAreaWrap = styled.div``;

const CheckBoxWrap = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto 1fr;
  text-align: left;
  align-items: start;
  grid-gap: 5px;
  line-height: 1.3;
  font-weight: 500;
  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: #ff9f00;
    text-decoration: underline;
  }

  span {
    grid-column: 1/-1;
    color: #990000;
    font-size: 0.7em;
  }

  @media only screen and (max-width: 992px) {
    a {
      color: #ff9f00;
    }
  }
`;
