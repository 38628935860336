import styled from "styled-components";
import { DataItem, Platforms } from "../../../../../types";
import DropdownItem from "../Item";
import { SidebarLink } from "../../../index";
import { parseSearch } from "../../../../../../../utils/utils";

type Props = {
  items: any;
  name: string;
};

function DropdownList(props: Props) {
  function isPlatform(obj: any): obj is Platforms {
    return Object.prototype.hasOwnProperty.call(obj, "categories");
  }

  const data = props.items;
  const supportLinks = props.items.ticket;
  const supportRequestLinks = props.items.requests;

  const titles = ["Category", "Featured Brands", "Support", "Value Packs"];

  if (isPlatform(data)) {
    return (
      <Wrapper>
        <Item>
          <SubMenuHeading>
            <b>{titles[0]}</b>
          </SubMenuHeading>
          {data.categories.map((category) => (
            <DropdownItem
              url={`${parseSearch(props.name)}${"/category"}/${parseSearch(
                category.shortName ? category.shortName : category.name
              )}`}
              name={category.shortName ? category.shortName : category.name}
            ></DropdownItem>
          ))}
        </Item>
        <Item>
          <SubMenuHeading>
            <b>{titles[1]}</b>
          </SubMenuHeading>
          {data.featuredBrands.map((featuredBrands) => (
            <DropdownItem
              name={featuredBrands.name}
              url={`${parseSearch(props.name)}${"/featured"}/${parseSearch(
                featuredBrands.shortName
                  ? featuredBrands.shortName
                  : featuredBrands.name
              )}`}
            ></DropdownItem>
          ))}
        </Item>
        <Item>
          <SubMenuHeading>
            <b>{titles[2]}</b>
          </SubMenuHeading>
          {data.supportList.map((supportList) => (
            <DropdownItem
              name={
                supportList.shortName ? supportList.shortName : supportList.name
              }
              url={
                supportList.productUrl
                  ? "downloads/" + supportList.productUrl
                  : ""
              }
            ></DropdownItem>
          ))}
        </Item>
        <Item>
          <SubMenuHeading>
            <b>{titles[3]}</b>
          </SubMenuHeading>
          {data.bundles.map((bundles) => (
            <DropdownItem
              name={bundles.shortName ? bundles.shortName : bundles.name}
              url={bundles.productUrl ? "downloads/" + bundles.productUrl : ""}
            ></DropdownItem>
          ))}
        </Item>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        {props.name === "Brands" && (
          <Item>
            {data.map((item: DataItem) => (
              <DropdownItem
                name={item.name}
                url={`${parseSearch(props.name)}/${parseSearch(
                  item.shortName ? item.shortName : item.name
                )}`}
              ></DropdownItem>
            ))}
          </Item>
        )}
        {props.name === "Driver Development" && (
          <Item>
            {data.map((item: SidebarLink) => (
              <DropdownItem name={item.name} url={item.url}></DropdownItem>
            ))}
          </Item>
        )}
        {props.name === "Support" && (
          <Item>
            <SubMenuHeading>
              <b>New Ticket</b>
            </SubMenuHeading>
            {supportLinks.map((item: SidebarLink) => (
              <DropdownItem name={item.name} url={item.url}></DropdownItem>
            ))}
            <SubMenuHeading>
              <b>Request</b>
            </SubMenuHeading>
            {supportRequestLinks.map((item: SidebarLink) => (
              <DropdownItem name={item.name} url={item.url}></DropdownItem>
            ))}
          </Item>
        )}
      </Wrapper>
    );
  }
}

export default DropdownList;

const SubMenuHeading = styled.span`
  color: var(--grey-color);
  text-align: left;
`;

const Wrapper = styled.div`
  background-color: white;
  margin-left: 30px;
  padding-top: 10px;

  a {
    color: var(--grey-color);
    text-decoration: none;

    :hover {
      color: var(--orange-color);
    }
  }

  display: grid;
  grid-gap: 10px;
  padding-left: 20px;
  border-left: solid 1px #d3d0d0;
  margin-bottom: 10px;
`;

const Item = styled.div`
  display: grid;
  box-sizing: border-box;
  border: solid 1px transparent;
  grid-gap: 10px;
`;
