import LabeledInput from "../../../../../components/form/components/labeledInput";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../../../components/other/buttons/save";
import CancelButton from "../../../../../components/other/buttons/cancel";
import { DateTime } from "luxon";
import styled from "styled-components";
import { InputRowProps } from "../../../../../components/table/Other/Types";
import { Controller, useForm } from "react-hook-form";
import { addBrand, editBrand } from "../../../../../api/Admin";

const InputRow = (props: InputRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const handleSave = async (data: any, id?: number) => {
    if (id) {
      return await editBrand({ ...data, seoTags: data.description, id: id });
    } else {
      return await addBrand({ ...data, seoTags: data.description });
    }
  };

  useEffect(() => {
    if (props.data) {
      const { url, name, description, imageUrl, seoTags } = props.data;
      setValue("name", name ? name : "");
      setValue("description", description ? description : "");
      setValue("url", url ? url : "");
      setValue("imageUrl", imageUrl ? imageUrl : "");
    }
  }, [props.data, setValue]);

  //TODO test add Row, switch refresh with same functionality as edit

  const onSubmit = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true);
        if (props.data) {
          await handleSave(data, props.data.id);
        } else {
          await handleSave(data);
        }
        props.refresh();
        props.close();
        props.updateInfo(
          `${props.data ? props.data.name : "Brand"} ${
            props.data ? "updated" : "added"
          } successfully`,
          props.data ? props.data.id : null,
          false
        );
      }
    } catch (e: any) {
      console.log(e);
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "Something went wrong, please try again"
        } `,
        props.data ? props.data.id : null,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const manualSubmit = handleSubmit(onSubmit);

  return (
    <>
      <td>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{ text: "Name", for: "name", required: true }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.name}
              disabled={props.buttonsDisabled}
              name="name"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{ required: "Description is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{
                text: "Description",
                for: "description",
                required: true,
              }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.description}
              disabled={props.buttonsDisabled}
              name="description"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="url"
          control={control}
          defaultValue=""
          rules={{ required: "URL is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{ text: "URL", for: "url", required: true }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.url}
              disabled={props.buttonsDisabled}
              name="url"
            />
          )}
        />
      </td>
      <td>
        {props.mobile && "Product Count: "}
        {props.data && props.data.productCount ? props.data.productCount : "-"}
      </td>
      <td>
        {props.mobile && "Add Date: "}
        {props.data && props.data.addDate
          ? DateTime.fromISO(props.data.addDate).toLocaleString()
          : "-"}
      </td>
      <td>
        <ButtonWrap>
          <SaveButton
            onClick={() => {
              manualSubmit();
            }}
            loading={loading}
            disabled={props.buttonsDisabled}
          />
          <CancelButton
            onClick={props.close}
            disabled={props.buttonsDisabled}
          />
        </ButtonWrap>
      </td>
    </>
  );
};

export default InputRow;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
