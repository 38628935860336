import ContactHeading from "../heading";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Address from "../address";
import ContactForm from "../../form";
import React, { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";

const ContactContent = () => {
  const { user } = useContext(AuthContext);
  const ScrollToForm = () => {
    const element = document.getElementById("ContactFormAnchor");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <ContactFormatted>
          <ContactUpperHeading>
            <ContactHeading heading={"INTRINSIC DEV"} />
          </ContactUpperHeading>
          <p>
            It’s easy to get in touch with the team at Intrinsic Dev. Please use
            the <button onClick={ScrollToForm}>Contact Form</button> below for
            manufacturer development queries, media enquiries and non-support
            related questions.
          </p>
          <h2>I need to Speak to Support</h2>
          {user && (
            <p>
              Please visit our dedicated{" "}
              <Link to={"/support/form"}>Support Page</Link>. All support
              queries must be submitted here to ensure we can quickly and
              effectively address your request.
            </p>
          )}
          {!user && (
            <p>
              Please{" "}
              <button>
                <Link to={"/login"}>Log in</Link>
              </button>{" "}
              and visit our dedicated <Link to={"/login"}>Support Page</Link>.
              All support queries must be submitted here to ensure we can
              quickly and effectively address your request.
            </p>
          )}
          <h2>I need to Transfer a Licence</h2>
          {user && (
            <p>
              Please use our{" "}
              <Link to={"/license-transfer/form"}>Transfer Licence Form</Link>{" "}
              to ensure this is dealt with quickly and efficiently by a member
              of our team.
            </p>
          )}
          {!user && (
            <p>
              Please{" "}
              <button>
                <Link to={"/login"}>Log in</Link>
              </button>{" "}
              to use our <Link to={"/login"}>Transfer Licence Form</Link> to
              ensure this is dealt with quickly and efficiently by a member of
              our team.
            </p>
          )}
          <h2>I’d like a Free Licence for my Demo System</h2>
          {user && (
            <p>
              No problem! We offer dealers free showroom licences for their
              public showrooms and testing systems. You will receive a pack of
              licences that will allow you to use our complete driver range for
              your chosen control platform. Simply complete our{" "}
              <Link to={"/showroom-licence/form"}>
                Showroom Licence Request Form
              </Link>
              .
            </p>
          )}
          {!user && (
            <p>
              No problem! We offer dealers free showroom licences for their
              public showrooms and testing systems. You will receive a pack of
              licences that will allow you to use our complete driver range for
              your chosen control platform. Please{" "}
              <button>
                <Link to={"/login"}>Log in</Link>
              </button>{" "}
              to use our{" "}
              <Link to={"/login"}>Showroom Licence Request Form</Link>.
            </p>
          )}
        </ContactFormatted>
        <Email>
          {" "}
          <EmailHeader>
            <ContactHeading heading={"EMAIL US"} />
          </EmailHeader>
          <EmailText href="mailto:info@intrinsicdev.com?subject=Website%20Contact%20Request">
            info@intrinsicdev.com
          </EmailText>{" "}
        </Email>
        <ContactHeading heading={"BY POST"} />
        <AddressWrapper>
          <Address />
        </AddressWrapper>
      </Sidebar>
      <ContactFormWrapper>
        <ContactForm />
      </ContactFormWrapper>
    </Wrapper>
  );
};
export default ContactContent;

const Wrapper = styled.div`
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 1fr 1fr;



  @media only screen and (max-width: 992px) {
    grid-gap: 0;
    grid-template-columns: 1fr;
`;

const ContactUpperHeading = styled.div`
  //grid-area: 1 / 1 / 2 / 2;
`;

const Sidebar = styled.div`
  grid-column: 1;
  display: grid;

  h2 {
    font-weight: 900;
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }
  @media only screen and (max-width: 920px) {
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const ContactFormatted = styled.div`
  a {
    color: var(--orange-color);
    text-decoration: underline;
  }

  p {
    line-height: 1.4;
    margin-bottom: 1.5em;
  }

  button {
    font-family: inherit;
    font-size: 1em;
    margin-bottom: 0.15em;
    color: #4b4949;

    a {
      color: #4b4949;
      text-decoration: none;
      font-family: inherit;
      font-size: 1em;
      margin-bottom: 0.15em;
      position: relative;
    }

    a:hover {
      text-decoration: underline;
      color: var(--orange-color);
    }

    @media only screen and (max-width: 992px) {
      color: var(--orange-color);
      text-decoration: underline;
      a {
        color: var(--orange-color);
      }
    }
  }

  button:hover {
    @media only screen and (max-width: 992px) {
      cursor: pointer;
    }
  }
`;

const ContactFormWrapper = styled.div`
  margin-left: 1em;
  grid-column: 2/4;
  @media only screen and (max-width: 920px) {
    grid-column: 1;
    margin-left: 0;
  }
`;

const EmailHeader = styled.a`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const EmailText = styled.a`
  color: #4b4949;
  text-decoration: none;
  font-family: inherit;
  font-size: 1em;
  position: relative;
  margin-top: 2em;
  margin-bottom: 2em;
  align-self: center;
`;

const AddressWrapper = styled.div``;

const Email = styled.div`
  margin-bottom: 1.5em;
`;
