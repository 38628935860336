import ContactContent from "./upper";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const Contact = () => {
  return (
    <ContentWrapper withLoc={true} style={{ marginBottom: "2em" }}>
      <ContactContent />
    </ContentWrapper>
  );
};

export default Contact;
