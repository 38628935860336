import styled from "styled-components";
import Number from "./Number";

type Props = {
  currentTab: number;
};

const Tabs = (props: Props) => {
  return (
    <Wrapper>
      <Tab>
        <Number number={1} isCurrent={props.currentTab === 1} />
        <p>My Cart</p>
      </Tab>
      <Tab>
        <Number number={2} isCurrent={props.currentTab === 2} />
        <p>Project Details</p>
      </Tab>
      <Tab>
        <Number number={3} isCurrent={props.currentTab === 3} />
        <p>My Details</p>
      </Tab>
      <Tab>
        <Number number={4} isCurrent={props.currentTab === 4} />
        Payment
      </Tab>
    </Wrapper>
  );
};
export default Tabs;

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  box-shadow: var(--shadow-standard);
  justify-items: center;
  height: min-content;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--grey-color);
  z-index: 3;
  @media only screen and (max-width: 920px) {
    box-shadow: unset;
  }
`;

const Tab = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  cursor: default;
`;
