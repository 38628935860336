import { useReducer } from "react";
import {
  AddressActionCase,
  AddressFormState,
  AddressValidationCase,
} from "../setup/address/addressTypes";
import {
  initialAddressValidityState,
  AddressReducer,
  AddressValidityReducer,
} from "../setup/address";
import { InputHandler, ValidationFunction } from "../types";
import { postAddress } from "../../../api/Addresses";
import { MyResponse } from "../../../api/Request";

export const useAddressForm = (initState?: AddressFormState) => {
  let [state, dispatch] = useReducer(
    AddressReducer,
    initState as AddressFormState
  );

  const [validationState, dispatchValidity] = useReducer(
    AddressValidityReducer,
    initialAddressValidityState
  );

  const handleValidation: ValidationFunction = (e) => {
    let validationCase = e.target.dataset
      .validationtype as keyof typeof AddressValidationCase;
    let field = e.target.dataset.field as string;
    if (validationCase === AddressValidationCase.VALIDATE_FORM) {
      dispatchValidity({
        value: state,
        case: AddressValidationCase.VALIDATE_FORM,
      });
      return;
    } else {
      dispatchValidity({
        field: field,
        value: e.target.value,
        case: AddressValidationCase[validationCase],
      });
    }
  };

  const handleFormValidation = (editState?: AddressFormState) => {
    dispatchValidity({
      value: editState ?? state,
      case: AddressValidationCase.VALIDATE_FORM,
    });
    return;
  };

  const handleInputChange: InputHandler = (e) => {
    let selectedCase: AddressActionCase;
    switch (e.target.dataset.case) {
      case AddressActionCase.GENERAL:
        selectedCase = AddressActionCase.GENERAL;
        break;
      case AddressActionCase.DISABLED:
        selectedCase = AddressActionCase.DISABLED;
        break;
      case AddressActionCase.ADDRESS_TYPE:
        selectedCase = AddressActionCase.ADDRESS_TYPE;
        break;
      default:
        selectedCase = AddressActionCase.GENERAL;
    }

    dispatch({
      field: e.target.name,
      value: e.target.value,
      case: selectedCase,
    });
  };

  /*


  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      field: e.target.name,
      value: e.target.value === "Other" ? "" : e.target.value,
      case: AddressActionCase.GENERAL,
    });
  };

  */

  /*{
    "name" : "testName",
    "email" : "sebastian.smith@intrinsicgrouplimited.com",
    "company" : "testCompany",
    "website" : "testWebsite",
    "ownerName" : "testOwnerName",
    "ownerEmail" : "testOwnerEmail@email.com",
    "macAddress" : "testMacAddress",
    "location" : "testLocation",
    "phone" : {
        "number" : "01234567891",
        "country" : {
            "name" : "Albania",
            "dialCode" : "1",
            "flagCode" : "1",
            "isoCode" : "1"
        }
    },
    "businessAddress" : {
        "firstLine" : "testBFirstLine",
        "secondLine" : "testBSecondLine",
        "city" : "testBCity",
        "state" : "testBState",
        "zipCode" : "testBZipCode",
        "country" : "testBCountry"
    },
    "showroomAddress" : {
        "firstLine" : "testSFirstLine",
        "secondLine" : "testSSecondLine",
        "city" : "testSCity",
        "state" : "testSState",
        "zipCode" : "testSZipCode",
        "country" : "testSCountry"
    }
}*/

  const handleSetDefault = async () => {
    console.log("Set Default not implemented: data: ", state);
    return;
  };

  const handleDelete = async () => {
    console.log("Delete not implemented: data: ", state);
    return;
  };

  const setEditState = (editState: AddressFormState) => {
    handleFormValidation(editState);
    dispatch({
      value: editState,
      case: AddressActionCase.EDIT,
    });
  };

  const handleSubmit = async (state: AddressFormState) => {
    //console.log(state)
    try {
      postAddress(state)
        .then((res: MyResponse) => {
          //console.log("got res: ", res);
        })
        .catch((err: any) => {
          //console.error(err);
        });
    } catch (e) {
      //TODO HANDLE ERROR
      //console.error(e);
    }
  };

  return [
    state,
    validationState,
    handleValidation,
    handleSubmit,
    handleInputChange,
    setEditState,
  ] as const;
};

export default useAddressForm;
