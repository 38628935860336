import React, { useState } from "react";

import styled from "styled-components";
import {
  AddressActionCase,
  AddressFormState,
  AddressValidationCase,
} from "../../../../../../components/form/setup/address/addressTypes";
import useAddressForm from "../../../../../../components/form/hooks/useAddressForm";
import LabeledInput from "../../../../../../components/form/components/labeledInput";
import AcceptButton from "../../../../../../components/other/buttons/accept";
import { AddressResponse } from "../../../../../../components/form/types";
import DropdownInput from "../../../../../../components/form/components/dropdownInput";
import { v4 as uuidv4 } from "uuid";

const EditAddressForm = (props: {
  disabled?: boolean;
  initState: AddressFormState;
  submitCallback: Function;
  toggleEdit: (value: boolean) => void;
  title?: string;
  userAddresses: AddressResponse[];
}): JSX.Element => {
  const [chosenAddress, setChosenAddress] = useState<
    AddressResponse | undefined
  >(undefined);
  const emptyStringify = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined) {
          obj[key] = "";
        }
      }
    }
    return obj;
  };
  let [
    formState,
    validationState,
    handleValidation,
    ,
    handleInputChange,
    setEditState,
  ] = useAddressForm(emptyStringify(props.initState));

  // update initial state from reducer with initState passed to hook
  // this is required for editing addresses
  const [showErrors, setShowErrors] = useState(false);
  //TODO: Add user Dropdown of their addresses.
  return (
    <Wrapper>
      {props.title && (
        <Heading>
          <HeadingText>{props.title}</HeadingText>
          <DropdownWrapper>
            <DropdownInput
              id={uuidv4()}
              handlechange={(e) => {
                let chosenAddress = props.userAddresses.find(
                  (address) => address.id?.toString() === e.target.value
                );
                if (chosenAddress) {
                  setEditState(chosenAddress);
                  setChosenAddress(chosenAddress);
                }
              }}
              value={chosenAddress?.id ? chosenAddress.id?.toString() : ""}
              items={props.userAddresses.map((address) => {
                return {
                  displayValue: address.addressName,
                  value: address.id?.toString() ?? "",
                };
              })}
            />
          </DropdownWrapper>

          <ButtonsWrapper>
            <AcceptButton
              onClick={() => {
                props.submitCallback(formState);
              }}
            />
          </ButtonsWrapper>
        </Heading>
      )}
      <Inner>
        <LabeledInput
          name={"addressName"}
          value={formState.addressName}
          handlechange={handleInputChange}
          label={{
            for: "addressName",
            required: true,
            text: "Address Name",
          }}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_ADDRESS_NAME}
          validationfield={"addressNameValid"}
          isvalid={validationState.addressNameValid}
          showerrors={showErrors}
        />

        <LabeledInput
          name={"firstLine"}
          value={formState.firstLine}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_FIRST_LINE}
          validationfield={"firstLineValid"}
          isvalid={validationState.firstLineValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "firstLine",
            required: true,
            text: "Line 1",
          }}
        />

        <LabeledInput
          isvalid
          name={"secondLine"}
          value={formState.secondLine}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_SECOND_LINE}
          validationfield={"secondLineValid"}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "secondLine",
            required: false,
            text: "Line 2",
          }}
        />

        <LabeledInput
          name={"city"}
          value={formState.city}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_CITY}
          validationfield={"cityValid"}
          isvalid={validationState.cityValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "city",
            required: true,
            text: "City",
          }}
        />
        <LabeledInput
          name={"state"}
          value={formState.state}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"stateValid"}
          isvalid={validationState.stateValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "state",
            required: true,
            text: "State/Region",
          }}
        />
        <LabeledInput
          name={"country"}
          value={formState.country}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"countryValid"}
          isvalid={validationState.countryValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "country",
            required: true,
            text: "Country",
          }}
        />
        <LabeledInput
          name={"zipCode"}
          value={formState.zipCode}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"zipCodeValid"}
          isvalid={validationState.zipCodeValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "zipCode",
            required: true,
            text: "Post Code",
          }}
        />
        {/*  <AddressTypeSelect*/}
        {/*    disabled={true}*/}
        {/*    selected={props.title ?? ""}*/}
        {/*    onChange={(e) => {*/}
        {/*      handleInputChange(e);*/}
        {/*      handleValidation(e);*/}
        {/*      formState.addressType = e.target.value;*/}
        {/*    }}*/}
        {/*  />*/}
        <LabeledInput
          name={"deliveryContact"}
          value={formState.deliveryContact}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_DELIVERY_CONTACT}
          validationfield={"deliveryContactValid"}
          isvalid={validationState.deliveryContactValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "deliveryContact",
            required: true,
            text: "Delivery Contact No.",
          }}
        />
        <LabeledInput
          name={"vatCode"}
          value={formState.vatCode}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_VAT_CODE}
          validationfield={"vatCodeValid"}
          isvalid={validationState.vatCodeValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "vatCode",
            required: true,
            text: "VAT Code",
          }}
        />
      </Inner>
    </Wrapper>
  );
};

export default EditAddressForm;

const Wrapper = styled.div`
  padding-bottom: 1.5em;
`;

const Inner = styled.div`
  grid-column: 1;
  display: grid;
  //grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  border-radius: 6px;
  background-color: white;
  align-self: center;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  //grid-template-columns: minmax(17em, 1fr) minmax(17em, 1fr);
`;

const Heading = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: center;
  font-size: 0.7em;
  justify-self: start;
  //margin-top: 2em;
  margin-bottom: 2em;
  //margin-left: 14px;
  //margin-right: 14px;
`;

const HeadingText = styled.div`
  font-size: 2em;
  font-weight: 500;
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DropdownWrapper = styled.div`
  margin-right: 1em;
  select {
    min-width: 16em;
  }
`;
