import { Link, useLocation } from "react-router-dom";
import "./location.css";
import { capitaliseString } from "../../utils/utils";
import styled from "styled-components";

type Props = React.ComponentProps<"div">;
const Location = (props: Props) => {
  const locationItem = useLocation();
  let uri = locationItem.pathname.split("/");
  const routeHistory = uri.map((location, i) => {
    if (location === "") {
      return (
        <Link className={"text-link"} to="/">
          {"Home >"}
        </Link>
      );
    }
    if (i === uri.length - 1) {
      return <p className={"dummy-link"}>{capitaliseString(location)}</p>;
    }

    return <p className={"dummy-link"}>{`${capitaliseString(location)} >`}</p>;
  });

  /*   <SidebarLink className={"text-link"} to={`/${location}`}>{`${capitaliseString(
        location
      )} >`}</SidebarLink>*/

  return <Wrapper style={props.style}>{routeHistory}</Wrapper>;
};

export default Location;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
  grid-auto-rows: min-content;
  color: black;
  grid-gap: 4px;
  justify-items: start;
  justify-content: start;
  grid-column: 1/-1;
  font-family: "Raleway", sans-serif;
  height: min-content;
  word-wrap: break-word;
  a {
    color: black;
  }
  @media only screen and (max-width: 920px) {
    grid-auto-flow: row;

    p {
      margin: 0;
    }
  }
`;
