import { useLocation } from "react-router-dom";
import CookieBannerModal from "./Modal";
import CookieBannerHidden from "./Hidden";

type Props = {
  accept: () => void;
  decline: () => void;
};
const CookieBanner = (props: Props) => {
  const location = useLocation();
  return location.pathname === "/privacy-policy" ? (
    <CookieBannerHidden {...props} />
  ) : (
    <CookieBannerModal {...props} />
  );
};

export default CookieBanner;
