import React, { useState } from "react";
import styled from "styled-components";
import { SupportTicket } from "../types";
import Window from "../../../Admin/Reusables/window";
import useInfo from "../../../Admin/Hooks/useInfo";
import SupportForm from "../form";
type Props = {
  support?: SupportTicket;
  close: () => void;
};

const UpdateSupportForm = (props: Props) => {
  const emptyStringify = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined) {
          obj[key] = "";
        } else if (typeof obj[key] === "object") {
          emptyStringify(obj[key]);
        }
      }
    }
    return obj;
  };
  const [showEdit, setShowEdit] = useState(!props.support?.id);
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
      noPlaceholder={true}
    >
      <Wrapper>
        <SupportForm
          support={props.support}
          modal={true}
          editing={showEdit}
          // toggleEdit={toggleEdit}
          // updateInfo={updateInfo}
          // resetInfo={resetInfo}
          // showProducts={showProducts}
          // setShowProducts={setShowProducts}
          // resetState={resetState}
        />
      </Wrapper>
    </Window>
  );
};

export default UpdateSupportForm;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  min-height: 25em;
  width: 75vw;
  max-width: 1100px;
`;
