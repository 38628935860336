import React from "react";
import styled from "styled-components";

import SidebarItem from "../../SidebarInner/Item";

function SidebarInner() {
  return (
    <Wrapper>
      <SidebarItem name={"Orders"} linkMode={true} url={"/admin/orders"} />
      <SidebarItem name={"Products"} linkMode={true} url={"/admin/products"} />
      <SidebarItem name={"Users"} linkMode={true} url={"/admin/users"} />
      <SidebarItem
        name={"Platforms"}
        linkMode={true}
        url={"/admin/platforms"}
      />
      <SidebarItem name={"Brands"} linkMode={true} url={"/admin/brands"} />
      <SidebarItem
        name={"Categories"}
        linkMode={true}
        url={"/admin/categories"}
      />
      <SidebarItem name={"Vouchers"} linkMode={true} url={"/admin/vouchers"} />
      <SidebarItem
        name={"Requests"}
        linkMode={true}
        url={"/admin/licencetransfer"}
      />
    </Wrapper>
  );
}

export default SidebarInner;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
`;
