import styled from "styled-components";
import Card from "../../../../Reusables/card";
import React, { useRef, useState } from "react";
import {
  UpdateProductFormState,
  UpdateProductImageActionCase,
} from "../../../../../../components/form/setup/admin/product/updateProductTypes";
import { InputHandler } from "../../../../../../components/form/types";
import MediaCarousel from "../../../../../../components/carousel/mediaCarousel";
import { MediaItem } from "../../../../../../globaltypes";
import Subheading from "../../../../../../components/subheading";
import ModalButtons from "../../../../Reusables/modalButtons";
import ImagePicker from "./ImagePicker";
import useUpdateProductImageForm from "../../../../../../components/form/hooks/admin/useUpdateProductImageForm";
import { emptyStringify } from "../../../../../../utils/utils";
import { deleteProductMediaAdmin } from "../../../../../../api/Admin";
import AdminDropDownMenu from "../../../../Reusables/dropdown";

interface Props {
  product: UpdateProductFormState;
  toggleEdit: () => void;
  edit: boolean;
  handleInputChange: InputHandler;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  resetInfo: () => void;
  updateProductMedia: (media: MediaItem, type: string) => void;
  hide?: boolean;
}

const initialMedia = {
  // id: 0,
  name: "",
  description: "",
  imageUrl: "",
  sequence: 0,
  mediaType: "IMAGE",
};

const ProductMediaSection = (props: Props) => {
  const [newMediaObj, setNewMediaObj] = useState(
    emptyStringify({ ...initialMedia, productId: props.product.id })
  );
  const [
    state,
    handleInputChange,
    handleSubmit,
    setEditState,
    handleFileInput,
    handleDropdownInputChange,
    removeFile,
  ] = useUpdateProductImageForm(newMediaObj);
  const [loading, setLoading] = useState<boolean>(false);
  const [newMedia, setNewMedia] = useState(false);
  const empty = useRef(true);

  function extractVideoID(url: string): string {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      return match[7];
    } else {
      return "";
      //todo error handle?
    }
  }

  const createNewMedia = () => {
    empty.current = false;
    setNewMedia(true);
    setEditState(newMediaObj);
  };

  const saveMedia = async () => {
    try {
      if (!loading) {
        setLoading(true);
        const res = await handleSubmit(newMedia);
        if (res) {
          props.updateInfo(
            `${res.data.name ? res.data.name : "Media"}` +
              " updated successfully",
            res.data.id,
            false
          );
          //TODO: new mediaObject or id/url needs to be returned from post response (since a new url is made).
          if (newMedia) {
            if (state.file !== undefined) {
              props.updateProductMedia(
                { ...state, id: 0 } as MediaItem,
                "ADD FILE"
              );
            } else if (state.imageUrl !== "") {
              props.updateProductMedia(
                { ...state, id: 0 } as MediaItem,
                "ADD URL"
              );
            }
          } else {
            props.updateProductMedia({ ...state } as MediaItem, "EDIT");
          }
        }
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "media update failed"
        }`,
        props.product.id,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteMedia = async (media: MediaItem) => {
    try {
      if (!loading) {
        setLoading(true);
        const res = await deleteProductMediaAdmin(media.id);
        props.updateInfo(
          `${res.data.name ? res.data.name : "Media"}` +
            " deleted successfully",
          res.data.id,
          false
        );
        props.updateProductMedia(media, "DELETE");
        setEditState(newMediaObj);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "media delete failed"
        }`,
        props.product.id,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      {props.hide ? (
        <InformationText>
          A product needs to exist to create/edit it's media
        </InformationText>
      ) : (
        <>
          <Heading>
            <Subheading h3={"Update Product"} h2={"Media"} />
            {!props.hide && (
              <ButtonWrapper>
                {props.edit && !newMedia && (
                  <NewButtonWrapper>
                    <i onClick={createNewMedia} className="ri-add-box-line"></i>
                  </NewButtonWrapper>
                )}
                <ModalButtons
                  editMode={props.edit}
                  loading={loading}
                  handleSave={saveMedia}
                  setEditMode={props.toggleEdit}
                  resetState={() => {
                    empty.current = true;
                    setNewMedia(false);
                    setEditState(newMediaObj);
                  }}
                  resetInfo={props.resetInfo}
                  disabled={false}
                />
              </ButtonWrapper>
            )}
          </Heading>

          {props.product.media.length > 0 && (
            <MediaCarousel
              images={props.product.media}
              handleClick={(media) => {
                setNewMedia(false);
                setEditState(emptyStringify(media));
                empty.current = false;
              }}
              extractId={extractVideoID}
              deleteMedia={deleteMedia}
              editing={props.edit}
            />
          )}
          {props.edit && !empty.current ? (
            newMedia ? (
              <InformationText>
                Create new media by{" "}
                {state.file !== undefined
                  ? "File"
                  : state.imageUrl === ""
                  ? "File or URL"
                  : "URL"}{" "}
                <p>
                  (Note - Media is submitted separate from the other product
                  tabs)
                </p>
              </InformationText>
            ) : (
              <InformationText>
                Edit existing media
                <p>
                  (Note - Media is submitted separate from the other product
                  tabs)
                </p>
              </InformationText>
            )
          ) : null}
          {!empty.current && (
            <Inner>
              {!state.file && !state.imageUrl && state.imageUrl === "" ? (
                <ImagePickerWrapper>
                  <ImagePicker
                    updateMedia={(file) => {
                      handleFileInput(file);
                    }}
                  />
                </ImagePickerWrapper>
              ) : (
                (state.file || state.imageUrl) &&
                newMedia && (
                  <DeleteWrapper>
                    <DeleteButton
                      onClick={(e) => {
                        removeFile();
                      }}
                    >
                      <i className="ri-close-circle-line"></i>
                    </DeleteButton>
                    <ProductImage
                      src={
                        state.file !== undefined
                          ? URL.createObjectURL(state.file)
                          : state.mediaType === "VIDEO"
                          ? `https://img.youtube.com/vi/${extractVideoID(
                              state.imageUrl
                            )}/hqdefault.jpg`
                          : state.imageUrl
                      }
                    />
                  </DeleteWrapper>
                )
              )}
              <Card
                heading={"Name"}
                text={state.name}
                editing={props.edit}
                inputSetup={{
                  value: state.name,
                  onChange: handleInputChange,
                  name: "name",
                }}
              />
              {!newMedia && (
                <Card heading={"Media Type"} text={state.mediaType ?? ""} />
              )}
              <Card
                heading={"Description"}
                text={state.description}
                editing={props.edit}
                inputSetup={{
                  value: state.description,
                  onChange: handleInputChange,
                  name: "description",
                }}
              />
              {state.file === undefined && (
                <>
                  <Card
                    heading={"Url"}
                    text={state.imageUrl}
                    editing={props.edit}
                    inputSetup={{
                      value: state.imageUrl,
                      onChange: handleInputChange,
                      name: "imageUrl",
                    }}
                  />
                </>
              )}
              {newMedia && state.imageUrl !== "" && (
                <AdminDropDownMenu
                  heading={"Media Type"}
                  items={["IMAGE", "VIDEO"]}
                  text={state.mediaType ?? "-"}
                  handleClick={(value) =>
                    handleDropdownInputChange(
                      value,
                      UpdateProductImageActionCase.UPDATE_MEDIA_TYPE
                    )
                  }
                  data-field={"mediaType"}
                  editMode={props.edit}
                />
              )}
            </Inner>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ProductMediaSection;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  height: 100%;
  position: relative;
  margin: 0.5em 2em 2em 2em;
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NewButtonWrapper = styled.div`
  color: var(--orange-color);
  i {
    cursor: pointer;
    font-size: 20px;
  }
  justify-self: end;
  margin-right: 15px;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  @media only screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;

const ProductImage = styled.img`
  //width: 200px;
  height: 5em;
`;

const ImagePickerWrapper = styled.div`
  grid-column: 1/-1;
`;

const DeleteButton = styled.div`
  position: absolute;
  right: 2px;
  top: 0;
  i {
    cursor: pointer;
    font-size: 1.2em;
  }
  :hover {
    color: red;
  }
`;

const DeleteWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const InformationText = styled.div`
  display: block ruby;
  font-size: 1em;
  p {
    font-size: 0.8em;
  }
  @media only screen and (max-width: 920px) {
    display: unset;
  }
`;
