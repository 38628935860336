import styled, { ThemeProvider } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import { Link, useParams } from "react-router-dom";
import useShowMenu from "../../../../hooks/useShowMenu";

interface Props {
  links: { to: string; text: string }[];
}
const SubMenu = (props: Props) => {
  const params = useParams();
  const [showMenu, toggleOn, toggleOff] = useShowMenu();
  const links = props.links.map((item) => {
    const colorTheme = {
      color: item.to === params["*"] ? "var(--orange-color)" : "white",
    };

    return (
      <ThemeProvider key={uuidv4()} theme={colorTheme}>
        <StyledLink data-menu={true} to={item.to}>
          {item.text}
        </StyledLink>
      </ThemeProvider>
    );
  });

  const dummyLinkTheme = {
    color:
      params["*"] === "licenceTransfer" ||
      params["*"] === "driver" ||
      params["*"] === "showroom"
        ? "var(--orange-color)"
        : "white",
  };
  return (
    <Wrapper
      onMouseOver={(e: React.MouseEvent<HTMLElement>) => {
        toggleOn(e);
      }}
      onMouseOut={toggleOff}
      data-menu={true}
    >
      <ThemeProvider theme={dummyLinkTheme}>
        <DummyLink>Requests</DummyLink>
      </ThemeProvider>
      {showMenu && <Menu>{links}</Menu>}
    </Wrapper>
  );
};

export default SubMenu;

const Wrapper = styled.div`
  width: max-content;
  position: relative;
  height: 100%;
  align-self: center;
  display: grid;
  align-items: center;
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: #4b4b4b;
  position: absolute;
  width: max-content;
  padding: 10px;
  right: 0%;
  top: 100%;
  border-top: solid 1px var(--orange-color);
  grid-gap: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    color: var(--orange-color);
  }
  color: ${(props) => props.theme.color};
`;

const DummyLink = styled.p`
  text-decoration: none;
  :hover {
    color: var(--orange-color);
  }
  cursor: pointer;
  align-self: center;
  color: ${(props) => props.theme.color};
`;
