import {
  Document,
  DocumentHeader,
  DocumentIndent,
} from "../../../../styles/styledComponents/document";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const IntrinsicPrivacy = () => {
  return (
    <ContentWrapper withLoc={true}>
      <Document>
        <DocumentHeader>PRIVACY POLICY</DocumentHeader>
        <p>
          Intrinsic Dev Limited (we, us or our) understand the importance of
          keeping your data secure, which is why we are committed to protecting
          and respecting your privacy at all times.
        </p>
        <p>
          This Privacy Policy, along with our Terms and Conditions and any other
          documents referred to in it, sets out the basis for the personal data
          we collect from you, how we process it and how you can amend it.
        </p>
        <p>
          Please read the following information carefully to ensure you
          understand all the ways in which your personal information is
          processed. This privacy notice supplements any other notices we may
          issue from time to time and is not intended to override them.
        </p>
        <p>
          If you are unhappy with anything in this Privacy Policy please either
          contact our data privacy manager or navigate to an alternative
          website. By visiting or purchasing from www.intrinsicdev.co.uk you are
          consenting to the practices described in this policy.
        </p>
        <DocumentHeader>1. Controller</DocumentHeader>
        <p>
          1.1 Intrinsic Dev Limited is the controller responsible for your
          personal data.
        </p>
        <p>
          1.2 We have appointed a data privacy manager who is responsible for
          overseeing any questions that arise from this Privacy Policy. If you
          need to contact this person, including any requests to exercise your
          legal rights, please do so using the details set out in section 2.
        </p>
        <DocumentHeader>2. Contact Details</DocumentHeader>{" "}
        <p>
          <b> Legal Entity: </b>Intrinsic Dev Limited
        </p>
        <p>
          <b>Associated trading names: </b> Intrinsic Dev
        </p>
        <p>
          <b>Email address: </b>gdpr@intrinsicdev.com
        </p>
        <p>
          <b>Postal address: </b>The Maltings, Allendale, NE47 9EE
        </p>
        <p>
          2.1 You have a legal right to make a complaint at any time to the
          Information Commissioner’s Office (ICO), the UK supervisory authority
          for data protection issues (www.ico.org.uk). We would, however,
          appreciate the chance to deal with your concerns before you approach
          the ICO so please contact us in the first instance.
        </p>
        <DocumentHeader>3. What data do we collect about you?</DocumentHeader>
        <p>
          3.1 Personal data, or personal information, means any information
          about an individual from which that person can be identified. It does
          not include data where the identity has been removed (anonymous data).
        </p>
        <p>
          3.2 We may collect, use, store and transfer different kinds of
          personal data about you:
        </p>
        <DocumentIndent>
          3.2.1 <b>Identity Data:</b> including first name, last name, job
          title, username or similar identifier, your signature, title, date of
          birth and gender.
        </DocumentIndent>
        <DocumentIndent>
          3.2.2 <b>Contact Data:</b> including billing address, email address
          and telephone numbers.
        </DocumentIndent>
        <DocumentIndent>
          3.2.3 <b>Financial Data:</b> including bank account and payment card
          details.
        </DocumentIndent>
        <DocumentIndent>
          3.2.4 <b>Transaction Data:</b> including details about payments to and
          from you and other details of products and services you have purchased
          from us.
        </DocumentIndent>
        <DocumentIndent>
          3.2.5 <b>Technical Data:</b> including internet protocol (IP) address,
          your login data, browser type and version, time zone setting and
          location, browser plug-in types and versions, operating system and
          platform and other technology on the devices you use to access our
          website.
        </DocumentIndent>
        <DocumentIndent>
          3.2.6 <b>Profile Data:</b> including your username and password,
          purchases made by you, your interests, preferences, feedback, survey
          responses, and correspondence.
        </DocumentIndent>
        <DocumentIndent>
          3.2.7 <b>Usage Data:</b> including information about how you use our
          website, products and services.
        </DocumentIndent>
        <DocumentIndent>
          3.2.8 <b>Marketing and Communications Data:</b> including your
          preferences in receiving marketing from us and your communication
          preferences.
        </DocumentIndent>
        <p>
          3.3 We may also collect, use and share Aggregated Data such as
          statistical or demographic data for any purpose. Aggregated Data may
          be derived from your personal data but is not considered personal data
          in law as this data does not directly or indirectly reveal your
          identity. For example, we may aggregate your Usage Data to calculate
          the percentage of users accessing a specific website feature. However,
          if we combine or connect Aggregated Data with your personal data so
          that it can directly or indirectly identify you, we treat the combined
          data as personal data which will be used in accordance with this
          privacy notice.
        </p>
        <p>
          3.4 Where we need to collect personal data by law, or under the terms
          of a contract we have with you and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services).
        </p>
        <DocumentHeader>4. How do we collect your data?</DocumentHeader>
        <p>
          4.1 There are various ways you can provide us with information,
          including, identity, contact, financial and profile data when browsing
          our website. This data is most likely provided when you:
        </p>
        <DocumentIndent>
          4.1.1 Enquire about a product or service;
        </DocumentIndent>
        <DocumentIndent>4.1.2 Purchase a product or service;</DocumentIndent>
        <DocumentIndent>4.1.3 Create an account on our website;</DocumentIndent>
        <DocumentIndent>4.1.4 Request marketing information;</DocumentIndent>
        <DocumentIndent>
          4.1.5 Fill in an online form or survey on our website; or
        </DocumentIndent>
        <DocumentIndent>4.1.6 Submit a ticket to our helpdesk.</DocumentIndent>
        <p>
          4.2 When you interact with our website we may automatically collect
          technical information about your equipment and browsing patterns. We
          collect this data by using cookies and similar technologies. Please
          refer to section 6 for more information on cookies.
        </p>
        <p>
          4.2.1 Much of this technical information is collected automatically by
          Google Analytics, their privacy policy can be found here:
          https://policies.google.com/privacy
        </p>
        <p>
          4.3 We may also receive information about you from various third
          parties, including social media sites, payment providers, credit
          reference agencies and analytics providers.
        </p>
        <p>
          4.4 Payment information is not stored by our website or viewed by
          anyone at Intrinsic Dev Limited. Payment is completely secure and
          dealt with by our payment provider Stripe. Please visit their website
          for more information or view their privacy policy here:
          https://stripe.com/gb/privacy.
        </p>
        <DocumentHeader>5. How we use your information</DocumentHeader>
        <p>
          5.1 We only use your personal data to the extent permitted by law.
          Most commonly it is used in the following circumstances:
        </p>
        <DocumentIndent>
          5.1.1 To complete obligations that arise from contracts (usually in
          the form of orders placed or support) entered in to between you and
          Intrinsic Dev. Information is used to deliver the product or service
          that you request from us.
        </DocumentIndent>
        <DocumentIndent>
          5.1.2 To comply with a legal or regulatory obligation
        </DocumentIndent>
        <DocumentIndent>
          5.1.3 To offer information about alternative products/services that
          are similar to those you have purchased or enquired about, provided
          consent is given.
        </DocumentIndent>
        <DocumentIndent>
          5.1.4 To provide you with information regarding promotions, new
          software lines and news about our company, provided consent is given.
        </DocumentIndent>
        <DocumentIndent>
          5.1.5 To improve our website and ensure content is presented in a
          suitable way for your computer/device.
        </DocumentIndent>
        <DocumentIndent>
          5.1.6 To administer our website, including trouble shooting, data
          analysis, testing and research. Historical data collected by Google
          Analytics is preserved in order that we can segment effectively for
          marketing analysis.
        </DocumentIndent>
        <DocumentIndent>
          5.1.7 To assist in keeping our website safe and secure.
        </DocumentIndent>
        <p>
          5.2 Generally we do not rely on consent as a legal basis for
          processing your personal data other than in relation to sending
          marketing communications to you via email. You have the right to
          withdraw consent to marketing at any time by contacting us or clicking
          the Unsubscribe link within any marketing email we send.
        </p>
        <p>
          5.3 We may process your personal data for more than one lawful ground
          depending on the specific purpose for which we are using your data.
          Please contact us if you need details about the specific legal ground
          we are relying on to process your personal data.
        </p>
        <p>
          5.4 We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so.
        </p>
        <DocumentHeader>6. Cookies</DocumentHeader>
        <p>
          6.1 In common with many other websites, we make use of cookies.
          Cookies assist us in ensuring our website looks as good as possible
          and is easy to use.
        </p>
        <p>
          6.2 A cookie is a small file of letters and numbers that a website
          puts on your computer (if you agree) so that it can remember something
          about you at a later time. Cookies allow us to recognise if you have
          visited our website before and remember your preferences. Cookies also
          help us to collect information on how you are using our website so we
          can deliver an improved service and ensure relevant information is
          provided to you.
        </p>
        <p>
          6.3 You can block cookies at any time by activating the setting on
          your internet browser that allows you to refuse the setting of all or
          some cookies. However, if you use your browser settings to block all
          cookies (including essential cookies) you may not be able to access
          parts or all of our website. Some of the cookies we use improve the
          functionality of our website, so if you choose to disable cookies on
          your browser, you might find that you can’t access some aspects of our
          website, or that parts of the website don’t function in the way that
          you might expect them to. We regret if you choose to disable cookies
          in your browser you will be unable to login, register or purchase from
          our website.
        </p>
        <p>
          6.4 We are required by law to provide you with a clear and
          comprehensive statement on how we use cookies, if the information
          provided here does not answer all of your queries please contact us at
          gdpr@intrinsicdev.com.
        </p>
        <p>
          6.5 We use cookies to help us distinguish you from other website
          users, login to secure areas of our site and complete purchases. Our
          cookies do not store information that could be used to identify you
          personally, such as your contact details or financial information.
        </p>
        <p>6.6 We use the following cookies:</p>
        <DocumentIndent>
          6.6.1 <b>Strictly Necessary Cookies:</b> These are necessary for the
          correct operation of our website. These include cookies to enable you
          to login to secure areas and use the shopping basket.
        </DocumentIndent>
        <DocumentIndent>
          6.6.2 <b>Analytical Cookies:</b> These allow us to recognise and count
          the number of visitors to our site and assess how these visitors
          interact with and navigate the pages. They help us improve the user
          experience and help us ensure you can find what you’re looking for.
        </DocumentIndent>
        <DocumentIndent>
          6.6.3 <b>Functionality Cookies:</b> These allow us to recognise you
          when you return to our website. We can then offer personalised content
          and remember your preferences.
        </DocumentIndent>
        <DocumentIndent>
          6.6.4 <b>Targeting Cookies:</b> These log your visit to our website,
          the pages you have visited and the links you’ve followed. This is used
          to make our website and subsequent advertising more relevant to you
          and may be shared with third parties for the purpose of targeted
          advertising.
        </DocumentIndent>
        <DocumentHeader>7. Disclosure of your information</DocumentHeader>
        <p>
          7.1 In some circumstances we may have to share your information with
          third parties:
        </p>
        <DocumentIndent>
          7.1.1 If it is necessary to complete any contract we enter in to with
          you, for example payment providers.
        </DocumentIndent>
        <DocumentIndent>
          7.1.2 To assist in the provision of our website, including analytics
          and search engine providers, review collectors and software providers.
        </DocumentIndent>
        <DocumentIndent>
          7.1.3 With third parties to whom we may choose to sell, transfer, or
          merge parts of our business or our assets. Alternatively, we may seek
          to acquire other businesses or merge with them. If a change happens to
          our business, then the new owners may only use your personal data in
          the same way as set out in this privacy notice.
        </DocumentIndent>
        <p>
          7.2 We require all third parties to respect the security of your
          personal data and to treat it in accordance with the law. We do not
          allow our third-party service providers to use your personal data for
          their own purposes and only permit them to process your personal data
          for specified purposes and in accordance with our instructions.
        </p>
        <p>
          7.3 Unless we are required to share your personal data by law, we will
          not otherwise share, sell or distribute any of the information you
          provide to us. In some circumstances we may have a duty to disclose
          personal data in order to enforce our Terms and Conditions, or to
          protect our rights, property, customers or staff. This includes
          exchanging information with other companies and organisations for the
          purposes of fraud prevention and credit risk reduction.
        </p>
        <p>
          7.4 We will get your express opt-in consent before we share your
          personal data with any company outside of Intrinsic Dev for marketing
          purposes.
        </p>
        <DocumentHeader>8. Where we store your data</DocumentHeader>
        <p>
          8.1 All our data is stored on industry leading external secure
          servers.
        </p>
        <p>
          8.2 We have put in place all reasonable and appropriate security
          measures to prevent your personal data from being accidentally lost,
          used or accessed in an unauthorised way, altered or disclosed. In
          addition, we limit access to your personal data to those employees,
          agents, contractors and other third parties who have a business need
          to know. They will only process your personal data on our instructions
          and they are subject to a duty of confidentiality.
        </p>
        <p>
          8.3 We will only retain your personal data for as long as necessary to
          fulfil the purposes we collected it for, including for the purposes of
          satisfying any legal, accounting, or reporting requirements. To
          determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
        </p>
        <p>
          8.4 Intrinsic Dev Limited is registered and operates from the UK
          within the European Economic Area (EEA). Many of our external third
          parties are based outside the EEA so their processing of your personal
          data will involve a transfer of data outside the EEA. Whenever we
          transfer your personal data out of the EEA, we ensure a similar degree
          of protection is afforded to it by ensuring at least one of the
          following safeguards is implemented:
        </p>
        <DocumentIndent>
          8.4.1 We will only transfer your personal data to countries that have
          been deemed to provide an adequate level of protection for personal
          data by the European Commission.
        </DocumentIndent>
        <DocumentIndent>
          8.4.2 Where we use certain service providers, we may use specific
          contracts approved by the European Commission which give personal data
          the same protection it has in Europe.
        </DocumentIndent>
        <DocumentIndent>
          8.4.3 Where we use providers based in the US, we may transfer data to
          them if they are part of the Privacy Shield which requires them to
          provide similar protection to personal data shared between the Europe
          and the US.
        </DocumentIndent>
        <p>
          8.5 You are responsible for keeping your account password secure, we
          ask you not to share this with anyone and to keep it confidential.
          Passwords can be changed by logging in to your account. We take no
          responsibility for unauthorized use of your account.
        </p>
        <DocumentHeader>9. Your rights</DocumentHeader>
        <p>
          9.1 This Privacy Policy is written in accordance with the Data
          Protection Act (UK) and the General Data Protection Regulation (EU).
        </p>
        <p>
          9.2 You have the right to purchase from our website without opting in
          to marketing from Intrinsic Dev. If you do choose to opt in to
          marketing you can withdraw consent at any time either by managing your
          preferences in your account or by utilising the unsubscribe option on
          any marketing email.
        </p>
        <p>
          9.3 You can check the data we hold about you either by logging in to
          your online account, or by emailing gdpr@intrinsicdev.com. The easiest
          way to make changes to your personal information is by logging in to
          your account. You can also email changes to us and these will be
          processed within 28 days.
        </p>
        <p>9.4 Right to erasure:</p>
        <DocumentIndent>
          9.4.1 Under the General Data Protection Regulation (GDPR) you have the
          right to have personal data erased
        </DocumentIndent>
        <DocumentIndent>
          9.4.2 If you wish to close your account or have your information
          removed please contact our Data Protection Officer by email at
          gdpr@intrinsicgrouplimited.com. Requests will be dealt with in a
          timely fashion but we reserve the right to take up to one month to
          respond to requests. Requests will be denied if we have a legal
          obligation to hold the data.
        </DocumentIndent>
        <p>
          9.5 You have a right to complain to a supervisory authority if you
          believe we have handled your information incorrectly. Please visit the
          Information Commissioner’s Office (ICO) website here
          https://ico.org.uk/ for more information. We would, however,
          appreciate the chance to deal with your concerns before you approach
          the ICO so please contact us in the first instance.
        </p>
        <DocumentHeader>10. Third party websites</DocumentHeader>
        <p>
          10.1 You may have arrived at our website through a referring website
          or manufacturer that utilizes our software. We have no control over
          the personal data these websites collect and advise you to read their
          terms of service and privacy policy to ensure you are aware what data
          they collect from you.
        </p>
        <p>
          10.2 Our site may contain links to other websites or applications. If
          you follow a link from our site to any other website it may allow
          third parties to collect or share data about you. Please note that
          these sites have their own privacy policies and we recommend you read
          these before using the website or service. We do not accept any
          responsibility or liability for the content of third party privacy
          notices.
        </p>
        <DocumentHeader>11. Remote Support</DocumentHeader>
        <p>
          11.1 We offer support for all our licensed drivers as per our Terms
          and Conditions. In some instances it is necessary for us to remotely
          access your computer or control platform, which we undertake through
          TeamViewer.
        </p>
        <p>
          11.2 We will only connect remotely to a system with your express
          consent, and upon receipt of your TeamViewer access password. Our
          contract is with you, if you require assistance with a system already
          installed in an end-users property it is your responsibility to gain
          consent from your client and inform them of the implications for their
          personal data. In providing us with password activated access you are
          confirming your acceptance of this Privacy Policy and affirming that
          all necessary consents have been granted.
        </p>
        <p>
          11.3 Any content of screens, calls, and video transmissions, or actual
          files that you exchange with Intrinsic Group Limited through our
          support features and functionalities will be transmitted by TeamViewer
          using end-to-end encryption technology. This means that TeamViewer
          will not be aware of the content and subject matter of such exchanges.
        </p>
        <p>
          11.4 We recommend you close all other applications on your computer
          before a remote session starts, as Intrinsic Dev Limited cannot be
          held responsible for any sensitive information inadvertently obtained.
        </p>
        <p>
          11.5 We recommend you are present during the entire session. In most
          cases we will be working together through the relevant query and will
          require your assistance to show us the issue and give you guidance.
        </p>
        <p>
          11.6 We will not extract any data during a remote support session
          without your express consent. After a remote session is complete, if a
          solution cannot be found, with your permission we may take a copy of
          the systems database for further investigation, for example to verify
          a possible software bug or repair database corruption.
        </p>
        <p>11.7 You can view TeamViewer’s Privacy Policy here:</p>
        <p>https://www.teamviewer.com/en/privacy-policy/</p>
        <DocumentHeader>12. Updates to our Privacy Policy</DocumentHeader>
        <p>12.1 This Privacy Policy was last updated on 18th February 2020.</p>
        <p>
          12.2 We reserve the right to amend this Privacy Policy. Please check
          back frequently to ensure you are aware of any updates or changes
          made.
        </p>
        <p>
          12.3 It is important that the personal data we hold about you is
          accurate and correct. Please keep us informed of any changes during
          your relationship with us.
        </p>
      </Document>
    </ContentWrapper>
  );
};

export default IntrinsicPrivacy;
