import LabeledInput from "../../../../../components/form/components/labeledInput";
import React, { useState } from "react";
import styled from "styled-components";
import { FormInputWrap } from "../../../../../components/form/styledComponents";
import { Controller } from "react-hook-form";

type ShowroomRadioProps = {
  name: string;
  control: any;
  errors: any;
  defaultValues: {
    location: string;
  };
};

const ShowroomRadio = (props: ShowroomRadioProps) => {
  const { name, control, errors, defaultValues } = props;
  const [showOther, setShowOther] = useState(false);
  const options = [
    "Public Showroom",
    "Testing System",
    "Employees Home",
    "Other",
  ];

  const handleRadioChange = (value: string) => {
    setShowOther(value === "Other");
  };

  return (
    <RadioWrap>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValues.location}
        rules={{ required: "Location is required" }}
        render={({ field }) => (
          <>
            {options.map((option, index) => (
              <div key={index}>
                <input
                  {...field}
                  type="radio"
                  value={option}
                  checked={field.value === option}
                  onChange={() => {
                    field.onChange(option);
                    handleRadioChange(option);
                  }}
                />{" "}
                {option}
              </div>
            ))}
          </>
        )}
      />
      {showOther && (
        <OtherDropDown>
          <Controller
            name={`${name}`}
            control={control}
            defaultValue=""
            rules={{
              required: 'Please provide additional details for "Other"',
            }}
            render={({ field }) => (
              <LabeledInput
                name={field.name}
                value={field.value}
                handlechange={(e) => field.onChange(e.target.value)}
                error={errors[`${name}`]}
                label={{
                  for: `${name}`,
                  required: true,
                  text: "Please provide details:",
                }}
              />
            )}
          />
        </OtherDropDown>
      )}
    </RadioWrap>
  );
};

export default ShowroomRadio;

const RadioWrap = styled.div`
  justify-self: start;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
  grid-gap: 5px;
  margin-top: 2px;
  ${FormInputWrap} {
    grid-column: 1;
    width: 100%;
  }
`;

const OtherDropDown = styled.div`
  margin-top: 15px;
  grid-column: 1/-1;
`;
