import styled from "styled-components";

const Banner = () => {
  return (
    <>
      <Wrapper>
        <Stars>
          <i className="ri-star-fill"></i>
          <i className="ri-star-fill"></i>
          <i className="ri-star-fill"></i>
          <i className="ri-star-fill"></i>
          <i className="ri-star-fill"></i>
          <p>Top Rated Support</p>
        </Stars>
        <Download>
          <i className="ri-download-fill"></i>
          <p>Instant Download</p>
        </Download>
        <Payment>
          <i className="ri-secure-payment-fill"></i>
          <p>Secure payment via Stripe</p>
        </Payment>
      </Wrapper>
    </>
  );
};

export default Banner;

const Stars = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  width: max-content;
`;

const Payment = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: max-content;
  justify-self: start;
`;

const Download = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: max-content;
  justify-self: center;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column: 1/-1;
  font-weight: bold;
  width: 100%;
  max-width: 920px;
  justify-self: center;
  align-self: start;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  i {
    font-size: 1.4em;
  }

  p {
    align-self: center;
    margin-left: 5px;
  }

  ${Payment} {
    justify-self: end;
  }

  @media only screen and (max-width: 920px) {
    ${Stars},
    ${Payment},
    ${Download} {
      justify-self: center;
    }
    grid-template-columns: 1fr;
    margin-bottom: 0;
    grid-gap: 5px;
  }
`;
