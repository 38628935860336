import LabeledInput from "../../../../components/form/components/labeledInput";
import PhoneInput from "../../../../components/form/components/phoneinput";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CheckBoxWrap } from "../../../../components/form/styledComponents";
import { Controller, useForm } from "react-hook-form";
import { Country } from "../../../../components/form/types";
import { CountryContext } from "../../../../context/CountryContext";
import AddressInputNew from "../../../../components/addressInputNew";
import Label from "../../../../components/form/components/label";
import ShowroomRadio from "./radio";
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/other/buttons/submit";
import { postShowroom } from "../../../../api/Forms";

const ShowroomForm = () => {
  const [loading, setLoading] = useState(false);
  const { countries, countriesLoading } = useContext(CountryContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!countriesLoading) {
      setValue("country", countries[233]);
    }
  }, [countriesLoading, countries, setValue]);

  const watchedCountry = watch("country", countries[233]);

  const handleCountryNew = (country: Country) => {
    setValue("country", country);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formattedPhone = { number: data.phone, country: data.country };
    const formattedData = { ...data, phone: formattedPhone };
    delete formattedData.country;
    const response = await postShowroom(formattedData);
    if (response.ok) {
      console.log(response);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Form>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <TopRow>
            <SectionLeft>
              <Header>Your Details</Header>
              <InputGrid>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Name is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name={"name"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={errors.name}
                      label={{
                        for: "name",
                        required: true,
                        text: "Your Name",
                      }}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name={"email"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      error={errors.email}
                      label={{
                        for: "email",
                        required: true,
                        text: "Your Email",
                      }}
                    />
                  )}
                />
                {!countriesLoading && (
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <PhoneInput
                        name={"phone"}
                        value={field.value}
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        countries={countries}
                        currentCountry={watchedCountry}
                        handleCountry={handleCountryNew}
                      />
                    )}
                  />
                )}
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Company Name is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name="companyName"
                      value={field.value}
                      handlechange={(e) => field.onChange(e.target.value)}
                      error={errors.companyName}
                      label={{
                        text: "Company Name",
                        for: "companyName",
                        required: true,
                      }}
                    />
                  )}
                />

                <Controller
                  name="website"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Website is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name="website"
                      value={field.value}
                      handlechange={(e) => field.onChange(e.target.value)}
                      error={errors.website}
                      label={{
                        text: "Website",
                        for: "website",
                        required: true,
                      }}
                    />
                  )}
                />
                <Controller
                  name="ownerName"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Owner name is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name="ownerName"
                      value={field.value}
                      handlechange={(e) => field.onChange(e.target.value)}
                      error={errors.ownerName}
                      label={{
                        for: "ownerName",
                        required: true,
                        text: "Business Owner Name",
                      }}
                    />
                  )}
                />
                <Controller
                  name="ownerEmail"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Owner email is required" }}
                  render={({ field }) => (
                    <LabeledInput
                      name="ownerName"
                      value={field.value}
                      handlechange={(e) => field.onChange(e.target.value)}
                      error={errors.ownerEmail}
                      label={{
                        for: "ownerEmail",
                        required: true,
                        text: "Business Owner Email",
                      }}
                    />
                  )}
                />
              </InputGrid>
            </SectionLeft>
            <Sectionright>
              <Header>Business Address</Header>
              <AddressInputNew
                required={true}
                name="businessAddress"
                control={control}
                errors={errors}
                defaultValues={{
                  line1: "",
                  line2: "",
                  city: "",
                  state: "",
                  postcode: "",
                  country: "",
                }}
              />
              <Controller
                name="macAddress"
                control={control}
                defaultValue=""
                rules={{ required: "Mac address is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name="macAddress"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.macAddress}
                    label={{
                      for: "macAddress",
                      required: true,
                      text: "Showroom Controller MAC addressPicker",
                    }}
                  />
                )}
              />
            </Sectionright>
          </TopRow>
          <BottomRow>
            <SectionLeft>
              <Header>Showroom Address</Header>
              <AddressInputNew
                name="showroomAddress"
                control={control}
                errors={errors}
                required={true}
                defaultValues={{
                  line1: "",
                  line2: "",
                  city: "",
                  state: "",
                  postcode: "",
                  country: "",
                }}
              />
            </SectionLeft>
            <Sectionright>
              <BottomGrid>
                <RadioWrap>
                  <TandCHeader>
                    <Label
                      text={
                        "Please advise where your Showroom Controller is located:"
                      }
                      for={"location"}
                      required={true}
                    />
                  </TandCHeader>
                  <ShowroomRadio
                    control={control}
                    name={"location"}
                    errors={errors}
                    defaultValues={{
                      location: "Public Showroom",
                    }}
                  />
                </RadioWrap>
                <CheckBoxOuter>
                  <TandCHeader>
                    <Label
                      text={"I agree to the terms and conditions"}
                      for={"location"}
                      required={true}
                    />
                  </TandCHeader>
                  <Controller
                    name="agree"
                    control={control}
                    defaultValue={""}
                    rules={{ required: "You must confirm this to proceed." }}
                    render={({ field }) => (
                      <CheckBoxWrap>
                        <input
                          type={"checkbox"}
                          name={"agree"}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                        <p>
                          You agree to Intrinsic Dev’s{" "}
                          <Link to={"/terms-conditions"}>
                            Terms and Conditions
                          </Link>{" "}
                          and you confirm that this project represents a bona
                          fide showroom. You agree to the address of your
                          showroom being publicly listed on this website by
                          Intrinsic Dev and your use of Intrinsic Dev drivers
                          publicly advertised. You understand Intrinsic Dev
                          reserve the right to revoke a showroom licence.
                        </p>
                      </CheckBoxWrap>
                    )}
                  />
                  {errors.agree?.message && (
                    <span>{errors.agree.message as string}</span>
                  )}
                </CheckBoxOuter>
              </BottomGrid>
            </Sectionright>
            <FormSubmitBTN>
              <CustomButton
                text={"Submit"}
                type={"submit"}
                loading={loading}
                style={{ width: "120px" }}
              />
            </FormSubmitBTN>
          </BottomRow>
        </FormWrapper>
      </form>
    </Form>
  );
};

export default ShowroomForm;

const Form = styled.div`
  display: grid;
  margin-bottom: 2em;
  grid-column: 2/4;
  label {
    justify-self: start;
  }
  @media only screen and (max-width: 920px) {
    grid-column: 1;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: start;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const TopRow = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row: 1;
  align-items: start;
  align-content: start;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const BottomRow = styled.div`
  display: grid;
  grid-column: 1/-1;
  grid-row: 2;
  align-items: start;
  align-content: start;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const SectionLeft = styled.div`
  grid-column: 1;
  display: grid;
  align-items: start;
  align-content: start;

  @media only screen and (max-width: 920px) {
    grid-column: 1/-1;
  }
`;

const Sectionright = styled.div`
  grid-column: 2;

  @media only screen and (max-width: 920px) {
    grid-column: 1/-1;
  }
`;

const TandCHeader = styled.div`
  margin-bottom: 10px;
  justify-self: start;
`;

const RadioWrap = styled.div`
  display: grid;
`;

const CheckBoxOuter = styled.div`
  display: grid;
  align-self: start;
  span {
    grid-column: 1/-1;
    color: #990000;
    font-size: 0.7em;
    justify-self: start;
    margin-top: 10px;
    margin-left: 20px;
  }
`;

const FormSubmitBTN = styled.div`
  height: max-content;
  display: grid;
  grid-column: 1/-1;
  justify-items: center;
  align-self: center;
  margin-top: -20px;
  @media only screen and (max-width: 920px) {
    margin-top: 0px;
  }
`;

const Header = styled.div`
  justify-self: start;
  text-align: left;
  margin-bottom: 10px;
`;

const InputGrid = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const BottomGrid = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  grid-gap: 20px;
  align-items: start;
  align-content: start;
  @media only screen and (max-width: 920px) {
    justify-content: center;
  }
`;
