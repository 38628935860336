import { MyResponse, RequestConfig, requestMiddleware } from "./Request";
import { AddressResponse } from "../components/form/types";

export async function getOrders(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/orders",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getOrdersById(orderNumber: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/orders/${orderNumber}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function createOrder(data: {
  purchaseOrderNumber: number;
  status: string;
  billingMethod: string;
  macAddress: string;
  billingAddress: Partial<AddressResponse>;
  deliveryAddress: Partial<AddressResponse>;
  orderId: number | null;
  addOrderLineRequests: {
    productId: number;
    quantity: number;
    localValue: string;
    localCurrency: string;
  }[];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/orders/test",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function getOrdersAdmin(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/orders/transfer?userId=${id}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function updateOrder(data: {
  purchaseOrderNumber: number;
  phoneNumber: string;
  status: string;
  billingMethod: string;
  macAddress: string;
  voucher: string;
  projectName: string;
  orderEmail: string;
  billingAddress: Partial<AddressResponse>;
  deliveryAddress: Partial<AddressResponse>;
  orderId: number | null;
  addOrderLineRequests: {
    productId: number;
    quantity: number;
    localValue: string;
    localCurrency: string;
  }[];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/orders/test",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function deleteOrder(params: {
  orderNumber: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/orders",
    method: "DELETE",
    params,
  };
  return requestMiddleware(config);
}
