import styled from "styled-components";

interface HeaderProps {
  h3: string;
  h2: string;
  optClass?: string;
}
const Subheading = ({ h3, h2 }: HeaderProps) => {
  return (
    <Wrapper>
      <h3>{h3}</h3>
      <h2>{h2}</h2>
    </Wrapper>
  );
};

export default Subheading;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
  justify-content: start;
  height: min-content;
  grid-gap: 5px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    align-self: end;
  }

  h3 {
    color: var(--orange-color);
    border-top: solid 4px var(--orange-color);
    font-weight: bold;
    align-self: end;
    width: max-content;
    justify-self: start;
    font-size: 18px;
  }

  @media only screen and (max-width: 920px) {
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 24px;
    }
  }
`;

const SubheadingEmpty = styled.div`
  color: var(--orange-color);
  border-top: solid 4px var(--orange-color);
  font-weight: bold;
  align-self: end;
  justify-self: start;

  font-size: 1.1em;
  content: "";
  width: 15px;
  margin-right: 5px;

  align-self: start;
`;

const SubheadingSpacer = styled.div`
  color: white;
`;
