import Card from "../../../../Admin/Reusables/card";
import styled from "styled-components";
import React, { useState } from "react";
import Subheading from "../../../../../components/subheading";
import DropdownInput from "../../../../../components/form/components/dropdownInput";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";

const Details = () => {
  const [editMode, setEditMode] = useState(true);

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const handleSave = (data: any) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSave}>
      <Wrapper>
        <Card heading={"Company Name"} text={"Company Name"} editing={false} />
        <Controller
          name="website"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Website"}
              text={field.value}
              editing={true}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "website",
              }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Email"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "email",
              }}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Phone"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "phoneNumber",
              }}
            />
          )}
        />
        <Controller
          name="supportPreference"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DropdownInput
              id={uuidv4()}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              items={[
                {
                  displayValue:
                    "Allow all users to view and respond to all tickets",
                  value: "all",
                },
                {
                  displayValue:
                    "Allow Admin users to view and respond to all tickets",
                  value: "admin",
                },
                {
                  displayValue: "Allow all users to view all tickets",
                  value: "allView",
                },
                {
                  displayValue:
                    "Allow users to only view and respond to their own tickets",
                  value: "selfOnly",
                },
              ]}
            />
          )}
        />
        <Controller
          name="orderPreference"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DropdownInput
              id={uuidv4()}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              items={[
                {
                  displayValue:
                    "Allow all users to view and manage all company orders",
                  value: "all",
                },
                {
                  displayValue:
                    "Allow Admin users to view and manage all company orders",
                  value: "admin",
                },
                {
                  displayValue: "Allow all users to view all company orders",
                  value: "allView",
                },
                {
                  displayValue:
                    "Allow users to only view and manage their own orders",
                  value: "selfOnly",
                },
              ]}
            />
          )}
        />
        <Heading>
          {" "}
          <Subheading h3={"Account"} h2={"Numbers"} />
        </Heading>
        <Controller
          name="control4"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Control4"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "control4",
              }}
            />
          )}
        />
        <Controller
          name="crestron"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Crestron"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "crestron",
              }}
            />
          )}
        />
        <Controller
          name="elan"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Elan"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "elan",
              }}
            />
          )}
        />
        <Controller
          name="rti"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"RTI"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "rti",
              }}
            />
          )}
        />
        <Controller
          name="savant"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Savant"}
              text={field.value}
              editing={editMode}
              inputSetup={{
                value: field.value,
                onChange: field.onChange,
                name: "savant",
              }}
            />
          )}
        />
      </Wrapper>
    </form>
  );
};

export default Details;

const Wrapper = styled.div`
  background-color: white;
  padding: 20px;
  grid-gap: 20px;
  display: grid;
`;

const Heading = styled.div`
  padding-left: 20px;
`;
