import LabeledInput from "../../../../components/form/components/labeledInput";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TextAreaInput from "../../../../components/form/components/textareainput";
import ClipLoader from "react-spinners/ClipLoader";
import ButtonSpinner from "../../../../components/other/buttons/spinner";
import { FormButton } from "../../../../components/form/styledComponents";
import { getSingleUserAdmin } from "../../../../api/Users";
import { getOrdersAdmin } from "../../../../api/Orders";
import { AuthContext } from "../../../../context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import DropdownInput from "../../../../components/form/components/dropdownInput";
import { v4 as uuidv4 } from "uuid";

type LicenceTransfer = {
  username: string;
  id: string;
  displayName: string;
  email: string;
};

type LicenceOrderInfo = {
  orderId: string;
  macAddress: string;
};

const LicenseTransferForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState<LicenceTransfer | null>(null);
  const [orderInfo, setOrderInfo] = useState<LicenceOrderInfo[] | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loadingSubmit, setSubmitLoading] = useState<boolean>(false);
  const [showFinalError, setShowFinalError] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const selectedOrderId = (macAddr: any) => {
    const OrderId =
      orderInfo &&
      orderInfo.find(
        (LicenceOrderInfo) => LicenceOrderInfo.macAddress === macAddr
      )?.orderId;
    return OrderId;
  };

  const onSubmit = async (data: any) => {
    console.log(data); /*
    Object.assign(state, {
      name: userInfo?.username,
      email: userInfo?.email,
      oldMacAddress: selected,
      orderNumber: selectedOrderId(selected),
    });

    try {
      setSubmitLoading(true);
      const res = await postLicenseTransfer(state);
      if (res.ok) {
        setSuccess(true);
        setSubmitLoading(false);
      }
    } catch (e: any) {
      setSuccess(false);
      setShowFinalError(true);
      setSubmitLoading(false);
    } */
  };

  const loadTransferUser = async (userID: number) => {
    getSingleUserAdmin(userID).then((res) => {
      if (res.ok) {
        setUserInfo(res.data);
        setLoading(false);
      }
    });
  };

  const loadUserOrders = async (userID: number) => {
    getOrdersAdmin(userID).then((res) => {
      if (res.ok) {
        setOrderInfo(res.data.orders);
        setLoading(false);
      }
    });
  };

  const capitaliseName = (name: string) => {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  useEffect(() => {
    if (loading && user) {
      const userID = parseInt(user.id);
      loadTransferUser(userID);
      loadUserOrders(userID);
    }
  }, [loading, user]);

  const MAC_REGEX = new RegExp("^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$");

  const validateNewMac = (value: string) => {
    if (value.length <= 0) return "This field is required";
    if (!MAC_REGEX.test(value)) return "Please separate with : or -";
    return true;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LicenseTransferFormWrapper>
        {userInfo && !loading && (
          <>
            <Controller
              defaultValue={capitaliseName(userInfo.username)}
              rules={{ required: "Name is required" }}
              name="name"
              control={control}
              render={({ field }) => (
                <LabeledInput
                  name="name"
                  value={field.value}
                  handlechange={(e) => field.onChange(e.target.value)}
                  label={{ for: "name", required: false, text: "Username" }}
                  error={errors.name}
                />
              )}
            />
            <Controller
              defaultValue={capitaliseName(userInfo.displayName)}
              name="displayName"
              control={control}
              render={({ field }) => (
                <LabeledInput
                  name="displayName"
                  value={field.value}
                  handlechange={(e) => field.onChange(e.target.value)}
                  label={{
                    for: "displayName",
                    required: false,
                    text: "Display Name",
                  }}
                  error={errors.displayName}
                />
              )}
            />
            <Controller
              defaultValue={capitaliseName(userInfo.email)}
              name="email"
              control={control}
              render={({ field }) => (
                <LabeledInput
                  name="email"
                  value={field.value}
                  handlechange={(e) => field.onChange(e.target.value)}
                  label={{ for: "email", required: false, text: "Email" }}
                  error={errors.email}
                />
              )}
            />
            <DropdownInputWrap>
              <Controller
                name="order"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <DropdownInput
                    id={uuidv4()}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                      /*
                      const macAddr = e.target.value;
                      setSelected(macAddr);
                      selectedOrderId(macAddr);*/
                    }}
                    items={
                      orderInfo
                        ? [
                            { value: "", displayValue: "Select Order" },
                            ...orderInfo.map((LicenceOrderInfo) => {
                              return {
                                value: LicenceOrderInfo.orderId,
                                displayValue: LicenceOrderInfo.orderId,
                              };
                            }),
                          ]
                        : []
                    }
                  />
                )}
              />
              {selected && <p>Current MAC Address</p>}
              {selected ? (
                <Controller
                  name="oldMac"
                  control={control}
                  render={({ field }) => (
                    <LabeledInput
                      name="oldMac"
                      value={field.value}
                      handlechange={(e) => field.onChange(e.target.value)}
                      label={{
                        for: "oldMac",
                        required: false,
                        text: "Old MAC Address",
                      }}
                      error={errors.oldMac}
                    />
                  )}
                />
              ) : (
                <OldMacInput>Current MAC Address</OldMacInput>
              )}
            </DropdownInputWrap>
            <Controller
              defaultValue={""}
              name="newMacAddress"
              control={control}
              rules={{
                validate: validateNewMac,
              }}
              render={({ field }) => (
                <LabeledInput
                  placeholder={"Example: 01-23-45-67-89-AB"}
                  name="newMacAddress"
                  value={field.value}
                  handlechange={(e) =>
                    field.onChange(e.target.value.toUpperCase())
                  }
                  label={{
                    for: "newMacAddress",
                    required: true,
                    text: "New MAC Address",
                  }}
                  error={errors.newMacAddress}
                />
              )}
            />
            <Controller
              defaultValue={""}
              name="reason"
              control={control}
              render={({ field }) => (
                <TextAreaInput
                  name="reason"
                  value={field.value}
                  handlechange={(e) => field.onChange(e.target.value)}
                  label={{
                    for: "reason",
                    required: true,
                    text: "Reason for Transfer",
                  }}
                />
              )}
            />
          </>
        )}

        {/* TODO - Either/or logic so can choose ORDERNO or MAC and opposite gets populated / becomes editable */}

        {true ? (
          <LicenceFormSubmitBTN>
            <FormButton type={"submit"} style={{ width: "305px" }}>
              {loading ? <ButtonSpinner /> : "Submit"}
            </FormButton>
          </LicenceFormSubmitBTN>
        ) : submitted && loadingSubmit ? (
          <SpinnerWrap>
            <ClipLoader size={30} color={"#a19e9e"} />
          </SpinnerWrap>
        ) : submitted && success && !loadingSubmit ? (
          <SuccessText>
            Licence Transfer Request submitted <i className="ri-check-line"></i>{" "}
          </SuccessText>
        ) : null}

        {!success && !loadingSubmit && selected && showFinalError ? (
          <ErrorText>Submit failed. Please try again or contact us</ErrorText>
        ) : (
          <Spacer>.</Spacer>
        )}
      </LicenseTransferFormWrapper>
    </Form>
  );
};

export default LicenseTransferForm;

const Form = styled.form`
  grid-column: 2/4;
  @media only screen and (max-width: 920px) {
    grid-column: 1;
  }

  select {
    color: var(--grey-color);
  }
`;

const LicenseTransferFormWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-left: 60px;
  @media only screen and (max-width: 920px) {
    margin-left: 0;
  }
`;

const LicenceFormSubmitBTN = styled.div`
  justify-self: center;
  //margin-top: 15px;
  height: max-content;
`;

const DropdownInputWrap = styled.div`
  border-radius: 5px;
  transform: translateY(-4px);
  display: grid;
  grid-gap: 20px;
`;

const OldMacInput = styled.div`
  display: grid;
  color: var(--grey-color);
  justify-content: start;
  align-items: center;
  font-size: 13px;
  height: 36px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  padding-left: 10px;
  cursor: default;
  background-color: white;
  border-radius: 4px;
`;

const SuccessText = styled.div`
  justify-self: center;
  padding: 12px;
  background-color: rgba(238, 238, 238, 0.1);
  width: max-content;
  display: grid;
  align-content: center;
  align-items: center;
  border: solid 1px rgba(100, 97, 97, 0.23);
  font-size: 1em;
  box-shadow: rgba(255, 159, 0) 0 0 0 2px;
  height: 15px;

  i {
    //color: #34b233;
    //color: #a5c90f;
    color: var(--white-color);
    font-size: 20px;
    background-color: var(--orange-color);
    border-radius: 50%;
    width: max-content;
    grid-area: 1 / 2 / 2 / 3;
    margin-left: 10px;
    padding: 1px;
  }
`;

const ErrorText = styled.div`
  background-color: #f34642;
  color: white;
  margin-top: 20px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const Spacer = styled.div`
  margin-top: 20px;
  color: white;
`;

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1/-1;
  height: 40px;
  display: grid;
  align-items: center;
`;
