import { useEffect, useState } from "react";
import styled from "styled-components";
import { AddressResponse } from "../../../../../../components/form/types";
import EditAddressForm from "../form/EditAddressForm";
import { initialAddressState } from "../../../../../../components/form/setup/address";

export interface AddressCardProps {
  editable?: boolean;
  address: AddressResponse;
  title?: string;
  saveAddress: (address: AddressResponse) => void;
  toggleEditing: (prev: boolean) => void;
  setShowAddresses?: (showAddresses: boolean) => void;
  changeUpdateMsg: (msg: string) => void;
  userAddresses: AddressResponse[];
}

const AddressCard = (props: AddressCardProps) => {
  const [isEditable, setIsEditable] = useState(props.editable);
  const toggleEdit = (value: boolean) => {
    setIsEditable(value);
    props.toggleEditing(value);
  };

  useEffect(() => {
    setIsEditable(props.editable);
  }, [props.editable]);

  return (
    <>
      {isEditable ? (
        <EditAddressForm
          title={props.title}
          initState={props.address ? props.address : initialAddressState}
          submitCallback={(address: AddressResponse) => {
            props.saveAddress(address);
            props.changeUpdateMsg(`${props.title} Updated`);
          }}
          toggleEdit={toggleEdit}
          userAddresses={props.userAddresses}
        />
      ) : (
        <DetailsWrap>
          <HeaderWrapper>
            <DetailHeading>{props.title ? props.title : ""}</DetailHeading>
          </HeaderWrapper>
          <DetailSubHeading>
            {props.address && props.address.addressName
              ? props.address.addressName
              : "No name found"}
          </DetailSubHeading>
          <Detail>
            {props.address && props.address.firstLine
              ? props.address.firstLine
              : "-"}
          </Detail>
          <Detail>
            {props.address && props.address.secondLine
              ? props.address.secondLine
              : "-"}
          </Detail>
          <Detail>
            {props.address && props.address.city ? props.address.city : "-"}
          </Detail>
          <Detail>
            {props.address && props.address.state ? props.address.state : "-"}
          </Detail>
        </DetailsWrap>
      )}
    </>
  );
};

export default AddressCard;

const DetailsWrap = styled.div`
  border: var(--border-main);
  width: 12em;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  grid-gap: 4px;
  padding-left: 12px;
`;

const Detail = styled.p`
  font-size: 0.8em;
`;

const HeaderWrapper = styled.div`
  font-size: 0.6em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DetailHeading = styled.p`
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  flex: 1;
`;

const DetailSubHeading = styled.p`
  font-weight: 600;
  font-size: 0.8em;
`;
