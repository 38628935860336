import Subheading from "../../../components/subheading";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { User } from "../../Admin/Views/Users";
import { getUser, getUserMenu } from "../../../api/Account";
import UpdateUserModal from "../User/UserModal";
import ContentWrapper from "../../../components/reusables/ContentWrapper";
import CompanyModal from "../User/CompanyModal";

const Account = () => {
  const { user, isAdmin, logout } = useContext(AuthContext);

  const [showUserForm, setShowUserForm] = useState(false);
  const [showCompanyForm, setShowCompanyForm] = useState(false);
  const [userData, setUserData] = useState<User | undefined>();
  //TODO: Change this to the new user menu endpoint when it is made.
  const [userMenu, setUserMenu] = useState({
    license: 0,
    showroom: 0,
    support: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserMenu();
  }, []);

  const fetchUserMenu = async () => {
    getUserMenu()
      .then((res: any) => {
        if (res.ok) {
          setUserMenu({
            license: res.data.obj.licenseTransfers ?? 0,
            showroom: res.data.obj.showroomLicenses ?? 0,
            support: res.data.obj.supportTickets ?? 0,
          });
        }
      })
      .catch(() => {
        setUserMenu({
          license: 0,
          showroom: 0,
          support: 0,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadUser = async () => {
    if (user !== null) {
      if (!userData) {
        getUser().then((res: any) => {
          if (res.ok) {
            setUserData(res.data);
            // setLoading(false);
          }
        });
      }
    }
  };

  const handleClose = () => {
    setShowUserForm(false);
    setShowCompanyForm(false);
  };
  return (
    <Wrapper>
      {loading ? (
        <></>
      ) : (
        <ContentWrapper withLoc={true}>
          <AccountSection>
            <AccountContainer>
              <AccountContent>
                <AccountRow>
                  <AccountTitle>
                    <Subheading h3={"YOUR"} h2={"ACCOUNT"}></Subheading>
                  </AccountTitle>
                  <AccountManage>MANAGE YOUR ACCOUNT DETAILS</AccountManage>
                  <div></div>
                  <AccountTxt>
                    <Link to={"/Orders"}>View Your Purchase History</Link>
                    <AccountSpacer> - </AccountSpacer> Download Invoices,
                    Licence Keys and Driver Files
                  </AccountTxt>
                  <div></div>
                  <AccountTxt>
                    <a
                      href={"#"}
                      onClick={() => {
                        if (!userData) {
                          loadUser();
                        }
                        setShowUserForm(true);
                      }}
                    >
                      Edit Your Profile
                    </a>
                    <AccountSpacer> - </AccountSpacer> Change your details,
                    reset your password
                  </AccountTxt>
                  <div></div>
                  <AccountTxt>
                    {userMenu.license > 0 ? (
                      <>
                        {/*TODO: Go to table of license transfers*/}
                        <Link to={"/license-transfer/Requests"}>
                          View Your License Transfers
                        </Link>
                        <AccountSpacer> - </AccountSpacer> View all of your
                        licenses transfers
                      </>
                    ) : (
                      <>
                        <Link to={"/license-transfer/form"}>
                          Request a Licence Transfer
                        </Link>
                        <AccountSpacer> - </AccountSpacer> Transfer a licence in
                        the event of kit failure
                      </>
                    )}
                  </AccountTxt>
                  <div></div>
                  <AccountTxt>
                    {userMenu.showroom > 0 ? (
                      <>
                        {/*TODO: Go to table of Showroom Licences*/}
                        <Link to={"/showroom-licence/Requests"}>
                          View Your Showroom Licenses
                        </Link>
                        <AccountSpacer> - </AccountSpacer> View all of your
                        showroom licenses
                      </>
                    ) : (
                      <>
                        <Link to={"/showroom-licence/form"}>
                          Request a Showroom Licence
                        </Link>
                        <AccountSpacer> - </AccountSpacer> Get a free copy of
                        all drivers for your showroom
                      </>
                    )}
                  </AccountTxt>
                  <div></div>
                  <AccountTxt>
                    {userMenu.support > 0 ? (
                      <>
                        {/*TODO: Go to table of Support Licences*/}
                        <Link to={"/support/Requests"}>
                          View Your Support Requests
                        </Link>
                        <AccountSpacer> - </AccountSpacer> View all of your
                        support requests
                      </>
                    ) : (
                      <>
                        <Link to={"/support/form"}>
                          Create a Support Request
                        </Link>
                        <AccountSpacer> - </AccountSpacer> Create a support
                        request
                      </>
                    )}
                  </AccountTxt>
                  <div></div>

                  {isAdmin && (
                    <AccountTxt>
                      <Link to={"/admin"}>Admin Tools</Link>
                      <AccountSpacer> - </AccountSpacer> Configure Intrinsicdev
                      website
                    </AccountTxt>
                  )}
                  <div></div>
                  {isAdmin && (
                    <AccountTxt>
                      <a
                        href={"#"}
                        onClick={() => {
                          setShowCompanyForm(true);
                        }}
                      >
                        Edit Company Profile
                      </a>
                      <AccountSpacer> - </AccountSpacer> Change your company
                      details
                    </AccountTxt>
                  )}
                </AccountRow>
              </AccountContent>
              <LogoutBTN>
                <Link
                  onClick={() => {
                    logout();
                  }}
                  to={"/"}
                >
                  Log out
                </Link>
              </LogoutBTN>
            </AccountContainer>
          </AccountSection>
        </ContentWrapper>
      )}
      {showUserForm && userData && (
        <UpdateUserModal
          user={userData}
          close={handleClose}
          updateTable={(userData) => {}}
        />
      )}
      {showCompanyForm && <CompanyModal close={handleClose} />}
    </Wrapper>
  );
};

export default Account;

const AccountSection = styled.div`
  width: 100%;
  @media only screen and (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  align-content: start;
  align-items: start;
  padding: 20px;
`;

const AccountContainer = styled.div``;

const AccountContent = styled.div`
  max-width: 100%;
  margin-bottom: 30px;
  font-size: 15px;

  @media only screen and (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    width: 20em;
  }
`;

const AccountRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: start;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

const AccountTitle = styled.div`
  display: grid;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  margin-bottom: 10px;

  @media only screen and (max-width: 992px) {
    padding: 0;
  }
`;

const AccountManage = styled.div`
  font-size: 2em;
  font-weight: 400;
  justify-self: start;
  margin-bottom: 25px;

  @media only screen and (max-width: 992px) {
    text-align: center;
    font-size: 1.1em;
    margin-top: 30px;
    font-weight: 500;
  }
`;

const AccountTxt = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: justify;
  justify-self: start;
  min-height: 1px;
  font-size: 15px;
  line-height: 1.48;
  margin-top: 13px;
  a {
    color: var(--orange-color);
    text-decoration: underline;
    line-height: 1.4;
  }

  @media only screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: auto;
    font-size: 1.2em;
  }
`;

const AccountSpacer = styled.div`
  margin-left: 5px;
  margin-right: 5px;

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const LogoutBTN = styled.button`
  a {
    text-decoration: none;
    color: #4b4949;
    padding: 7px 20px 7px 20px;
    display: grid;
    align-content: center;
    border: solid 1px rgba(100, 97, 97, 0.23);
    box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px;
    background-color: rgba(238, 238, 238, 0.51);
    font-size: 1.25em;
    cursor: pointer;
  }

  a:hover {
    background-color: #eeeeee;
  }

  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
`;
