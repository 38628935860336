import styled from "styled-components";
import Card from "../../../../Reusables/card";
import AdminDropDownMenu from "../../../../Reusables/dropdown";
import {
  UpdateProductActionCase,
  UpdateProductFormState,
} from "../../../../../../components/form/setup/admin/product/updateProductTypes";
import React from "react";
import { InputHandler } from "../../../../../../components/form/types";
import LongCard from "../../../../Reusables/longCard";

interface Props {
  product: UpdateProductFormState;
  toggleEdit: () => void;
  edit: boolean;
  handleInputChange: InputHandler;
  handleDropdownInputChange: (
    value: string,
    productCase?: UpdateProductActionCase
  ) => void;
}
//TODO: Add tags to the details screen (it's an array so use picker).
const ProductDetailsSection = (props: Props) => {
  return (
    <>
      <Inner>
        <Card
          heading={"Product ID"}
          text={props.product.productId}
          editing={props.edit}
          inputSetup={{
            value: props.product.productId,
            onChange: props.handleInputChange,
            name: "productId",
          }}
        />
        <Card
          heading={"Alternate ID"}
          text={props.product.alternateId.toString()}
          editing={props.edit}
          inputSetup={{
            value: props.product.alternateId,
            onChange: props.handleInputChange,
            name: "alternateId",
          }}
          case={UpdateProductActionCase.UPDATE_NUMBER}
        />
        <Card
          heading={"Name"}
          text={props.product.name}
          editing={props.edit}
          inputSetup={{
            value: props.product.name,
            onChange: props.handleInputChange,
            name: "name",
          }}
        />
        <Card
          heading={"Product URL"}
          text={props.product.productUrl}
          editing={props.edit}
          inputSetup={{
            value: props.product.productUrl,
            onChange: props.handleInputChange,
            name: "productUrl",
          }}
        />
        <AdminDropDownMenu
          heading={"Status"}
          items={["PUBLISH", "PRIVATE", "DRAFT"]}
          text={props.product.status ?? "-"}
          handleClick={(value) =>
            props.handleDropdownInputChange(
              value,
              UpdateProductActionCase.UPDATE_STATUS
            )
          }
          data-field={"status"}
          editMode={props.edit}
        />
        <Card
          heading={"Download SidebarLink"}
          text={props.product.downloadLink}
          editing={props.edit}
          inputSetup={{
            value: props.product.downloadLink,
            onChange: props.handleInputChange,
            name: "downloadLink",
          }}
        />
        <AdminDropDownMenu
          heading={"Type"}
          items={["PRODUCT", "BUNDLE"]}
          text={props.product.type ?? "-"}
          handleClick={(value) =>
            props.handleDropdownInputChange(
              value,
              UpdateProductActionCase.UPDATE_TYPE
            )
          }
          data-field={"type"}
          editMode={props.edit}
        />
        <Card
          heading={"Short Name"}
          text={props.product.shortName}
          editing={props.edit}
          inputSetup={{
            value: props.product.shortName,
            onChange: props.handleInputChange,
            name: "shortName",
          }}
        />
        <Card
          heading={"SEO Title"}
          text={props.product.seoTitle}
          editing={props.edit}
          inputSetup={{
            value: props.product.seoTitle,
            onChange: props.handleInputChange,
            name: "seoTitle",
          }}
        />
        <Card
          heading={"Licence Key"}
          text={props.product.licenceKey}
          editing={props.edit}
          inputSetup={{
            value: props.product.licenceKey,
            onChange: props.handleInputChange,
            name: "licenceKey",
          }}
        />
        <LongCard
          heading={"Description"}
          text={props.product.description}
          editing={props.edit}
          inputSetup={{
            value: props.product.description,
            onChange: props.handleInputChange,
            name: "description",
          }}
          markdown={true}
        />
        <LongCard
          heading={"Supported Modals"}
          text={props.product.supportedModels}
          editing={props.edit}
          inputSetup={{
            value: props.product.supportedModels,
            onChange: props.handleInputChange,
            name: "supportedModels",
          }}
          markdown={true}
        />
        <LongCard
          heading={"Notes"}
          text={props.product.notes}
          editing={props.edit}
          inputSetup={{
            value: props.product.notes,
            onChange: props.handleInputChange,
            name: "notes",
          }}
        />
        <LongCard
          heading={"SEO Description"}
          text={props.product.seoDescription}
          editing={props.edit}
          inputSetup={{
            value: props.product.seoDescription,
            onChange: props.handleInputChange,
            name: "seoDescription",
          }}
        />
      </Inner>
    </>
  );
};

export default ProductDetailsSection;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
`;
