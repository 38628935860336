export type DataItem = {
  id: number;
  name: string;
  description: string | null;
  productUrl: string | null;
  addDate: string;
  type: string;
  updateDate: string;
  imageUrl: string | null;
  categoryType?: string;
  shortName?: string;
};

export interface Platforms extends DataItem {
  categories: DataItem[];
  supportList: DataItem[];
  featuredBrands: DataItem[];
  bundles: DataItem[];
}

export const defaultPlatforms: Platforms = {
  addDate: "",
  bundles: [],
  categories: [],
  description: null,
  featuredBrands: [],
  id: 0,
  imageUrl: null,
  name: "",
  supportList: [],
  type: "",
  updateDate: "",
  productUrl: null,
};

/*
{
  "id": 10,
  "name": "Sennheiser",
  "innerDescription": null,
  "url": null,
  "addDate": "2022-09-27T13:07:59.925+00:00",
  "updateDate": "2022-09-27T13:07:59.925+00:00",
  "imageUrl": "https://source.unsplash.com/random/300x300"
} */
