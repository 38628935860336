import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getUser(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/users",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getUserMenu(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/users/menu",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function putUpdateUser(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/users",
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}
