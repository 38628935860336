import styled from "styled-components";
import Window from "../../../Reusables/window";
import { Platform } from "../index";
import Details from "./details";
import React from "react";
import useInfo from "../../../Hooks/useInfo";

interface Props {
  close: () => void;
  platform: Platform;
  updateTable: (data: any) => void;
}

const UpdatePlatformModal = (props: Props): JSX.Element => {
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();

  return (
    <Window
      close={props.close}
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      showInfo={showInfo}
    >
      <Wrapper>
        <Details
          platform={props.platform}
          updateTable={props.updateTable}
          updateInfo={updateInfo}
          resetInfo={resetInfo}
        />
      </Wrapper>
    </Window>
  );
};

export default UpdatePlatformModal;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;
