import { useState } from "react";

const useInfo = () => {
  const [info, setInfo] = useState<string>("");
  const [infoId, setInfoId] = useState<number | null>(null);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);
  const [index, setIndex] = useState<number | null>(null);

  const updateInfo = (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number
  ) => {
    setInfo(message);
    setInfoId(id);
    setFail(fail);
    index && setIndex(index);
    setShowInfo(true);
  };

  const resetInfo = () => {
    setInfo("");
    setInfoId(null);
    setFail(false);
    setIndex(null);
    setShowInfo(false);
  };

  return [fail, info, infoId, showInfo, resetInfo, updateInfo, index] as const;
};
export default useInfo;
