import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePaginate = (data: any, elementsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(elementsPerPage);
  const [pages, setPages] = useState<number>(0);
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const [toDisplay, setToDisplay] = useState<any[]>([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [location]);

  useEffect(() => {
    if (data.length > 0) {
      setPages(Math.ceil(data.length / pageSize));
      setToDisplay(data.slice(0, elementsPerPage + 1));
    }
  }, [data, pageSize]);

  useEffect(() => {
    setToDisplay(data.slice(indexOfFirstItem, indexOfLastItem));
  }, [currentPage]);

  const updateCurrentPage = (pageNum: number) => {
    setCurrentPage(pageNum);
  };

  return [currentPage, pages, updateCurrentPage, toDisplay] as const;
};

export default usePaginate;
