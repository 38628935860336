import banner from "../../../../assets/images/about/who-we-work-with2.jpg";
import styled from "styled-components";
import Subheading from "../../../../components/subheading";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const AboutBanner = () => {
  return (
    <ContentWrapper>
      {" "}
      <Banner>
        <Subheading
          h3={"PLATFORMS"}
          h2={"WE WORK WITH"}
          optClass={"carousel-banner-about"}
        />
        <img src={banner} alt={"who we work with"} />
      </Banner>
    </ContentWrapper>
  );
};

export default AboutBanner;

const Banner = styled.div`
  margin-top: 4em;
  margin-bottom: 4em;
  background-color: white;
  padding: 4em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  img {
    height: auto;
    width: 100%;
    max-width: 80vw;
    min-width: 15vw;
  }

  @media only screen and (max-width: 920px) {
    display: grid;
    padding: 20px;
    justify-items: center;
    img {
      justify-self: center;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
`;
