import styled, { ThemeProvider } from "styled-components";
import React, { useRef } from "react";

export interface InfoProps {
  message: string;
  hasFailed: boolean;
  resetInfo: () => void;
}

const InfoRow = (props: InfoProps) => {
  const rowRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const theme = {
    color: props.hasFailed ? "#f34642" : "#31a82a",
  };

  return (
    <InfoRowWrap ref={rowRef}>
      <ThemeProvider theme={theme}>
        <InfoWrapInner>
          {props.message}{" "}
          <CloseButton onClick={props.resetInfo}>
            <i className="ri-close-line" />
          </CloseButton>
        </InfoWrapInner>
      </ThemeProvider>
    </InfoRowWrap>
  );
};

export default InfoRow;

const InfoRowWrap = styled.div`
  height: 20px;
  display: grid;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 10px;
  @media only screen and (min-width: 900px) {
    grid-column: 1/-1;
    margin-bottom: unset;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 2px;
  left: 6px;
  font-size: 1.1em;
  cursor: pointer;
`;

const InfoWrapInner = styled.div`
  background-color: ${(props) => props.theme.color};
  height: 100%;
  display: grid;
  align-content: center;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  color: white;
  font-size: 0.9em;
  border-radius: 4px;
  @media only screen and (min-width: 900px) {
    border-radius: unset;
  }
`;
