import { Product } from "../../../../../globaltypes";
import styled from "styled-components";

type Props = {
  product: Product;
};

const Item = (props: Props) => {
  return (
    <Wrapper>
      <ProductImage src={props.product.media[0].imageUrl} />
      <Detail>{props.product.name}</Detail>
      <Detail>£{props.product.gbpPrice}</Detail>
    </Wrapper>
  );
};

export default Item;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 120px auto;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 0.7em;
  gap: 10px;
  text-align: left;
`;

const Detail = styled.p`
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const ProductImage = styled.img`
  height: 6em;
`;
