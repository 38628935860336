import React, { useEffect, useMemo, useState } from "react";
import brand1 from "../../../assets/images/brands/kef.jpg";
import brand2 from "../../../assets/images/brands/naim.jpg";
import brand3 from "../../../assets/images/brands/meridian.jpg";
import brand4 from "../../../assets/images/brands/qmotion.jpg";
import brand5 from "../../../assets/images/brands/atlona.jpg";
import brand6 from "../../../assets/images/brands/bluestream.jpg";
import brand7 from "../../../assets/images/brands/hdanywhere.jpg";
import brand8 from "../../../assets/images/brands/pulseEight.jpg";
import brand9 from "../../../assets/images/brands/wyrestorm.jpg";
import brand10 from "../../../assets/images/brands/lode.jpg";
import brand11 from "../../../assets/images/brands/dynaudio.jpg";
import brand12 from "../../../assets/images/brands/lyngdorf.jpg";
import brand13 from "../../../assets/images/brands/insteon.jpg";
import brand15 from "../../../assets/images/brands/vitrea.jpg";
import brand16 from "../../../assets/images/brands/demopad.jpg";
import useCarousel from "../../../hooks/useCarousel";
import CarouselSegment from "./segment";
import "../../../styles/carousel.css";
import "./brandcarousel.css";
import useBreakpoint from "../../../hooks/useBreakpoint";

const BrandCarousel = () => {
  // arrange images how you like, currently needs to be split into equal sections
  const data = useMemo(() => {
    return [
      { src: brand1, id: 1, name: "kef" },
      { src: brand2, id: 2, name: "naim" },
      { src: brand3, id: 3, name: "meridian" },
      { src: brand4, id: 4, name: "qmotion" },
      { src: brand5, id: 5, name: "atlona" },
      { src: brand6, id: 6, name: "bluestream" },

      { src: brand7, id: 7, name: "hdanywhere" },
      { src: brand8, id: 8, name: "pulseEight" },
      { src: brand9, id: 9, name: "wyrestorm" },
      { src: brand10, id: 10, name: "lode" },
      { src: brand11, id: 11, name: "dynaudio" },
      { src: brand12, id: 12, name: "lyngdorf" },

      { src: brand13, id: 13, name: "insteon" },
      { src: brand15, id: 14, name: "vitrea" },
      { src: brand16, id: 15, name: "demopad" },
    ];
  }, []);
  type dataType = { src: string; id: number; name: string };
  const [columns, setColumns] = useState<number>(6);
  const [toDisplay, setToDisplay] = useState(split(data, 6));
  const { breakpoint } = useBreakpoint();

  function split(
    array: { src: string; id: number; name: string }[],
    n: number
  ) {
    let [...arr] = array;
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }

  const resplit = (n: number) => {
    let arr: dataType[];
    [...arr] = data;
    const res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  };
  const {
    handleClick,
    left,
    right,
    current,
    expandLeft,
    expandRight,
    showCurrent,
    setRefresh,
  } = useCarousel(toDisplay);
  useEffect(() => {
    let rightVal: string = `translateX(${
      (left.length / current.length) * 100
    }%)`;

    document.documentElement.style.setProperty("--right-value", rightVal);
    let leftVal: string = `translateX(-${
      (right.length / current.length) * 100
    }%)`;

    document.documentElement.style.setProperty("--left-value", leftVal);
  }, [right, left, columns]);

  useEffect(() => {
    if (breakpoint >= 1200) {
      setToDisplay(resplit(6));
      setColumns(6);
    }
    if (breakpoint < 1200 && breakpoint > 800) {
      setToDisplay(resplit(4));
      setColumns(4);
    }
    if (breakpoint <= 800 && breakpoint >= 500) {
      setToDisplay(resplit(1));
      setColumns(1);
    }
    if (breakpoint < 500) {
      setToDisplay(resplit(1));
      setColumns(1);
    }
  }, [breakpoint]);

  return (
    <div className="carousel-wrapper">
      <i
        className="ri-arrow-left-circle-fill brand-arrow-left"
        data-arrow={"left"}
        onClick={handleClick}
      ></i>
      <div className="carousel-container">
        <CarouselSegment
          imageData={left}
          class={`image-container left-images ${
            expandRight && left.length < current.length
              ? "reverse-brand"
              : expandLeft
              ? "expand-left-brand"
              : expandRight && "expand-right-brand"
          } `}
          setRefresh={setRefresh}
          showElements={true}
          columns={columns}
        />
        <CarouselSegment
          imageData={current}
          class={`image-container current-images) ${
            expandLeft
              ? "expand-left-brand"
              : expandRight && "expand-right-brand"
          } `}
          setRefresh={setRefresh}
          showElements={showCurrent}
          columns={columns}
        />
        <CarouselSegment
          imageData={right}
          class={`image-container right-images ${
            expandLeft
              ? "expand-left-brand"
              : expandRight && "expand-right-brand"
          } `}
          setRefresh={setRefresh}
          showElements={true}
          columns={columns}
        />
      </div>
      <i
        className="ri-arrow-right-circle-fill brand-arrow-right"
        data-arrow={"right"}
        onClick={handleClick}
      ></i>
    </div>
  );
};

export default BrandCarousel;
