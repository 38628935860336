import { parseSearch } from "../../../../utils/utils";
import { DataItem } from "../../types";
import { StyledLink } from "../submenu/element";

export type LinkItem = {
  url: string;
};

type Props = {
  header?: string;
  name: string;
  dataItem: DataItem;
};

const MenuLink = (props: Props) => {
  let linkContent =
    "- " +
    (props.dataItem.shortName ? props.dataItem.shortName : props.dataItem.name);
  let toLink: string;

  if (
    ["value", "enhanced support", "support"].includes(
      props.header?.toLowerCase() ?? ""
    )
  ) {
    toLink = `downloads/${props.dataItem.productUrl}`;
  } else if (
    props.header === "driver-request" ||
    props.header === "supportLink"
  ) {
    const urlItem = props.dataItem as unknown as LinkItem;
    toLink = urlItem.url;
  } else {
    toLink = `${parseSearch(props.name)}${props.header ? "/" : ""}${
      props.header ? parseSearch(props.header) : ""
    }/${parseSearch(linkContent)}`;
  }

  return (
    <StyledLink data-menu={true} to={toLink}>
      {linkContent}
    </StyledLink>
  );
};

export default MenuLink;
