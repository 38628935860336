import { Product, MediaItem } from "../../../../globaltypes";

import styled from "styled-components";

import { useState } from "react";

import MediaCarousel from "./carousel";
import useShowMenu from "../../../../hooks/useShowMenu";
import FullPage from "./fullPage";
import ReactPlayer from "react-player";

interface MediaProps {
  product: Product;
}

const Media = (props: MediaProps) => {
  const [showFullImage, toggleOn] = useShowMenu();
  const [displayImage, setDisplayImage] = useState<MediaItem>(
    props.product.media[0]
  );

  //TODO if images length less than 4

  const handleClick = (item: MediaItem): void => {
    setDisplayImage(item);
  };

  function extractVideoID(url: string): string {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[7].length === 11) {
      return match[7];
    } else {
      return "";
      //todo error handle?
    }
  }

  return (
    <MediaWrapper>
      {" "}
      <MediaInner>
        {displayImage.mediaType === "IMAGE" ? (
          <img
            src={displayImage.imageUrl}
            alt={displayImage.description}
            onClick={toggleOn}
          />
        ) : (
          <ReactPlayer
            width={"30em"}
            height={"25em"}
            url={displayImage.imageUrl}
          />
        )}
      </MediaInner>
      <CarouselWrap>
        <MediaCarousel
          images={props.product.media}
          handleClick={handleClick}
          extractId={extractVideoID}
        />
      </CarouselWrap>
      {showFullImage && <FullPage imageSrc={displayImage.imageUrl} />}
    </MediaWrapper>
  );
};

export default Media;

const CarouselWrap = styled.div`
  position: relative;
  display: grid;
  width: max-content;
  height: min-content;
  justify-self: center;
  grid-template-columns: auto 1fr auto;
`;

const MediaWrapper = styled.div`
  display: grid;
  grid-template-rows: 30em min-content;
  margin-bottom: 5em;
  @media only screen and (max-width: 920px) {
    margin-bottom: 0;
    grid-template-rows: min-content min-content;
  }
`;

const MediaInner = styled.div`
  align-self: center;
  justify-self: center;
  img {
    width: auto;
    height: 25em;
  }
  @media only screen and (max-width: 920px) {
    img {
      height: 18em;
    }
  }
`;
