import styled, { ThemeProvider } from "styled-components";
import { Button } from "../../util/types";
import { getColorTheme } from "../../util/other";

const EditButton = (props: Button) => {
  return (
    <ThemeProvider
      theme={getColorTheme(props.disabled === true, "var(--orange-color)")}
    >
      <EditWrap
        onClick={() => {
          if (!props.disabled) {
            props.onClick();
          }
        }}
      >
        <i className="ri-edit-2-line"></i>
      </EditWrap>
    </ThemeProvider>
  );
};

export default EditButton;

const EditWrap = styled.button`
  width: 2em;
  height: 2em;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color};
  color: white;
`;
