import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { ShowMenuContext } from "../../context/MenuContext";

export type SearchableDropdownValue = {
  displayValue: string;
  value: string;
};

export type SearchableDropdownList = SearchableDropdownValue[];

export type CustomDropdownEvent = {
  target: {
    value: string;
  };
};

interface Props {
  id: string;
  placeholder: string;
  value: string;
  handlechange: (event: CustomDropdownEvent) => void;
  width?: string;
  items?: SearchableDropdownList;
  selectedStyle?: React.CSSProperties;
}

const SearchableDropdown: React.FC<Props> = (props) => {
  const { isMenuOpen, toggleOn, reset } = useContext(ShowMenuContext);
  const { value, handlechange, items = [], selectedStyle, placeholder } = props;
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const displayValue = useMemo(
    () => items.find((item) => item.value === value)?.displayValue,
    [items, value]
  );

  const [searchValue, setSearchValue] = useState(displayValue);

  const effectivePlaceholder = useMemo(() => {
    if (showInput) return placeholder;
    return displayValue || placeholder;
  }, [showInput, displayValue, placeholder]);

  useEffect(() => {
    setSearchValue(displayValue);
  }, [displayValue]);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  useEffect(() => {
    if (!isMenuOpen(props.id)) {
      setShowInput(false);
    }
  }, [isMenuOpen]);

  const handleSelect = (itemValue: string, displayValue: string) => {
    handlechange({
      target: {
        value: itemValue,
      },
    });
    setSearchValue(displayValue);
    setShowInput(false);
    reset();
  };

  const filteredItems = useMemo(
    () =>
      searchValue
        ? items.filter((item) =>
            item.displayValue.toLowerCase().includes(searchValue.toLowerCase())
          )
        : items,
    [items, searchValue]
  );

  return (
    <DropdownInputWrap
      data-menu={true}
      style={props.width ? { width: props.width } : {}}
    >
      <Selected
        onClick={() => {
          if (!showInput) {
            setSearchValue("");
            setShowInput(true);
          }
          if (!isMenuOpen(props.id)) {
            toggleOn(props.id);
          }
        }}
        style={selectedStyle}
      >
        {showInput ? (
          <SearchInput
            ref={inputRef}
            placeholder={effectivePlaceholder}
            type="text"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              toggleOn(props.id);
            }}
          />
        ) : (
          <>{effectivePlaceholder}</>
        )}
        <i
          className={`ri-arrow-${isMenuOpen(props.id) ? "up" : "down"}-s-fill`}
        ></i>
      </Selected>
      {isMenuOpen(props.id) && (
        <Menu>
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <p
                key={item.value}
                onClick={() => handleSelect(item.value, item.displayValue)}
              >
                {item.displayValue}
              </p>
            ))
          ) : (
            <p>No matching items</p>
          )}
        </Menu>
      )}
    </DropdownInputWrap>
  );
};

export default SearchableDropdown;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 0.9em;
`;

const DropdownInputWrap = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

const Selected = styled.div`
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.23);
  font: inherit;
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: solid 1px rgba(0, 0, 0, 0.23);
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: left;
  border-radius: 4px;
  z-index: 8;

  p {
    z-index: 9;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
