import Subheading from "../../../../../../components/subheading";
import styled, { ThemeProvider } from "styled-components";
import { User } from "../../index";
import Card from "../../../../Reusables/card";
import AdminDropDownMenu from "../../../../Reusables/dropdown";
import { useContext, useState } from "react";
import { UserAdminContext } from "./context/UserAdminContext";
import Item from "../../../../Reusables/picker/item";
import Picker, { MenuItem } from "../../../../Reusables/picker";
import ModalButtons from "../../../../Reusables/modalButtons";
import Submit from "./submit";

interface Props {
  user: User;
  resetInfo: () => void;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  padding: string | number;
}
const UserDetailsSection = (props: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const paddingTheme = {
    padding: props.padding,
  };
  const {
    submitChanges,
    userState,
    updateState,
    possibleStates,
    resetState,
    rolesList,
    userRoles,
    rolesText,
    updateRole,
    removeRole,
  } = useContext(UserAdminContext);

  const updateItems = (text: string) => {
    updateState(text);
  };

  const mappedItems = possibleStates.filter((item) => {
    return item !== userState;
  });

  const updateStatus = (text: string) => {
    updateRole(text);
  };

  const handleSave = async () => {
    try {
      if (!loading) {
        setLoading(true);
        const res = await submitChanges();
        props.updateInfo(
          `${res.data.name ? res.data.name : "User"}` + " updated successfully",
          res.data.id,
          false
        );
        setEditMode(false);
        setShowSave(false);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "User update failed"
        }`,
        props.user.id,
        true
      );
      if (e.response.status !== 401) {
        setShowSave(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const showSaveModal = () => {
    setShowSave(true);
  };

  const hideSaveModal = () => {
    setShowSave(false);
  };

  const userRolesDisplay = userRoles.map((item) => {
    return (
      <Item
        editMode={editMode}
        text={rolesText[item]}
        remove={() => {
          removeRole(rolesText[item]);
        }}
      />
    );
  });

  const menuRoles: MenuItem[] = rolesList
    .filter((item) => {
      return !userRoles.includes(item);
    })
    .map((item) => {
      return { text: rolesText[item], data: item };
    });

  return (
    <ThemeProvider theme={paddingTheme}>
      <Wrapper>
        <Heading>
          <Subheading h3={"User"} h2={"Details"} />
          <ModalButtons
            editMode={editMode}
            loading={false}
            handleSave={showSaveModal}
            setEditMode={setEditMode}
            resetState={resetState}
            resetInfo={props.resetInfo}
            disabled={false}
          />
        </Heading>
        <Inner>
          <Card
            heading={"Username"}
            text={props.user.username ? props.user.username : "-"}
          />
          <Card
            heading={"First Name"}
            text={props.user.firstName ? props.user.firstName : "-"}
          />
          <Card
            heading={"Last Name"}
            text={props.user.lastName ? props.user.lastName : "-"}
          />
          <Card
            heading={"Company Name"}
            text={props.user.companyName ? props.user.companyName : "-"}
          />
          <AdminDropDownMenu
            handleClick={updateItems}
            heading={"Status"}
            text={userState ? userState : "-"}
            items={mappedItems}
            editMode={editMode}
          />
          <Card
            heading={"Email"}
            text={props.user.email ? props.user.email : "-"}
          />
          <Picker
            heading={"ROLES"}
            menuItems={menuRoles}
            editMode={editMode}
            handleClick={updateStatus}
          >
            {userRolesDisplay}
          </Picker>
          <Card
            heading={"Phone"}
            text={props.user.phone.number ? props.user.phone.number : "-"}
          />
          <Card
            heading={"Phone alt"}
            text={props.user.phone2.number ? props.user.phone2.number : "-"}
          />
        </Inner>
        {showSave && (
          <Submit
            loading={loading}
            handleSave={handleSave}
            close={hideSaveModal}
          />
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default UserDetailsSection;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-content: center;
  align-items: center;
  grid-gap: 20px;
  box-sizing: border-box;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;

  input[type="password"] {
    :focus {
      border: solid 1px var(--orange-color);
    }
  }

  @media only screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  position: relative;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 2em;
  padding-top: ${(props) => props.theme.padding};
`;
