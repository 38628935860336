import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import RegisterIntro from "../intro";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";
import RegisterForm from "../form";

const RegisterFormPage = () => {
  const [success, setSuccess] = useState(false);
  const theme = {
    gridTemplateColumns: success ? "1fr" : "1fr 1fr 1fr",
  };
  return (
    <ContentWrapper style={{ marginTop: "2em" }}>
      <ThemeProvider theme={theme}>
        <Wrapper>
          {!success && <RegisterIntro />}
          <RegisterForm setSuccess={setSuccess} success={success} />
        </Wrapper>
      </ThemeProvider>
    </ContentWrapper>
  );
};
export default RegisterFormPage;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.theme.gridTemplateColumns && props.theme.gridTemplateColumns};
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
