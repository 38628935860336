import React from "react";
import { Segment } from "../../types";
import ProductCard from "../../../../views/Main/SearchResults/productCard";
import styled from "styled-components";

const LatestSegment = (props: Segment) => {
  const product = {
    id: 150538,
    name: "KEF W2 Control Driver for Crestron Home",
    description:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    addDate: "2022-10-12T16:20:48.109+00:00",
    updateDate: "2022-10-12T16:20:48.109+00:00",
    gbpPrice: 0,
    shortName: null,
    productUrl: "kef-ls50-wireless-ii-driver-for-crestron-home",
    supportedModels:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    costs: [
      {
        currencyType: "GBP",
        currencyValue: 0,
      },
      {
        currencyType: "AUD",
        currencyValue: 0,
      },
      {
        currencyType: "CAD",
        currencyValue: 0,
      },
      {
        currencyType: "EUR",
        currencyValue: 0,
      },
      {
        currencyType: "USD",
        currencyValue: 0,
      },
    ],
    type: "PRODUCT",
    seoTitle: null,
    seoDescription: null,
    media: [
      {
        id: 1444,
        name: "",
        description: "",
        mediaType: "IMAGE",
        sequence: 1,
        addDate: "2022-11-08T07:31:59.141+00:00",
        updateDate: "2022-11-08T07:31:59.141+00:00",
        imageUrl:
          "https://www.intrinsicdev.com/wp-content/uploads/edd/2022/07/crestron-home-kef-wirelessII-small.jpg",
      },
    ],
    downloadLink:
      "https://www.dropbox.com/sh/fw3z3tthellwcl8/AABzITDKoyyT2kZviB-N8Ruja?dl=0",
    platformName: "Crestron",
    featuredImageUrl: "",
  };
  const images = props.imageData.map((item, index) => {
    return (
      <Wrapper>
        <ProductCard product={product} />
      </Wrapper>
    );
  });

  return (
    <div
      id={"segment"}
      onTransitionEnd={(event) => {
        if (event.propertyName === "transform") {
          props.setRefresh(true);
        }
      }}
      className={props.class}
      style={{
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
        transform: props.style ? props.style : undefined,
      }}
    >
      {props.showElements && images}
    </div>
  );
};

export default LatestSegment;

const Wrapper = styled.div`
  padding: 10px;
`;
