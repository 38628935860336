import styled, { ThemeProvider } from "styled-components";
import InfoRow, { InfoProps } from "../infoRow";
import React from "react";
import usePreventScroll from "../../../../hooks/usePreventScroll";

interface Props {
  close: () => void;
  children: JSX.Element;
  infoProps: InfoProps;
  showInfo: boolean;
  noPlaceholder?: boolean;
  width?: string;
}

const Window = (props: Props) => {
  // most cases will want placeholder, I added the toggle for a specific case
  // where the placeholder was causing an issue

  usePreventScroll(true);

  const theme = {
    width: props.width ? props.width : "50em",
  };
  return (
    <WindowOverlay>
      <ThemeProvider theme={theme}>
        <WindowWrap>
          <CloseWrap onClick={props.close}>
            <i className="ri-close-fill"></i>
          </CloseWrap>
          {props.showInfo ? (
            <InfoRow
              message={props.infoProps.message}
              hasFailed={props.infoProps.hasFailed}
              resetInfo={props.infoProps.resetInfo}
            />
          ) : (
            !props.noPlaceholder && <InfoPlaceholder />
          )}
          {props.children}
        </WindowWrap>
      </ThemeProvider>
    </WindowOverlay>
  );
};

export default Window;

const WindowOverlay = styled.div`
  display: grid;
  position: fixed;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  z-index: 10;
  background-color: rgba(40, 38, 38, 0.85);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  overflow-y: scroll;

  @media (max-width: 920px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    border-radius: 0;
  }
`;

const WindowWrap = styled.div`
  position: relative;
  border-radius: 3px;
  overflow-y: scroll;
  min-width: ${(props) => props.theme.width};
  @media (max-width: 920px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    min-width: unset;
  }
`;

const CloseWrap = styled.div`
  font-size: 1.8em;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 6px;
  background-color: var(--orange-color);
  color: white;
  display: grid;
  justify-items: end;
  justify-content: end;

  @media (max-width: 920px) {
    justify-items: start;
    justify-content: start;
    border-radius: 0;
  }
`;

const InfoPlaceholder = styled.div`
  height: 20px;
  grid-column: 1/-1;
  background-color: white;

  @media (max-width: 920px) {
  }
`;
