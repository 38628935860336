import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ShowroomFormPage from "./formPage";
import ShowroomRequests from "./requests";

const Showroom = () => {
  const { type } = useParams();
  const location = useLocation();
  const [route, setRoute] = useState("");
  useEffect(() => {
    if (type === "requests") {
      setRoute("requests");
    } else if (type === "form") {
      setRoute("form");
    }
  }, [location]);
  return (
    <>
      {route === "form" ? (
        <ShowroomFormPage />
      ) : route === "requests" ? (
        <ShowroomRequests />
      ) : (
        <div />
      )}
    </>
  );
};
export default Showroom;
