import styled from "styled-components";
import RegisterFormPage from "./formPage";

const RegisterUserPage = () => {
  return (
    <Wrapper>
      <RegisterFormPage />
    </Wrapper>
  );
};

export default RegisterUserPage;

const Wrapper = styled.div`
  justify-self: center;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 4em;
  @media only screen and (max-width: 920px) {
    margin-right: 2%;
    margin-left: 2%;
    margin-top: 2%;
  }
`;
