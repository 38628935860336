import BrandCarousel from "../../../../components/carousel/brandCarousel";
import InfoPanel from "./infopanel";
import styled from "styled-components";

const CompanyInfo = () => {
  return (
    <Wrapper>
      <InfoPanel />
      <BrandCarousel />
    </Wrapper>
  );
};

export default CompanyInfo;

const Wrapper = styled.div`
  display: grid;
  color: #5a5454;
`;
