import Subheading from "../../../../../components/subheading";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import LoginBox from "../../../../../components/other/login";

const LicenseTransferUpper = () => {
  const { user } = useContext(AuthContext);
  return (
    <LicenseTransferHeading>
      <Subheading h3="TRANSFER" h2="REQUEST" />
      <LicenseTransferInfo>
        <p>
          Intrinsic Dev is happy to transfer a licence from one
          processor/controller to another where it represents a genuine change
          in like-for-like hardware due to an upgrade, fault, manufacturer
          recall or warranty issue. In order to process this transfer we may
          require evidence of the approved return by the applicable
          manufacturer/supplier.
        </p>
        <p>
          We regret, as per our{" "}
          <Link data-menu={true} to={"/terms-conditions"}>
            Terms and Conditions
          </Link>
          , we cannot offer transfers for a processor/controller failure for
          reasons not relating to a manufacturing fault, e.g. lightning strike,
          power surge etc. In this circumstance we are happy to offer a quote to
          replace the licences purchased; this can be submitted to your
          insurance company to recover the costs of purchasing replacement
          licences. Once you have read and understood this statement, please
          {!user
            ? " login to complete the form."
            : "  complete the form below."}
        </p>
        <p>
          Select the order number from 'Your Orders' below to provide the
          current MAC address and then enter the new MAC address. A reason for
          transfer is required. Transfers are granted at the sole discretion of
          Intrinsic Dev.
        </p>
        <p>
          NB Licence Transfers can take up to 2 business days to be verified.
        </p>
        {!user && <LoginBox />}
      </LicenseTransferInfo>
    </LicenseTransferHeading>
  );
};

export default LicenseTransferUpper;

const LicenseTransferHeading = styled.div`
  width: 100%;
  grid-column: 1;
  display: grid;
  justify-items: start;
  margin-bottom: 1.5em;
  align-items: start;
  align-content: start;
  grid-gap: 20px;

  @media only screen and (max-width: 992px) {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
`;

const LicenseTransferInfo = styled.div`
  text-align: left;

  line-height: 1.3;
  display: grid;
  grid-gap: 10px;

  a {
    color: #ff9f00;
  }

  @media only screen and (max-width: 992px) {
  }
`;
