import logo from "../../../../assets/images/header/logo.png";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SearchBox from "../../mainheader/searchbox";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { OpenOrdersContext } from "../../../../context/OpenOrdersContext";
import { orderLine } from "../../../../views/Main/Orders";

type Props = {
  isScrolled: boolean;
  adminMode?: boolean;
};

const SidebarHeader = (props: Props) => {
  const { user } = useContext(AuthContext);
  const { openOrders } = useContext(OpenOrdersContext);
  const location = useLocation();
  const [openOrderInfo, setOpenOrderInfo] = useState({
    baseValue: 0.0,
    items: 0,
  });

  useEffect(() => {
    if (user !== null) {
      if (openOrders.length > 0) {
        let orderInfo = { baseValue: 0, items: 0 };
        orderInfo = {
          baseValue: openOrders[0].baseValue,
          items: 0,
        };
        openOrders[0].orderLines.forEach((orderLine: orderLine) => {
          orderInfo = {
            ...orderInfo,
            items: orderInfo.items + 1,
          };
        });
        setOpenOrderInfo(orderInfo);
      }
    }
  }, [user, location, openOrders]);
  return (
    <Wrapper isScrolled={props.isScrolled}>
      {" "}
      <LogoWrap>
        <img src={logo} alt="logo" />
      </LogoWrap>
      {!props.adminMode && (
        <SearchWrap>
          {" "}
          <SearchBox />
        </SearchWrap>
      )}
      {user && !props.adminMode && (
        <Cart>
          <Link to={"Orders"}>
            <i className="ri-shopping-cart-2-fill"></i>
            {openOrderInfo.items} items - £{openOrderInfo.baseValue.toFixed(2)}
          </Link>
        </Cart>
      )}
    </Wrapper>
  );
};
export default SidebarHeader;

const LogoWrap = styled.div`
  padding-bottom: 20px;
  display: grid;
  img {
    margin-top: 20px;
    width: 60%;
    justify-self: center;
  }
`;

const SearchWrap = styled.div`
  margin-left: 25px;
  margin-right: 25px;
`;

const Cart = styled.div`
  margin-top: 20px;
  display: grid;
  align-items: center;
  align-content: center;
  justify-self: start;
  margin-left: 20px;
  i {
    margin-top: 10px;
    font-size: 1.2em;
  }
  a {
    text-decoration: none;
    color: var(--grey-color);
  }
`;

const Wrapper = styled.div<{ isScrolled: boolean }>`
  display: grid;
  padding-bottom: 20px;
  box-shadow: ${({ isScrolled }) =>
    isScrolled ? "rgba(0, 0, 0, 0.24) 0px 3px 8px;" : "none"};
  transition: box-shadow 0.3s ease;
  z-index: 10;
`;
