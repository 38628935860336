import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { resetPassword } from "../../../../../api/Auth";
import Subheading from "../../../../../components/subheading";
import styled from "styled-components";
import LabeledInput from "../../../../../components/form/components/labeledInput";
import GreyButton from "../../../../../components/other/buttons/submit";
import { useParams } from "react-router-dom";

type Props = {
  toggleForm: Function;
};

const Form: React.FC<Props> = (props) => {
  const { authcode, email } = useParams<{ authcode: string; email: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const [serverError, setServerError] = useState<string>("");

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await resetPassword({
        email: data.email,
        newPassword: data.password1,
        authCode: data.auth,
      });
      props.toggleForm();
    } catch (e: any) {
      switch (e.response.status) {
        case 404:
          setServerError("Invalid authentication code or email");
          break;
        case 418:
          setServerError(e.response.data.message);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ResetFormWrap>
      <ErrorWindow>
        {serverError.length > 0 && <p>{serverError}</p>}
      </ErrorWindow>
      <Wrapper>
        <Subheading h3="Reset" h2="Password" />
      </Wrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Controller
            name="email"
            control={control}
            defaultValue={email}
            rules={{ required: "Email is required" }}
            render={({ field }) => (
              <LabeledInput
                disabled={true}
                label={{
                  for: "email",
                  required: true,
                  text: "Email",
                }}
                placeholder={"Email"}
                name={"email"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.email}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="auth"
            control={control}
            defaultValue={authcode}
            rules={{ required: "Authentication code is required" }}
            render={({ field }) => (
              <LabeledInput
                disabled={true}
                label={{
                  for: "auth",
                  required: true,
                  text: "Authentication Code",
                }}
                placeholder={"Authentication code"}
                name={"auth"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.auth}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="password1"
            control={control}
            defaultValue={""}
            rules={{
              required: "This field is required",
              validate: {
                matchesOtherEmail: (value) => {
                  const { password2 } = getValues();
                  return (
                    password2 === "" ||
                    password2 === value ||
                    "Passwords do not match"
                  );
                },
              },
            }}
            render={({ field }) => (
              <LabeledInput
                label={{
                  for: "password1",
                  required: true,
                  text: "Password",
                }}
                type="password"
                autoComplete={"new-password"}
                placeholder={"Password"}
                name={"password1"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.password1}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name="password2"
            control={control}
            defaultValue={""}
            rules={{
              required: "This field is required",
              validate: {
                matchesOtherPass: (value) => {
                  const { password1 } = getValues();
                  return password1 === value || "Passwords do not match";
                },
              },
            }}
            render={({ field }) => (
              <LabeledInput
                label={{
                  for: "password2",
                  required: true,
                  text: "Confirm Password",
                }}
                type="password"
                autoComplete={"new-password"}
                placeholder={"Confirm Password"}
                name={"password2"}
                value={field.value}
                handlechange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={errors.password2}
              />
            )}
          />
        </InputWrapper>
        <GreyButton
          loading={loading}
          text={"Submit"}
          disabled={!isValid}
          type={"submit"}
        />
      </StyledForm>
    </ResetFormWrap>
  );
};

export default Form;

const ResetFormWrap = styled.div`
  display: grid;
  align-self: center;
  margin-top: 2em;
  margin-bottom: 1em;
  justify-self: center;
  grid-gap: 15px;
  border: var(--border-main);
  box-shadow: var(--box-shadow-main);
  width: 28em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 9px;

  button {
    font-size: 0.9em;
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  justify-items: start;
  width: 100%;
`;

const ErrorWindow = styled.div`
  width: 100%;
  color: red;
  text-align: center;
  min-height: 1em;
  font-size: 0.8em;
`;

const Wrapper = styled.div`
  justify-self: center;
`;

const StyledForm = styled.form`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
`;
