import styled from "styled-components";
import CartList from "./CartList";
import Subtotal from "./Subtotal";
import Promo from "./Promo";
import Total from "./Total";

type Props = {};
const Cart = (props: Props) => {
  const product = {
    id: 665,
    name: "RTI AD-4 Driver for ELAN",
    description:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    addDate: "2022-10-12T16:20:26.743+00:00",
    updateDate: "2022-10-12T16:20:26.743+00:00",
    gbpPrice: 35,
    shortName: null,
    productUrl: "rti-ad4-driver-for-elan",
    supportedModels:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    costs: [
      {
        currencyType: "GBP",
        currencyValue: 35,
      },
      {
        currencyType: "AUD",
        currencyValue: 68.6,
      },
      {
        currencyType: "CAD",
        currencyValue: 59.85,
      },
      {
        currencyType: "EUR",
        currencyValue: 40.95,
      },
      {
        currencyType: "USD",
        currencyValue: 44.1,
      },
    ],
    type: "PRODUCT",
    seoTitle: null,
    seoDescription: null,
    media: [
      {
        id: 1207,
        name: "",
        description: "",
        mediaType: "IMAGE",
        sequence: 1,
        addDate: "2022-11-08T07:31:29.928+00:00",
        updateDate: "2022-11-08T07:31:29.928+00:00",
        imageUrl:
          "https://www.intrinsicdev.com/wp-content/uploads/edd/2019/03/elan-rti-ad4-small.jpg",
      },
    ],
    downloadLink:
      "https://www.dropbox.com/sh/ec70fckk8a0t4k6/AAB_d_aQT6YVcBKC75S_9OxSa?dl=0",
    platformName: "ELAN",
  };
  const products = Array.from({ length: 20 }, () =>
    JSON.parse(JSON.stringify(product))
  );
  const SUBTOTAL = products.reduce((total, product) => {
    return total + product.gbpPrice;
  }, 0);

  const TAX = SUBTOTAL * 0.2;

  const TOTAL = SUBTOTAL * 1.2;

  return (
    <Wrapper>
      <Border>
        <CartList products={products} />
      </Border>
      <Border>
        <Subtotal subtotal={SUBTOTAL} tax={TAX} />
      </Border>
      <Border>
        <Promo />
      </Border>
      <Border>
        {" "}
        <Total total={TOTAL} />
      </Border>
    </Wrapper>
  );
};

export default Cart;

const Wrapper = styled.div`
  width: 100%;
  box-shadow: var(--shadow-standard);
  box-sizing: border-box;
  justify-self: center;
  @media only screen and (max-width: 920px) {
    box-shadow: unset;
  }
`;

const Border = styled.div`
  border-bottom: solid 1px var(--light-border);
  padding: 20px;
`;
