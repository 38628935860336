import styled from "styled-components";
import useShowMenu from "../../../../hooks/useShowMenu";
import React, { useEffect } from "react";

import { DataItem } from "../../types";
import { useLocation } from "react-router-dom";
import Elements from "./elements";

interface BrandProps {
  brands: DataItem[];
}

const Brands = (props: BrandProps) => {
  const [showMenu, toggleOn, toggleOff, reset] = useShowMenu();
  let currentLocation = useLocation();

  useEffect(() => {
    toggleOff();
  }, [currentLocation]);

  return (
    <Wrapper
      onMouseOver={(e: React.MouseEvent<HTMLElement>) => {
        toggleOn(e);
        document.getElementById("navBrandsTitle")?.classList.add("active");
      }}
      onMouseOut={(e) => {
        toggleOff();
        document.getElementById("navBrandsTitle")?.classList.remove("active");
      }}
      data-menu={true}
    >
      <p id="navBrandsTitle">{"Brands"}</p>
      {showMenu && <Border></Border>}
      {showMenu && (
        <Menu data-menu={true}>
          <SubMenuWrapper data-menu={true}>
            <Elements elements={props.brands} name={"brands"} />
          </SubMenuWrapper>
        </Menu>
      )}
    </Wrapper>
  );
};

const Border = styled.div`
  height: 3px;
  position: absolute;
  top: 98%;
  width: 100%;
  background-color: #ff9f00;
`;

const SubMenuWrapper = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  align-content: start;
  align-items: start;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  font-weight: bold;
  position: relative;
  height: 100%;
  display: grid;
  align-content: center;
  align-items: center;
  cursor: default;
  .active {
    color: var(--orange-color);
  }
  .NavPlatName {
    height: 100%;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 101%;
  background-color: var(--grey-color);
  display: grid;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: 0%;
`;

export default Brands;
