import styled from "styled-components";
import cookie from "../../../assets/images/other/cookie-white.png";
import { useState } from "react";
import CookieBannerModal from "../Modal";

type Props = {
  accept: () => void;
  decline: () => void;
};
const CookieBannerHidden = (props: Props) => {
  const [hidden, setHidden] = useState(true);
  return hidden ? (
    <Wrapper onClick={() => setHidden(false)}>
      <Window>
        <ImageWrap>
          <Cookie src={cookie} />
        </ImageWrap>
      </Window>
    </Wrapper>
  ) : (
    <CookieBannerModal
      {...props}
      hideHandler={() => {
        setHidden(true);
      }}
    />
  );
};

export default CookieBannerHidden;

const Wrapper = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
`;

const Window = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--orange-color);
  align-self: center;
  justify-self: center;
  display: grid;
`;

const ImageWrap = styled.div`
  align-self: center;
`;

const Cookie = styled.img`
  height: 35px;
  width: 35px;
  justify-self: center;
`;
