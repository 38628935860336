import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LabeledInput from "../../../../components/form/components/labeledInput";
import { AuthContext } from "../../../../context/AuthContext";
import Subheading from "../../../../components/subheading";
import Captcha from "../../../../components/captcha";
import CustomButton from "../../../../components/other/buttons/submit";

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const { handleLogin } = useContext(AuthContext);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [captchaVerified, setCaptchaVerified] = React.useState(false);

  const onSubmit = async (data: any) => {
    if (!captchaVerified) return;
    setLoggingIn(true);
    await handleLogin(data.username, data.password);
    setLoggingIn(false);
    //TODO handle err
  };

  return (
    <FormWrap onSubmit={handleSubmit(onSubmit)} as="form">
      <Wrapper>
        <Subheading h3={"Welcome To"} h2={"Intrinsic Dev"} />
      </Wrapper>
      <Controller
        name="username"
        defaultValue={""}
        control={control}
        rules={{ required: "Username is required" }}
        render={({ field }) => (
          <LabeledInput
            name={field.name}
            value={field.value}
            handlechange={(e) => {
              field.onChange(e.target.value);
            }}
            label={{
              text: "Username or Email",
              for: "username",
              required: true,
            }}
            error={errors.username}
          />
        )}
      />
      <Controller
        name="password"
        defaultValue={""}
        control={control}
        rules={{ required: "Password is required" }}
        render={({ field }) => (
          <LabeledInput
            name={field.name}
            value={field.value}
            handlechange={(e) => {
              field.onChange(e.target.value);
            }}
            type="password"
            label={{
              text: "Password",
              for: "password",
              required: true,
            }}
            error={errors.password}
          />
        )}
      />
      <LinkWrap>
        Can’t remember your password? Click{" "}
        <OrangeText>
          <Link to={"/lostpassword"}>here</Link>
        </OrangeText>{" "}
        to reset.
      </LinkWrap>
      <LinkWrap>
        Not yet registered? Click{" "}
        <OrangeText>
          <Link to={"/register"}>here</Link>
        </OrangeText>{" "}
        to create an account.
      </LinkWrap>
      <CustomButton
        text={"Submit"}
        disabled={!isValid || loggingIn || !captchaVerified}
        type="submit"
        loading={loggingIn}
      />
      <CaptchaWrap>
        <Captcha resultCallback={setCaptchaVerified} />
      </CaptchaWrap>
    </FormWrap>
  );
};

export default LoginForm;

const FormWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  align-items: center;
  align-content: start;
  button {
    width: 100%;
  }
`;

const LinkWrap = styled.p`
  justify-self: start;
  height: 100%;
  margin-bottom: 5px;
  font-size: 0.9em;
  padding-left: 2px;
`;

const OrangeText = styled.span`
  a {
    color: var(--orange-color);
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const CaptchaWrap = styled.div`
  justify-content: center;
  justify-self: center;
  margin-top: 0.5em;
`;
