import React from "react";
import CountryItem from "../country";
import useInputFocus from "../../../../hooks/useInputFocus";
import useInput from "../../../../hooks/useInput";
import useShowMenu from "../../../../hooks/useShowMenu";
import "../../styles/form.css";
import { PhoneInputProps } from "../../types";
import styled from "styled-components";
import {
  CountryInput,
  CountryInputHidden,
  PhoneInputWrap,
} from "../../styledComponents";
import LabeledInput from "../labeledInput";

const PhoneInput = (props: PhoneInputProps) => {
  const [showMenu, toggleOn, toggleOff] = useShowMenu();
  const [countrySearch, setCountrySearch] = useInput();
  const [callbackRef] = useInputFocus();

  const countries = props.countries
    .filter((country) => {
      return country.isoCode.includes(countrySearch.toUpperCase());
    })
    .map((country) => {
      return (
        <CountryItem
          country={country}
          key={country.flagCode}
          handleCountry={props.handleCountry}
          toggleOff={toggleOff}
          name={props.name}
        />
      );
    });

  return (
    <PhoneInputWrap>
      {showMenu ? (
        <CountryInput
          ref={callbackRef}
          data-menu={true}
          className={"country-input"}
          type={"text"}
          value={countrySearch}
          name={"country code"}
          onChange={setCountrySearch}
          maxLength={2}
          placeholder={
            props.currentCountry.flagCode + props.currentCountry.dialCode
          }
        />
      ) : (
        <CountryInputHidden onClick={toggleOn}>
          {props.currentCountry.flagCode}
          {props.currentCountry.dialCode}
        </CountryInputHidden>
      )}

      <LabeledInput
        name={props.name}
        value={props.value}
        handlechange={props.handlechange}
        handlevalidation={props.handlevalidation}
        validationfield={props.validationfield}
        isvalid={props.isvalid}
        showerrors={props.showerrors}
        case={props.case}
        label={{
          text: "Number",
          for: "phone",
          required: props.required ?? false,
        }}
      />
      {showMenu && (
        <CountryList data-menu={true} className={"country-list"}>
          {countries}
        </CountryList>
      )}
    </PhoneInputWrap>
  );
};

export default PhoneInput;

const CountryList = styled.div`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  max-height: 10em;
  position: absolute;
  background-color: white;
  width: 4em;
  top: 95%;
  overflow: scroll;
  display: grid;
  justify-content: center;
  grid-gap: 5px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  min-height: 2em;
  border: 1px solid var(--border-active-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1;
  ::-webkit-scrollbar {
    display: none;
  }
`;
