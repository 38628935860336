type Environment = "CLOUD" | "DEMO" | "STAGING" | "DEV" | "";

function getType(value: string): Environment {
  switch (value) {
    case "cloud":
    case "master":
      return "CLOUD";
    case "demo":
      return "DEMO";
    case "staging":
      return "STAGING";
    case "dev":
    case "develop":
      return "DEV";
    default:
      return "";
  }
}

export function getEnvironment(): Environment {
  const fromURL: string = window.location.hostname.split(".")[0];
  const fromENV: string = process.env.REACT_APP_GIT_BRANCH_NAME ?? "";

  return getType(fromURL) ?? getType(fromENV);
}
