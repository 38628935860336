import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Order } from "../index";
import OrdersDetailsSection from "./details";
import AddressPicker from "./address/picker";
import { AddressResponse } from "../../../../components/form/types";
import Window from "../../../Admin/Reusables/window";
import useInfo from "../../../Admin/Hooks/useInfo";
interface FormProps {
  close: () => void;
  order: Order;
}

const UpdateOrderForm = (props: FormProps) => {
  const emptyStringify = (obj: any) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined) {
          obj[key] = "";
        } else if (typeof obj[key] === "object") {
          emptyStringify(obj[key]);
        }
      }
    }
    return obj;
  };
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [order, setOrder] = useState(emptyStringify(props.order));
  const [addresses, setAddresses] = useState<AddressResponse[]>([
    props.order.billingAddress,
    props.order.deliveryAddress,
  ]);
  const [showAddresses, setShowAddresses] = useState(false);

  const [fail, info, , showInfo, resetInfo, updateInfo] = useInfo();
  const [closeType, setCloseType] = useState("window");
  const [addressEditing, setAddressEditing] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    setAddressEditing(undefined);
  }, [addressEditing]);

  const updateAddresses = (newAddresses: AddressResponse[]) => {
    setAddresses(newAddresses);
    let newOrder = { ...order };
    newOrder.billingAddress = newAddresses[0];
    newOrder.deliveryAddress = newAddresses[1];
    setOrder(newOrder);
  };

  const toggleEdit = () => {
    setShowEditOrder((prev) => {
      return !prev;
    });
  };

  const resetState = () => {
    setAddresses([props.order.billingAddress, props.order.deliveryAddress]);
  };
  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={
        closeType === "window"
          ? props.close
          : () => {
              setCloseType("window");
              setShowAddresses(false);
              setAddressEditing(false);
            }
      }
      showInfo={showInfo}
      noPlaceholder={true}
    >
      <Wrapper>
        {!showAddresses && (
          <OrdersDetailsSection
            initialOrder={emptyStringify(props.order)}
            order={order}
            edit={showEditOrder}
            toggleEdit={toggleEdit}
            updateInfo={updateInfo}
            resetInfo={resetInfo}
            resetState={resetState}
            setCloseType={setCloseType}
          />
        )}
        <AddressPicker
          addresses={[
            { title: "Billing Address", data: addresses[0] },
            { title: "Delivery Address", data: addresses[1] },
          ]}
          editable={showEditOrder || addressEditing}
          updateAddresses={updateAddresses}
          setShowAddresses={setShowAddresses}
          showAddresses={showAddresses}
          setCloseType={setCloseType}
          addressEditing={addressEditing}
        />
      </Wrapper>
    </Window>
  );
};

export default UpdateOrderForm;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  min-height: 75vh;
  width: 90vw;
  max-width: 1280px;
`;
