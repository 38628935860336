import styled from "styled-components";
type Props = {
  onClick: () => void;
};
const CheckoutButton = (props: Props) => {
  return <Wrapper onClick={props.onClick}>Next</Wrapper>;
};
export default CheckoutButton;

const Wrapper = styled.div`
  height: 2.5em;
  width: 100%;
  background-color: var(--orange-color);
  color: white;
  display: grid;
  align-items: center;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
`;
