import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

const SidebarFooter = () => {
  return (
    <Wrapper>
      <Link to={"/about"}>About</Link>
      <Link to={"/showroom-licence/form"}>Showroom Licence</Link>
      <Link to={"/contact"}>Contact</Link>
      <Link to={"/account"}>My Account</Link>
    </Wrapper>
  );
};

export default SidebarFooter;

const Wrapper = styled.div`
  display: grid;
  text-align: left;
  margin: 20px;
  margin-top: 10px;
  grid-gap: 10px;
  border-top: solid 1px #b7b4b4;
  padding-top: 20px;

  a {
    text-decoration: none;
    color: var(--grey-color);
    font-weight: 450;

    :hover {
      color: var(--orange-color);
    }
  }
`;
