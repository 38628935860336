import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SidebarInner from "./SidebarInner";
import SidebarHeader from "./SidebarHeader";
import SidebarFooter from "./SidebarFooter";
import AdminSidebarInner from "./AdminSidebar/AdminSidebarInner";

type Props = {
  isExpanded: boolean;
  adminMode?: boolean;
};

const Sidebar = (props: Props) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { isExpanded } = props;

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isExpanded]);

  useEffect(() => {
    const scrollHandler = () => {
      if (contentRef.current) {
        setIsScrolled(contentRef.current.scrollTop > 0);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", scrollHandler);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", scrollHandler);
      }
    };
  }, []);

  return (
    <Wrapper>
      <SidebarHeader adminMode={props.adminMode} isScrolled={isScrolled} />
      <ContentWrapper ref={contentRef}>
        {props.adminMode ? <AdminSidebarInner /> : <SidebarInner />}
      </ContentWrapper>
      <SidebarFooter />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  width: 42vh;
  min-width: 300px;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    width: 100vw;
  }
`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export default Sidebar;
