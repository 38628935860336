import React, { ReactElement, useEffect, useState } from "react";
import { getPlatforms } from "../../../../api/Platform";

import styled from "styled-components";
import AddButton from "../../../../components/other/buttons/add";
import { deletePlatform } from "../../../../api/Admin";
import InputRow from "./inputRow";
import { DateTime } from "luxon";
import { AxiosError } from "axios";
import InfoRow from "../../Reusables/infoRow";
import useInfo from "../../Hooks/useInfo";
import EditButton from "../../../../components/other/buttons/edit";
import DeleteButton from "../../../../components/other/buttons/delete";
import DelRow from "./delRow";
import UpdatePlatformModal from "./modal";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import { useLocation } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";

export type Platform = {
  id: number;
  name: string;
  description: string;
  url: string;
  addDate: string;
  updateDate: string;
  imageUrl: string;
  status: string;
  productCount: number;
  featuredBrands: [];
};

const Platforms = () => {
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [platformToEdit, setPlatformToEdit] = useState<{
    data: Platform;
    index: number;
  } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [platformToDelete, setPlatformToDelete] = useState<Platform | null>(
    null
  );
  const [showAddRow, setShowAddRow] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();
  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (platformToEdit) {
      setPlatformToDelete(null);
    }
  }, [platformToEdit]);

  useEffect(() => {
    if (platformToDelete) {
      setPlatformToEdit(null);
    }
  }, [platformToDelete]);

  const loadPlatforms = async () => {
    getPlatforms().then((res) => {
      if (res.ok) {
        setPlatforms(res.data.objects);
        setLoading(false);
      }
    });
  };

  const handleRowClick = (data: Platform, index?: number) => {
    if (index !== undefined) {
      setPlatformToEdit({ data: data, index: index });
      setShowModal(true);
    }
  };

  const handleCloseAddRow = () => {
    setShowAddRow(false);
  };

  const refreshData = () => {
    setLoading(true);
  };

  const handleDel = async (
    index?: number,
    setData?: (newData: any) => void
  ) => {
    if (platformToDelete) {
      try {
        setSubmitting(true);
        await deletePlatform(platformToDelete.id);
        setData && setData({ ...platformToDelete, status: "DELETED" });
        setPlatforms((prev) => {
          return prev.map((item) => {
            if (item.id === platformToDelete.id) {
              return { ...item, status: "DELETED" };
            }
            return item;
          });
        });
        setSubmitting(false);
        updateInfo(
          `Brand ${
            platformToDelete.name ? platformToDelete.name : platformToDelete.id
          } deleted successfully`,
          platformToDelete.id,
          false,
          index
        );
      } catch (e: any | AxiosError) {
        updateInfo(
          `${
            e.message ? e.message : "Something went wrong, please try again"
          } `,
          platformToDelete.id ? platformToDelete.id : null,
          true
        );
      } finally {
        setSubmitting(false);
        setPlatformToEdit(null);
        setPlatformToDelete(null);
      }
    }
  };

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Name",
        accessor: "name",
        sortBy: "name",
        columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Description",
        accessor: "description",
        sortBy: "description",
        columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Url",
        accessor: "url",
        columnSizing: { min: "120px", max: "1fr" },
      },

      {
        text: "Product Count",
        accessor: "productCount",
        sortBy: "productCount",
      },
      {
        text: "Add Date",
        accessor: "addDate",
        sortBy: "addDate",
        cb: (data: any, value: any) => {
          return DateTime.fromISO(value).toLocaleString();
        },
      },
      {
        text: "",
        columnSizing: { min: "120px", max: "120px" },
        cb: (data: any, value: any, index: number | undefined) => {
          return (
            <ButtonWrap>
              <EditButton
                onClick={() => {
                  handleRowClick(data, index);
                }}
                disabled={
                  showAddRow ||
                  (showInfo && fail) ||
                  platformToEdit != null ||
                  (platformToDelete != null && platformToDelete.id != data.id)
                }
              />
              <DeleteButton
                iconType={"Row"}
                onClick={() => setPlatformToDelete(data)}
                disabled={
                  showAddRow ||
                  (showInfo && fail) ||
                  platformToEdit !== null ||
                  (platformToDelete !== null && platformToDelete.id !== data.id)
                }
              />
            </ButtonWrap>
          );
        },
      },
    ],
    [showAddRow, platformToDelete, platformToEdit, showInfo]
  );

  useEffect(() => {
    if (loading) {
      loadPlatforms();
    }
  }, [loading]);

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setShowAddRow(true);
            setPlatformToEdit(null);
            setPlatformToDelete(null);
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  const infoRow = React.useMemo<React.ReactNode>(
    () => <InfoRow message={info} hasFailed={fail} resetInfo={resetInfo} />,
    [fail, info, infoId, index]
  );

  const getInputRow = (setData?: (newData: any) => void): ReactElement => {
    return (
      <InputRow
        close={handleCloseAddRow}
        refresh={refreshData}
        updateInfo={updateInfo}
        buttonsDisabled={showInfo && fail}
        updateData={setData}
      />
    );
  };

  const getDelRow = (setData?: (newData: any) => void): ReactElement => {
    return (
      <DelRow
        data={platformToDelete}
        handleDel={handleDel}
        close={() => setPlatformToDelete(null)}
        refresh={refreshData}
        updateInfo={updateInfo}
        buttonsDisabled={showInfo && fail}
      />
    );
  };

  useEffect(() => {
    setPlatformToEdit(null);
    setShowModal(false);
  }, [location]);

  return (
    <Wrapper>
      {showModal && platformToEdit && (
        <UpdatePlatformModal
          platform={platformToEdit.data}
          close={() => {
            setPlatformToEdit(null);
            setShowModal(false);
          }}
          updateTable={refreshData}
        />
      )}
      {breakpoint > 920 ? (
        <TableV2
          data={platforms}
          headerRow={setupTable}
          showAddRow={showAddRow}
          infoRow={showInfo && infoRow}
          tableHeader={tableHeader}
          loading={loading}
          delId={platformToDelete && platformToDelete.id}
          infoId={infoId}
          getInputRow={getInputRow}
          getDelRow={getDelRow}
        />
      ) : (
        <MobileTable
          padding={40}
          data={platforms}
          headerRow={setupTable}
          showAddRow={showAddRow}
          infoRow={showInfo && infoRow}
          tableHeader={tableHeader}
          loading={loading}
          delId={platformToDelete && platformToDelete.id}
          infoId={infoId}
          getInputRow={getInputRow}
          getDelRow={getDelRow}
        />
      )}
    </Wrapper>
  );
};
export default Platforms;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
