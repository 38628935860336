import { Link } from "react-router-dom";
import styled from "styled-components";
import React, { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import Subheading from "../../../../components/subheading";
import LoginBox from "../../../../components/other/login";
import SupportForm from "../form";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const SupportUpper = () => {
  const { user } = useContext(AuthContext);
  return (
    <ContentWrapper withLoc={true}>
      <Wrapper>
        <SupportText>
          <SupportTitle>
            <Subheading h3={"GET"} h2={"SUPPORT"}></Subheading>
          </SupportTitle>
          <p>
            Please complete the form below to submit your support query and our
            development team will get back to you as quickly as possible. The
            more information you can provide us with, the quicker we can deal
            with your query.
          </p>

          <h5>I need to Transfer a Licence</h5>
          {user && (
            <p>
              Please use our{" "}
              <Link to={"/license-transfer/form"}>Transfer Licence Form</Link>{" "}
              to transfer a licence from one controller to another. Using this
              form will ensure this is dealt with quickly and efficiently by a
              member of our team.
            </p>
          )}
          {!user && (
            <p>
              Please Log in to use our{" "}
              <Link to={"/login"}>Transfer Licence Form</Link> to transfer a
              licence from one controller to another. Using this form will
              ensure this is dealt with quickly and efficiently by a member of
              our team.
            </p>
          )}

          <h5>I'd like a Free Licence for my Demo System</h5>
          {user && (
            <p>
              No problem! We offer dealers free showroom licences for their
              public showrooms and testing systems. You will receive a pack of
              licences that will allow you to use our complete driver range for
              your chosen control platform. Simply complete our{" "}
              <Link to={"/showroom-licence/form"}>
                Showroom Licence Request Form
              </Link>
              .
            </p>
          )}
          {!user && (
            <p>
              No problem! We offer dealers free showroom licences for their
              public showrooms and testing systems. You will receive a pack of
              licences that will allow you to use our complete driver range for
              your chosen control platform. Please Log in to use our{" "}
              <Link to={"/login"}>Showroom Licence Request Form</Link>.
            </p>
          )}
          <h5>View your support tickets</h5>
          {user && (
            <p>
              To see your support tickets please follow this{" "}
              <Link to={"/support/requests"}>link</Link>.
            </p>
          )}
        </SupportText>
        {!user ? (
          <LoginWrap>
            <LoginBox />
          </LoginWrap>
        ) : (
          <SupportForm />
        )}
      </Wrapper>
    </ContentWrapper>
  );
};

export default SupportUpper;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  grid-gap: 2em;
  margin-bottom: 4em;
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

const SupportTitle = styled.div`
  display: grid;
  position: relative;
  min-height: 1px;
  justify-self: start;
  font-size: 0.8em;
  margin-bottom: 1em;
`;

const SupportText = styled.div`
  position: relative;
  min-height: 1px;
  grid-column: 1;
  justify-self: end;
  p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.48;
  }

  h5 {
    text-align: justify;
    font-size: 18px;
    line-height: 1.4;
    word-wrap: break-word;
    font-weight: 800;
    margin-block-start: 1.67rem;
    margin-block-end: 0.25rem;
  }

  a {
    color: var(--orange-color);
    text-decoration: underline;
    line-height: 1.4;
  }

  @media only screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: auto;
    font-size: 1.2em;
    padding: 0;
  }
`;

const LoginWrap = styled.div`
  display: grid;
  grid-column: 2;
  margin-top: 3em;
  justify-self: start;
  @media only screen and (max-width: 992px) {
    grid-column: 1;
    justify-self: center;
    margin-top: 0;
  }
`;
