import React from "react";
import "../../styles/form.css";
import { FormInputProps, LabelInterface } from "../../types";
import Label from "../label";
import FormInput from "../forminput";
import { LabeledInputWrap } from "../../styledComponents";
import { ThemeProvider } from "styled-components";

export interface LabeledInputProps extends FormInputProps {
  label: LabelInterface;
}

const LabeledInput = (props: LabeledInputProps) => {
  const theme = {
    transform:
      props.value.length <= 0 ? "translateY(0px)" : "translateY(-18px)",
    opacity: props.value.length <= 0 ? 0 : 100,
    index: props.value.length <= 0 ? -1 : 1,
  };

  return (
    <ThemeProvider theme={theme}>
      <LabeledInputWrap spellCheck={"false"}>
        {" "}
        {props.label && (
          <Label
            text={props.label.text}
            for={props.label.for}
            required={props.label.required}
          />
        )}
        <FormInput
          placeholder={
            props.label.required ? props.label.text + "*" : props.label.text
          }
          {...props}
        />
      </LabeledInputWrap>
    </ThemeProvider>
  );
};

export default LabeledInput;
