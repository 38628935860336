import React, { useEffect, useState } from "react";
import "../../styles/form.css";
import { FormInputProps } from "../../types";
import styled from "styled-components";
import { FormInputWrap } from "../../styledComponents";

export interface InputProps extends FormInputProps {
  placeholder?: string;
}

const FormInput = (props: InputProps) => {
  const [displayError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(true);

  useEffect(() => {
    if (props.showerrors && !props.isvalid) {
      showError();
    }
  }, [props.showerrors, props.isvalid]);

  useEffect(() => {
    if (props.isvalid) {
      hideError();
    } else {
      showError();
    }
  }, [props.value, props.isvalid]);

  const showError = (): void => {
    setShowError(true);
  };

  const hideError = (): void => {
    setShowError(false);
  };

  useEffect(() => {
    hideError();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handlechange(event);
    if (props.handlevalidation) {
      props.handlevalidation(event);
    }
  };

  const handleBlur = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.FocusEvent<HTMLInputElement, Element>
      | React.FocusEvent<HTMLTextAreaElement, Element>
      | React.FocusEvent<HTMLSelectElement, Element>
  ) => {
    if (props.handlevalidation) {
      props.handlevalidation(event);
      showError();
    }
  };

  return (
    <>
      <FormInputWrap>
        <input
          disabled={props.disabled}
          placeholder={props.placeholder}
          name={props.name}
          className={` ${!props.isvalid && displayError && "error-outline"}`}
          type={props.type && showPassword ? props.type : "text"}
          value={props.value}
          onChange={(event) => {
            handleChange(event);
          }}
          data-field={props.validationfield}
          data-case={props.case}
          data-validationtype={props.validationtype}
          onBlur={(event) => {
            handleBlur(event);
          }}
        />

        {props.type === "password" && (
          <Visualiser
            onClick={() => {
              setShowPassword((prev) => {
                return !prev;
              });
            }}
            className={`${showPassword ? "ri-eye-fill" : "ri-eye-off-fill"}`}
          ></Visualiser>
        )}
      </FormInputWrap>
      {props.error && props.disabled !== true && (
        <ErrorP>{props.error.message}</ErrorP>
      )}
    </>
  );
};

const ErrorP = styled.p`
  color: #990000;
  font-size: 0.7em;
  //grid-Row: -1;
  grid-column: 1/-1;
  justify-self: start;
  position: absolute;

  margin-top: 38px;
  text-align: start;
`;

// const DummyP = styled.p`
//   color: #990000;
//   height: 0.7em;
//   grid-Row: -1;
//   grid-column: 1/-1;
//   justify-self: start;
// `;

const Visualiser = styled.i`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  cursor: pointer;
`;

export default FormInput;
