import LoginForm from "./form";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ContentWrapper from "../../../components/reusables/ContentWrapper";

// Left in for testing T247
const BuggyComponent = () => {
  throw new Error("Unreachable code");
  return <div>Unreachable Code</div>;
};

const Login = () => {
  const { user, showError, errorMsg } = useContext(AuthContext);
  const nav = useNavigate();

  useEffect(() => {
    if (user) {
      nav("/account");
    }
  }, [user, nav]);

  return (
    <ContentWrapper>
      <LoginWrap>
        <ErrorDisplay>
          <p>{showError && errorMsg}</p>
        </ErrorDisplay>
        <LoginForm />
      </LoginWrap>
    </ContentWrapper>
  );
};

export default Login;

const ErrorDisplay = styled.div`
  height: 1em;
  justify-self: start;
  color: #990000;
`;

const LoginWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  margin-left: 10%;
  margin-right: 10%;
  grid-gap: 1em;
  margin-bottom: 4em;
  border: solid 1px rgba(0, 0, 0, 0.16);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 26em;
  padding: 1.5em;
  justify-items: center;
  justify-self: center;
  border-radius: 9px;
  align-self: center;
  grid-row: 1/-1;
  margin-top: 6em;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    width: fit-content;
    box-shadow: none;
    border: none;
    align-self: center;
    padding: 0;
    margin-top: 0;
  }
`;
