import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ShowMenuContext } from "../../../../context/MenuContext";

export type DropdownValue = {
  displayValue: string;
  value: string;
  fieldName?: string;
};

export type DropdownList = DropdownValue[];

export type DropdownEvent = {
  target: {
    value: string;
    dataset: { case: any };
    displayName: string;
    name: string | undefined;
  };
};

interface DropdownInputProps {
  items?: DropdownList;
  value: string;
  handlechange: (event: any) => void;
  dataCase?: any;
  width?: string;
  selectedStyle?: React.CSSProperties;
  id: string;
  height?: string;
}

const DropdownInput = (props: DropdownInputProps) => {
  const { isMenuOpen, toggleOn, toggleOff, reset } =
    useContext(ShowMenuContext);
  const { value, handlechange, items, dataCase, selectedStyle } = props;

  const [displayValue, setDisplayValue] = useState(
    props.items?.find((item) => {
      return item.value === value;
    })?.displayValue || ""
  );

  useEffect(() => {
    const newDisplayValue = props.items?.find((item) => {
      return item.value === value;
    })?.displayValue;

    setDisplayValue(newDisplayValue ?? "");
  }, [value, items]);

  const handleSelect = (
    itemValue: string,
    displayValue: string,
    field?: string
  ) => {
    handlechange({
      target: {
        value: itemValue,
        dataset: { case: dataCase },
        displayName: displayValue,
        name: field,
      },
    });
    reset();
  };

  return (
    <DropdownInputWrap style={props.width ? { width: props.width } : {}}>
      <Selected
        data-menu={true}
        onClick={(e) => {
          if (!isMenuOpen(props.id)) {
            toggleOn(props.id);
          }
        }}
        style={selectedStyle}
      >
        {displayValue ? displayValue : value}
        <i
          data-menu={true}
          className={`ri-arrow-${isMenuOpen(props.id) ? "up" : "down"}-s-fill`}
        ></i>
      </Selected>
      {isMenuOpen(props.id) && items && (
        <Menu style={{maxHeight: props.height ?? "300px"}}>
          {items
            .filter((item) => item.value !== value)
            .map((item) => (
              <p
                data-menu={true}
                key={item.value}
                onClick={() =>
                  handleSelect(item.value, item.displayValue, item.fieldName)
                }
              >
                {item.displayValue}
              </p>
            ))}
        </Menu>
      )}
    </DropdownInputWrap>
  );
};

export default DropdownInput;

const DropdownInputWrap = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
`;

const Selected = styled.div`
  position: relative;
  border: solid 1px rgba(0, 0, 0, 0.23);
  font: inherit;
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: solid 1px rgba(0, 0, 0, 0.23);
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: left;
  border-radius: 4px;
  z-index: 10;
    overflow-y: scroll;

  p {
    z-index: 9;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;
