import { Product } from "../../../../../globaltypes";
import InnerDescription from "./innerPanel/innerDescription";
import Information from "./innerPanel/intrinsic";
import Supported from "./innerPanel/supported";
import styled from "styled-components";

interface PanelProps {
  product: Product;
  activeTab: string;
}

const Panel = (props: PanelProps) => {
  return (
    <Wrapper>
      {props.activeTab === "description" && (
        <InnerDescription product={props.product} />
      )}
      {props.activeTab === "intrinsic" && <Information />}
      {props.activeTab === "supported" && <Supported product={props.product} />}
      <Cover />
    </Wrapper>
  );
};

export default Panel;

const Wrapper = styled.div`
  position: relative;
  padding: 25px;
  background-color: #f8f8f8;
  grid-column: 1/-1;
  text-align: left;
  height: max-content;
  line-height: 1.3;
  display: grid;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 35em;

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: initial;
    padding-left: 2em;
    padding-right: 2em;
  }

  li {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  h2,
  h3 {
    font-weight: bold;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    font-size: 1.1em;
  }

  a {
    color: var(--orange-color);
  }

  @media only screen and (max-width: 920px) {
    width: inherit;
  }
`;

const Cover = styled.div`
  background-color: #f8f8f8;
  width: 100%;
  position: absolute;
  height: 2px;
  left: 0;
  top: -2px;
`;
