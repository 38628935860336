import { MyResponse, RequestConfig, requestMiddleware } from "./Request";
import { DriverRequestForm } from "../views/FormPages/Driver/requestForm";

export async function postDriverRequestForm(
  data: DriverRequestForm
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/forms/driver-request",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function getDriverRequests(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/driver-requests",
    method: "GET",
  };
  return requestMiddleware(config);
}
