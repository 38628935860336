import styled from "styled-components";
import cookie from "../../../assets/images/other/cookie.png";
import { Link } from "react-router-dom";
import usePreventScroll from "../../../hooks/usePreventScroll";

type Props = {
  accept: () => void;
  decline: () => void;
  hideHandler?: () => void;
};
const CookieBannerModal = (props: Props) => {
  usePreventScroll(!props.hideHandler);

  return (
    <Wrapper>
      <Window>
        <Header>
          <ImageWrap>
            <Cookie src={cookie} />
          </ImageWrap>
          {props.hideHandler ? (
            <Hide
              onClick={props.hideHandler}
              className="ri-arrow-down-s-line"
            />
          ) : (
            <Close onClick={props.decline} className="ri-close-line"></Close>
          )}
        </Header>
        <Inner>
          <Info>
            Our website uses cookies to provide and improve your shopping
            experience. If you want to benefit from this improved service, and
            ensure our website functions correctly for you please opt-in.{" "}
            <Link to={"/privacy-policy"}>View </Link>
            our Cookies Policy.
          </Info>
          <Buttons>
            <Accept onClick={props.accept}>Accept</Accept>
            <Reject onClick={props.decline}>Reject</Reject>
          </Buttons>
        </Inner>
      </Window>
    </Wrapper>
  );
};

export default CookieBannerModal;

const Wrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Window = styled.div`
  height: 250px;
  width: 500px;
  background-color: white;
  align-self: center;
  justify-self: center;
  border-radius: 6px;
  display: grid;
  @media only screen and (max-width: 920px) {
    width: 100%;
    align-self: end;
    justify-self: unset;
    border-radius: unset;
  }
`;

const Info = styled.p`
  align-self: center;
  z-index: 10;

  a {
    text-decoration: none;
    color: var(--orange-color);
  }
`;
const Buttons = styled.div`
  align-self: end;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  button {
    height: 40px;
    width: 100%;
    color: white;
    display: grid;
    align-items: center;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: bold;
  }
`;

const ImageWrap = styled.div`
  background-color: white;
  width: min-content;
  height: min-content;
  justify-self: center;
  border-radius: 50%;
  padding: 10px;
  top: 15px;
  position: absolute;
`;

const Header = styled.div`
  background-color: var(--orange-color);
  height: 40px;
  display: grid;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  @media only screen and (max-width: 920px) {
    border-radius: unset;
  }
`;

const Inner = styled.div`
  padding: 20px;
  display: grid;
`;

const Cookie = styled.img`
  height: 50px;
  width: 50px;
  justify-self: center;
`;

const Accept = styled.button`
  background-color: var(--orange-color);
`;
const Reject = styled.button`
  background-color: var(--grey-color);
`;

const Close = styled.i`
  justify-self: end;
  align-self: center;
  padding-right: 6px;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
`;

const Hide = styled.i`
  justify-self: end;
  align-self: center;
  padding-right: 6px;
  font-size: 1.5em;
  color: white;
  cursor: pointer;
`;
