import { forwardRef, Ref, useRef } from "react";
import Reaptcha from "reaptcha";
import useBreakpoint from "../../hooks/useBreakpoint";

const Captcha = forwardRef(
  (props: { resultCallback: Function }, ref: Ref<Reaptcha> | undefined) => {
    // TODO: fixe environment variable always being undefined
    const { breakpoint } = useBreakpoint();
    const env_site_key = process.env.REACT_APP_CAPTCHA_KEY;

    const site_key = env_site_key
      ? env_site_key
      : "6LdrZvciAAAAAPJ-IYKDTlpbzDVLJyR_U4VbYMrH";
    const captchaRef = useRef(null);

    const handleVerify = () => {
      props.resultCallback(true);
    };

    return (
      <div>
        <Reaptcha
          size={breakpoint <= 350 ? "compact" : "normal"}
          ref={ref}
          sitekey={site_key}
          onVerify={handleVerify}
        />
      </div>
    );
  }
);

export default Captcha;
