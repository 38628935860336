import { useReducer } from "react";
import { InputHandler } from "../../types";
import {
  UpdateProductImageActionCase,
  UpdateProductImageFormState,
} from "../../setup/admin/product/updateProductTypes";
import { UpdateProductImageReducer } from "../../setup/admin/product/productImage";
import {
  addNewProductMediaFileAdmin,
  addNewProductMediaUrlAdmin,
  editProductMediaAdmin,
} from "../../../../api/Admin";

const useUpdateProductImageForm = (
  initState?: UpdateProductImageFormState | null
) => {
  let [state, dispatch] = useReducer(
    UpdateProductImageReducer,
    initState as UpdateProductImageFormState
  );

  const handleInputChange: InputHandler = (e): void => {
    let selectedCase: UpdateProductImageActionCase;
    switch (e.target.dataset.case) {
      default:
        selectedCase = UpdateProductImageActionCase.GENERAL;
    }
    dispatch({
      field: e.target.name,
      value: e.target.value,
      case: selectedCase,
    });
  };

  const handleFileInput = (file: File): void => {
    dispatch({
      value: file,
      case: UpdateProductImageActionCase.UPDATE_IMAGE,
    });
  };

  const removeFile = (): void => {
    dispatch({
      case: UpdateProductImageActionCase.REMOVE_FILE,
    });
  };

  const handleDropdownInputChange = (
    value: string,
    productCase?: UpdateProductImageActionCase
  ): void => {
    let selectedCase: UpdateProductImageActionCase;
    switch (productCase) {
      case UpdateProductImageActionCase.UPDATE_MEDIA_TYPE:
        selectedCase = UpdateProductImageActionCase.UPDATE_MEDIA_TYPE;
        dispatch({
          value: value,
          case: selectedCase,
        });
        break;
    }
  };

  const handleSubmit = async (newMedia: boolean) => {
    if (newMedia) {
      //POST
      if (state.file !== undefined) {
        let formData = new FormData();
        formData.append("name", state.name);
        formData.append("productId", state.productId.toString());
        formData.append("file", state.file);
        formData.append("description", state.description);
        formData.append("sequence", state.sequence.toString());
        return await addNewProductMediaFileAdmin(formData);
      } else if (state.imageUrl !== "") {
        return await addNewProductMediaUrlAdmin(state);
      }
    } else {
      //PUT
      if (state.id) {
        return await editProductMediaAdmin(state.id, {
          name: state.name,
          description: state.description,
          imageUrl: state.imageUrl,
          sequence: state.sequence,
        });
      }
    }
  };

  const setEditState = (editState: UpdateProductImageFormState) => {
    dispatch({
      value: editState,
      case: UpdateProductImageActionCase.EDIT,
    });
  };

  return [
    state,
    handleInputChange,
    handleSubmit,
    setEditState,
    handleFileInput,
    handleDropdownInputChange,
    removeFile,
  ] as const;
};

export default useUpdateProductImageForm;
