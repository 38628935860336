import styled from "styled-components";
import Card from "../../../../Reusables/card";
import React from "react";
import {
  UpdateProductActionCase,
  UpdateProductFormState,
} from "../../../../../../components/form/setup/admin/product/updateProductTypes";
import { InputHandler } from "../../../../../../components/form/types";

interface Props {
  product: UpdateProductFormState;
  toggleEdit: () => void;
  edit: boolean;
  handleInputChange: InputHandler;
}

const ProductCostSection = (props: Props) => {
  const renderSymbol = (type: string) => {
    switch (type) {
      case "GBP":
        return "£";
      case "AUD":
        return "$";
      case "CAD":
        return "$";
      case "EUR":
        return "€";
      case "USD":
        return "$";
      default:
        return "$";
    }
  };

  return (
    <Inner>
      {props.product.costs.map((cost) => (
        <Card
          heading={cost.currencyType}
          text={renderSymbol(cost.currencyType) + cost.currencyValue.toString()}
          editing={props.edit}
          inputSetup={{
            value: cost.currencyValue,
            onChange: props.handleInputChange,
            name: cost.currencyType,
          }}
          case={UpdateProductActionCase.UPDATE_COST}
        />
      ))}
    </Inner>
  );
};

export default ProductCostSection;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  @media only screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;
