import LabeledInput from "../../../../components/form/components/labeledInput";
import React, { useContext, useState } from "react";
import PhoneInput from "../../../../components/form/components/phoneinput";
import Captcha from "../../../../components/captcha";
import DropdownInput from "../../../../components/form/components/dropdownInput";
import { CountryContext } from "../../../../context/CountryContext";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Address, Country } from "../../../../components/form/types";
import { v4 as uuidv4 } from "uuid";
import ControlCheckboxes from "./ControlCheckboxes";
import { registerUser } from "../../../../api/Auth";
import SuccessScreen from "../successScreen";
import CustomButton from "../../../../components/other/buttons/submit";

export interface ControlPlatforms {
  Control4: string;
  Crestron: string;
  Elan: string;
  RTI: string;
  Savant: string;
}

export interface FormValues {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailConfirmation: string;
  phone: { number: string; country: Country };
  country: Country;
  website: string;
  password: string;
  passwordConfirmation: string;
  companyName: string;
  address: Address;
  controlPlatforms: ControlPlatforms;
  newsletter: boolean;
}

type Props = {
  success: boolean;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

const RegisterForm = (props: Props) => {
  const { countries, countriesLoading } = useContext(CountryContext);
  const [loading, setLoading] = useState(false);
  const { success, setSuccess } = props;
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      emailConfirmation: "",
      phone: { number: "", country: countries[233] },
      website: "",
      password: "",
      passwordConfirmation: "",
      companyName: "",
      address: {
        firstLine: "",
        secondLine: "",
        city: "",
        state: "",
        zipCode: "",
        country: "United Kingdom",
      },
      controlPlatforms: {
        Control4: "",
        Crestron: "",
        Elan: "",
        RTI: "",
        Savant: "",
      },
      newsletter: false,
    },
    mode: "onChange",
  });

  const handleCountryNew = (country: Country) => {
    setValue("phone.country", country);
  };

  const watchedCountry = watch("phone.country", countries[233]);

  const onSubmit = async (data: any) => {
    setErrorMsg(null);
    try {
      setLoading(true);
      const res = await registerUser(data);
      if (res.ok) {
        setSuccess(true);
      }
    } catch (e: any) {
      setErrorMsg(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const [selectedPlatforms, setSelectedPlatforms] = useState({
    Control4: false,
    Crestron: false,
    Elan: false,
    RTI: false,
    Savant: false,
  });

  const handlePlatformChange = (platform: keyof ControlPlatforms) => {
    setSelectedPlatforms((prev) => ({
      ...prev,
      [platform]: !prev[platform],
    }));
  };
  return (
    <>
      {!success ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper>
            <Column>
              <Controller
                name="companyName"
                control={control}
                defaultValue={""}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name={"companyName"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={errors.companyName}
                    label={{
                      for: "companyName",
                      required: true,
                      text: "Company Name",
                    }}
                  />
                )}
              />
              <Controller
                defaultValue={""}
                name="website"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <LabeledInput
                    name={"website"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Website/Url",
                      for: "website",
                      required: false,
                    }}
                  />
                )}
              />
              <Controller
                name="firstName"
                control={control}
                defaultValue={""}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name={"firstName"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={errors.firstName}
                    label={{
                      for: "firstName",
                      required: true,
                      text: "Your first name",
                    }}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"lastName"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Last Name",
                      for: "lastName",
                      required: true,
                    }}
                    error={errors.lastName}
                  />
                )}
              />
              <Controller
                name="username"
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"username"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Username",
                      for: "username",
                      required: true,
                    }}
                    error={errors.username}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: {
                    matchesOtherEmail: (value) => {
                      const { emailConfirmation } = getValues();
                      return (
                        emailConfirmation === "" ||
                        emailConfirmation === value ||
                        "Emails do not match"
                      );
                    },
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"email"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{ text: "Email", for: "email", required: true }}
                    error={errors.email}
                  />
                )}
              />
              <Controller
                name="emailConfirmation"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: {
                    matchesPreviousEmail: (value) => {
                      const { email } = getValues();
                      return email === value || "Emails do not match";
                    },
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"emailConfirmation"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Confirm Email",
                      for: "emailConfirm",
                      required: true,
                    }}
                    error={errors.emailConfirmation}
                  />
                )}
              />
            </Column>
            <Column>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: {
                    matchesOtherEmail: (value) => {
                      const { passwordConfirmation } = getValues();
                      return (
                        passwordConfirmation === "" ||
                        passwordConfirmation === value ||
                        "Passwords do not match"
                      );
                    },
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"password"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Password",
                      for: "password",
                      required: true,
                    }}
                    type="password"
                    error={errors.password}
                  />
                )}
              />
              <Controller
                name="passwordConfirmation"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: {
                    matchesOtherPass: (value) => {
                      const { password } = getValues();
                      return password === value || "Passwords do not match";
                    },
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"passwordConfirmation"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Confirm Password",
                      for: "passwordConfirm",
                      required: true,
                    }}
                    type="password"
                    error={errors.passwordConfirmation}
                  />
                )}
              />
              <Controller
                name="address.firstLine"
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"line1"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Address line 1",
                      for: "line1",
                      required: true,
                    }}
                    error={errors.address?.firstLine}
                  />
                )}
              />
              <Controller
                name="address.secondLine"
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue={""}
                render={({ field }) => (
                  <LabeledInput
                    name={"line2"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    label={{
                      text: "Address line 2",
                      for: "line2",
                      required: true,
                    }}
                    error={errors.address?.secondLine}
                  />
                )}
              />
              <InputSplitter>
                <Controller
                  name="address.city"
                  control={control}
                  rules={{ required: "This field is required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <LabeledInput
                      name={"city"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      label={{ text: "City", for: "city", required: true }}
                      error={errors.address?.city}
                    />
                  )}
                />
                <Controller
                  name="address.state"
                  control={control}
                  rules={{ required: "This field is required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <LabeledInput
                      name={"state"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      label={{ text: "State", for: "state", required: true }}
                      error={errors.address?.state}
                    />
                  )}
                />
              </InputSplitter>
              <InputSplitter>
                <Controller
                  name="address.zipCode"
                  control={control}
                  rules={{ required: "This field is required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <LabeledInput
                      name={"zipcode"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      label={{
                        text: "Zip code",
                        for: "zipCode",
                        required: true,
                      }}
                      error={errors.address?.zipCode}
                    />
                  )}
                />
                <DropdownWrapper>
                  <Controller
                    name="address.country"
                    control={control}
                    rules={{ required: "This field is required" }}
                    defaultValue={""}
                    render={({ field }) => (
                      <DropdownInput
                        height={"200px"}
                        id={uuidv4()}
                        value={field.value}
                        handlechange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        items={countries.map((country) => {
                          return {
                            displayValue: country.name,
                            value: country.name,
                            fieldName: "country",
                          };
                        })}
                      />
                    )}
                  />
                </DropdownWrapper>
              </InputSplitter>
              {!countriesLoading && (
                <Controller
                  name="phone.number"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <PhoneInput
                      name={"phone"}
                      value={field.value}
                      handlechange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      countries={countries}
                      currentCountry={watchedCountry}
                      handleCountry={handleCountryNew}
                    />
                  )}
                />
              )}
            </Column>
            <CheckboxWrapper>
              <ControlCheckboxes
                watch={watch}
                getValues={getValues}
                setError={setError}
                clearErrors={clearErrors}
                errors={errors}
                selectedPlatforms={selectedPlatforms}
                control={control}
                handlePlatformChange={handlePlatformChange}
              />
            </CheckboxWrapper>
            <CheckboxWrapper>
              <Controller
                name="newsletter"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <label>
                    <input
                      checked={field.value}
                      type="checkbox"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                    <p>
                      Sign up to our newsletter to be informed of important
                      updates to your purchased drivers and to be the first to
                      hear about new drivers and promotions. You can opt-out at
                      any time in accordance with our{" "}
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>.
                    </p>
                  </label>
                )}
              />
            </CheckboxWrapper>
            <SubmitWrap>
              <CaptchaWrap
                style={{ marginBottom: errorMsg ? "unset" : "20px" }}
              >
                <Captcha resultCallback={setCaptchaVerified} />
              </CaptchaWrap>
              {errorMsg && <ServerError>{errorMsg}</ServerError>}
              <CustomButton
                loading={loading}
                text={"Submit for Approval"}
                disabled={!captchaVerified}
                type={"submit"}
              />
              <LinkWrap>
                Already registered? click{" "}
                <OrangeText>
                  {" "}
                  <Link to={"/login"}>here</Link> to
                </OrangeText>{" "}
                Log in
              </LinkWrap>
            </SubmitWrap>
          </FormWrapper>
        </Form>
      ) : (
        <SuccessScreen email={getValues().email} />
      )}
    </>
  );
};

export default RegisterForm;

const Form = styled.form`
  display: grid;
  margin-bottom: 2em;
  grid-column: 2/4;

  label {
    justify-self: start;
  }
  @media only screen and (max-width: 920px) {
    grid-column: 1;
  }
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: start;
  grid-column-gap: 0;
  @media only screen and (max-width: 920px) {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const InputSplitter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 32em;
  grid-gap: 10px;
`;

const DropdownWrapper = styled.div`
  font-size: 0.9em;
  @media only screen and (max-width: 992px) {
    font-size: 11px;
  }
`;

const Column = styled.div`
  display: grid;
  grid-gap: 25px;
  align-items: center;
  align-content: center;
  padding: 1em;
  border-radius: 7px;
  height: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 920px) {
    padding: 1em 0 1em 0;
  }
`;

const CheckboxWrapper = styled.div`
  grid-column: 1/-1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  input {
    margin-right: 10px;
  }
  a {
    color: var(--orange-color);
    text-decoration: underline;
  }
  p {
    text-align: start;
  }
`;

const CaptchaWrap = styled.div`
  margin-top: 20px;
  justify-self: center;
`;

const SubmitWrap = styled.div`
  display: grid;
  grid-column: 1/-1;
  align-self: center;
  border-radius: 7px;
  justify-self: center;
  height: min-content;
  button {
    width: 100%;
  }
`;

const LinkWrap = styled.p`
  justify-self: center;
  height: 100%;
  margin-top: 1em;
  font-size: 0.9em;
  padding-left: 2px;
`;

const OrangeText = styled.text`
  a {
    color: var(--orange-color);
    text-decoration: none;
  }
`;

const ServerError = styled.div`
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
`;
