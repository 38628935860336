import styled from "styled-components";

const Address = () => {
  return (
    <ContactAddress>
      <AddressTitle>Intrinsic Dev</AddressTitle>
      <AddressText>
        <p>The Maltings</p>
        <p>Allendale</p>
        <p>Hexham</p>
        <p>Northumberland</p>
        <p>NE47 9EE</p>
        <p>United Kingdom</p>
      </AddressText>
      <p>
        Please note – our driver development team operate from multiple offices.{" "}
      </p>
      <AddressTitle>
        If you are sending hardware for testing please confirm a delivery
        address with us before posting.
      </AddressTitle>
    </ContactAddress>
  );
};

export default Address;

const ContactAddress = styled.div`
  line-height: 1.3;
  //width: 9em;
`;

const AddressTitle = styled.div`
  font-weight: 900;
`;
const AddressText = styled.div`
  margin-top: 3px;
  margin-bottom: 12px;
`;
