import React, { useContext } from "react";
import { UserAdminContext } from "../context/UserAdminContext";
import styled from "styled-components";
import LabeledInput from "../../../../../../../components/form/components/labeledInput";
import { ClipLoader } from "react-spinners";

interface Props {
  handleSave: () => void;
  close: () => void;
  loading: boolean;
}

const Submit = (props: Props) => {
  const { updatePass, adminPass } = useContext(UserAdminContext);
  //<SubmitButton onClick={props.handleSave}>Submit</SubmitButton>
  return (
    <Wrapper>
      <Inner>
        <CloseWrap onClick={props.close}>
          <i className="ri-close-fill"></i>
        </CloseWrap>
        <InputWrap>
          <LabeledInput
            type={"password"}
            label={{
              text: "Admin Password",
              for: "adminPass",
              required: false,
            }}
            isvalid={true}
            name={"adminPass"}
            handlechange={updatePass}
            value={adminPass}
          />
        </InputWrap>
        <SubmitButton onClick={props.handleSave}>
          {!props.loading ? "Submit" : <ClipLoader size={10} color={"white"} />}
        </SubmitButton>
      </Inner>
    </Wrapper>
  );
};

export default Submit;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  border-radius: 6px;
`;

const SubmitButton = styled.div`
  background-color: var(--orange-color);
  color: white;
  padding: 5px;
  height: 25px;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  justify-items: center;
  border-radius: 3px;
  cursor: pointer;
`;

const Inner = styled.div`
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const InputWrap = styled.div`
  padding: 20px;
`;
const CloseWrap = styled.div`
  font-size: 1.1em;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 2px;
  background-color: var(--orange-color);
  color: white;
  display: grid;
  justify-items: end;
  justify-content: end;
`;
