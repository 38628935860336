import AboutBanner from "./banner";
import AboutIntro from "./intro";
import styled from "styled-components";
import Subheading from "../../../components/subheading";
import BrandCarousel from "../../../components/carousel/brandCarousel";
import LatestCarousel from "../../../components/carousel/latestCarousel";
import HomeContact from "../Home/contact";
import ContentWrapper from "../../../components/reusables/ContentWrapper";
import { FullWidthSection } from "../../../styles/styledComponents/document";
import useBreakpoint from "../../../hooks/useBreakpoint";

const About = () => {
  const { breakpoint } = useBreakpoint();
  return (
    <>
      <AboutIntro />
      <FullWidthSection bgColor={"#F6F4F2"}>
        <ContentWrapper style={{ marginBottom: "4em" }}>
          <Wrapper>
            <Subheading h3={"TRUSTED"} h2={"SOFTWARE PARTNERS FOR"} />{" "}
            <CarouselWrapper>
              <BrandCarousel />
            </CarouselWrapper>
          </Wrapper>
        </ContentWrapper>
      </FullWidthSection>
      <ContentWrapper style={{ paddingTop: "2em", marginBottom: "2em" }}>
        <Subheading h3="LATEST" h2="RELEASES" />
        <Margin>
          <LatestCarousel hideHeading={true} />
        </Margin>
      </ContentWrapper>
      {breakpoint > 600 && (
        <FullWidthSection style={{ marginBottom: "4em" }} bgColor={"#F6F4F2"}>
          <AboutBanner />
        </FullWidthSection>
      )}
      <HomeContact />
    </>
  );
};

export default About;

const CarouselWrapper = styled.div`
  width: 100%;
  display: grid;
  padding-bottom: 3em;
  @media only screen and (max-width: 920px) {
    padding-bottom: 0em;
  }
`;

const Wrapper = styled.div`
  border-radius: 4px;
  padding-left: 5em;
  padding-right: 5em;
  padding-top: 5em;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  @media only screen and (max-width: 920px) {
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 2.5em;
  }

  @media only screen and (max-width: 920px) {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
  }

  h2 {
    font-size: 0.6em;
  }
  h2 {
    font-size: 1.2em;
  }
`;

const Margin = styled.div`
  margin-top: 1em;
`;
