import { useEffect, useState } from "react";
import useAddressForm from "../../form/hooks/useAddressForm";
import LabeledInput from "../../form/components/labeledInput";
import {
  AddressFormWrap,
  AddressLineWrap,
  FormButton,
} from "../../form/styledComponents";
import {
  AddressActionCase,
  AddressValidationCase,
} from "../../form/setup/address/addressTypes";
import { AddressResponse } from "../../form/types";
import AddressTypeSelect from "../typeSelect";
import { postAddress } from "../../../api/Addresses";
import { MyResponse } from "../../../api/Request";

//const AddAddressForm = (props: {initState? : AddressResponse}): JSX.Element => {
const AddAddressForm = (props: {
  disabled?: boolean;
  initState: AddressResponse;
  submitCallback: Function;
  type?: string;
}): JSX.Element => {
  let [
    formState,
    validationState,
    handleValidation,
    ,
    handleInputChange,
    setEditState,
  ] = useAddressForm(props.initState);

  useEffect(() => {
    setEditState(formState);
  }, []);

  // update initial state from reducer with initState passed to hook
  // this is required for editing addresses

  const [showErrors, setShowErrors] = useState(false);

  return (
    <>
      <AddressFormWrap>
        <LabeledInput
          name={"addressName"}
          value={formState.addressName}
          handlechange={handleInputChange}
          label={{
            for: "addressName",
            required: true,
            text: "Address Name",
          }}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_ADDRESS_NAME}
          validationfield={"addressNameValid"}
          isvalid={validationState.addressNameValid}
          showerrors={showErrors}
        />
        <AddressLineWrap>
          <LabeledInput
            name={"firstLine"}
            value={formState.firstLine}
            handlechange={handleInputChange}
            handlevalidation={handleValidation}
            validationtype={AddressValidationCase.VALIDATE_FIRST_LINE}
            validationfield={"firstLineValid"}
            isvalid={validationState.firstLineValid}
            showerrors={showErrors}
            case={
              props.disabled
                ? AddressActionCase.DISABLED
                : AddressActionCase.GENERAL
            }
            label={{
              for: "firstLine",
              required: true,
              text: "Line 1",
            }}
          />
        </AddressLineWrap>
        <AddressLineWrap>
          <LabeledInput
            name={"secondLine"}
            value={formState.secondLine}
            handlechange={handleInputChange}
            handlevalidation={handleValidation}
            validationtype={AddressValidationCase.VALIDATE_SECOND_LINE}
            validationfield={"secondLineValid"}
            isvalid={validationState.secondLineValid}
            showerrors={showErrors}
            case={
              props.disabled
                ? AddressActionCase.DISABLED
                : AddressActionCase.GENERAL
            }
            label={{
              for: "secondLine",
              required: false,
              text: "Line 2",
            }}
          />
        </AddressLineWrap>
        <LabeledInput
          name={"city"}
          value={formState.city}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_CITY}
          validationfield={"cityValid"}
          isvalid={validationState.cityValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "city",
            required: true,
            text: "City",
          }}
        />
        <LabeledInput
          name={"state"}
          value={formState.state}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"stateValid"}
          isvalid={validationState.stateValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "state",
            required: true,
            text: "State/Region",
          }}
        />
        <LabeledInput
          name={"country"}
          value={formState.country}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"countryValid"}
          isvalid={validationState.countryValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "country",
            required: true,
            text: "Country",
          }}
        />
        <LabeledInput
          name={"zipCode"}
          value={formState.zipCode}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_STATE}
          validationfield={"zipCodeValid"}
          isvalid={validationState.zipCodeValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "zipCode",
            required: true,
            text: "Post Code",
          }}
        />
        <LabeledInput
          name={"deliveryContact"}
          value={formState.deliveryContact}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_DELIVERY_CONTACT}
          validationfield={"deliveryContactValid"}
          isvalid={validationState.deliveryContactValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "deliveryContact",
            required: true,
            text: "Delivery Contact No.",
          }}
        />
        <LabeledInput
          name={"vatCode"}
          value={formState.vatCode}
          handlechange={handleInputChange}
          handlevalidation={handleValidation}
          validationtype={AddressValidationCase.VALIDATE_VAT_CODE}
          validationfield={"vatCodeValid"}
          isvalid={validationState.vatCodeValid}
          showerrors={showErrors}
          case={
            props.disabled
              ? AddressActionCase.DISABLED
              : AddressActionCase.GENERAL
          }
          label={{
            for: "vatCode",
            required: true,
            text: "VAT Code",
          }}
        />

        <AddressTypeSelect
          disabled={props.disabled}
          selected={formState.addressType}
          onChange={(e) => {
            handleInputChange(e);
            handleValidation(e);
            formState.addressType = e.target.value;
          }}
        />

        {!props.disabled && (
          <FormButton
            onClick={(e) => {
              if (props.type === "new") {
                postAddress(formState)
                  .then((res: MyResponse) => {
                    if (res.ok) {
                      props?.submitCallback(true, "", formState);
                    }
                  })
                  .catch((err: any) => {
                    //console.error(err);
                  });
              } else {
                //TODO: Add update when the endpoint is avaliable.
              }
            }}
            disabled={!validationState.formValid}
          >
            Save
          </FormButton>
        )}
      </AddressFormWrap>
    </>
  );
};

export default AddAddressForm;
