import { Link } from "react-router-dom";
import ErrorWrapper from "../ErrorWrapper";

const Error500 = () => {
  return (
    <ErrorWrapper>
      <h2>500</h2>
      <div className="return-link">
        <p>Something went wrong, click the link to return</p>
        <Link to={"/"}>Home</Link>
      </div>
      <p>If the problem persists please contact Intrinsic (DEV) support</p>
    </ErrorWrapper>
  );
};
export default Error500;
