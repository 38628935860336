import React, { useContext, useEffect, useState } from "react";
import { User } from "../Views/Users";
import { getUsersAdmin } from "../../../api/Users";
import { AuthContext } from "../../../context/AuthContext";

interface UserAdminContextInterface {
  users: User[];
  loading: boolean;
}

const initialState = {
  users: [],
  loading: true,
};

const UserAdminContext =
  React.createContext<UserAdminContextInterface>(initialState);

const UserAdminContextProvider = (props: { children: JSX.Element }) => {
  const { isAdmin } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    if (loadingUsers && isAdmin) {
      loadUsers();
    }
  }, [loadingUsers, isAdmin]);

  const loadUsers = async () => {
    //TODO ERROR
    try {
      getUsersAdmin().then((res) => {
        if (res.ok) {
          setUsers(res.data);
          setLoadingUsers(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <UserAdminContext.Provider
      value={{
        users: users,
        loading: loadingUsers,
      }}
    >
      {props.children}
    </UserAdminContext.Provider>
  );
};

export { UserAdminContextProvider, UserAdminContext };
