import React from "react";
import styled from "styled-components";

type Props = {
  loading?: boolean;
} & React.ComponentProps<"input">;

const SearchInput = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>) => {
    const { loading, ...inputProps } = props;
    return (
      <Wrapper>
        <StyledInput {...inputProps} ref={ref} />
        <InputIcon>
          {loading ? <div>loading</div> : <i className="ri-search-line"></i>}
        </InputIcon>
      </Wrapper>
    );
  }
);

export default SearchInput;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  width: 100%;
  grid-column: 1/-1;
`;

const StyledInput = styled.input`
  border: 1px solid var(--grey-color) !important;
  width: 100%;

  color: var(--grey-color);
  padding: 8px;

  border-radius: 4px 0 0 4px;
  margin: 0 !important;

  &:focus {
    outline: 0;
  }
`;

const InputIcon = styled.div`
  height: 40px;
  width: 40px;
  background: var(--grey-color);
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 0 4px 4px 0;
  color: white;
`;
