import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getBrands(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/brands",
    method: "GET",
  };
  return requestMiddleware(config);
}

///admin/Platforms
