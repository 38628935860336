import { MyResponse, RequestConfig, requestMiddleware } from "./Request";
import { User } from "../views/Admin/Views/Users";
import {
  UpdateProductFormState,
  UpdateProductImageFormState,
} from "../components/form/setup/admin/product/updateProductTypes";
import { Voucher } from "../views/Admin/Views/Vouchers";

export async function addBrand(data: {
  name: string;
  description: string;
  url: string;
  imageUrl: string;
  seoTags: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/brands",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function editBrand(data: {
  id: number;
  name: string;
  description: string;
  url: string;
  imageUrl: string;
  seoTags: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/brands",
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}

export async function deleteBrand(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/brands?id=${id}`,
    method: "DELETE",
  };
  return requestMiddleware(config);
}

export async function getBrandsByPlatformId(id: number): Promise<MyResponse> {
  ///admin/Brands/platform/:platformId
  const config: RequestConfig = {
    url: `/api/admin/brands/platform/${id}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

//////////////////////////// CATEGORY ///////////////////////////////

export async function addCategory(data: {
  name: string;
  description: string;
  type: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/categories",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function editCategory(data: {
  id: number;
  name: string;
  description: string;
  type: string;
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/categories",
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}
export async function deleteCategory(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/categories?id=${id}`,
    method: "DELETE",
  };
  return requestMiddleware(config);
}

//////////////////////////// PLATFORM ///////////////////////////////

export async function addPlatform(data: {
  name: string;
  description: string;
  url: string;
  //imageUrl: string;
  // featuredBrands: [];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/platforms",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}
/*
"id": 196826,
  "name": "testing14",
  "description" : "testDesc",
  "url" : "testUrl",
  "featuredBrands" : [] */

export async function editPlatformAdmin(data: {
  id: number;
  name: string;
  description: string;
  url: string;
  featuredBrands: number[];
}): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/platforms`,
    method: "PUT",
    data: { ...data },
  };
  return requestMiddleware(config);
}

export async function deletePlatform(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/platforms?id=${id}`,
    method: "DELETE",
  };
  return requestMiddleware(config);
}

// Users

export async function editUserAdmin(data: User): Promise<MyResponse> {
  const toSend = {
    adminPassword: data.adminPassword,
    roles: data.roles,
    userState: data.userState,
    id: data.id,
  };
  const config: RequestConfig = {
    url: "/api/admin/users/rolesAndState",
    method: "PUT",
    data: toSend,
  };
  return requestMiddleware(config);
}

//////////////////////////// PRODUCTS ///////////////////////////////

export async function getProductsAdmin(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/products",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getAdminProductById(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/products/id?id=${id}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function addProductAdmin(
  data: UpdateProductFormState
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/products`,
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function editProductAdmin(
  data: UpdateProductFormState
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/products`,
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}

//////////////////////////// PRODUCT MEDIA ///////////////////////////////

export async function addNewProductMediaFileAdmin(
  data: FormData
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/productImages/file`,
    method: "POST",
    data,
    headers: { "content-type": "multipart/form-data" },
  };
  return requestMiddleware(config);
}

export async function addNewProductMediaUrlAdmin(
  data: UpdateProductImageFormState
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/productImages/url`,
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function editProductMediaAdmin(
  id: number,
  data: {
    name: string;
    description: string;
    imageUrl: string;
    sequence: number;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/productImages/image/${id}`,
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}

export async function deleteProductMediaAdmin(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/productImages/image/${id}`,
    method: "DELETE",
  };
  return requestMiddleware(config);
}

//////////////////////////// ORDERS ///////////////////////////////

export async function getOrdersAdmin(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/orders",
    method: "GET",
  };
  return requestMiddleware(config);
}

/// VOUCHERS

export async function getVouchersAdmin(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/vouchers",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function postNewVoucherAdmin(data: Voucher): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/vouchers",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function editVoucherAdmin(
  data: Voucher,
  id: number
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/vouchers",
    method: "PUT",
    data,
  };
  return requestMiddleware(config);
}
