import styled from "styled-components";
import LabeledInput from "../../../../../components/form/components/labeledInput";
import useInput from "../../../../../hooks/useInput";

const ProjectDetails = () => {
  const [macAddress, setMacAddress] = useInput();
  const [projectName, setProjectName] = useInput();
  return (
    <Wrapper>
      <Border>
        <InputWrap>
          <InputWidth>
            <LabeledInput
              isvalid={macAddress.length > 0}
              label={{ text: "Mac Address", required: true, for: "macAddress" }}
              name={"macAddress"}
              handlechange={setMacAddress}
              value={macAddress}
            />
          </InputWidth>

          <p>
            Enter the Mac Address for your <b>Crestron</b> controller e.g:
            00:10:7F:00 :00:00
          </p>
        </InputWrap>
        <Information>
          <p>
            <b>Please Note - </b> If you enter an incorrect MAC address or use
            the wrong formatting your driver will not licence and you will need
            to submit a support request to rectify.
          </p>
          <StyledList>
            <li>
              A correct MAC address will be 12 characters long and separated by
              a colon
            </li>
            <li>
              Do not enter the MAC address of the device you want to integrate
            </li>
            <li>Only one controller/MAC address per order</li>
          </StyledList>
        </Information>
      </Border>
      <Border>
        <InputWrap>
          <InputWidth>
            <LabeledInput
              isvalid={projectName.length > 0}
              label={{
                text: "Project Name",
                required: true,
                for: "projectName",
              }}
              name={"projectName"}
              handlechange={setProjectName}
              value={projectName}
            />
          </InputWidth>

          <p>Enter the name of your project (for your reference only)</p>
        </InputWrap>
      </Border>
    </Wrapper>
  );
};
export default ProjectDetails;

const Wrapper = styled.div`
  width: 100%;
  box-shadow: var(--shadow-standard);
  box-sizing: border-box;
  justify-self: center;
  display: grid;
  grid-auto-flow: row;

  @media only screen and (max-width: 920px) {
    box-shadow: unset;
    justify-items: start;
    justify-content: start;
  }
`;

const InputWrap = styled.div`
  display: flex;
  word-break: keep-all;
  white-space: nowrap;
  gap: 10px;
  align-items: center;
  @media only screen and (max-width: 920px) {
    display: grid;
    word-break: unset;
    white-space: unset;
    text-align: left;
  }
`;

const Information = styled.div`
  text-align: left;
  display: grid;
  margin-top: 10px;
  grid-gap: 10px;
`;

const Border = styled.div`
  border-bottom: solid 1px var(--light-border);
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  @media only screen and (max-width: 920px) {
    padding: 20px;
  }
`;

const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
  display: grid;
  grid-gap: 10px;
`;

const InputWidth = styled.div`
  width: 100%;
  max-width: 300px;
  @media only screen and (max-width: 920px) {
    max-width: unset;
  }
`;
