import styled, { ThemeProvider } from "styled-components";

interface TabInterface {
  name: string;
  updateTab: (name: string) => void;
  activeTab: string;
  header: string;
}

const Tab = (props: TabInterface) => {
  const tabActiveTheme = {
    color: props.activeTab === props.name ? "#f8f8f8" : "#ebebeb",
  };
  return (
    <ThemeProvider theme={tabActiveTheme}>
      <TabWrapper
        onClick={() => {
          props.updateTab(props.name);
        }}
      >
        {props.header}
      </TabWrapper>
    </ThemeProvider>
  );
};

export default Tab;

const TabWrapper = styled.div`
  width: max-content;
  height: 2em;
  background-color: ${(props) => props.theme.color};
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 15px;
  display: grid;
  align-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media only screen and (max-width: 920px) {
    width: auto;
  }
`;
