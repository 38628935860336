import Media from "../../../../../Main/Product/media";
import Description from "../../../../../Main/Product/description";
import { Product as AdminProduct } from "../../index";
import { Product } from "../../../../../../globaltypes";
import styled from "styled-components";

type Props = {
  product: AdminProduct;
};

const ProductDemoSection = (props: Props) => {
  const localProduct: Product = {
    platformName: props.product.platformName,
    featuredImageUrl: "",
    id: 0,
    name: props.product.name,
    media: props.product.media,
    description: props.product.description,
    supportedModels: props.product.supportedModels,
    addDate: props.product.addDate,
    updateDate: props.product.updateDate,
    gbpPrice: props.product.gbpPrice,
    productUrl: props.product.productUrl,
    costs: props.product.costs,
    downloadLink: props.product.downloadLink,
  };
  return (
    <Wrapper>
      {props.product && props.product.media.length > 0 && (
        <Media product={localProduct} />
      )}
      {props.product && <Description product={localProduct} disabled={true} />}
    </Wrapper>
  );
};

export default ProductDemoSection;

const Wrapper = styled.div`
  max-width: 90vw;
  grid-row: 2;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  margin-bottom: 2em;
  grid-gap: 2em;
`;
