import useShowMenu from "../../../../hooks/useShowMenu";
import styled from "styled-components";
interface Props {
  heading: string;
  items: any[];
  text: string;
  handleClick: (item: any) => void;
  editMode: boolean;
}
const AdminDropDownMenu = (props: Props) => {
  const [showMenu, toggleOn, toggleOff, reset] = useShowMenu();

  return (
    <Wrapper>
      <Heading data-menu={true}>{props.heading}</Heading>
      <Selected data-menu={true}>
        {props.text}
        {props.editMode && (
          <i
            onClick={(e) => {
              if (!showMenu) {
                toggleOn(e);
              } else {
                toggleOff();
              }
            }}
            data-menu={true}
            className="ri-arrow-down-s-fill"
          ></i>
        )}
      </Selected>
      {showMenu && (
        <Menu data-menu={true}>
          {props.items.map((item) => {
            if (item !== props.text) {
              return (
                <p
                  data-menu={true}
                  onClick={(e) => {
                    props.handleClick(item);
                    reset();
                  }}
                >
                  {item}
                </p>
              );
            }
          })}
        </Menu>
      )}
    </Wrapper>
  );
};

export default AdminDropDownMenu;

const Wrapper = styled.div`
  border: var(--border-main);
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  grid-gap: 4px;
  padding-left: 12px;
  position: relative;
  height: 3em;
  min-width: 16em;
`;

const Selected = styled.p`
  font-size: 0.8em;
  cursor: pointer;
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr auto;
  width: fit-content;

  i {
    justify-self: start;
    padding-left: 2px;
    font-size: 1.4em;
  }
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;

const Menu = styled.div`
  top: 99%;
  border: var(--border-main);
  border-top: none;
  background-color: white;
  width: min-content;
  position: absolute;
  display: grid;
  font-size: 0.8em;
  z-index: 2;

  p {
    padding: 8px;
    cursor: pointer;

    :hover {
      background-color: rgba(255, 159, 0, 0.22);
    }
  }
`;
