export interface AddressFormState {
  vatCode: string;
  deliveryContact: string;
  firstLine: string;
  secondLine: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  addressType: string;
  addressName: string;
}

export enum AddressActionCase {
  EDIT = "EDIT",
  GENERAL = "GENERAL",
  DISABLED = "DISABLED",
  ADDRESS_TYPE = "ADDRESS_TYPE",
}

export type AddressAction =
  | {
      case: AddressActionCase.GENERAL;
      value: string;
      field: string;
    }
  | {
      case: AddressActionCase.DISABLED;
      value: string;
      field: string;
    }
  | {
      case: AddressActionCase.ADDRESS_TYPE;
      value: string;
      field: string;
    }
  | { case: AddressActionCase.EDIT; value: AddressFormState };

export interface AddressValidityState {
  vatCodeValid: boolean;
  deliveryContactValid: boolean;
  firstLineValid: boolean;
  secondLineValid: boolean;
  cityValid: boolean;
  stateValid: boolean;
  countryValid: boolean;
  zipCodeValid: boolean;
  addressTypeValid: boolean;
  addressNameValid: boolean;
  formValid: boolean;
}

export enum AddressValidationCase {
  VALIDATE_VAT_CODE = "VALIDATE_VAT_CODE",
  VALIDATE_DELIVERY_CONTACT = "VALIDATE_DELIVERY_CONTACT",
  VALIDATE_FIRST_LINE = "VALIDATE_FIRST_LINE",
  VALIDATE_SECOND_LINE = "VALIDATE_SECOND_LINE",
  VALIDATE_CITY = "VALIDATE_CITY",
  VALIDATE_STATE = "VALIDATE_STATE",
  VALIDATE_COUNTRY = "VALIDATE_COUNTRY",
  VALIDATE_ZIP_CODE = "VALIDATE_ZIP_CODE",
  VALIDATE_ADDRESS_TYPE = "VALIDATE_ADDRESS_TYPE",
  VALIDATE_ADDRESS_NAME = "VALIDATE_ADDRESS_NAME",
  VALIDATE_FORM = "VALIDATE_FORM",
}

export type AddressValidationAction =
  | {
      case: AddressValidationCase.VALIDATE_VAT_CODE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_DELIVERY_CONTACT;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_FIRST_LINE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_SECOND_LINE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_CITY;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_STATE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_COUNTRY;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_ZIP_CODE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_ADDRESS_TYPE;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_ADDRESS_NAME;
      value: string;
      field: string;
    }
  | {
      case: AddressValidationCase.VALIDATE_FORM;
      value: AddressFormState;
    };
