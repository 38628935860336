import {
  Document,
  DocumentHeader,
  DocumentIndent,
} from "../../../styles/styledComponents/document";
import ContentWrapper from "../../../components/reusables/ContentWrapper";

const Terms = () => {
  return (
    <ContentWrapper withLoc={true}>
      <Document>
        <DocumentHeader>TERMS & CONDITIONS</DocumentHeader>
        <p>
          This page gives you all the information you need to know about
          Intrinsic Dev and the legal Terms and Conditions through which we sell
          the software listed on our website to you. These Terms along with our
          Privacy Policy and Cloud Licensing Privacy Policy apply to any
          contract of sale for products that exists between us and you.
        </p>
        <p>
          Please read these Terms and Conditions and our Privacy Policy and
          Cloud Licensing Privacy Policy carefully, if you do not agree with
          something written within them do not use our website our software.
          Before completing your purchase you will be asked to confirm you have
          read and understood these Terms, unfortunately if you refuse our Terms
          you will be unable to complete a transaction on our website.
        </p>
        <p>
          We recommend you print a copy of our Terms and Conditions or save them
          to your computer so you can refer to them in the future as necessary.
        </p>
        <p>
          We reserve the right to change these Terms and Conditions and
          recommend you read through them each time you use our site to ensure
          you understand how they apply to you. These Terms and Conditions were
          last amended on 24th March 2020.
        </p>
        <p>
          These Terms, and any contract that exists between us, are in the
          English language only.
        </p>
        <DocumentHeader>1. About Us</DocumentHeader>
        <p>
          1.1 Our registered office address is 9 Dicconson Terrace, Lytham,
          Lytham St Annes, FY8 5JY. Our trading address for all non-financial
          correspondence is The Maltings, Allendale, NE47 9EE.
        </p>
        <p>
          1.2 Intrinsic Dev Limited is registered in England and Wales under
          company number 12430076.
        </p>
        <p>
          1.3 Intrinsic Dev Limited is a subsidiary of Intrinsic Group Limited,
          registered in England and Wales under company number 09659256.
        </p>
        <p>1.4 Contacting us:</p>
        <DocumentIndent>
          1.4.1 To discuss a contract with us in accordance with your legal
          rights please simply contact us with your query. You can contact us by
          email at info@intrinsicdev.com
        </DocumentIndent>
        <DocumentIndent>
          1.4.2 To contact us for any other reason please submit a helpdesk
          ticket online as this is the quickest way to get in touch.
          Alternatively you can email info@intrinsicdev.com but please note tech
          support cannot be contacted in any way other than the online helpdesk.
          You can also use the form on the Contact Us page.
        </DocumentIndent>
        <DocumentIndent>
          1.4.3 If we need to contact you in regards to the contract that exists
          between us we will do so using the information you provided when
          placing the order, usually by phone or email.
        </DocumentIndent>
        <p>
          1.5 Intrinsic Dev Limited is a member of CEDIA. CEDIA is the
          international trade association and central touch point for over 3,700
          member companies worldwide who design, manufacture, and install
          technology for the home.
        </p>
        <DocumentHeader>2. Our Products</DocumentHeader>
        <p>
          {" "}
          2.1 We make every effort to ensure we provide you with accurate
          information on our software. If you believe we have misrepresented the
          functionality of a driver or module please contact us as set out in
          section 1.4.
        </p>
        <p>
          2.2 Images on our website are for illustrative purposes only. Our
          drivers are only available as instant downloads and not available in
          any hard format.
        </p>
        <p>
          2.3 Our software is licensed to you, not sold to you. All of our
          software is available to download free of charge but will require
          licensing to function. All products that are offered for sale on this
          website are licences for the automation controller specified at the
          time of purchase.
        </p>
        <DocumentIndent>
          2.3.1 Obtaining a licence key from Intrinsic Dev grants you a
          non-exclusive, non-transferable, limited and revocable licence to
          install and use the specified software on a device owned or managed by
          you or your organization. The number of licence installations may not
          exceed the total number of licences you purchased for the software.
        </DocumentIndent>
        <DocumentIndent>
          2.3.2 This licence applies only to the applicable driver/module
          purchased and any updates that supplement the original driver/module.
        </DocumentIndent>
        <p>
          2.3.3 Intrinsic Dev’s software is licensed, not sold, to you. This
          licence is sold by Intrinsic Dev Limited. Ownership of the software
          rests with Intrinsic Dev Limited even after installation on your
          device.
        </p>
        <DocumentIndent>
          2.3.4 This licence is valid only for the specific system identified at
          the time the licence was purchased and cannot be resold or transferred
          by you on to any other system. Under exceptional circumstances
          Intrinsic Dev Ltd may grant authorization to transfer an existing
          licence to a new system but maintains the right to charge an
          administrative fee for this service as well as the right to refuse
          authorization at Intrinsic Dev Limited’s sole discretion.
        </DocumentIndent>
        <DocumentIndent>
          2.3.5 Intrinsic Dev Limited may transfer its rights and obligations,
          or sub-contract or sub-license to another legal entity, provided your
          consumer rights are not prejudiced.
        </DocumentIndent>
        <p>
          2.4 All software written by Intrinsic Dev remains the property of
          Intrinsic Dev Limited. This software is the intellectual property of
          Intrinsic Dev Limited, and is protected by law, including English and
          International copyright laws.
        </p>
        <p>
          2.5 You may not decrypt, reverse engineer, modify, translate,
          disassemble or decompile any of Intrinsic Dev Limited’s
          drivers/modules in whole or in part. Any modifications will
          immediately terminate all licences held and full compensation for any
          and all infringements will be sought.
        </p>
        <p>
          2.6 Intrinsic Dev reserve the right to withdraw a module/driver from
          sale at any time and for any reason, including, for example, if the
          equipment for which the driver/module is written is discontinued or
          substantially modified.
        </p>
        <p>
          2.7 Intrinsic Dev Limited carry out extensive beta testing on all
          their software. Software that remains in beta will not be offered for
          sale on this website. Our beta testing scheme is covered by separate
          Terms, but in signing up to this scheme you consent to also abide by
          these Terms and Conditions. All beta software remains the property of
          Intrinsic Dev Limited and is covered by confidentiality agreements.
          This software must not be abused in any way as per section 2.5 and all
          discussions and findings during the testing phase are to remain
          confidential. Intrinsic Dev Limited reserve the right to revoke beta
          testing privileges at any time.
        </p>
        <DocumentHeader>3. Use of our website</DocumentHeader>
        <p>
          3.1 Your use of our site is governed by these Terms and our Privacy
          Policy. Please ensure you read these documents fully so you are aware
          of all Terms that apply to you.
        </p>
        <p>3.2 You must be over 18 to purchase from this website.</p>
        <p>
          3.3 You are responsible for ensuring that your account details and
          passwords remain confidential when using our website and agree to
          accept responsibility for all activities that occur under your account
          and password.
        </p>
        <p>
          3.4 Please ensure you provide us with details that are complete and
          correct when setting up your account or placing an order. We cannot be
          held responsible for any delays or losses suffered due to incorrect
          information being supplied.
        </p>
        <p>
          3.5 We will endeavor to provide you with uninterrupted, error free
          access to our website, however due to the nature of the internet this
          cannot always be guaranteed. On rare occasions your access may be
          restricted or interrupted to allow updates, upgrades or repairs; we
          will endeavor to carry out such tasks in the shortest time possible.
        </p>
        <DocumentHeader>4. Our Contract with you</DocumentHeader>
        <p>
          4.1 All of the software listed on this website is designed to be used
          by qualified system integrators for the relevant control platform.
          When obtaining a licence to use our software (whether free or
          purchased) you are confirming your status as above. We regret we only
          sell B2B and cannot license our software to end consumers. If you are
          the owner of a system and you believe you require our software please
          contact your system integrator to obtain a licence and carry out the
          applicable integration work on your behalf.
        </p>
        <p>
          4.2 Our drivers/modules are all available for instant download free of
          charge. The majority of our drivers require licence keys to activate,
          you do need an account to obtain a licence key. To obtain a licence
          key (whether for a free or chargeable driver) simply add the relevant
          driver(s) to your basket and follow the checkout procedure online.
          Please check you have provided us with all the correct information
          before completing the transaction. Please note we do not accept
          purchases or provide licence keys by phone or email.
        </p>
        <p>
          4.3 When you place an order to purchase a licence key from Intrinsic
          Dev you will be sent a confirmation email to confirm we have received
          your order. Please check through this email and notify us if anything
          is incorrect.
        </p>
        <p>
          4.4 A contract between you and Intrinsic Dev Limited exists only once
          the purchase confirmation has been generated.
        </p>
        <p>
          4.5 Intrinsic Dev Limited will deem this contract null and void in the
          event a licence is used in any way that can be deemed harmful,
          including, but not limited to attempts to decrypt, reverse engineer,
          modify, translate, disassemble or decompile a driver/module, or the
          use of a licence on a system not specified for at the time of
          purchase.
        </p>
        <p>
          4.6 Downloading and installing our drivers is subject to our Terms and
          Conditions and our Privacy Policy and Cloud Licensing Privacy Policy.
        </p>
        <DocumentHeader>5. Payment</DocumentHeader>
        <p>
          5.1 We accept all major credit or debit cards excluding American
          Express. Payment is taken immediately by our online payment provider
          Stripe. Stripe offers a secure payment gateway for online credit and
          debit card processing. All card numbers processed by Stripe are
          encrypted on disk with AES-256. Decryption keys are stored on separate
          machines. Stripe’s infrastructure for storing, decrypting, and
          transmitting card numbers runs in separate hosting infrastructure, and
          doesn’t share any credentials with Stripe’s primary services.
        </p>
        <p>
          5.2 Do not email your card details. We accept no responsibility if you
          provide us with your details in this way.
        </p>
        <p>
          5.3 Card fraud is illegal and will always lead to prosecution. Every
          transaction taken is subject to fraud checks performed by Stripe; if
          we believe the risk of fraud is present on a transaction we reserve
          the right to cancel the order with immediate effect and without
          correspondence.
        </p>
        <p>
          5.5 The Intrinsic Dev website is fully PCI Compliant. Stripe has been
          audited by a PCI-certified auditor and is certified to PCI Service
          Provider Level 1. This is the most stringent level of certification
          available in the payments industry. To accomplish this, Stripe make
          use of best-in-class security tools and practices to maintain a high
          level of security.
        </p>
        <DocumentHeader>6. Delivery</DocumentHeader>
        <p>
          6.1 There are no delivery charges for any of the products listed for
          sale on this website. All software is available to download instantly,
          free of charge. All licence keys (where applicable) are automatically
          emailed upon receipt of applicable payment.
        </p>
        <p>6.2 Our drivers/modules are not available in any hard format</p>
        <p>
          6.3 Licence keys are emailed (where applicable) to the address given
          during the checkout process. Please check you spam folder if you have
          not received your licence and allow up to 12 hours before contacting
          us regarding a missing licence key.
        </p>
        <p>
          6.4 We recommend reading the integration notes for each driver before
          attempting installation. We also recommend that you first try out our
          drivers in non-critical environments.
        </p>
        <DocumentHeader>7. Refunds and Warranty</DocumentHeader>
        <p>
          7.1 We do not offer refunds on our licensing. If you have made a
          mistake during your purchase or have another legitimate reason for
          requiring a refund please contact us as set out above in 1.4. Refunds
          may be offered within 7 working days in exceptional circumstances, but
          please note no refunds will be considered if the driver/module has
          already been installed onto the relevant control platform.
        </p>
        <p>
          7.2 Whilst every care is taken to ensure the thorough testing of our
          drivers, you should always carry out your own tests before first use.
          You agree not to hold Intrinsic Dev Limited, Intrinsic Group Limited
          or any related party, liable for any issues or loss arising from the
          installation of our drivers/modules.
        </p>
        <p>
          7.3 Our software is provided “as is” and “as available”, without
          express or implied warranty or condition of any kind to the maximum
          extent permitted by law. We disclaim any warranty that our
          drivers/modules will meet your requirements or will be constantly
          available, uninterrupted, timely, secure, or error-free; the results
          obtained from the use of our drivers/modules will be effective,
          accurate, or reliable; the quality of the software will meet your
          expectations; or any errors or defects in the software will be
          corrected.
        </p>
        <p>
          7.4 Intrinsic Dev Limited specifically disclaim all liability for any
          actions resulting from your use of software obtained from this
          website. You use our software at your own discretion and risk, and you
          are solely responsible for any damage to computer systems/control
          platforms, loss of data or loss of profits that results from the use
          of our software.
        </p>
        <p>
          7.5 Our software is written and tested on the most recent version of
          3rdParty platforms available at the time of release. Intrinsic Dev
          Limited make no assurances of stability of any untested updates that
          may be released subsequently as all 3rdParty developments are beyond
          our control. Where an updated driver becomes available this can be
          downloaded from our website or from within your purchase history.
          Whilst we make every effort to keep all drivers/modules up to date we
          disclaim any obligation to do so.
        </p>
        <DocumentHeader>8. Pricing</DocumentHeader>
        <p>
          8.1 We make every effort to maintain the prices shown on our Website,
          however we reserve the right to discontinue drivers or to amend prices
          when necessary.
        </p>
        <p>8.2 All prices exclude VAT (where applicable)</p>
        <p>
          8.3 Our pricing originates in £ sterling, we make use of live currency
          exchange rates to automatically calculate pricing for alternative
          currencies. The rate used is beyond our control.
        </p>
        <DocumentHeader>9. Support</DocumentHeader>
        <p>
          9.1 We offer comprehensive support for our drivers/modules via an
          online ticket system. All support queries must be submitted through
          this ticket system. Any attempt to by-pass this system or to
          misrepresent priority levels will result in delays.
        </p>
        <p>
          9.2 Intrinsic Dev offer paid for Remote Support packages for support
          that falls outside of standard driver queries, including, but not
          limited to, driver installation, wider system integration/programming
          and system design.
        </p>
        <p>
          9.3 All drivers/modules written by Intrinsic Dev Limited that require
          the purchase of a licence key are fully supported by Intrinsic Dev
          Limited for as long as they are listed on the Intrinsic Dev website.
        </p>
        <p>
          9.4 We reserve the right to withdraw licensing and remove download
          links for a driver/module, thereby discontinuing on-going support at
          any time and for any reason. This includes, for example, if the
          equipment for which the driver/module is written is discontinued or
          substantially modified. The remainder of your rights and obligations
          pursuant to this licence will not be affected should support be
          discontinued.
        </p>
        <DocumentHeader>10. Other Terms</DocumentHeader>
        <p>
          10.1 This contract is between you and us, no other person has the
          right to enforce these Terms.
        </p>
        <p>
          10.2 Each of the paragraphs in these Terms operate separately. If any
          court or relevant authority deems any of them are unenforceable the
          remaining paragraphs remain in force and in effect.
        </p>
        <p>
          10.3 The name Intrinsic Dev and associated logos are protected under
          UK copyright laws. The contents of this website, including (but not
          limited to) downloadable content, imagery and text may not be copied,
          reproduced, replicated, sold, downloaded or distributed by any means
          to third parties without the express consent of Intrinsic Dev Limited.
        </p>
        <p>
          10.4 The content of Intrinsic Dev and its Third Party associates are
          protected by copyright and database laws. You may not extract or use
          any content (in part or entirety) of the website without express
          written consent of the owners.
        </p>
        <p>
          10.5 You must not use this website in any way that causes damage or
          impairment to the site or company of Intrinsic Dev Limited or any
          other persons. Unlawful and fraudulent activity will be prosecuted.
        </p>
        <p>
          10.6 The content contained on this website is for information purposes
          only. Intrinsic Dev (whether written on the website, through the
          helpdesk, via email or verbally) are not providing any recommendations
          or endorsements to any Third Party solutions, software or products.
          The material on this website does not constitute advice and you should
          not use the information on the website whilst making any decisions.
        </p>
        <p>
          10.7 We accept no responsibility for the content of websites that are
          linked to Intrinsic Dev and do not necessarily endorse the material
          contained within these external websites. These links are provided for
          convenience only.
        </p>
        <DocumentHeader>11. Contact Details</DocumentHeader>
        <div>
          {" "}
          <p>Intrinsic Dev Limited</p>
          <p>The Maltings</p>
          <p>Allendale</p>
          <p>NE47 9EE</p>
        </div>
        <p>Email: info@intrinsicdev.com</p>
        <p>VAT Registration Number: 298 2167 63</p>
        <p>Company Reg Number: 12430076</p>
      </Document>
    </ContentWrapper>
  );
};

export default Terms;
