import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const SuccessScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/login");
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <SuccessWrap>
      <i className="ri-checkbox-circle-line"></i>
      <TextWrap>
        <h2>Password updated successfully</h2>
        <p>
          If you are not automatically redirected click{" "}
          <Link to={"/login"}>here</Link> to return to the login page
        </p>
      </TextWrap>
    </SuccessWrap>
  );
};

export default SuccessScreen;

const SuccessWrap = styled.div`
  i {
    color: var(--orange-color);
    font-size: 6em;
  }
`;

const TextWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  h2 {
    font-size: 1.6em;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: var(--orange-color);
  }
`;
