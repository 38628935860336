import {
  UpdateProductAction,
  UpdateProductActionCase,
  UpdateProductFormState,
  UpdateProductValidationAction,
  UpdateProductValidationCase,
  UpdateProductValidationState,
} from "./updateProductTypes";
import { Category } from "../../../../../views/Admin/Views/Categories";

export const UpdateProductReducer = (
  state: UpdateProductFormState,
  action: UpdateProductAction
): UpdateProductFormState => {
  switch (action.case) {
    case UpdateProductActionCase.EDIT:
      return action.value;
    case UpdateProductActionCase.UPDATE_DRIVER_PLATFORM:
      return { ...state, driverPlatform: { ...action.value } };
    case UpdateProductActionCase.UPDATE_BRAND:
      return { ...state, brand: { ...action.value } };
    case UpdateProductActionCase.UPDATE_COST:
      state.costs.forEach((cost) => {
        if (cost.currencyType === action.field) {
          cost.currencyValue = action.value;
        }
      });
      return { ...state, costs: state.costs };
    case UpdateProductActionCase.UPDATE_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.value] as Category[],
      };
    case UpdateProductActionCase.REMOVE_CATEGORY:
      let categories = (state.categories as Category[]).filter(
        (category) => category !== action.value
      );
      return { ...state, categories: [...categories] };
    case UpdateProductActionCase.UPDATE_TYPE:
      return { ...state, type: action.value };
    case UpdateProductActionCase.UPDATE_STATUS:
      return { ...state, status: action.value };
    default:
      return { ...state, [action.field]: action.value };
  }
};

export const initialProductValidityState: UpdateProductValidationState = {
  platformValid: false,
  brandValid: false,
  categoriesValid: false,
  formValid: false,
};

export const ProductValidityReducer = (
  state: UpdateProductValidationState,
  action: UpdateProductValidationAction
): UpdateProductValidationState => {
  switch (action.case) {
    case UpdateProductValidationCase.VALIDATE_EDIT:
      const brandCheck = action.state.brand !== undefined;
      const platformCheck = action.state.driverPlatform !== undefined;
      const categoriesCheck = action.state.categories.length > 0;
      return {
        brandValid: brandCheck,
        platformValid: platformCheck,
        categoriesValid: categoriesCheck,
        formValid: brandCheck && platformCheck && categoriesCheck,
      };
    // case UpdateProductValidationCase.VALIDATE_PLATFORM:
    // case UpdateProductValidationCase.VALIDATE_BRAND:
    // case UpdateProductValidationCase.VALIDATE_CATEGORIES:
    default:
      return state;
  }
};
