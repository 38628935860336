import styled from "styled-components";

export const Document = styled.div`
  text-align: left;
  display: grid;
  line-height: 22px;
  grid-gap: 10px;
  font-weight: 400;
  margin-bottom: 5%;
`;

export const DocumentHeader = styled.p`
  font-weight: 700;
`;

export const DocumentIndent = styled.p`
  margin-left: 5%;
`;

export const FullWidthSection = styled.div<{ bgColor?: string }>`
  width: 100%;
  background-color: ${(props) => props.bgColor || "transparent"};
`;
