import React, { useEffect, useState } from "react";

type Quarter = "first" | "second" | "third" | "fourth" | "complete";

type Props = {
  step: Quarter;
  color: string;
};

const ProgressIndicator = (props: Props): JSX.Element => {
  const radius = 35;
  const circumference = 2 * Math.PI * radius;

  const quarterCompletion: Record<Quarter, number> = {
    first: 0,
    second: 0.25,
    third: 0.5,
    fourth: 0.75,
    complete: 1,
  };

  const [currentOffset, setCurrentOffset] = useState(circumference);

  useEffect(() => {
    const targetOffset = circumference * (1 - quarterCompletion[props.step]);
    const interval = 10;
    const stepSize = (currentOffset - targetOffset) / (250 / interval);

    const animateStep = () => {
      setCurrentOffset((prevOffset) => {
        if (Math.abs(prevOffset - targetOffset) < Math.abs(stepSize)) {
          return targetOffset;
        }
        return prevOffset - stepSize;
      });
    };

    const intervalId = setInterval(animateStep, interval);

    return () => clearInterval(intervalId);
  }, [props.step]);

  return (
    <svg height="120" width="120">
      <circle
        cx="60"
        cy="60"
        r={radius}
        stroke="#ddd"
        strokeWidth="5"
        fill="none"
      />
      <circle
        cx="60"
        cy="60"
        r={radius}
        stroke={props.color}
        strokeWidth="5"
        fill="none"
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={currentOffset}
        transform="rotate(-90 60 60)"
      />
      <text
        x="60"
        y="60"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={radius * 0.3}
      >
        {" "}
        {props.step === "complete"
          ? "Complete"
          : `Step ${quarterCompletion[props.step] * 4 + 1}/4`}
      </text>
    </svg>
  );
};

export default ProgressIndicator;
