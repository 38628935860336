import React, { useEffect, useState } from "react";
import { login } from "../api/Auth";
import axios, { AxiosError } from "axios";
import { useLocation } from "react-router-dom";
import { decodeToken, isExpired } from "react-jwt";
import { useCookies } from "react-cookie";
interface AuthInterface {
  user: { username: string; id: string } | null;
  handleLogin: (username: string, password: string) => Promise<void>;
  logout: () => void;
  errorMsg: string;
  showError: boolean;
  isAdmin: boolean;
  token: string | null;
  loading: boolean;
}
const properties = {
  user: null,
  handleLogin: () => Promise.resolve(),
  errorMsg: "",
  showError: false,
  logout: () => {},
  isAdmin: false,
  token: null,
  loading: true,
};
const AuthContext = React.createContext<AuthInterface>(properties);
const AuthContextProvider = (props: { children: JSX.Element }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "INT_USER",
    "INT_TOKEN",
  ]);
  const [user, setUser] = useState<{ username: string; id: string } | null>(
    null
  );
  const [token, setToken] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showError, setShowError] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  type Token = {
    sub: string;
    roles: string[];
    iat: number;
    exp: number;
  };

  useEffect(() => {
    const cookie_token = cookies["INT_TOKEN"];
    if (cookie_token) {
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: `Bearer ${cookie_token}`,
      };
    }
  }, [token]);

  const logout = (): void => {
    clearStorage();
    setUser(null);
    setToken(null);
  };
  const clearStorage = (): void => {
    removeCookie("INT_TOKEN", { path: "/" });
    removeCookie("INT_USER", { path: "/" });
  };
  useEffect(() => {
    if (!user || !token) {
      setLoading(true);
      const cookie_token = cookies["INT_TOKEN"];
      const cookie_user = cookies["INT_USER"];
      if (cookie_token && cookie_user && !isExpired(cookie_token)) {
        const decodedToken: Token | null = decodeToken(cookie_token);
        if (decodedToken && decodedToken.roles.includes("ROLE_ADMIN")) {
          setIsAdmin(true);
        }
        setUser(cookie_user);
        setToken(cookie_token);
      } else {
        clearStorage();
      }
      setLoading(false);
    }
  }, []);
  const handleLogin = async (
    username: string,
    password: string
  ): Promise<void> => {
    try {
      setLoading(true);
      setShowError(false);
      setErrorMsg("");
      const response = await login({ username, password });
      if (response.ok) {
        const decodedToken: Token | null = decodeToken(response.data.token);
        setCookie("INT_TOKEN", response.data.token, { path: "/" });
        setCookie(
          "INT_USER",
          { username: response.data.username, id: response.data.userId },
          { path: "/" }
        );
        if (decodedToken && decodedToken.roles.includes("ROLE_ADMIN")) {
          setIsAdmin(true);
        }
        setToken(response.data.token);
        setUser({
          username: response.data.username,
          id: response.data.id,
        });
      }
    } catch (e: any | AxiosError) {
      setErrorMsg("Username and Password combination not valid");
      setShowError(true);
      logout();
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // check token on location change, if expired logout
    // TODO switch to server check?
    if (token) {
      if (isExpired(token)) {
        logout();
      }
    }
    setErrorMsg("");
    setShowError(false);
  }, [location]);
  return (
    <AuthContext.Provider
      value={{
        user,
        handleLogin,
        errorMsg,
        showError,
        logout,
        isAdmin,
        token,
        loading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthContextProvider };
