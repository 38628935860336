import styled from "styled-components";
import React from "react";
import { StyledLink } from "../../submenu/element";

const Elements = () => {
  return (
    <Wrapper data-menu={true}>
      <StyledLink data-menu={true} to={"/driver-request/Control4"}>
        Control4
      </StyledLink>
      <StyledLink data-menu={true} to={"/driver-request/Crestron"}>
        Crestron
      </StyledLink>
      <StyledLink data-menu={true} to={"/driver-request/ELAN"}>
        ELAN
      </StyledLink>
      <StyledLink data-menu={true} to={"/driver-request/RTI"}>
        RTI
      </StyledLink>
      <StyledLink data-menu={true} to={"/driver-request/Savant"}>
        Savant
      </StyledLink>
    </Wrapper>
  );
};

export default Elements;

const Wrapper = styled.div`
  display: grid;
  text-align: left;
  grid-gap: 5px;
  padding: 5px;
`;
