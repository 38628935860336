import { Product } from "../../../../../../../globaltypes";
import styled from "styled-components";
import useBreakpoint from "../../../../../../../hooks/useBreakpoint";

type Props = {
  product: Product;
};

const Item = (props: Props) => {
  const { breakpoint } = useBreakpoint();
  return (
    <Wrapper>
      <ProductImage>
        {" "}
        {props.product.media.length > 0 ? (
          <img
            src={props.product.media[0].imageUrl}
            alt={props.product.media[0].description}
          />
        ) : (
          <Dummy />
        )}
      </ProductImage>
      <ProductDetail>
        <p>{props.product.name}</p>
        <Price>
          <p>
            £{props.product.gbpPrice}
            {breakpoint >= 920 && " - "}
          </p>
          <Tax> (Excluding 20% tax)</Tax>
        </Price>
      </ProductDetail>

      <Remove>
        <i className="ri-close-fill"></i>
      </Remove>
    </Wrapper>
  );
};

export default Item;

const Wrapper = styled.div`
  display: grid;
  color: var(--grey-color);
  align-items: center;
  align-content: center;
  grid-auto-flow: column;

  @media only screen and (max-width: 920px) {
    text-align: left;
    border: solid 1px var(--light-border);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
    box-shadow: var(--box-shadow-main);
  }
`;

const Dummy = styled.div`
  width: 100%;
  height: 5em;
`;

const ProductImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: start;
  gap: 30px;
  img {
    height: 5em;
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-self: end;
  margin-right: 4em;
  @media only screen and (max-width: 920px) {
    flex-direction: column;
    margin-right: 10px;
    text-align: center;
    justify-self: center;
  }
`;

const Remove = styled.div`
  justify-self: end;
  margin-right: 20px;
  font-size: 1.6em;
  color: var(--orange-color);
  @media only screen and (max-width: 920px) {
    margin-right: unset;
  }
`;

const Tax = styled.p`
  @media only screen and (max-width: 920px) {
    font-size: 0.7em;
  }
`;

const ProductDetail = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  text-align: left;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
`;
