import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getProduct(url: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/productUrl/${url}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsBySearch(name: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/search?search=${name}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsByCategory(
  categoryId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/${categoryId}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsByPlatform(
  postId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/${postId}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsForPlatformAndCategory(
  platformId: number | null,
  categoryId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/category/${categoryId}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsForPlatformAndBrand(
  platformId: number | null,
  brandId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/brand/${brandId}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getProductsByBrand(
  brandId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/brand/${brandId}`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getAllProductsByPlatform(
  platformId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/normal`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getAllFeaturedProductsByPlatform(
  platformId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/featured`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getAllSupportProductsByPlatform(
  platformId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/support`,
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getAllBundleProductsByPlatform(
  platformId: number | null
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/products/platform/${platformId}/bundles`,
    method: "GET",
  };
  return requestMiddleware(config);
}
