import React, { useContext, useEffect, useState } from "react";
import {
  Category,
  getAllStatuses,
  getCategories,
  Status,
} from "../api/Support";
import { AuthContext } from "./AuthContext";
import axios from "axios";

interface SupportContextInterface {
  categories: Category[];
  statuses: Status[];
}

const initialState = {
  categories: [],
  statuses: [],
};

const SupportContext =
  React.createContext<SupportContextInterface>(initialState);

const SupportContextProvider = (props: { children: JSX.Element }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [finishedLoading, setFinishedLoading] = useState<boolean>(false);

  const auth = axios.defaults.headers.common.Authorization;

  useEffect(() => {
    if (
      axios.defaults.headers.common.Authorization &&
      !loading &&
      !finishedLoading
    ) {
      loadCategoriesAndStatuses();
    }
  }, [loading, auth, finishedLoading]);

  const loadCategoriesAndStatuses = async () => {
    setLoading(true);
    try {
      const statusesPromise = getAllStatuses();
      const categoriesPromise = getCategories();
      const [statusesResult, categoriesResult] = await Promise.all([
        statusesPromise,
        categoriesPromise,
      ]);
      if (statusesResult.ok) {
        setStatuses(statusesResult.data);
      }
      if (categoriesResult.ok) {
        setCategories(categoriesResult.data);
      }
      if (statusesResult.ok && categoriesResult.ok) {
        setLoading(false);
        setFinishedLoading(true);
      }
    } catch (e) {
      console.log(`An error occurred: ${e}`);
    }
  };

  return (
    <SupportContext.Provider value={{ categories, statuses }}>
      {props.children}
    </SupportContext.Provider>
  );
};

export { SupportContext, SupportContextProvider };
