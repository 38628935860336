const Information = () => {
  return (
    <div className={"intrinsic-info"}>
      <h2>The Intrinsic difference</h2>
      <p>
        Our drivers are all developed in-house, in the UK so you can be sure of
        the highest reliability and responsiveness when updates are required. We
        never use open source code or code libraries. All of our drivers are
        thoroughly tested in-house as well as externally through our Beta
        Programme before being offered on our website.
      </p>
      <h2>Support</h2>
      <p>
        All of our drivers/modules are supported free of charge through our
        Online Support system. We offer comprehensive support for all of our
        software that requires the purchase of a license key for as long as they
        are listed on the Intrinsic Dev website. If it’s your first time
        installing a driver or if you believe there is a technical problem
        simply submit a ticket via our Online Support system.
      </p>
      <h2>Enhanced support</h2>
      <p>
        We also offer Enhanced Remote Support for each control platform. This
        support is charged in 30 minute intervals where we are pleased to advise
        on anything that falls outside of standard driver queries, including,
        but not limited to, driver installation, wider system
        integration/programming, general training and system design.
      </p>
      <h2>License</h2>
      <p>
        All of our software is available to download free of charge but will
        require licensing to function. Unless specifically noted only one
        license is required per controller/processor. You can run as many
        devices as required up to the limit supported by your chosen control
        platform.
      </p>
      <h2>Easy setup</h2>
      <p>
        All of our drivers/modules come with comprehensive Integration Notes to
        ensure the easiest possible set up of our software. Many also have
        Installation Tutorial videos to further assist you. Please ensure you
        refer to these resources each time you install a new driver/module.
      </p>
      <h2>Feedback</h2>
      <p>
        If you have any suggestions on new features or changes you would like to
        see to a driver/module then please let us know via our Online Support
        system.
      </p>
    </div>
  );
};
export default Information;
