import { Link, useLocation } from "react-router-dom";
import useShowMenu from "../../../../hooks/useShowMenu";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Elements from "./Elements";
import { AuthContext } from "../../../../context/AuthContext";
import { SupportContext } from "../../../../context/SupportContext";

const SupportMenu = () => {
  const [showMenu, toggleOn, toggleOff] = useShowMenu();
  const [isActive, setIsActive] = useState(false);
  let currentLocation = useLocation();
  const { categories, statuses } = useContext(SupportContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    toggleOff();
  }, [currentLocation]);

  return (
    <Wrapper
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        toggleOn(e);
        setIsActive(true);
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        toggleOff();
        setIsActive(false);
      }}
      data-menu={true}
    >
      <Link
        id={"support"}
        to={"/support/form"}
        className={isActive ? "active" : ""}
      >
        Support
      </Link>
      {showMenu && user && <Border></Border>}
      {showMenu && user && (
        <Menu data-menu={true}>
          <Elements categories={categories} statuses={statuses} />
        </Menu>
      )}
    </Wrapper>
  );
};

const Border = styled.div`
  height: 3px;
  position: absolute;
  top: 98%;
  right: 20%;
  width: 150px;
  background-color: #ff9f00;
`;

const Wrapper = styled.div`
  font-weight: bold;
  position: relative;
  height: 100%;
  display: grid;
  align-content: center;
  align-items: center;
  cursor: default;
  .active {
    color: var(--orange-color);
  }
  .NavPlatName {
    height: 100%;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 101%;
  background-color: var(--grey-color);
  display: grid;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  right: 20%;
`;

export default SupportMenu;
