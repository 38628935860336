import styled, { ThemeProvider } from "styled-components";
import { Button } from "../../util/types";
import { getColorTheme } from "../../util/other";
import ButtonSpinner from "../spinner";

interface Props extends Button {
  iconType: "bin" | "Row";
  index?: number;
}

const DeleteButton = (props: Props) => {
  return (
    <ThemeProvider theme={getColorTheme(props.disabled === true, "#f34642")}>
      <DelButtonWrap
        onClick={() => {
          if (!props.disabled && !props.loading) {
            props.onClick(props.index && props.index);
          }
        }}
      >
        {props.loading ? (
          <ButtonSpinner />
        ) : (
          <i
            className={
              props.iconType === "Row" ? "ri-delete-row" : "ri-delete-bin-line"
            }
          ></i>
        )}
      </DelButtonWrap>
    </ThemeProvider>
  );
};

export default DeleteButton;

const DelButtonWrap = styled.button`
  position: relative;
  width: 2em;
  height: 2em;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color};
  color: white;
`;
