import "./orders.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { deleteOrder, getOrders } from "../../../api/Orders";
import { DateTime } from "luxon";
import EditButton from "../../../components/other/buttons/edit";
import UpdateOrderForm from "./modal";
import { AddressResponse } from "../../../components/form/types";
import Subheading from "../../../components/subheading";
import DownloadButton from "../../../components/other/buttons/download";
import DeleteButton from "../../../components/other/buttons/delete";
import Location from "../../../components/location";
import TableV2 from "../../../components/table/TableV2";
import { HeaderRowItem } from "../../../components/table/Other/Types";

export type Order = {
  customerOrderId: number;
  orderNumber: string;
  purchaseOrderNumber: number;
  phoneNumber: string;
  orderStatus: string;
  orderDate: string;
  orderEmail: string;
  billingAddress: AddressResponse;
  deliveryAddress: AddressResponse;
  voucher: string;
  projectName: string;
  macAddress: string;
  username: string;
  userPrimaryEmail: string;
  userId: number;
  baseValue: number;
  addDate: string;
  editDate: string;
  licenceKey: string;
  paymentCurrency: string;
  localValue: number;
  termsDate: string;
  privacyDate: string;
  orderLines: orderLine[];
};

export type orderLine = {
  orderNumber: null;
  productId: number;
  productName: string;
  quantity: number;
  baseValue: number;
  baseCurrency: string;
  localValue: string;
  localCurrency: string;
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [orderToEdit, setOrderToEdit] = useState<Order | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  function sortDatesAsc(receivedObject: any, property: string): any {
    if (receivedObject) {
      const clonedObject = receivedObject.map((item: any) => item);
      const processedObject = clonedObject.sort(function (a: any, b: any) {
        const nameA = new Date(a[property]);
        const nameB = new Date(b[property]);
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      return processedObject;
    }
  }

  useEffect(() => {
    getOrders().then((res) => {
      if (res.ok) {
        setOrders(sortDatesAsc(res.data.objects, "orderDate"));
        setLoading(false);
      }
    });
  }, []);

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Customer Order ID",
        accessor: "customerOrderId",
        sortBy: "customerOrderId",
      },
      {
        text: "Primary Email",
        accessor: "userPrimaryEmail",
        sortBy: "userPrimaryEmail",
      },
      {
        text: "Order Date",
        accessor: "editDate",
        sortBy: "editDate",
        cb: (data: any, value: any) => {
          return <div>{DateTime.fromISO(value).toLocaleString()}</div>;
        },
      },
      {
        text: "Project Name",
        accessor: "projectName",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "MAC Address",
        accessor: "macAddress",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Total",
        accessor: "baseValue",
        cb: (data: any, value: any, index: number | undefined) => {
          return <p>£{value}</p>;
        },
      },
      {
        text: "Status",
        accessor: "orderStatus",
        sortBy: "orderStatus",
      },
      {
        text: "",
        cb: (data: any, value: any, index: number | undefined) => {
          return (
            <EditButton
              onClick={() => {
                handleRowClick(data);
              }}
              disabled={data.orderStatus !== "OPEN"}
            />
          );
        },
        columnSizing: { min: "60px", max: "70px" },
      },
      {
        text: "",
        cb: (data: any, value: any, index: number | undefined) => {
          return (
            <DownloadButton
              onClick={() => {
                //Todo: Implement download invoice logic.
              }}
              disabled={data.orderStatus === "OPEN"}
            />
          );
        },
        columnSizing: { min: "60px", max: "70px" },
      },
      {
        text: "",
        cb: (data: any) => {
          return (
            <DeleteButton
              iconType={"Row"}
              onClick={() => removeOrder(data)}
              disabled={data.orderStatus !== "OPEN"}
            />
          );
        },
        columnSizing: { min: "60px", max: "70px" },
      },
    ],
    []
  );

  const removeOrder = async (order: Order) => {
    let res = await deleteOrder({ orderNumber: order.orderNumber });
    if (res.ok) {
      setOrders(
        orders.filter(
          (localOrder: Order) => localOrder.orderNumber !== order.orderNumber
        )
      );
    }
  };

  const handleRowClick = (data: Order) => {
    if (data) {
      setOrderToEdit(data);
      setShowForm(true);
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <Wrapper>
      <LocationWrap>
        <Location />
      </LocationWrap>
      {orderToEdit !== null && showForm && (
        <UpdateOrderForm order={orderToEdit} close={handleClose} />
      )}
      <Heading>
        <Subheading h3={"Your"} h2={"Orders"} />
      </Heading>
      <TableV2
        data={orders}
        headerRow={setupTable}
        loading={loading}
        editId={orderToEdit && orderToEdit.customerOrderId}
      />
    </Wrapper>
  );
};
export default Orders;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  text-align: start;
`;

const Heading = styled.div`
  margin-left: 14px;
`;

const LocationWrap = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
