import { useEffect, useState } from "react";
import { AddressResponse } from "../../../../../../../components/form/types";
import { getAddresses } from "../../../../../../../api/Addresses";
import { MyResponse } from "../../../../../../../api/Request";
import styled, { ThemeProvider } from "styled-components";
import Subheading from "../../../../../../../components/subheading";
import AddressCardAdmin from "../card";

interface Props {
  addresses?: AddressResponse[];
  padding: string | number;
}

const AddressPickerAdmin = (props: Props) => {
  // pull from props
  const [addresses, setAddresses] = useState<AddressResponse[]>();

  const paddingTheme = {
    padding: props.padding,
  };

  useEffect(() => {
    getAddresses()
      .then((res: MyResponse) => {
        if (res.ok) {
          //console.log(res.data);
          setAddresses(res.data);
        } else {
          console.error("get addresses request failed");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <ThemeProvider theme={paddingTheme}>
      <AddressPickerWrap>
        <Heading>
          <Subheading h3={"Manage"} h2={"Addresses"} />
        </Heading>
        <CardList>
          {props.addresses && props.addresses.length > 0 ? (
            props.addresses.reverse().map((val: AddressResponse) => {
              return <AddressCardAdmin key={val.addressName} address={val} />;
            })
          ) : (
            <EmptyWrap>No Addresses</EmptyWrap>
          )}
        </CardList>
      </AddressPickerWrap>
    </ThemeProvider>
  );
};

export default AddressPickerAdmin;

const EmptyWrap = styled.p`
  justify-self: center;
`;

const CardList = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  align-self: start;
  grid-gap: 10px;
  padding: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media only screen and (max-width: 920px) {
    grid-auto-flow: column;
    justify-items: start;
    justify-content: start;
    overflow-x: scroll;
  }
`;

const AddressPickerWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  margin: 0 1.5em 2em 1.5em;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 1em;
  @media only screen and (max-width: 920px) {
    grid-auto-flow: column;
    grid-column: 1/-1;
  }
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: center;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 10px;
  margin-right: 14px;
  display: grid;
  grid-template-columns: 1fr auto;
  @media only screen and (max-width: 920px) {
    grid-row: 1;
    grid-column: 1/-1;
    margin-bottom: unset;
    margin-top: unset;
  }
`;

const ButtonWrapper = styled.div`
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin-right: 10px;
`;
