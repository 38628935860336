import styled, { ThemeProvider } from "styled-components";
import { Button } from "../../util/types";
import { getColorTheme } from "../../util/other";
import ButtonSpinner from "../spinner";

const DownloadButton = (props: Button) => {
  return (
    <ThemeProvider theme={getColorTheme(props.disabled === true, "#31a82a")}>
      <DownloadButtonWrap
        onClick={(event) => {
          if (!props.disabled) {
            props.onClick();
          }
        }}
      >
        {props.loading ? (
          <ButtonSpinner />
        ) : (
          <i className="ri-file-download-line"></i>
        )}
      </DownloadButtonWrap>
    </ThemeProvider>
  );
};

export default DownloadButton;

const DownloadButtonWrap = styled.button`
  width: 2em;
  height: 2em;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.3em;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color};
  color: white;
`;
