import styled, { ThemeProvider } from "styled-components";
import { Button } from "../../util/types";
import { getColorTheme } from "../../util/other";

interface Props extends Button {
  color?: string;
  text?: string;
}

const AddButton = (props: Props) => {
  return (
    <ThemeProvider
      theme={{
        ...getColorTheme(
          props.disabled === true,
          props.color ? props.color : "var(--grey-color)"
        ),
        width: props.text ? "max-content" : "2em",
      }}
    >
      <AddWrap
        onClick={() => {
          if (!props.disabled) {
            props.onClick();
          }
        }}
      >
        {props.text ? <p>{props.text}</p> : <i className="ri-add-box-line"></i>}
      </AddWrap>
    </ThemeProvider>
  );
};

export default AddButton;

const AddWrap = styled.div`
  width: ${(props) => props.theme.width};
  height: 2em;
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => props.theme.color};
  color: white;
  p {
    padding-right: 1em;
    padding-left: 1em;
    font-size: 0.8em;
  }
`;
