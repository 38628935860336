import { capitaliseString } from "../../../../utils/utils";
import React from "react";
import styled from "styled-components";

interface LabelInterface {
  text: string;
  for: string;
  required: boolean;
}

const Label = (props: LabelInterface) => {
  return (
    <LabelWrapper>
      <label htmlFor={props.for}>
        {props.for === "location" && <Asterisk>*</Asterisk>}
        {capitaliseString(props.text)}
        {props.required && props.for !== "location" && <Asterisk>*</Asterisk>}
      </label>
    </LabelWrapper>
  );
};

export default Label;

const LabelWrapper = styled.div`
  text-align: start;
`;

const Asterisk = styled.p`
  color: #990000;
  font-size: 1.1em;
`;
