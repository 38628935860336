import styled from "styled-components";

type Props = {
  total: number;
};
const Total = (props: Props) => {
  return (
    <Wrapper>
      <div>
        <p>Order Total: </p>
        <p>{`£${props.total}`}</p>
      </div>
    </Wrapper>
  );
};

export default Total;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-self: end;
  width: 100%;
  justify-items: end;
  grid-gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  div {
    display: grid;
    grid-template-columns: 1fr auto;
    text-align: left;
    width: 200px;
    padding-right: 20px;
  }
`;
