import React, { useState } from "react";
import styled from "styled-components";
import OrdersDetailsSection from "./details";
import AddressPicker from "../../../../Main/Orders/modal/address/picker";
import Window from "../../../Reusables/window";
import useInfo from "../../../Hooks/useInfo";
import { Order } from "../../../../Main/Orders";

interface FormProps {
  close: () => void;
  order: Order;
}

const UpdateOrderFormAdmin = (props: FormProps) => {
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();
  const [showProducts, setShowProducts] = useState(false);
  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
      noPlaceholder={true}
    >
      <Wrapper>
        <OrdersDetailsSection
          order={props.order}
          showProducts={showProducts}
          setShowProducts={setShowProducts}
        />
        {!showProducts && (
          <AddressPicker
            addresses={[
              { title: "Billing Address", data: props.order.billingAddress },
              {
                title: "Delivery Address",
                data: props.order.deliveryAddress,
              },
            ]}
          />
        )}
      </Wrapper>
    </Window>
  );
};

export default UpdateOrderFormAdmin;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  overflow-y: scroll;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
