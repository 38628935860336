import { MediaItem, ProductCost } from "../../../../../globaltypes";
import { Platform } from "../../../../../views/Admin/Views/Platforms";
import { Brand } from "../../../../../views/Admin/Views/Brands";
import { Category } from "../../../../../views/Admin/Views/Categories";

export interface UpdateProductFormState {
  id: number | null;
  productId: string;
  alternateId: number;
  name: string;
  media: MediaItem[];
  description: string;
  supportedModels: string;
  addDate: string;
  updateDate: string;
  gbpPrice: number;
  productUrl: string;
  downloadLink: string;
  costs: ProductCost[];
  type: string;
  driverPlatform: Platform | number;
  categories: Category[] | number[];
  brand: Brand | number;
  shortName: string;
  seoTitle: string;
  seoDescription: string;
  licenceKey: string;
  // tags: []
  status: string;
  notes: string;
  originalCategories: string;
  originalTags: string;
  originalDateCreated: string;
  originalAuthor: string;
}

export enum UpdateProductActionCase {
  GENERAL = "GENERAL",
  UPDATE_DRIVER_PLATFORM = "UPDATE_DRIVER_PLATFORM",
  UPDATE_BRAND = "UPDATE_BRAND",
  UPDATE_COST = "UPDATE_COST",
  UPDATE_NUMBER = "UPDATE_NUMBER",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  REMOVE_CATEGORY = "REMOVE_CATEGORY",
  UPDATE_TYPE = "UPDATE_TYPE",
  UPDATE_STATUS = "UPDATE_STATUS",
  EDIT = "EDIT",
}

export type UpdateProductAction =
  | {
      case: UpdateProductActionCase.GENERAL;
      value: string;
      field: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_DRIVER_PLATFORM;
      value: Platform;
      // field: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_TYPE;
      value: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_STATUS;
      value: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_COST;
      value: number;
      field: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_NUMBER;
      value: number;
      field: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_BRAND;
      value: Brand;
      // field: string;
    }
  | {
      case: UpdateProductActionCase.UPDATE_CATEGORY;
      value: Category;
      // field: string;
    }
  | {
      case: UpdateProductActionCase.REMOVE_CATEGORY;
      value: Category;
    }
  | {
      case: UpdateProductActionCase.EDIT;
      value: UpdateProductFormState;
    };
export interface UpdateProductValidationState {
  platformValid: boolean;
  brandValid: boolean;
  categoriesValid: boolean;

  formValid: boolean;
}

export enum UpdateProductValidationCase {
  VALIDATE_EDIT = "VALIDATE_EDIT",
  VALIDATE_PLATFORM = "VALIDATE_PLATFORM",
  VALIDATE_BRAND = "VALIDATE_BRAND",
  VALIDATE_CATEGORIES = "VALIDATE_CATEGORIES",
}

export type UpdateProductValidationAction =
  | {
      case: UpdateProductValidationCase.VALIDATE_EDIT;
      state: UpdateProductFormState;
    }
  | {
      case: UpdateProductValidationCase.VALIDATE_PLATFORM;
      value: string;
      field: string;
    }
  | {
      case: UpdateProductValidationCase.VALIDATE_BRAND;
      value: string;
      field: string;
    }
  | {
      case: UpdateProductValidationCase.VALIDATE_CATEGORIES;
      value: string;
      field: string;
    };

//PRODUCT IMAGE

export interface UpdateProductImageFormState {
  id: number;
  productId: number;
  name: string;
  description: string;
  imageUrl: string;
  sequence: number;
  mediaType: string;
  file: File | undefined;
}

export enum UpdateProductImageActionCase {
  GENERAL = "GENERAL",
  EDIT = "EDIT",
  UPDATE_IMAGE = "UPDATE_IMAGE",
  UPDATE_MEDIA_TYPE = "UPDATE_MEDIA_TYPE",
  REMOVE_FILE = "REMOVE_FILE",
}

export type UpdateProductImageAction =
  | {
      case: UpdateProductImageActionCase.GENERAL;
      value: string;
      field: string;
    }
  | {
      case: UpdateProductImageActionCase.UPDATE_IMAGE;
      value: File;
    }
  | {
      case: UpdateProductImageActionCase.UPDATE_MEDIA_TYPE;
      value: string;
    }
  | {
      case: UpdateProductImageActionCase.REMOVE_FILE;
    }
  | {
      case: UpdateProductImageActionCase.EDIT;
      value: UpdateProductImageFormState;
    };
