import SubMenuElements from "./element";
import styled, { ThemeProvider } from "styled-components";
import { Platforms } from "../../types";

interface SubMenuProps {
  name: string;
  index: number;
  data: Platforms;
}

const SubMenu = (props: SubMenuProps) => {
  const menuTheme = {
    right: props.index > 3 ? "0%" : null,
  };

  return (
    <ThemeProvider theme={menuTheme}>
      <Menu data-menu={true}>
        <SubMenuWrapper data-menu={true}>
          {props.data.categories.length > 0 && (
            <SubMenuElements
              elements={props.data.categories}
              header={"Category"}
              name={props.data.name}
            />
          )}
          {props.data.featuredBrands.length > 0 && (
            <SubMenuElements
              elements={props.data.featuredBrands}
              header={"Featured"}
              name={props.data.name}
            />
          )}
          {props.data.supportList.length > 0 && (
            <SubMenuElements
              elements={props.data.supportList}
              header={"Enhanced Support"}
              name={props.data.name}
            />
          )}{" "}
          {props.data.bundles.length > 0 && (
            <SubMenuElements
              elements={props.data.bundles}
              header={"Value"}
              name={props.data.name}
            />
          )}
        </SubMenuWrapper>
      </Menu>
    </ThemeProvider>
  );
};

export default SubMenu;

const SubMenuWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  justify-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

const Menu = styled.div`
  position: absolute;
  top: 101%;
  background-color: var(--grey-color);
  display: grid;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: ${(props) => props.theme.right && props.theme.right};
`;
