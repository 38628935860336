import { useState } from "react";
import Carousel from "react-simply-carousel";

import { MediaItem } from "../../../../../globaltypes";
import styled from "styled-components";

interface CarouselProps {
  images: MediaItem[];
  handleClick: (item: MediaItem) => void;
  extractId: (url: string) => string;
}

const MediaCarousel = (props: CarouselProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const images = props.images.map((img, index) => {
    if (img.mediaType === "IMAGE") {
      return (
        <ImageWrap key={index}>
          <img src={img.imageUrl} alt={img.description} key={index} />
        </ImageWrap>
      );
    } else {
      return (
        <ImageWrap key={index}>
          <img
            key={index}
            src={`https://img.youtube.com/vi/${props.extractId(
              img.imageUrl
            )}/hqdefault.jpg`}
            alt={img.description}
          />
        </ImageWrap>
      );
    }
  });

  const handleUpdate = () => {
    props.handleClick(props.images[activeSlideIndex]);
  };

  return (
    <div>
      <Carousel
        onAfterChange={handleUpdate}
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={3}
        itemsToScroll={1}
        updateOnItemClick={true}
        forwardBtnProps={{
          style: {
            alignSelf: "center",
            color: "#9e9e9e",
            fontSize: "1.5em",
            cursor: "pointer",
          },
          children: <i className="ri-arrow-right-circle-fill" />,
        }}
        backwardBtnProps={{
          style: {
            alignSelf: "center",
            color: "#9e9e9e",
            fontSize: "1.5em",
            cursor: "pointer",
          },
          children: <i className="ri-arrow-left-circle-fill" />,
        }}
        dotsNav={{
          show: true,
          itemBtnProps: {
            style: {
              height: 16,
              width: 16,
              borderRadius: "50%",
              border: 0,
            },
          },
        }}
        speed={400}
        easing="linear"
        infinite={true}
        centerMode={true}
      >
        {images}
      </Carousel>
    </div>
  );
};

export default MediaCarousel;

const ImageWrap = styled.div`
  display: grid;
  img {
    width: 7em;
    height: auto;
    align-self: center;
    margin: 5px;
  }
`;
