import React, { useContext } from "react";
import styled from "styled-components";
import SidebarItem from "./Item";
import { NavContext } from "../../../../context/NavContext";

export type SidebarLink = {
  name: string;
  url: string;
};

export type SidebarData = {
  ticket: SidebarLink[];
  requests: SidebarLink[];
};

const driverLinks = [
  {
    name: "Control4",
    url: "/driver-request/Control4",
  },
  {
    name: "Crestron",
    url: "/driver-request/Crestron",
  },
  {
    name: "Elan",
    url: "/driver-request/ELAN",
  },
  {
    name: "RTI",
    url: "/driver-request/RTI",
  },
  {
    name: "Savant",
    url: "/driver-request/Savant",
  },
];

const supportNames = [
  "Manufacturer Development Query",
  "Billing",
  "Pre-sales Enquiries",
  "CUK LTD Dealer Support",
  "Control4",
  "Elan",
  "Crestron",
  "RTI",
  "Savant",
];

function SidebarInner() {
  const { loading, platforms, brands } = useContext(NavContext);
  const formUrl = "/support/form/";
  const requestUrl = "/support/requests/";

  const supportTicketLinks = supportNames.map((name, index) => {
    return { name: name, url: `${formUrl}${index + 1}` };
  });

  const supportRequestLinks = [
    { name: "All", url: `${requestUrl}${0}` },
    { name: "Unresolved", url: `${requestUrl}${1}` },
    { name: "Follow-up", url: `${requestUrl}${2}` },
    { name: "Closed", url: `${requestUrl}${3}` },
  ];

  const supportData: SidebarData = {
    ticket: supportTicketLinks,
    requests: supportRequestLinks,
  };

  return (
    <Wrapper>
      {!loading &&
        platforms.map((item) => <SidebarItem name={item.name} items={item} />)}
      {!loading && (
        <>
          <SidebarItem name={"Brands"} items={brands} />
          <SidebarItem name={"Support"} items={supportData} />
          <SidebarItem name={"Driver Development"} items={driverLinks} />
        </>
      )}
    </Wrapper>
  );
}

export default SidebarInner;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
`;
