import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios";
export type RequestConfig = AxiosRequestConfig;
export type RequestMethod = Method;

export interface MyResponse extends AxiosResponse {
  ok: boolean;
  status: number;
}

const request = async (config: RequestConfig): Promise<MyResponse> => {
  const response = await axios.request(config);
  return {
    ok: response.status >= 200 && response.status < 400,
    ...response,
  };
};

export async function requestMiddleware(
  config: RequestConfig
): Promise<MyResponse> {
  return await request(config);
}

/*
axios.interceptors.request.use((config) => {
  // @ts-ignore
  let token = config.headers.Authorization ?? store.getState().auth.sessionKey;
  if (token !== null) {
    token = "Bearer " + token;
  }
  if (config.url?.includes("openweathermap")) {
    return config;
  }
  return {
    ...config,
    headers: {
      Authorization: token,
    },
    withCredentials: true,
  };
});*/

axios.interceptors.response.use(undefined, (error) => {
  if (error.code === "ERR_CANCELED") {
    error.response = { status: 408 };
  }
  throw error;
});

//possibly throw and catch in each component

export default request;
