import "./App.css";
import { Helmet } from "react-helmet";
import Home from "../../views/Main/Home/home";
import { Route, Routes } from "react-router-dom";
import Contact from "../../views/FormPages/Contact/page";
import Terms from "../../views/Main/TermsAndConditions";
import IntrinsicPrivacy from "../../views/Main/PrivacyPolicy/intrinsic";
import CloudPrivacy from "../../views/Main/PrivacyPolicy/cloud";
import Account from "../../views/Main/Account";
import Results from "../../views/Main/SearchResults";
import Scroller from "../scroller";
import { CountryContextProvider } from "../../context/CountryContext";
import Showroom from "../../views/FormPages/Showroom";
import Login from "../../views/Main/Login";
import AppWrapper from "./AppWrapper";
import { AuthContextProvider } from "../../context/AuthContext";
import RegisterUserPage from "../../views/FormPages/Register";
import { NavContextProvider } from "../../context/NavContext";
import { CookiesProvider } from "react-cookie";
import ProductPage from "../../views/Main/Product";
import { ErrorContextProvider } from "../../context/ErrorContext";
import Error403 from "../../views/Error/403";
import ResetRequest from "../../views/FormPages/PasswordReset/resetRequest";
import ResetPassword from "../../views/FormPages/PasswordReset/resetForm";
import HeaderWrapper from "./HeaderWrapper";
import Error500 from "../../views/Error/500";
import DriverDev from "../../views/FormPages/Driver";
import Orders from "../../views/Main/Orders";
import Profile from "../../views/Main/Profile";
import Admin from "../../views/Admin";
import Error401 from "../../views/Error/401";
import DriverRequestForm from "../../views/FormPages/Driver/requestForm";
import LicenseTransferRouter from "../../views/FormPages/LicenceTransfer";
import { OpenOrdersContextProvider } from "../../context/OpenOrdersContext";
import About from "../../views/Main/About";
import SupportUpper from "../../views/FormPages/Support/Upper";
import SupportRequests from "../../views/FormPages/Support/requests";
import { ShowMenuContextProvider } from "../../context/MenuContext";
import Checkout from "../../views/Main/Checkout";
import CartEmpty from "../../views/Main/CartEmpty";
import { CookieConsentProvider } from "../../context/CookiesConsentContext";
import { SupportContextProvider } from "../../context/SupportContext";
import { useContext } from "react";
import { ErrorModalContext } from "../../context/ErrorModalContext";
import { ErrorModal } from "../errorModal";

//When adding a new route, wrap with app wrapper for auto shift to correct grid Row

function App() {
  const { errorDetails, clearError } = useContext(ErrorModalContext);
  return (
    <>
      {errorDetails && <ErrorModal err={errorDetails} fallback={clearError} />}
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ShowMenuContextProvider>
        <CookieConsentProvider>
          <CookiesProvider>
            <ErrorContextProvider>
              <AuthContextProvider>
                <NavContextProvider>
                  <SupportContextProvider>
                    <div className="App">
                      <Scroller>
                        <OpenOrdersContextProvider>
                          <CountryContextProvider>
                            <Routes>
                              <Route path="admin/*" element={<Admin />} />
                              <Route element={<HeaderWrapper />}>
                                <Route path="/" element={<Home />} />
                                <Route element={<AppWrapper />}>
                                  <Route path="/login" element={<Login />} />
                                  <Route
                                    path="/register"
                                    element={<RegisterUserPage />}
                                  />
                                  <Route path="/about" element={<About />} />
                                  <Route
                                    path="/support/form/:number"
                                    element={<SupportUpper />}
                                  />
                                  <Route
                                    path="/support/requests/:number"
                                    element={<SupportRequests />}
                                  />
                                  <Route
                                    path="/support/form"
                                    element={<SupportUpper />}
                                  />
                                  <Route
                                    path="/support/requests"
                                    element={<SupportRequests />}
                                  />
                                  <Route
                                    path="/contact"
                                    element={<Contact />}
                                  />
                                  <Route
                                    path="/account"
                                    element={<Account />}
                                  />
                                  <Route
                                    path="/terms-conditions"
                                    element={<Terms />}
                                  />
                                  <Route
                                    path="/license-transfer/:type"
                                    element={<LicenseTransferRouter />}
                                  />
                                  <Route
                                    path="/showroom-licence/:type"
                                    element={<Showroom />}
                                  />
                                  <Route
                                    path="/privacy-policy"
                                    element={<IntrinsicPrivacy />}
                                  />
                                  <Route
                                    path="/cloud-privacy-policy"
                                    element={<CloudPrivacy />}
                                  />
                                  <Route path="/orders" element={<Orders />} />
                                  <Route
                                    path="/profile"
                                    element={<Profile />}
                                  />
                                  <Route
                                    path="/services"
                                    element={<DriverDev />}
                                  />
                                  <Route
                                    path="/brands/:query"
                                    element={<Results />}
                                  />
                                  <Route
                                    path="/:platformName/:searchType"
                                    element={<Results />}
                                  />
                                  <Route
                                    path="/:platformName/:searchType/:query"
                                    element={<Results />}
                                  />
                                  <Route
                                    path="/products/:query"
                                    element={<Results />}
                                  />
                                  <Route
                                    path="/driver-request/:platform"
                                    element={<DriverRequestForm />}
                                  />
                                  <Route
                                    path={"/checkout"}
                                    element={<Checkout />}
                                  />
                                  <Route
                                    path={"/empty-cart"}
                                    element={<CartEmpty />}
                                  />
                                </Route>
                                <Route
                                  path="/lostpassword"
                                  element={<ResetRequest />}
                                />
                                <Route
                                  path="/privacy-policy"
                                  element={<IntrinsicPrivacy />}
                                />
                                <Route
                                  path="/cloud-privacy-policy"
                                  element={<CloudPrivacy />}
                                />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route
                                  path="/services"
                                  element={<DriverDev />}
                                />
                                <Route
                                  path="/showroom-licence-request"
                                  element={<Showroom />}
                                />
                                <Route
                                  path="/driver-request/:platform"
                                  element={<DriverRequestForm />}
                                />
                                <Route
                                  path="/lostpassword"
                                  element={<ResetRequest />}
                                />
                                <Route
                                  path="/resetpassword/:authcode/:email"
                                  element={<ResetPassword />}
                                />
                                <Route
                                  path="/cloud-privacy-policy"
                                  element={<CloudPrivacy />}
                                />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route
                                  path="/services"
                                  element={<DriverDev />}
                                />
                                <Route
                                  path="/driver-request/:platform"
                                  element={<DriverRequestForm />}
                                />

                                <Route
                                  path="/resetpassword"
                                  element={<ResetPassword />}
                                />
                                <Route
                                  path={"/downloads/:productUrl"}
                                  element={<ProductPage />}
                                />
                              </Route>
                              <Route path={"/401"} element={<Error401 />} />
                              <Route path={"/403"} element={<Error403 />} />
                              <Route path={"/500"} element={<Error500 />} />
                            </Routes>
                          </CountryContextProvider>
                        </OpenOrdersContextProvider>
                      </Scroller>
                    </div>
                  </SupportContextProvider>
                </NavContextProvider>
              </AuthContextProvider>
            </ErrorContextProvider>
          </CookiesProvider>
        </CookieConsentProvider>
      </ShowMenuContextProvider>
    </>
  );
}

export default App;
