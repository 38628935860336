import React, { useEffect, useMemo, useState } from "react";
import useCarousel from "../../../hooks/useCarousel";
import LatestSegment from "./segment";
import "./latestCarousel.css";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { ImageData } from "../types";
import Subheading from "../../subheading";
import styled from "styled-components";

interface LatestCarouselProps {
  hideHeading?: boolean;
}
const LatestCarousel = (props: LatestCarouselProps) => {
  const data = useMemo(() => {
    return [
      { src: "1", id: 1, name: "" },
      { src: "2", id: 2, name: "" },
      { src: "3", id: 3, name: "" },
      { src: "4", id: 4, name: "" },

      { src: "5", id: 7, name: "" },
      { src: "6", id: 8, name: "" },
      { src: "7", id: 9, name: "" },
      { src: "8", id: 10, name: "" },

      { src: "9", id: 13, name: "" },
      { src: "10", id: 14, name: "" },
    ];
  }, []);

  function split(
    array: { src: string; id: number; name: string }[],
    n: number
  ) {
    let [...arr] = array;
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }

    return res;
  }

  const [columns, setColumns] = useState<number>(4);
  const [toDisplay, setToDisplay] = useState(split(data, 4));

  const resplit = (n: number) => {
    let arr: ImageData[];
    [...arr] = data;
    const res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  };

  const {
    handleClick,
    left,
    right,
    current,
    expandLeft,
    expandRight,
    showCurrent,
    setRefresh,
  } = useCarousel(toDisplay);

  const { breakpoint } = useBreakpoint();

  useEffect(() => {
    if (breakpoint >= 1200) {
      setToDisplay(resplit(4));
      setColumns(4);
    }
    if (breakpoint < 1200 && breakpoint > 1000) {
      setToDisplay(resplit(3));
      setColumns(3);
    }
    if (breakpoint <= 1000 && breakpoint >= 500) {
      setToDisplay(resplit(2));
      setColumns(2);
    }
    if (breakpoint < 600) {
      setToDisplay(resplit(1));
      setColumns(1);
    }
  }, [breakpoint]);

  useEffect(() => {
    let rightVal: string = `translateX(${
      (left.length / current.length) * 100
    }%)`;
    document.documentElement.style.setProperty(
      "--latest-right-value",
      rightVal
    );
    let leftVal: string = `translateX(-${
      (right.length / current.length) * 100
    }%)`;
    document.documentElement.style.setProperty("--latest-left-value", leftVal);
  }, [right, left]);

  return (
    <div className={"latest-outer"}>
      <div className="carousel-wrapper latest-wrapper">
        <i
          className="ri-arrow-left-circle-fill latest-arrow-left"
          data-arrow={"left"}
          onClick={handleClick}
        ></i>
        {!props.hideHeading && (
          <Header>
            <Subheading h3={"LATEST"} h2={"RELEASES"} />
          </Header>
        )}
        <div className="carousel-container" style={{ borderRadius: "4px" }}>
          <LatestSegment
            imageData={left}
            class={`image-container left-images ${
              expandRight && left.length < current.length
                ? "reverse-latest"
                : expandLeft
                ? "expand-left-latest"
                : expandRight && "expand-right-latest"
            } `}
            setRefresh={setRefresh}
            showElements={true}
            columns={columns}
          />
          <LatestSegment
            imageData={current}
            class={`image-container current-images) ${
              expandLeft
                ? "expand-left-latest"
                : expandRight && "expand-right-latest"
            } `}
            setRefresh={setRefresh}
            showElements={showCurrent}
            columns={columns}
          />
          <LatestSegment
            imageData={right}
            class={`image-container right-images ${
              expandLeft
                ? "expand-left-latest"
                : expandRight && "expand-right-latest"
            } `}
            setRefresh={setRefresh}
            showElements={true}
            columns={columns}
          />
        </div>
        <i
          className="ri-arrow-right-circle-fill latest-arrow-right"
          data-arrow={"right"}
          onClick={handleClick}
        ></i>
      </div>
    </div>
  );
};

export default LatestCarousel;

const Header = styled.div`
  margin-bottom: 20px;
`;
