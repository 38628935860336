import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import DropdownList from "./Dropdown/List";
import { useNavigate } from "react-router-dom";

type MobileNavItemProps = {
  name: string;
  items?: any;
  linkMode?: boolean;
  url?: string;
};

function SidebarItem({ name, items, linkMode, url }: MobileNavItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const colorTheme = {
    color: isExpanded ? "rgb(227, 224, 224)" : "",
    padding: isExpanded ? "4px" : "",
  };

  return (
    <ThemeProvider theme={colorTheme}>
      <MenuItemWrapper
        onClick={() => {
          if (linkMode && url) {
            navigate(url);
            return;
          }
          setIsExpanded(!isExpanded);
        }}
      >
        <MenuItem>
          <ItemName>{<b>{name}</b>}</ItemName>
          <ExpandButton
            onClick={() => {
              if (linkMode) {
                return;
              }
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded && !linkMode ? (
              <i
                style={{ fontSize: "1.8em" }}
                className="ri-arrow-drop-up-line"
              ></i>
            ) : (
              !linkMode && (
                <i
                  style={{ fontSize: "1.8em" }}
                  className="ri-arrow-drop-down-line"
                ></i>
              )
            )}
          </ExpandButton>
        </MenuItem>
        {items && (
          <DropdownListWrapper className={isExpanded ? "expanded" : ""}>
            <DropdownList name={name} items={items} />
          </DropdownListWrapper>
        )}
      </MenuItemWrapper>
    </ThemeProvider>
  );
}

export default SidebarItem;

export const MenuItem = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: large;
  padding: 0.5em;
  background-color: ${(props) => props.theme.color};
  color: var(--grey-color);
  cursor: pointer;
  margin: 10px;
  border-radius: 4px;
  min-height: 1.8em;

  :hover {
    background-color: rgb(227, 224, 224);
  }
`;

const ItemName = styled.div`
  margin-left: 5px;
  grid-column: 1;
  text-align: left;
  display: grid;
  align-content: center;
  align-items: center;
`;

export const ExpandButton = styled.div`
  grid-column: 2;
  font-size: large;
  margin: auto;
  display: grid;
  align-items: center;
  align-content: center;
`;

const MenuItemWrapper = styled.div`
  grid-column: 1;
`;

const DropdownListWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;

  &.expanded {
    max-height: 2000px;
    transition: max-height 0.6s ease-in;
  }
`;
