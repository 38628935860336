import styled from "styled-components";
import Subheading from "../../../../../components/subheading";

const InfoPanel = () => {
  return (
    <Wrapper>
      <Left>
        <Subheading h3={"BRANDS"} h2={"WE WORK FOR"} />
      </Left>
      <Right>
        <p>
          Working closely with installers and manufacturers, Intrinsic Dev
          provide a range of services from system design to custom drivers and
          advanced integration solutions. InstrincDev offers drivers and white
          label support services for the Custom Install market, and are proud to
          have the following world-class brands as our clients:
        </p>
      </Right>
    </Wrapper>
  );
};

export default InfoPanel;

const Left = styled.div`
  margin-bottom: 20px;
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-self: center;
  text-align: left;
  line-height: 1.6;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4em 2em 2em;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 992px) {
    ${Right} {
      display: grid;
      grid-template-columns: 1fr;
      align-self: center;
      text-align: left;
      line-height: 1.6;
    }
  }
`;
