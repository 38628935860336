import ContentWrapper from "../../../components/reusables/ContentWrapper";
import styled, { ThemeProvider } from "styled-components";
import Tabs from "./Tabs";
import Cart from "./Views/Cart";
import useBreakpoint from "../../../hooks/useBreakpoint";
import MobileTabs from "./Tabs/Mobile";
import CheckoutButton from "./Views/Reusable/CheckoutButton";
import { useState } from "react";
import ProjectDetails from "./Views/ProjectDetails";
import CheckoutComplete from "./Views/CheckoutComplete";
import CustomerDetails from "./Views/CustomerDetails";
import Payment from "./Views/Payment";

const Checkout = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const { breakpoint } = useBreakpoint();
  const nextTab = () => {
    if (currentTab === 4) {
      return;
    }
    setCurrentTab((prev) => {
      return prev + 1;
    });
  };

  const theme = {
    marginBottom: currentTab <= 3 ? "2em" : "unset",
  };

  return (
    <ContentWrapper withLoc={breakpoint > 920 && currentTab <= 3}>
      <ThemeProvider theme={theme}>
        <Wrapper>
          {breakpoint > 920 && currentTab <= 3 && (
            <HeaderWrap>
              <Tabs currentTab={currentTab + 1} />
            </HeaderWrap>
          )}

          {breakpoint <= 920 && (
            <HeaderWrap>
              <MobileTabs currentTab={currentTab + 1} />
            </HeaderWrap>
          )}

          {currentTab === 0 && <Cart />}
          {currentTab === 1 && <ProjectDetails />}
          {currentTab === 2 && <CustomerDetails />}
          {currentTab === 3 && <Payment />}
          {currentTab === 4 && <CheckoutComplete />}
          {currentTab <= 3 && (
            <ButtonWrapper>
              <CheckoutButton onClick={nextTab} />
            </ButtonWrapper>
          )}
        </Wrapper>
      </ThemeProvider>
    </ContentWrapper>
  );
};

export default Checkout;

const HeaderWrap = styled.div`
  position: sticky;
  top: 150px;
  z-index: 8;

  @media only screen and (max-width: 920px) {
    font-size: 1em;
    top: 90px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => props.theme.marginBottom};
  display: grid;
  grid-gap: 10px;
  font-size: 0.9em;

  @media only screen and (max-width: 920px) {
    font-size: 1em;
  }
`;

const ButtonWrapper = styled.div`
  min-width: 30em;
  justify-self: end;
  align-self: end;
  @media only screen and (max-width: 920px) {
    min-width: unset;
    justify-self: unset;
  }
`;
