import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getNavBar(): Promise<MyResponse> {
  let controller = new AbortController();
  setTimeout(() => controller.abort(), 3000);
  const config: RequestConfig = {
    url: "/api/navBar",
    method: "GET",
    //signal: controller.signal,
  };
  return requestMiddleware(config);
}
