import { AddressResponse } from "../../../../../../../components/form/types";
import styled from "styled-components";

export interface AddressCardProps {
  editable?: boolean;
  address: AddressResponse;
}

const AddressCardAdmin = (props: AddressCardProps) => {
  const { address } = props;

  return (
    <DetailsWrap>
      <DetailHeading>
        {address.addressName ? address.addressName : "No name found"}
      </DetailHeading>
      <Detail>{address.firstLine ? address.firstLine : "-"}</Detail>
      <Detail>{address.secondLine ? address.secondLine : "-"}</Detail>
      <Detail>{address.city ? address.city : "-"}</Detail>
      <Detail>{address.state ? address.state : "-"}</Detail>
    </DetailsWrap>
  );
};

export default AddressCardAdmin;

const DetailsWrap = styled.div`
  border: var(--border-main);
  width: 12em;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  grid-gap: 4px;
  padding-left: 12px;
`;

const Detail = styled.p`
  font-size: 0.8em;
`;

const DetailHeading = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;
