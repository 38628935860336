import "../passwordReset.css";
import useInput from "../../../../hooks/useInput";
import CustomButton from "../../../../components/other/buttons/submit";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../../../api/Auth";
import { useState } from "react";
import SuccessScreen from "./successScreen";
import { Input, Header } from "../styledComponents";
import Subheading from "../../../../components/subheading";
import Captcha from "../../../../components/captcha";
const ResetRequest = () => {
  const [email, updateEmail] = useInput();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await forgotPassword(email);
      setSuccess(true);
    } catch (e: any) {
      //const errorJson = JSON.parse(e.request.response);
      setSuccess(true);
      //todo handle server errors
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ResetRequestWrap>
      {!success ? (
        <ResetRequestInner>
          <Wrapper>
            <Subheading h3={"Reset"} h2={"Password"} />
          </Wrapper>
          <Header>
            {" "}
            <p>Please enter your email address.</p>
            <p>Enter a valid address to receive a reset link via email</p>
          </Header>
          <Input type="text" value={email} onChange={updateEmail} />
          <Captcha resultCallback={setCaptchaVerified} />
          <CustomButton
            loading={submitting}
            text={"Submit"}
            onClick={handleSubmit}
            disabled={submitting || email.length <= 0 || !captchaVerified}
          />
          <Links>
            <p>
              Return to <Link to={"/login"}>Login</Link>
            </p>
            <p>
              Not yet registered? <Link to={"/register"}>Register</Link>
            </p>
          </Links>
        </ResetRequestInner>
      ) : (
        <SuccessScreen email={email} />
      )}
    </ResetRequestWrap>
  );
};

export default ResetRequest;

const ResetRequestWrap = styled.div`
  display: grid;
  grid-row: 2;
  align-self: center;
  margin-top: 2em;
`;
const ResetRequestInner = styled.div`
  border-radius: 7px;
  padding: 2.5em;
  border: var(--border-main);
  box-shadow: var(--box-shadow-main);
  justify-self: center;
  display: grid;
  justify-items: center;
  justify-content: center;
  align-content: start;
  width: max-content;
  grid-gap: 15px;
  height: min-content;

  button {
    justify-self: start;
    font-size: 0.9em;
    width: 100%;
  }
`;

const Links = styled.div`
  display: grid;
  justify-self: start;
  text-align: left;
  grid-gap: 5px;
  font-size: 0.9em;

  a {
    text-decoration: none;
    color: var(--orange-color);
  }
`;

const Wrapper = styled.div`
  justify-self: start;
`;
