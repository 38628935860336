import styled from "styled-components";
import React from "react";
import { StyledLink, SubTitle } from "../../submenu/element";
import { Category, Status } from "../../../../../api/Support";

type Props = {
  categories: Category[];
  statuses: Status[];
};
const SupportElements = (props: Props) => {
  const formUrl = "/support/form/";
  const requestUrl = "/support/requests/";

  const categoryLinks = props.categories.map((cat, idx) => {
    return (
      <StyledLink data-menu={true} to={`${formUrl}${cat.id}`}>
        {cat.name}
      </StyledLink>
    );
  });

  const statusLinks = props.statuses.map((status) => {
    return (
      <StyledLink data-menu={true} to={`${requestUrl}${status.id}`}>
        {status.name}
      </StyledLink>
    );
  });

  return (
    <MenuItem data-menu={true}>
      <Columns data-menu={true}>
        <MenuItem>
          <SubTitle>
            <p>Create New Ticket</p>
          </SubTitle>
          <Links style={{ paddingLeft: "10px" }}>{categoryLinks}</Links>
        </MenuItem>
        <MenuItem>
          <SubTitle>
            <p>My Tickets</p>
          </SubTitle>
          <Links style={{ marginLeft: "10px", paddingRight: "10px" }}>
            {statusLinks}
          </Links>
        </MenuItem>
      </Columns>
    </MenuItem>
  );
};

export default SupportElements;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const MenuItem = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  justify-content: start;
  grid-template-rows: auto 1fr;
`;

const Links = styled.div`
  display: grid;
  align-content: start;
  text-align: left;
  grid-gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
