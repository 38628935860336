import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import UpdateUserModal from "./EditModal";
import EditButton from "../../../../components/other/buttons/edit";
import { AddressResponse, Country } from "../../../../components/form/types";
import { UserAdminContext } from "../../Context/AdminUserContext";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import { useLocation } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";
import AddButton from "../../../../components/other/buttons/add";
import AddUserModal from "./AddModal";

export type User = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  userState: string;
  purchaseValue: string | number;
  purchaseCount: string | number;
  lastLoggedInDate: string;
  joinDate: string;
  displayName: string;
  companyName: string;
  addresses: AddressResponse[];
  roles: string[];
  phone: { number: string; country: Country };
  phone2: { number: string; country: Country };
  website: string;
  password: string;
  adminPassword?: string;
  emails: string[];
  country: string;
};

const Users = () => {
  const [userToEdit, setUserToEdit] = useState<{
    data: User;
    index: number;
  } | null>(null);
  const [showForm, setShowForm] = useState(false);
  const { users, loading } = useContext(UserAdminContext);
  const { breakpoint } = useBreakpoint();
  const location = useLocation();

  const handleRowClick = (
    data: User,
    index?: number,
    cb?: (newData: any) => void
  ) => {
    if (index !== undefined) {
      setUserToEdit({ data: data, index: index });
      setShowForm(true);
    }
  };

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            setUserToEdit(null);
            setShowForm(true);
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Username",
        accessor: "username",
        sortBy: "username",
        columnSizing: { min: "300px", max: "300px" },
      },
      {
        text: "Email",
        accessor: "email",
        sortBy: "email",
        columnSizing: { min: "300px", max: "300px" },
      },
      {
        text: "Prch Val",
        accessor: "purchaseValue",
        columnSizing: { min: "150px", max: "1fr" },
      },

      {
        text: "Prch Count",
        accessor: "purchaseCount",
        sortBy: "purchaseCount",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Last Login",
        accessor: "lastLoggedInDate",
        sortBy: "lastLoggedInDate",
        columnSizing: { min: "150px", max: "1fr" },
        cb: (data: any, value: any) => {
          return value ? DateTime.fromISO(value).toLocaleString() : "-";
        },
      },
      {
        text: "Name",
        accessor: "displayName",
        sortBy: "displayName",
        columnSizing: { min: "150px", max: "1fr" },
      },
      {
        text: "Company Name",
        accessor: "companyName",
        sortBy: "companyName",
        columnSizing: { min: "300px", max: "1fr" },
      },
      {
        text: "",
        columnSizing: { min: "60px", max: "60px" },
        cb: (
          data: any,
          value: any,
          index: number | undefined,
          cb?: (data: any) => void
        ) => {
          return (
            <EditButton
              onClick={() => {
                handleRowClick(data, index, cb);
              }}
              disabled={showForm}
            />
          );
        },
      },
    ],
    [userToEdit, showForm]
  );

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <Wrapper>
      {userToEdit == null && showForm && <AddUserModal close={handleClose} />}
      {userToEdit !== null && showForm && (
        <UpdateUserModal user={userToEdit.data} close={handleClose} />
      )}
      {breakpoint > 920 ? (
        <TableV2
          data={users}
          headerRow={setupTable}
          loading={loading}
          tableHeader={tableHeader}
        />
      ) : (
        <MobileTable
          padding={40}
          data={users}
          headerRow={setupTable}
          loading={loading}
        />
      )}
    </Wrapper>
  );
};

export default Users;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;
