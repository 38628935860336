import LicenseTransferUpper from "./upper";
import LicenseTransferForm from "../form";
import styled from "styled-components";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const LicenseTransfer = () => {
  const { user } = useContext(AuthContext);
  return (
    <ContentWrapper withLoc={true}>
      <Wrapper>
        <LicenseTransferUpper />
        {user && <LicenseTransferForm />}
      </Wrapper>
    </ContentWrapper>
  );
};

export default LicenseTransfer;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 40px;
  @media only screen and (max-width: 920px) {
    justify-content: center;
    grid-template-columns: 1fr;
    margin-top: 60px;
  }
`;
