import {
  UpdateProductImageAction,
  UpdateProductImageActionCase,
  UpdateProductImageFormState,
} from "./updateProductTypes";

export const UpdateProductImageReducer = (
  state: UpdateProductImageFormState,
  action: UpdateProductImageAction
): UpdateProductImageFormState => {
  switch (action.case) {
    case UpdateProductImageActionCase.EDIT:
      return action.value;
    case UpdateProductImageActionCase.UPDATE_IMAGE:
      return { ...state, file: action.value };
    case UpdateProductImageActionCase.REMOVE_FILE:
      return { ...state, file: undefined };
    case UpdateProductImageActionCase.UPDATE_MEDIA_TYPE:
      return { ...state, mediaType: action.value };
    default:
      return { ...state, [action.field]: action.value };
  }
};
