import styled from "styled-components";
import Item from "./Item";
import { Product } from "../../../../globaltypes";
import { useNavigate } from "react-router-dom";

type Props = {
  products: Product[];
  close: () => void;
};
const Dropdown = (props: Props) => {
  const nav = useNavigate();
  return (
    <WrapperContainer onMouseOut={props.close}>
      <Barrier />
      <Wrapper>
        <Header>
          <p>Your Cart</p>
          <CloseButton
            onClick={props.close}
            className="ri-close-line"
          ></CloseButton>
        </Header>
        <Items>
          {props.products.map((product) => {
            return <Item product={product} />;
          })}
        </Items>
        <Total>
          <p>x Items</p>
          <p style={{ justifySelf: "end" }}>£100</p>
        </Total>
        <Footer>
          <CheckoutButton
            onClick={(e) => {
              e.stopPropagation();
              nav("/checkout");
              props.close();
            }}
          >
            Checkout
          </CheckoutButton>
        </Footer>
      </Wrapper>
    </WrapperContainer>
  );
};

export default Dropdown;

const WrapperContainer = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  background-color: white;
  position: absolute;
  font-size: 0.9em;
  right: 0;
  grid-gap: 10px;
  border-radius: 4px;
  box-shadow: var(--box-shadow-main);
  cursor: auto;
  top: 15px;
  z-index: 10;

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -8px;
    right: 10px;
    z-index: 1;
  }

  &::after {
    border-bottom: 10px solid rgba(0, 0, 0, 0.1); // shadow color
    filter: blur(3px);
    z-index: 0;
    top: -9px;
    right: 11px;
  }
`;

const Header = styled.div`
  display: grid;
  height: 3em;
  border-bottom: var(--border-main);
  align-items: center;
  font-size: 1.1em;
  align-content: center;
  justify-items: start;
  padding-left: 20px;
  padding-right: 10px;
  position: sticky;
  top: 0;
  background-color: white;
  color: var(--grey-color);
  z-index: 8;
  grid-template-columns: 1fr 1fr;
  p {
    justify-self: start;
  }
`;

const Footer = styled.div`
  display: grid;
  height: 3em;
  font-size: 1.1em;
  position: sticky;
  bottom: 0;
`;

const Items = styled.div`
  max-height: 550px;
  overflow-y: scroll;
  grid-gap: 10px;
  display: grid;
  padding: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Total = styled.div`
  display: grid;
  height: 3em;
  position: sticky;
  bottom: 0;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  color: var(--grey-color);
  font-size: 0.9em;
`;

const CheckoutButton = styled.button`
  width: 100%;
  background-color: var(--orange-color);
  color: white;
  cursor: pointer;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const Barrier = styled.div`
  position: absolute;
  height: 2em;
  width: 100%;
  right: 0;
  top: -10px;
  background-color: transparent;
  cursor: initial;
`;

const CloseButton = styled.i`
  justify-self: end;
  color: var(--grey-color) !important;
  cursor: pointer;
`;
