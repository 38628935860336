import { DateTime } from "luxon";
import EditButton from "../../../../../components/other/buttons/edit";
import DeleteButton from "../../../../../components/other/buttons/delete";
import styled from "styled-components";
import React from "react";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import MobileTable from "../../../../../components/table/MobileTable";
import TableV2 from "../../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../../components/table/Other/Types";

const TransferLicence = () => {
  const objects = [
    {
      id: 352649,
      orderId: 313817,
      userId: 303626,
      oldMacAddress: "DA:26:1D:DA:54:4E",
      newMacAddress: "00-7C-2A-6E-DF-F2",
      userName: "rwhittaker",
      userEmail: "robert.whittaker+1@intrinsicdev.com",
      reason: "testing all the form, i think its all good now",
      addDate: "2023-02-03 16:25:22.475000",
      editDate: "2023-02-03 16:25:22.475000",
      status: "OPEN",
    },
  ];
  const { breakpoint } = useBreakpoint();

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Email",
        accessor: "userEmail",
        sortBy: "userEmail",
        columnSizing: { min: "200px", max: "1fr" },
      },
      {
        text: "Order ID",
        accessor: "orderId",
        sortBy: "orderId",
        columnSizing: { min: "120px", max: "120px" },
      },
      {
        text: "Old Mac Address",
        accessor: "oldMacAddress",
        columnSizing: { min: "200px", max: "1fr" },
      },
      {
        text: "New Mac Address",
        accessor: "newMacAddress",
        columnSizing: { min: "200px", max: "1fr" },
      },
      {
        text: "Status",
        accessor: "status",
        columnSizing: { min: "120px", max: "120px" },
      },
      {
        text: "Add Date",
        accessor: "addDate",
        sortBy: "addDate",

        cb: (data: any, value: any) => {
          return DateTime.fromISO(value).toLocaleString();
        },
      },
      {
        text: "",
        columnSizing: { min: "120px", max: "120px" },
        cb: () => {
          return (
            <ButtonWrap>
              <EditButton
                onClick={() => {
                  //handleRowClick(data, index, cb);
                }}
                disabled={false}
              />
              <DeleteButton
                iconType={"Row"}
                onClick={() => {}}
                disabled={false}
              />
            </ButtonWrap>
          );
        },
      },
    ],
    []
  );

  return (
    <Wrapper>
      {breakpoint > 920 ? (
        <TableV2 loading={false} data={objects} headerRow={setupTable} />
      ) : (
        <MobileTable
          padding={40}
          data={objects}
          headerRow={setupTable}
          loading={false}
        />
      )}
    </Wrapper>
  );
};

export default TransferLicence;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
