import styled from "styled-components";
import UserDetailsSection from "./details";
import AddressPickerAdmin from "./address/picker";
import { User } from "../index";
import React, { useEffect, useState } from "react";
import Window from "../../../Reusables/window";
import { UserAdminContextProvider } from "./details/context/UserAdminContext";
import useInfo from "../../../Hooks/useInfo";
import { getSingleUserAdmin } from "../../../../../api/Users";
import ClipLoader from "react-spinners/ClipLoader";

interface FormProps {
  close: () => void;
  user: User;
}

//TODO convert to use react hook form library

const UpdateUserModal = (props: FormProps): JSX.Element => {
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();
  const [user, setUser] = useState<User>(props.user);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const res = await getSingleUserAdmin(props.user.id);
        if (res.ok) {
          setUser(res.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      } finally {
      }
    };
    if (loading) {
      loadUser();
    }
  }, []);

  const theme = {
    padding: showInfo ? 0 : "20px",
  };

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
      noPlaceholder={true}
    >
      {loading ? (
        <Loader>
          <ClipLoader size={30} color={"var(--orange-color)"} />
        </Loader>
      ) : (
        <UserAdminContextProvider user={user}>
          <Wrapper>
            <UserDetailsSection
              padding={theme.padding}
              user={user}
              resetInfo={resetInfo}
              updateInfo={updateInfo}
            />
            <AddressPickerAdmin
              addresses={user.addresses}
              padding={theme.padding}
            />
          </Wrapper>
        </UserAdminContextProvider>
      )}
    </Window>
  );
};

export default UpdateUserModal;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const Loader = styled.div`
  height: 30em;
  width: 50em;
  background-color: white;
  display: grid;
  align-items: center;
  justify-items: center;
`;
