import React, { ReactNode } from "react";
import styled, { ThemeProvider } from "styled-components";
import Location from "../../location";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1232px;
  padding: 0 2rem;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: ${(props) => props.theme.rows};

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

interface ContentWrapperProps {
  children: ReactNode;
  style?: React.CSSProperties;
  withLoc?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  children,
  style,
  withLoc,
}) => {
  const theme = {
    rows: withLoc ? "auto 1fr" : "1fr",
  };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper style={style}>
        {withLoc && <div className={"app-location-wrap"}>{<Location />}</div>}
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};

export default ContentWrapper;
