import styled from "styled-components";
import React from "react";

type Props = {
  updateMedia: (url: File) => void;
};

const ImagePicker = (props: Props) => {
  return (
    <Wrapper
      onDragOver={(event) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
      }}
    >
      <ImagePickerLabel htmlFor="myImage">
        <Picker
          type="file"
          name="myImage"
          id={"myImage"}
          onChange={(event) => {
            if (event.target.files) {
              props.updateMedia(event.target.files[0]);
            }
          }}
        />
        <ImagePickerView
          onDrop={(event) => {
            event.preventDefault();
            if (event.dataTransfer.items) {
              // Use DataTransferItemList interface to access the file(s)
              Array.from(event.dataTransfer.items, (item, index) => {
                if (item.kind === "file") {
                  const file = item.getAsFile();
                  if (file) {
                    props.updateMedia(file);
                  }
                }
              });
            } else {
              // Use DataTransfer interface to access the file(s)
              Array.from(event.dataTransfer.files, (file, index) => {
                if (file) {
                  props.updateMedia(file);
                }
              });
            }
          }}
        >
          <ImagePickerText>Upload New Media</ImagePickerText>
        </ImagePickerView>
      </ImagePickerLabel>
    </Wrapper>
  );
};

export default ImagePicker;

const Picker = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ImagePickerView = styled.div`
  height: 10em;
  border: 1px dashed var(--grey-color);
  cursor: pointer;
`;
const ImagePickerText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  grid-column: 1/-1;
`;
const ImagePickerLabel = styled.label`
  grid-template-columns: auto;
`;
