import styled from "styled-components";

interface FullPage {
  imageSrc: string;
}

const FullPage = (props: FullPage) => {
  return (
    <Overlay>
      {" "}
      <FullPageImageWrap data-menu={true}>
        <FullPageImg src={props.imageSrc} data-menu={true} />{" "}
      </FullPageImageWrap>
    </Overlay>
  );
};

export default FullPage;

const FullPageImg = styled.img`
  height: 80vh;
  width: auto;
`;

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const FullPageImageWrap = styled.div`
  width: min-content;
  padding: 8px;
  background-color: var(--grey-color);
  height: 80vh;
  z-index: 4;
  top: 10vh;
  left: 20%;
  right: 20%;
`;
