import React, { ReactElement, useEffect, useRef } from "react";
import { HeaderRowItem } from "../../Other/Types";
import { resolvePath } from "../../../../utils/utils";
import styled from "styled-components";

interface Props {
  showEditRow: boolean;
  showDeleteRow: boolean;
  showDelInfo: boolean;
  showInfoRow: boolean;
  infoRow?: React.ReactNode;
  data: any;
  headerRow: HeaderRowItem[];
  handleRowClick?: (row: any) => void;
  index: number;
  getInputRow?: (setData: (newData: any) => void) => ReactElement;
  getDelRow?: (setData?: (newData: any) => void) => ReactElement;
  getModal?: (setData?: (newData: any) => void) => ReactElement;
  rowRef: React.RefObject<HTMLTableRowElement>;
}

const Row = (props: Props) => {
  const refIndex = useRef(0);

  useEffect(() => {
    if (props.showEditRow) {
      refIndex.current = props.index;
    } else {
      refIndex.current = 0;
    }
  }, [props.showEditRow, props.index]);

  return (
    <>
      {props.showEditRow && props.getInputRow ? (
        <tr ref={props.rowRef}>{props.getInputRow(props.data)}</tr>
      ) : props.showDeleteRow && props.getDelRow ? (
        <tr ref={props.rowRef}>{props.getDelRow(props.data)}</tr>
      ) : (
        props.data.status !== "DELETED" && (
          <tr
            key={props.data.id}
            ref={props.index === refIndex.current ? props.rowRef : null}
            onClick={() => {
              props.handleRowClick && props.handleRowClick(props.data);
            }}
          >
            {props.headerRow.map((item) => {
              return (
                <td>
                  <Cell>
                    {item.text && item.text.length > 0 && item.text + ": "}
                    {item.cb
                      ? item.cb(
                          props.data,
                          item.accessor &&
                            resolvePath(props.data, item.accessor),
                          props.index
                        )
                      : item.accessor && resolvePath(props.data, item.accessor)}
                  </Cell>
                </td>
              );
            })}
          </tr>
        )
      )}
    </>
  );
};

export default Row;

export const Cell = styled.div``;
