import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProduct } from "../../../api/Product";
import { Product } from "../../../globaltypes";

import Description from "./description";
import { PulseLoader } from "react-spinners";
import styled from "styled-components";
import Banner from "./banner";
import Media from "./media";
import { useLocation } from "react-router";
import ContentWrapper from "../../../components/reusables/ContentWrapper";

const ProductPage = () => {
  const [product, setProduct] = useState<Product | null>(null);
  const { productUrl } = useParams();
  const location = useLocation();
  const loadProduct = async () => {
    //TODO error handler
    if (productUrl) {
      getProduct(productUrl).then((res) => {
        if (res.ok) {
          setProduct(res.data.objects[0]);
        }
      });
    }
  };

  useEffect(() => {
    loadProduct();
    return () => {
      setProduct(null);
    };
  }, [location]);

  return (
    <ContentWrapper withLoc={true} style={{ gridRow: 2 }}>
      {!product && (
        <SpinnerWrap>
          {" "}
          <PulseLoader size={7} color={"var(--grey-color)"} />
        </SpinnerWrap>
      )}
      <Wrapper>
        {product && <Banner />}
        {product && product.media.length > 0 && <Media product={product} />}
        {product && <Description product={product} />}
      </Wrapper>
    </ContentWrapper>
  );
};

export default ProductPage;

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1/-1;
  height: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  margin-bottom: 2em;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
