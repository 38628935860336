import React from "react";
import Window from "../../../Reusables/window";
import useInfo from "../../../Hooks/useInfo";
import styled from "styled-components";
import Details from "./Details";
import Subheading from "../../../../../components/subheading";

interface FormProps {
  close: () => void;
}

const AddUserModal = (props: FormProps): JSX.Element => {
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
    >
      <Wrapper>
        <Heading>
          <Subheading h3={"Create"} h2={"User"} />
          <SaveButton onClick={() => {}} className="ri-save-fill"></SaveButton>
        </Heading>
        <Details />
      </Wrapper>
    </Window>
  );
};

export default AddUserModal;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
`;

const Loader = styled.div`
  height: 30em;
  width: 50em;
  background-color: white;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const SaveButton = styled.i`
  color: var(--orange-color);
  justify-self: end;
  font-size: 1.1em;
`;

const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 20px;
  margin-right: 20px;
`;
