import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { AuthContext } from "../../../../context/AuthContext";
import { capitaliseString } from "../../../../utils/utils";
import Checkout from "../../Checkout";

const HeaderLinks = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const pathname = location.pathname;

  const isActive = (path: string) => pathname === path;

  return (
    <Links>
      <HeaderLink to="/about" active={isActive("/about")}>
        <i className="ri-team-fill"></i>
        <div>About Us</div>
      </HeaderLink>
      <HeaderLink to="/contact" active={isActive("/contact")}>
        <i className="ri-mail-fill"></i>
        <div>Contact</div>
      </HeaderLink>
      <HeaderLink
        to="/showroom-licence/form"
        active={isActive("/showroom-licence/form")}
      >
        <i className="ri-home-office-fill"></i>
        <div>Showroom</div>
      </HeaderLink>
      {!user && (
        <>
          <HeaderLink to="/login" active={isActive("/login")}>
            <i className="ri-user-fill"></i>
            <AccountName>Login</AccountName>
          </HeaderLink>
          <HeaderLink to="/register" active={isActive("/register")}>
            <i className="ri-user-add-fill"></i>
            <AccountName>Register</AccountName>
          </HeaderLink>
        </>
      )}
      {user && (
        <HeaderLink to="/account" active={isActive("/account")}>
          <i className="ri-user-fill"></i>
          <AccountName>{capitaliseString(user.username)}</AccountName>
        </HeaderLink>
      )}
      {user && <Checkout />}
    </Links>
  );
};

export default HeaderLinks;

const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  font-size: 0.9em;
  min-width: 30em;
  justify-items: start;
  grid-gap: 1em;
  align-items: flex-end;
`;

const underlineStyle = css`
  border-bottom: solid 1px #ff9d26;
`;

const HeaderLink = styled(Link)<{ active: boolean }>`
  i {
    font-size: 1.8em;
    color: var(--orange-color);
  }
  div {
    line-height: 1.1em;
  }
  display: grid;
  cursor: pointer;
  color: black;
  text-decoration: none;
  border-bottom: solid 1px rgba(255, 157, 38, 0);

  ${(props) => props.active && underlineStyle}

  :hover {
    border-bottom: solid 1px #ff9d26;
  }
`;

const AccountName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
