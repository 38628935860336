import Markdown from "markdown-to-jsx";
import { Product } from "../../../../../../../globaltypes";

interface PanelProps {
  product: Product;
}
const Supported = (props: PanelProps) => {
  return (
    <div className={"innerDescription"}>
      {
        <Markdown>
          {props.product.supportedModels
            ? props.product.supportedModels
            : "No description available."}
        </Markdown>
      }
    </div>
  );
};

export default Supported;
