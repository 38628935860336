import React from "react";
import { orderLine } from "../../index";
import styled from "styled-components";
import { InputHandler } from "../../../../../components/form/types";
import ProductQuantity from "../../../../../components/form/components/productQuantity";

interface Props {
  orderLines: orderLine[];
  controls?: boolean;
  handlechange?: InputHandler;
}

const Products = (props: Props) => {
  return (
    <ProductsWrapper>
      <Header>
        <ProductsHeader>Products</ProductsHeader>
      </Header>
      <ProductContainer>
        {props.orderLines.length > 0 ? (
          props.orderLines.map((orderLine) =>
            props.controls ? (
              <ProductQuantity
                handlechange={props.handlechange as InputHandler}
                orderLine={orderLine}
              />
            ) : (
              <Product
                productName={orderLine.productName}
                value={orderLine.quantity.toString()}
                localValue={orderLine.localValue}
              />
            )
          )
        ) : (
          <NoProductsContainer>No Products Found</NoProductsContainer>
        )}
      </ProductContainer>
    </ProductsWrapper>
  );
};

const Product = (props: {
  productName: string;
  value: string;
  localValue: string;
}) => {
  return (
    <Wrapper>
      <ProductWrapper>
        {props.productName ? props.productName : "-"}
      </ProductWrapper>
      <QuantityWrapper>
        <QuantityNumber>{props.value ? props.value : "0"}</QuantityNumber>
      </QuantityWrapper>
      <ValueContainer>
        £{props.localValue ? props.localValue : "0"}
      </ValueContainer>
    </Wrapper>
  );
};

export default Products;

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 20px;
`;

const ProductsHeader = styled.h2`
  font-size: 1.2em;
  font-weight: 500;
  color: var(--header-color);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
  height: fit-content;
  border-bottom: var(--border-main);
`;

const ProductWrapper = styled.div`
  font-size: 0.8em;
  display: flex;
  align-items: center;
  flex: 2;
  font-weight: 900;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: center;
`;

const QuantityNumber = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  display: flex;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

const NoProductsContainer = styled.div`
  margin-top: 1em;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  margin-top: 1em;
`;

const ProductContainer = styled.div``;
