import useInfo from "../../../Admin/Hooks/useInfo";
import styled from "styled-components";
import Details from "./Details";
import Subheading from "../../../../components/subheading";
import Window from "../../../Admin/Reusables/window";
import AddressPicker from "../../../../components/addressPicker";
import { AddressTypeCases } from "../../../../components/addressPicker/typeSelect";

interface FormProps {
  close: () => void;
}

const CompanyModal = (props: FormProps): JSX.Element => {
  const [fail, info, , showInfo, resetInfo] = useInfo();

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
    >
      <Wrapper>
        <Main>
          <Heading>
            <Subheading h3={"Edit"} h2={"Company"} />
            <SaveButton
              onClick={() => {}}
              className="ri-save-fill"
            ></SaveButton>
          </Heading>

          <Details />
        </Main>

        <AddressPicker
          addresses={[
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
            {
              id: 123456,
              vatCode: "TEST VAT",
              deliveryContact: "TEST CONTACT",
              firstLine: "TEST ADDRESS",
              secondLine: "null",
              city: "NEW YORK",
              state: "TA",
              country: "US",
              zipCode: "TEST ZIP",
              addressType: AddressTypeCases.BILLING,
              addressName: "TEST ITEM",
            },
          ]}
        />
      </Wrapper>
    </Window>
  );
};

export default CompanyModal;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  text-align: left;
  height: 75vh;
  grid-template-columns: 1fr auto;
  padding-bottom: 20px;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    padding-bottom: unset;
    height: 90vh;
  }
`;

const Main = styled.div`
  overflow-y: scroll;
`;

const SaveButton = styled.i`
  color: var(--orange-color);
  justify-self: end;
  font-size: 1.1em;
`;

const Heading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 20px;
  margin-right: 20px;
  align-self: start;
`;
