import React, { Component, ErrorInfo, ReactNode } from "react";
import generateErrorObject from "../generateErrorObject";
import { ErrorModal } from "../../components/errorModal";

type Props = {
  modal?: { name: string; close: (state: false) => void };
  tab?: {
    name: string;
    handleFallback: (fallback: string) => void;
    fallback: string;
  };
  children: ReactNode;
};

interface Error {
  name: string;
  message: string;
  stack?: string;
}

type State = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  errorMsg: string;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      errorMsg: "",
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
    });
  }

  render(): ReactNode {
    const modal = this.props.modal;
    const tab = this.props.tab;
    let errorMsg: string;
    if (tab) {
      errorMsg = `There was a problem loading ${tab.name} tab`;
    } else if (modal) {
      errorMsg = `There was a problem loading ${modal.name} modal`;
    } else {
      errorMsg = "There was a problem loading the page";
    }
    function fallback(): void {
      if (tab) tab.handleFallback(tab.fallback);
      else window.location.assign("/");
    }

    if (this.state.errorInfo) {
      const customError = {
        response: {
          status: 0,
          data: { message: "" },
          config: { url: "url: " + window.location },
        },
      };
      return (
        <ErrorModal
          fallback={fallback}
          err={generateErrorObject(
            customError,
            this.state.error?.message,
            errorMsg
          )}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
