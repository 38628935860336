import React, { useState } from "react";
import { Order } from "../../index";
import styled from "styled-components";
import Subheading from "../../../../../components/subheading";
import Products from "./products";
import Card from "../../../../Admin/Reusables/card";
import ModalButtons from "../../../../Admin/Reusables/modalButtons";
import { Controller, useForm } from "react-hook-form";

interface OrderLineState {
  orderNumber: null;
  productId: number;
  productName: string;
  quantity: number;
  baseValue: number;
  baseCurrency: string;
  localValue: string;
  localCurrency: string;
}

interface Props {
  order: Order;
  initialOrder: Order;
  edit: boolean;
  toggleEdit: () => void;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  resetInfo: () => void;
  resetState: () => void;
  setCloseType: (closeType: string) => void;
}
//TODO - post/put
const OrdersDetailsSection = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const defaults = {
    orderNumber: props.order.orderNumber || "-",
    orderDate: props.order.orderDate || "-",
    phoneNumber: props.order.phoneNumber || "-",
    voucher: props.order.voucher || "-",
    projectName: props.order.projectName || "-",
    macAddress: props.order.macAddress || "-",
    orderEmail: props.order.orderEmail || "-",
    orderLines: props.order.orderLines || [],
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaults,
  });

  // ------ prev form implementation post, copied here in case it's needed for reference, seems to be incomplete
  /*
    const handleSubmit = async (id?: number) => {
    //Todo: Implement PUT request when backend has updated.
    let newOrder = {
      purchaseOrderNumber: state.customerOrderId,
      phoneNumber: state.phoneNumber,
      status: state.orderStatus,
      billingMethod: "STRIPE",
      macAddress: state.macAddress,
      projectName: state.projectName,
      voucher: state.voucher,
      orderEmail: state.orderEmail,
      orderId: Number(state.orderNumber.substring(3, state.orderNumber.length)),
      billingAddress: state.billingAddress,
      deliveryAddress: state.deliveryAddress,
      addOrderLineRequests: state.orderLines,
    };
    return await updateOrder(newOrder);
  };*/

  const orderLines: OrderLineState[] = watch("orderLines");
  const handleSave = async (data: any) => {
    console.log(data);
    /*
    try {
      if (!loading) {
        setLoading(true);
        const res = await handleSubmit(props.order.customerOrderId);
        props.updateInfo(
          `${res.data.name ? res.data.name : "Order"}` +
            " updated successfully",
          res.data.id,
          false
        );
        // props.updateTable(res.data);
        // setEditMode(false);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "Item update failed"
        }`,
        props.order.customerOrderId,
        true
      );
    } finally {
      setLoading(false);
    }*/
  };

  const manualSubmit = handleSubmit(handleSave);

  return (
    <Wrapper>
      <Heading>
        <Subheading h3={"Update"} h2={"Order"} />
        <ModalButtons
          editMode={props.edit}
          loading={loading}
          handleSave={() => {
            manualSubmit();
          }}
          setEditMode={props.toggleEdit}
          resetState={reset}
          resetInfo={props.resetInfo}
          disabled={false}
        />
      </Heading>
      <Inner>
        <>
          <Card
            heading={"Order Number"}
            text={props.order.orderNumber ? props.order.orderNumber : "-"}
          />
          <Card
            heading={"Order Date"}
            text={props.order.orderDate ? props.order.orderDate : "-"}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Phone Number"}
                text={field.value}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "phoneNumber",
                }}
                editing={props.edit}
              />
            )}
          />
          <Controller
            name="voucher"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Voucher"}
                text={field.value}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "voucher",
                }}
                editing={props.edit}
              />
            )}
          />
          <Controller
            name="projectName"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Project Name"}
                text={field.value}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "projectName",
                }}
                editing={props.edit}
              />
            )}
          />
          <Controller
            name="macAddress"
            control={control}
            render={({ field }) => (
              <Card
                heading={"MAC Address"}
                text={field.value}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "macAddress",
                }}
                editing={props.edit}
              />
            )}
          />
          <Controller
            name="orderEmail"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Order Email"}
                text={field.value}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "orderEmail",
                }}
                editing={props.edit}
              />
            )}
          />
        </>
      </Inner>

      <Controller
        name="orderLines"
        control={control}
        render={({ field }) => (
          <Products
            orderLines={field.value}
            controls={props.edit}
            handlechange={(event) => {
              let newOrderLines = orderLines.map((orderLine) => {
                if (orderLine.productId.toString() === event.target.name) {
                  orderLine.quantity = Number(event.target.value);
                }
                return orderLine;
              });
              setValue("orderLines", newOrderLines);
            }}
          />
        )}
      />
    </Wrapper>
  );
};

export default OrdersDetailsSection;

const Wrapper = styled.div`
  display: grid;
  position: relative;
  min-width: 17em;
  margin: 0 1.5em 2em 1.5em;
  max-height: 75vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  grid-template-rows: auto 1fr 1fr;
  padding-right: 1em;
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 2em;
  margin-bottom: 2em;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17em, 1fr));
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  padding: 10px;
`;
