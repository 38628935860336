import React, { useEffect, useRef, useState } from "react";

const useShowMenu = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (e !== null && e.target instanceof HTMLElement) {
        const element = e.target as HTMLElement;
        if (!element.closest("[data-menu]") && e.target !== menuRef.current) {
          setShowMenu(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const toggleOn = (e: React.MouseEvent<HTMLElement>): void => {
    setShowMenu(true);
    const target = e.target as HTMLElement;
    if (menuRef.current != null) {
      menuRef.current = target;
    }
  };

  const toggleOff = (): void => {
    setShowMenu(false);
    menuRef.current = null;
  };

  const reset = (): void => {
    menuRef.current = null;
    setShowMenu(false);
  };

  return [showMenu, toggleOn, toggleOff, reset] as const;
};

export default useShowMenu;
