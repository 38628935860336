import React from "react";
import CancelButton from "../../../../../components/other/buttons/cancel";
import { DateTime } from "luxon";
import DeleteButton from "../../../../../components/other/buttons/delete";
import styled from "styled-components";

interface Props {
  close: () => void;
  refresh: () => void;
  data?: any;
  updateInfo: (message: string, id: number | null, fail: boolean) => void;
  buttonsDisabled: boolean;
  handleDel: (index?: number, cb?: (data: any) => void) => void;
  disabled?: boolean;
  loading?: boolean;
  index?: number;
}
const DelRow = (props: Props) => {
  return (
    <>
      <td>{props.data.name}</td>
      <td>{props.data.description}</td>
      <td>{props.data.url}</td>
      <td>{props.data.productCount ? props.data.productCount : "-"}</td>
      <td>
        {props.data && props.data.addDate
          ? DateTime.fromISO(props.data.addDate).toLocaleString()
          : "-"}
      </td>
      <td>
        <ButtonWrap>
          <DeleteButton
            iconType={"bin"}
            onClick={() => props.handleDel(props.index)}
            disabled={props.disabled}
            loading={props.loading}
            index={props.index}
          />{" "}
          <CancelButton
            onClick={props.close}
            disabled={props.buttonsDisabled}
          />
        </ButtonWrap>
      </td>
    </>
  );
};

export default DelRow;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
