import { Link } from "react-router-dom";

type SidebarLinkProps = {
  name: string;
  url: string;
  prefix?: string;
  suffix?: string;
};

const SidebarLink = (props: SidebarLinkProps) => {
  const { name, url, prefix = "", suffix = "" } = props;
  const toLink = `${prefix}${url}${suffix}`;

  return (
    <Link data-menu={true} to={toLink}>
      {"- " + name}
    </Link>
  );
};

export default SidebarLink;
