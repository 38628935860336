import { Link } from "react-router-dom";
import styled from "styled-components";

const HomeContact = () => {
  return (
    <Wrapper>
      <Left>
        {" "}
        <h3>INTERESTED IN WORKING WITH US?</h3>
        <h3>GET IN TOUCH!</h3>
        <p>
          Drop us a line to see how Intrinsic Dev can offer you the solution you
          need.
        </p>
      </Left>
      <Right>
        <Button>
          <Link to={"/contact"}>Contact</Link>
        </Button>
      </Right>
    </Wrapper>
  );
};

export default HomeContact;

const Left = styled.div`
  justify-self: center;
  display: grid;
  grid-gap: 10px;
  font-weight: bold;
`;

const Right = styled.div`
  justify-self: start;
  padding-left: 1em;
`;

const Button = styled.button`
  color: white;
  border-radius: 22px;
  font-size: 1.1em;
  padding: 12px;
  cursor: pointer;
  border: 2px solid white;
  background: linear-gradient(to right, #ff9f00 50%, #b3aea9 50%);
  background-size: 200% 100%;
  background-position: bottom right;
  transition: all 0.2s ease-in-out;
  a {
    text-decoration: none;
    color: white;
  }
  :hover {
    background-position: bottom left;
  }
  @media only screen and (max-width: 920px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.9em;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 20em;
  background-color: #b3aea9;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  grid-column: 1/-1;
  margin-bottom: 6em;
  border-radius: 12px;
  max-width: 80vw;
  justify-self: center;
  padding-left: 40px;
  padding-right: 40px;

  h3 {
    color: white;
    font-size: 2em;
    justify-self: start;
    text-align: left;
  }
  p {
    color: white;
  }
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
    ${Right} {
      justify-self: center;
    }
    h3 {
      color: white;
      font-size: 1.5em;
      justify-self: center;
    }
  }

  @media only screen and (max-width: 600px) {
    height: 15em;
    grid-gap: 10px;
    max-width: 100%;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-radius: 0;
    h3 {
      font-size: 1em;
    }
    p {
      font-size: 0.8em;
    }
  }
`;
