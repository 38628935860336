import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getUsersAdmin(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/admin/users",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function getSingleUserAdmin(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/admin/users/user?id=${id}`,
    method: "GET",
  };
  return requestMiddleware(config);
}
