import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  max-width: 26em;
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.23);
  border-radius: 1px;
  font-size: 1em;
  height: 2.2em;
  box-sizing: border-box;
  grid-gap: 2px;
`;

export const Header = styled.div`
  text-align: left;
  line-height: 1.4;
`;
