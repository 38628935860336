import { InputHandler } from "../../types";
import React from "react";
import styled from "styled-components";
import {
  OrderActionCase,
  OrderLineState,
  OrderValidationCase,
} from "../../setup/orders/orderTypes";
import LabeledInput from "../labeledInput";

interface ProductQuantityInputProps {
  orderLine: OrderLineState;
  handlechange: InputHandler;
}

const ProductQuantity = (props: ProductQuantityInputProps) => {
  return (
    <Wrapper>
      <ProductWrapper>
        {props.orderLine.productName ? props.orderLine.productName : "-"}
      </ProductWrapper>
      <QuantityWrapper>
        <LabeledInput
          case={OrderActionCase.UPDATE_PRODUCT}
          value={props.orderLine.quantity.toString()}
          handlechange={props.handlechange}
          name={props.orderLine.productId.toString()}
          validationtype={OrderValidationCase.VALIDATE_PRODUCT_QUANTITY}
          // validationfield={}
          label={{
            for: "quantity",
            required: false,
            text: "Quantity",
          }}
          errormessage={" "}
          showerrors={false}
        />
      </QuantityWrapper>
      <ValueContainer>
        £{props.orderLine.localValue ? props.orderLine.localValue : "0"}
      </ValueContainer>
    </Wrapper>
  );
};

export default ProductQuantity;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: space-between;
  height: fit-content;
  border-bottom: var(--border-main);
`;

const ProductWrapper = styled.div`
  font-size: 0.8em;
  display: flex;
  align-items: center;
  flex: 2;
  font-weight: 900;
`;

const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: center;
  max-width: 100px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;
