import logo from "../../../assets/images/header/logo.png";
import HeaderLinks from "./headerlinks";
import NavBar from "../navbar";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import SearchBox from "./searchbox";
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import useBreakpoint from "../../../hooks/useBreakpoint";
import styled from "styled-components";

const Header = () => {
  let { breakpoint } = useBreakpoint();
  const [showHamburger, setShowHamburger] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  useEffect(() => {
    if (breakpoint < 920) {
      setShowHamburger(true);
    } else {
      setShowHamburger(false);
      setSidebarOpen(false);
    }
  }, [breakpoint]);

  return (
    <HeaderWrapper>
      <TopWrapper>
        {!showHamburger && (
          <SearchWrap>
            <SearchBox />
          </SearchWrap>
        )}
        <HomeLink to="/">
          {" "}
          <LogoImage alt="logo" src={logo} />
        </HomeLink>
        {!showHamburger && <HeaderLinks />}
        {showHamburger && !sidebarOpen && (
          <>
            <MenuIcon onClick={() => setSidebarOpen(true)}>
              <i className="ri-menu-unfold-line"></i>
            </MenuIcon>
          </>
        )}
        {sidebarOpen && (
          <MenuIcon
            onClick={() => {
              setSidebarOpen(false);
            }}
          >
            <i className="ri-menu-fold-line"></i>
          </MenuIcon>
        )}
      </TopWrapper>
      {sidebarOpen && (
        <Overlay
          isExpanded={sidebarOpen}
          onClick={() => setSidebarOpen(false)}
        />
      )}
      <SidebarWrapper isExpanded={sidebarOpen}>
        <Sidebar isExpanded={sidebarOpen} />
      </SidebarWrapper>
      {!showHamburger && <NavBar />}
    </HeaderWrapper>
  );
};

export default Header;

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: grid;
  position: fixed;
  grid-template-rows: auto 1fr;
  height: 9em;
  z-index: 9;
  @media only screen and (max-width: 920px) {
    height: 6em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const TopWrapper = styled.div`
  grid-column: 1/-1;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1400px;
  margin: 0 auto;
  height: 6em;
  box-sizing: border-box;
  @media only screen and (max-width: 920px) {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: content-box;
    margin: unset;
  }
`;

const LogoImage = styled.img`
  grid-column: 2;
  height: 2.5em;
  width: auto;
  justify-self: center;
`;

const HomeLink = styled(Link)`
  width: min-content;
  justify-self: center;
  grid-column: 2;
`;

const MenuIcon = styled.div`
  background-color: var(--grey-color);
  cursor: pointer;
  color: white;
  z-index: 10;
  display: grid;
  align-items: center;
  font-size: 1.3em;
  padding: 4px;
  border-radius: 4px;
  position: fixed;
  top: 5px;
  right: 5px;

  @media only screen and (max-width: 400px) {
    font-size: 1.1em;
  }
`;

const SidebarWrapper = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  transform: ${(props) =>
    props.isExpanded ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-out;
`;

export const Overlay = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: ${(props) => (props.isExpanded ? "block" : "none")};
`;

const SearchWrap = styled.div`
  max-width: 20em;
`;
