import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface ErrorContextInterface {
  updateCode: (code: number) => void;
  updateText: (text: string) => void;
  pagePaths: string[];
}

const properties: ErrorContextInterface = {
  updateCode: () => {},
  updateText: () => {},
  pagePaths: [],
};

const ErrorContext = React.createContext<ErrorContextInterface>(properties);

const ErrorContextProvider = (props: { children: JSX.Element }) => {
  const location = useLocation();
  const [errorStatusCode, setErrorStatusCode] = useState<number>();
  const [statusText, setStatusText] = useState<string>();
  const ERROR_PAGE_PATHS = ["/401", "/403", "/404", "/500"];
  const nav = useNavigate();

  useEffect(() => {
    // Listen for changes to the current location.
    // remove status code on location change
    if (ERROR_PAGE_PATHS.includes(location.pathname)) {
      setErrorStatusCode(undefined);
      setStatusText(undefined);
    }
  }, [location]);

  const updateCode = (code: number) => {
    setErrorStatusCode(code);
  };

  const updateText = (text: string) => {
    setStatusText(text);
  };
  useEffect(() => {
    if (errorStatusCode) {
      if (errorStatusCode === 401) {
        nav("/401");
      }
      if (errorStatusCode === 403) {
        nav("/403");
      }
      if (errorStatusCode >= 408) {
        nav("/500");
      }
    }
  }, [errorStatusCode, nav]);

  return (
    <ErrorContext.Provider
      value={{ updateCode, updateText, pagePaths: ERROR_PAGE_PATHS }}
    >
      {props.children}
    </ErrorContext.Provider>
  );
};

export { ErrorContext, ErrorContextProvider };
