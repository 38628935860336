import styled, { ThemeProvider } from "styled-components";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import logo from "../../../assets/images/header/logo.png";
import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { capitaliseString } from "../../../utils/utils";
import SubMenu from "./subMenu";
import useBreakpoint from "../../../hooks/useBreakpoint";

const Header = () => {
  const params = useParams();
  const { breakpoint } = useBreakpoint();

  const { user } = useContext(AuthContext);

  const linkArr = [
    { text: "Orders", to: "Orders" },
    { text: "Products", to: "products" },
    { text: "Users", to: "users" },
    { text: "Platforms", to: "platforms" },
    { text: "Brands", to: "brands" },
    { text: "Categories", to: "categories" },
    { text: "Vouchers", to: "Vouchers" },
  ];

  const links = linkArr.map((item) => {
    const colorTheme = {
      color: item.to === params["*"] ? "var(--orange-color)" : "white",
    };
    return (
      <ThemeProvider key={uuidv4()} theme={colorTheme}>
        <StyledLink to={item.to}>{item.text}</StyledLink>
      </ThemeProvider>
    );
  });
  return (
    <Wrapper>
      {" "}
      <TopWrapper>
        <HomeLink to="/">
          {" "}
          <LogoImage alt="logo" src={logo} />
        </HomeLink>
        {user && breakpoint > 920 && (
          <HeaderLink to={"/account/"}>
            <i className="ri-user-fill"></i>
            {capitaliseString(user && user.username)}
          </HeaderLink>
        )}
      </TopWrapper>
      {breakpoint > 920 && (
        <AdminLinkWrap>
          {links}
          <SubMenu
            links={[
              {
                text: "Transfer Licence Requests",
                to: "licenceTransfer",
              },
              {
                text: "Showroom Licence Requests",
                to: "Requests/showroom",
              },
              {
                text: "Driver Development Requests",
                to: "Requests/driver",
              },
            ]}
          />
        </AdminLinkWrap>
      )}
    </Wrapper>
  );
};

export default Header;

const AdminLinkWrap = styled.div`
  width: 100%;
  height: 2.5em;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  position: sticky;
  top: 0;
  background-color: #4b4b4b;
  z-index: 3;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  :hover {
    color: var(--orange-color);
  }
  color: ${(props) => props.theme.color};
`;

const LogoImage = styled.img`
  grid-column: 2;
  height: 2em;
  width: auto;
  justify-self: center;
`;

const HomeLink = styled(Link)`
  width: min-content;
  justify-self: center;
  grid-column: 2;
`;

const HeaderLink = styled(Link)`
  cursor: pointer;
  color: black;
  border-bottom: solid 1px rgba(255, 157, 38, 0);
  text-decoration: none;
  align-self: center;
  :hover {
    border-bottom: solid 1px #ff9d26;
  }
`;

const TopWrapper = styled.div`
  grid-column: 1/-1;
  background-color: #ffffff;
  display: grid;
  align-items: center;
  align-content: center;
  padding-top: 1.2em;
  justify-items: center;
  justify-content: center;
  padding-bottom: 1.2em;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Wrapper = styled.div``;
