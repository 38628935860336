import styled from "styled-components";
import ProgressIndicator from "./ProgressIndicator";

type Props = {
  currentTab: number;
};

const MobileTabs = (props: Props) => {
  const details = [
    "My Cart",
    "Project ProjectDetails",
    "My ProjectDetails",
    "Payment",
  ];
  const step =
    props.currentTab === 1
      ? "first"
      : props.currentTab === 2
      ? "second"
      : props.currentTab === 3
      ? "third"
      : props.currentTab === 4
      ? "fourth"
      : "complete";

  const currentIndex = props.currentTab - 1;
  return (
    <Wrapper>
      <ProgressIndicator step={step} color={"var(--orange-color)"} />
      <ProgressDetails>
        {step === "complete" ? (
          "Complete"
        ) : (
          <>
            <h1>{details[currentIndex]}</h1>
            <p>Next: {details[currentIndex + 1]}</p>
          </>
        )}
      </ProgressDetails>
      <Border />
    </Wrapper>
  );
};
export default MobileTabs;

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: auto auto;
  position: relative;
`;

const ProgressDetails = styled.div`
  text-align: left;
  align-self: center;
  margin-left: 20px;
  h1 {
    font-size: 1.6em;
  }
`;

const Border = styled.div`
  width: 100%;
  position: absolute;
  height: 2px;
  background-color: var(--light-border);
  bottom: 0;
`;
