import styled, { ThemeProvider } from "styled-components";
import { useState } from "react";

interface Props {
  text: string;
  remove: (item?: any) => void;
  editMode: boolean;
}
const Item = (props: Props) => {
  const [showOverlay, setShowOverlay] = useState(false);
  //Weird safari bug hiding element if border size is bellow 1.5px
  const theme = {
    border:
      props.editMode && showOverlay
        ? "solid 1px var(--orange-color)"
        : "1.5px solid rgba(0, 0, 0, 0.20)",
  };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper
        data-menu={true}
        onMouseOver={() => {
          setShowOverlay(true);
        }}
        onMouseLeave={() => setShowOverlay(false)}
      >
        {props.text.toLowerCase()}
        {showOverlay && props.editMode && (
          <Overlay data-menu={true}>
            <i
              data-menu={true}
              onClick={(e) => {
                props.remove();
                e.stopPropagation();
              }}
              className="ri-delete-bin-2-line"
            ></i>
          </Overlay>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};
export default Item;

const Wrapper = styled.div`
  width: max-content;
  border: ${(props) => props.theme.border};
  font-size: 1em;
  padding: 4px;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  background-color: var(--orange-color);
  justify-items: center;
  justify-content: center;
  color: white;
  i {
    margin-right: 0;
    padding: 0;
    cursor: pointer;
  }
`;
