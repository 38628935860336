import LabeledInput from "../../../../../components/form/components/labeledInput";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../../../components/other/buttons/save";
import CancelButton from "../../../../../components/other/buttons/cancel";
import { DateTime } from "luxon";
import { InputRowProps } from "../../../../../components/table/Other/Types";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { addPlatform, editPlatformAdmin } from "../../../../../api/Admin";
import { DataItem } from "../../../../../components/header/types";

const InputRow = (props: InputRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.data) {
      const { name, description, url, featuredBrands } = props.data;
      setValue("name", name ? name : "");
      setValue("description", description ? description : "");
      setValue("url", url ? url : "");
      setValue("featuredBrands", featuredBrands ? featuredBrands : []);
    }
  }, [props.data, setValue]);

  const handleSave = async (data: any, id?: number) => {
    if (id) {
      return await editPlatformAdmin({
        ...data,
        id: id,
        featuredBrands: props.data
          ? props.data.featuredBrands.map((item: DataItem) => {
              return item.id;
            })
          : [],
      });
    } else {
      return await addPlatform({ ...data });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await handleSave(data, props.data ? props.data.id : undefined);
      props.refresh();
      props.close();
      props.updateInfo(
        `${props.data ? props.data.name : "Platform"} ${
          props.data ? "updated" : "added"
        } successfully`,
        props.data ? props.data.id : null,
        false
      );
    } catch (e: any) {
      props.updateInfo(
        e.response?.data?.message || "Something went wrong, please try again",
        props.data ? props.data.id : null,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const manualSubmit = handleSubmit(onSubmit);

  return (
    <>
      <td>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{ text: "Name", for: "name", required: true }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.name}
              disabled={props.buttonsDisabled}
              name="name"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{ required: "Description is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{
                text: "Description",
                for: "description",
                required: true,
              }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.description}
              disabled={props.buttonsDisabled}
              name="description"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="url"
          control={control}
          defaultValue=""
          rules={{ required: "URL is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{ text: "URL", for: "url", required: true }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.url}
              disabled={props.buttonsDisabled}
              name="url"
            />
          )}
        />
      </td>
      <td>
        {props.data && props.data.productCount ? props.data.productCount : "-"}
      </td>
      <td>
        {props.data && props.data.addDate
          ? DateTime.fromISO(props.data.addDate).toLocaleString()
          : "-"}
      </td>
      <ButtonWrap>
        <td>
          <SaveButton
            onClick={() => {
              manualSubmit();
            }}
            loading={loading}
            disabled={props.buttonsDisabled}
          />
        </td>
        <td>
          <CancelButton
            onClick={props.close}
            disabled={props.buttonsDisabled}
          />
        </td>
      </ButtonWrap>
    </>
  );
};

export default InputRow;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
