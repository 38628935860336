import styled from "styled-components";
import Card from "../../../../Reusables/card";
import Subheading from "../../../../../../components/subheading";
import ModalButtons from "../../../../Reusables/modalButtons";
import React, { useContext, useState } from "react";
import AdminDropDownMenu from "../../../../Reusables/dropdown";
import { Voucher } from "../../index";
import DateRangePicker from "../../../../Reusables/dateTimePicker";
import { UserAdminContext } from "../../../../Context/AdminUserContext";
import { MenuItem } from "../../../../Reusables/searchPicker";
import { AdminProductContext } from "../../../../Context/AdminProductContext";
import { Controller, useForm } from "react-hook-form";
import Picker from "../../../../Reusables/picker";
import Item from "../../../../Reusables/picker/item";
import { Product } from "../../../Products";
import {
  editVoucherAdmin,
  postNewVoucherAdmin,
} from "../../../../../../api/Admin";

type Props = {
  voucher?: Voucher;
  edit: boolean;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  updateTable: (data: any) => void;
  resetInfo: () => void;
};

const VoucherDetailsSection = (props: Props) => {
  const [voucher, setVoucher] = useState<Voucher | null>(
    props.voucher ? props.voucher : null
  );

  // left here for now in case we need to refer to it when continuing with this component
  // should be safe to del but just a reminder to double check

  const [editMode, setEditMode] = useState<boolean>(!props.voucher);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { users, loading } = useContext(UserAdminContext);
  const { products } = useContext(AdminProductContext);

  // TODO voucher doesnt seem to have a users array currently, need to add this functionality in when the backend has been updated
  const userPickerData = users.map((item): MenuItem => {
    return {
      text: item.username,
      data: item,
    };
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      description: voucher?.description ? voucher.description : "-",
      name: voucher?.name ? voucher.name : "-",
      code: voucher?.code ? voucher.code : "-",
      type: voucher?.voucherType ? voucher.voucherType : "DISCOUNT",
      status: voucher?.status ? voucher.status : "INACTIVE",
      scope: voucher?.voucherScope ? voucher.voucherScope : "NOT_GLOBAL",
      amountType: voucher?.amountType ? voucher.amountType : "PERCENT",
      amount: voucher?.amount ? voucher.amount : "-",
      usesUser: voucher?.usesUser ? voucher.usesUser : "-",
      maxUses: voucher?.maxUses ? voucher.maxUses : "-",
      totalUseCount: voucher?.totalUseCount ? voucher.totalUseCount : "-",
      products: voucher?.products ? voucher.products : [],
    },
  });

  const formProducts: number[] = watch("products");
  //const formUsers: MenuItem[] = watch("users");
  const handlePost = async (data: any, id?: number) => {
    if (id) {
      return await editVoucherAdmin(data, id);
    } else {
      return await postNewVoucherAdmin(data);
    }
  };

  const handleSave = async (data: any) => {
    try {
      if (!loading) {
        setSubmitting(true);
        const res = await handlePost(data, voucher ? voucher.id : undefined);
        props.updateInfo(
          `${res.data.name ? res.data.name : "Voucher"}` +
            " updated successfully",
          res.data.id,
          false
        );
        setVoucher(res.data);
        props.updateTable(res.data);
        setEditMode(false);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "Item update failed"
        }`,
        null,
        true
      );
    } finally {
      setSubmitting(false);
    }
  };

  /*
  const addUser = (user: MenuItem) => {
    const updatedUsers = [...formUsers, user];
    setValue("users", updatedUsers);
  }; */

  const addProduct = (product: Product) => {
    if (product.id) {
      const updatedProducts = [...formProducts, product.id];
      setValue("products", updatedProducts);
    }
  };

  const rmProduct = (product: Product) => {
    const updatedProducts = formProducts.filter((item) => {
      return item !== product.id;
    });
    setValue("products", updatedProducts);
  };

  const productPickerData = products.map((item): MenuItem => {
    return {
      text: item.name,
      data: item,
    };
  });

  const productPickerItems = productPickerData
    .filter((item) => {
      return formProducts.includes(item.data.id);
    })
    .map((item) => {
      return (
        <Item
          editMode={editMode}
          text={item.text}
          remove={() => {
            rmProduct(item.data);
          }}
        />
      );
    });

  const manualSubmit = handleSubmit(handleSave);

  /*      <Picker
            heading={"Users"}
            editMode={editMode}
            handleClick={addUser}
            menuItems={userPickerData}
          >
            {userPickerItems}
          </Picker>*/

  return (
    <Wrapper>
      <Heading>
        <Subheading h3={"Create"} h2={"Voucher"} />
        <ModalButtons
          editMode={editMode}
          loading={false}
          handleSave={() => {
            manualSubmit();
          }}
          setEditMode={setEditMode}
          resetState={() => {}}
          resetInfo={() => {}}
          disabled={false}
        />
      </Heading>
      <Inner>
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Code"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "code",
              }}
              editing={editMode}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Name"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "name",
              }}
              editing={editMode}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Description"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "description",
              }}
              editing={editMode}
            />
          )}
        />

        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <AdminDropDownMenu
              heading={"Status"}
              items={["ACTIVE", "EXPIRED", "INACTIVE"]}
              text={field.value}
              handleClick={(e) => field.onChange(e.target.value)}
              editMode={editMode}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <AdminDropDownMenu
              heading={"Type"}
              items={["DISCOUNT", "TAX_RATE", "SHOWROOM"]}
              text={field.value}
              handleClick={(e) => field.onChange(e.target.value)}
              editMode={editMode}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <AdminDropDownMenu
              heading={"Type"}
              items={["DISCOUNT", "TAX_RATE", "SHOWROOM"]}
              text={field.value}
              handleClick={(e) => field.onChange(e.target.value)}
              editMode={editMode}
            />
          )}
        />

        <Controller
          name="scope"
          control={control}
          render={({ field }) => (
            <AdminDropDownMenu
              heading={"Scope"}
              items={["NOT_GLOBAL", "GLOBAL", "COUNTRY"]}
              text={field.value}
              handleClick={(e) => field.onChange(e.target.value)}
              editMode={editMode}
            />
          )}
        />

        <Controller
          name="amountType"
          control={control}
          render={({ field }) => (
            <AdminDropDownMenu
              heading={"Amount Type"}
              items={["PERCENT", "FLAT"]}
              text={field.value}
              handleClick={(e) => field.onChange(e.target.value)}
              editMode={editMode}
            />
          )}
        />

        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Amount"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "amount",
              }}
              editing={editMode}
            />
          )}
        />

        <Controller
          name="usesUser"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Uses User"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "usesUser",
              }}
              editing={editMode}
            />
          )}
        />

        <Controller
          name="maxUses"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Max Uses"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "maxUses",
              }}
              editing={editMode}
            />
          )}
        />

        <Controller
          name="totalUseCount"
          control={control}
          render={({ field }) => (
            <Card
              heading={"Total Use Count"}
              text={field.value}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "totalUseCount",
              }}
              editing={editMode}
            />
          )}
        />

        <DateRangePicker heading={"Start"} editing={editMode} />
        <PickerWrap>
          <Picker
            heading={"Products"}
            editMode={editMode}
            handleClick={addProduct}
            menuItems={productPickerData}
          >
            {productPickerItems}
          </Picker>
        </PickerWrap>
      </Inner>
    </Wrapper>
  );
};

export default VoucherDetailsSection;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  height: 100%;
  position: relative;
  margin: 2.5em 2em 0 2em;
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;

const PickerWrap = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 670px) {
    grid-template-columns: 1fr;
  }
`;
