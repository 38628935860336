import styled, { ThemeProvider } from "styled-components";
import { useState } from "react";
import Subheading from "../../../../../components/subheading";
import ModalButtons from "../../../../Admin/Reusables/modalButtons";
import Card from "../../../../Admin/Reusables/card";
import { User } from "../../../../Admin/Views/Users";
import { Controller, useForm } from "react-hook-form";
import { putUpdateUser } from "../../../../../api/Account";

interface Props {
  user: User;
  resetInfo: () => void;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  updateTable: (data: any) => void;
  padding: string | number;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
}
const UserDetailsSection = (props: Props) => {
  //TODO: Add editing card values.
  const [loading, setLoading] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const paddingTheme = {
    padding: props.padding,
  };

  const defaultPhoneObject = {
    country: {
      name: "",
      dialCode: "",
      isoCode: "",
      flagCode: "",
    },
    number: "",
  };

  const defaults = {
    username: props.user.username || "-",
    firstName: props.user.firstName || "-",
    lastName: props.user.lastName || "-",
    phone: props.user.phone || defaultPhoneObject,
    phone2: props.user.phone2 || defaultPhoneObject,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: defaults,
  });

  const handleSave = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true);
        const res = await await putUpdateUser(data);
        props.updateInfo(
          `${res.data.name ? res.data.name : "User"} updated successfully`,
          res.data.id,
          false
        );
        props.updateTable(res.data);
        if (props.setEditMode) {
          props.setEditMode(false);
        }
        setShowSave(false);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "User update failed"
        }`,
        props.user.id,
        true
      );
      if (e.response.status !== 401) {
        setShowSave(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const manualSubmit = handleSubmit(handleSave);

  return (
    <ThemeProvider theme={paddingTheme}>
      <Wrapper>
        <Heading>
          <Subheading h3={"User"} h2={"Details"} />
          <ModalButtons
            editMode={props.editMode ?? false}
            loading={false}
            handleSave={manualSubmit}
            setEditMode={props.setEditMode ? props.setEditMode : () => {}}
            resetState={() => {}}
            resetInfo={props.resetInfo}
            disabled={false}
          />
        </Heading>
        <Inner>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Username"}
                text={field.value || "-"}
                editing={props.editMode}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "username",
                }}
              />
            )}
          />
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Card
                heading={"First Name"}
                text={field.value || "-"}
                editing={props.editMode}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "firstName",
                }}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Last Name"}
                text={field.value || "-"}
                editing={props.editMode}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "lastName",
                }}
              />
            )}
          />
          {/*<Card*/}
          {/*  heading={"Company Name"}*/}
          {/*  text={state.companyName ? state.companyName : "-"}*/}
          {/*/>*/}
          {/*<DropDownMenu*/}
          {/*  handleClick={() => {}}*/}
          {/*  heading={"Status"}*/}
          {/*  text={state.userState ? userState : "-"}*/}
          {/*  items={mappedItems}*/}
          {/*  editMode={false}*/}
          {/*/>*/}
          <Card
            heading={"Email"}
            text={props.user.email ? props.user.email : "-"}
          />
          {/*<Picker*/}
          {/*  heading={"ROLES"}*/}
          {/*  menuItems={menuRoles}*/}
          {/*  editMode={false}*/}
          {/*  handleClick={updateStatus}*/}
          {/*>*/}
          {/*  {userRolesDisplay}*/}
          {/*</Picker>*/}
          <Controller
            name="phone.number"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Phone"}
                text={field.value || "-"}
                editing={props.editMode}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "phone.number",
                }}
              />
            )}
          />
          <Controller
            name="phone2.number"
            control={control}
            render={({ field }) => (
              <Card
                heading={"Phone alt"}
                text={field.value || "-"}
                editing={props.editMode}
                inputSetup={{
                  value: field.value,
                  onChange: (e) => field.onChange(e.target.value),
                  name: "phone2.number",
                }}
              />
            )}
          />
        </Inner>
      </Wrapper>
    </ThemeProvider>
  );
};

export default UserDetailsSection;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 10px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-content: center;
  align-items: center;
  grid-gap: 20px;
  width: 100%;
  box-sizing: border-box;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;

  input[type="password"] {
    :focus {
      border: solid 1px var(--orange-color);
    }
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  position: relative;
  min-width: 35em;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 2em;
  padding-top: ${(props) => props.theme.padding};
`;
