import Subheading from "../../../../components/subheading";
import styled from "styled-components";

export interface ServiceData {
  name: string;
  subHeading: {
    h3: string;
    h2: string;
  };
  mainText: JSX.Element;
}

const Service = (props: ServiceData) => {
  return (
    <ServiceWrap>
      <Subheading h3={props.subHeading.h3} h2={props.subHeading.h2} />
      <ServiceText>
        <p>{props.mainText}</p>
      </ServiceText>
    </ServiceWrap>
  );
};

export default Service;

const ServiceWrap = styled.div`
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap: 1rem;
  margin: 5% 10%;
  a {
    color: var(--orange-color);
  }

  word-break: break-word;
  @media only screen and (max-width: 850px) {
    grid-template-columns: auto;
    word-break: normal;
  }
`;

const ServiceText = styled.div`
  text-align: left;
  line-height: 1.3;
  p {
    margin: 10px 0;
  }
  @media only screen and (max-width: 850px) {
    margin: 0 auto;
  }
`;
