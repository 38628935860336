import { Link } from "react-router-dom";
import ErrorWrapper from "../ErrorWrapper";

const Error401 = () => {
  return (
    <ErrorWrapper>
      <h2>401</h2>
      <div className="return-link">
        <p>You are not authorised to access this resource, return </p>
        <Link to={"/"}>Home</Link>
      </div>
    </ErrorWrapper>
  );
};
export default Error401;
