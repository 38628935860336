import Subheading from "../../../../../../components/subheading";
import styled from "styled-components";
import Card from "../../../../Reusables/card";
import { Platform } from "../../index";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Picker, { MenuItem } from "../../../../Reusables/picker";
import Item from "../../../../Reusables/picker/item";
import { DataItem } from "../../../../../../components/header/types";
import {
  addPlatform,
  editPlatformAdmin,
  getBrandsByPlatformId,
} from "../../../../../../api/Admin";
import { Brand } from "../../../Brands";
import ModalButtons from "../../../../Reusables/modalButtons";
import { Controller, useForm } from "react-hook-form";

interface Props {
  platform: Platform;
  updateInfo: (
    message: string,
    id: number | null,
    fail: boolean,
    index?: number | undefined
  ) => void;
  updateTable: (data: any) => void;
  resetInfo: () => void;
}
const PlatformDetail = (props: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [platform, setPlatform] = useState<Platform>(props.platform);
  const [brands, setBrands] = useState<Brand[]>([]);

  const { url, name, description, featuredBrands } = platform;
  type FormData = {
    name: string;
    description: string;
    url: string;
    featuredBrands: DataItem[];
  };

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      name: name ? name : "",
      description: description ? description : "",
      url: url ? url : "",
      featuredBrands: featuredBrands ? featuredBrands : [],
    },
  });

  useEffect(() => {
    getBrandsByPlatformId(platform.id).then((res) => {
      setBrands(res.data);
    });
  }, []);

  const resetState = () => {
    setValue("name", name ? name : "");
    setValue("description", description ? description : "");
    setValue("url", url ? url : "");
    setValue("featuredBrands", featuredBrands ? featuredBrands : []);
  };

  const formFeatured: DataItem[] = watch("featuredBrands");

  const addFeatured = (brand: DataItem) => {
    const updatedBrands = [...formFeatured, brand];
    setValue("featuredBrands", updatedBrands);
  };

  const rmFeatured = (brand: DataItem) => {
    const updatedBrands = formFeatured.filter((item) => {
      return item.id !== brand.id;
    });
    setValue("featuredBrands", updatedBrands);
  };

  const handleSave = async (data: any, id?: number) => {
    if (id) {
      return await editPlatformAdmin({
        ...data,
        id: id,
        featuredBrands: formFeatured.map((item) => {
          return item.id;
        }),
      });
    } else {
      return await addPlatform({ ...data });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true);
        const res = await handleSave(data, props.platform.id);
        props.updateInfo(
          `${res.data.name ? res.data.name : "Platform"}` +
            " updated successfully",
          res.data.id,
          false
        );
        props.updateTable(res.data);
        setPlatform(res.data);
        setEditMode(false);
      }
    } catch (e: any) {
      props.updateInfo(
        `${
          e.response.data.message
            ? e.response.data.message
            : "Item update failed"
        }`,
        props.platform.id,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const brandNames: MenuItem[] = brands
    .filter((brand: Brand) => {
      return (
        formFeatured.find((item) => {
          return item.id === brand.id;
        }) === undefined && formFeatured.length < 10
      );
    })
    .map((brand) => {
      return { text: brand.name, data: brand };
    });

  const manualSubmit = handleSubmit(onSubmit);

  const pickerItems = formFeatured.map((item) => {
    return (
      <Item
        editMode={editMode}
        text={item.name}
        remove={() => {
          rmFeatured(item);
        }}
      />
    );
  });

  return (
    <Wrapper>
      <Heading>
        <Subheading h3={"Platform"} h2={"Edit"} />
        <ModalButtons
          editMode={editMode}
          loading={loading}
          handleSave={() => {
            manualSubmit();
          }}
          setEditMode={setEditMode}
          resetState={resetState}
          resetInfo={props.resetInfo}
          disabled={false}
        />
      </Heading>
      <Inner>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <Card
              heading={"Name"}
              text={name ? name : "-"}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "name",
              }}
              editing={editMode}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Description"}
              text={description ? description : "-"}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "description",
              }}
              editing={editMode}
            />
          )}
        />
        <Controller
          name="url"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Card
              heading={"Url"}
              text={url ? url : "-"}
              inputSetup={{
                value: field.value,
                onChange: (e) => field.onChange(e.target.value),
                name: "url",
              }}
              editing={editMode}
            />
          )}
        />

        <Card
          heading={"Add Date"}
          text={
            props.platform.addDate
              ? DateTime.fromISO(props.platform.addDate).toLocaleString()
              : "-"
          }
          editing={editMode}
        />
        <Card
          heading={"Product Count"}
          text={
            props.platform.productCount
              ? props.platform.productCount.toString()
              : "-"
          }
          editing={editMode}
        />
        <Card
          heading={"Update Date"}
          text={
            props.platform.updateDate
              ? DateTime.fromISO(props.platform.updateDate).toLocaleString()
              : "-"
          }
          editing={editMode}
        />
        <Picker
          heading={"Featured"}
          editMode={editMode}
          menuItems={brandNames}
          handleClick={addFeatured}
        >
          {pickerItems}
        </Picker>
      </Inner>
    </Wrapper>
  );
};

export default PlatformDetail;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  justify-self: start;
  margin-top: 2.5em;

  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  box-sizing: border-box;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-self: center;
  height: 100%;
  position: relative;
  min-width: 35em;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 2em;

  @media only screen and (max-width: 920px) {
    display: grid;
    height: 100%;
    position: relative;
    padding: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    min-width: unset;
  }
`;
