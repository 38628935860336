import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import SearchInput from "../Other/SearchInput";
import Row, { Cell } from "./Row/Row";
import { BarLoader } from "react-spinners";
import useVirtualisation from "../../../hooks/useVirtualisation";
import { useSearchAndFilter } from "../../../hooks/useSearchAndFilter";
import { HeaderRowItem } from "../Other/Types";
import useBreakpoint from "../../../hooks/useBreakpoint";

type Props = {
  data: any;
  headerRow: HeaderRowItem[];
  dataType?: string;
  handleRowClick?: (row: any) => void;
  loading: boolean;
  padding: number; //padding left/right of parent - used to maintain max-width
  showAddRow?: boolean;
  showSearch?: boolean;
  infoRow?: React.ReactNode;
  tableHeader?: React.ReactNode;
  editId?: string | number | undefined | null;
  delId?: string | number | undefined | null;
  infoId?: string | number | undefined | null;
  getInputRow?: (setData?: (newData: any) => void) => ReactElement;
  getDelRow?: (setData?: (newData: any) => void) => ReactElement;
  setRowCallback?: (setData: (newData: any) => void) => void;
  searchCallback?: (searchValue: string) => any;
};

const defaultProps = {
  showSearch: true,
  paginate: true,
};

const MobileTable = (props: Props): ReactElement => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortIndex, setSortIndex] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("");
  const tableRef = useRef() as React.MutableRefObject<HTMLTableElement>;
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { breakpoint } = useBreakpoint();

  const filteredAndSortedData = useSearchAndFilter({
    data: props.data,
    headerRow: props.headerRow,
    searchValue,
    sortBy,
    sortIndex,
    searchCallback: props.searchCallback,
  });

  const [items, offset, newHeight, firstIndex] = useVirtualisation(
    filteredAndSortedData,
    tableRef,
    rowRef,
    30,
    414
  );

  useEffect(() => {
    if (props.showAddRow) {
      tableRef.current.scrollTo(0, 0);
    }
  }, [props.showAddRow]);

  useEffect(() => {
    setSearchValue("");
  }, [props.loading]);

  useEffect(() => {
    tableRef.current.scrollTo(0, 0);
  }, [sortBy, searchValue]);

  const data = items.map((data: any, index) => {
    const showEditRow = props.editId === data.id;
    const showDeleteRow = props.delId === data.id;
    const showDelInfo = props.infoId === data.id && data.status === "DELETED";
    const showInfo = props.infoId === data.id && data.status !== "DELETED";

    return (
      <Row
        rowRef={rowRef}
        showEditRow={showEditRow}
        showInfoRow={showInfo}
        showDelInfo={showDelInfo}
        data={data}
        headerRow={props.headerRow}
        index={index}
        infoRow={props.infoRow}
        getInputRow={props.getInputRow}
        getDelRow={props.getDelRow}
        showDeleteRow={showDeleteRow}
      />
    );
  });

  const theme = {
    breakpoint: `${breakpoint - (props.padding + 40)}px`,
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <TableToolbar>
          {props.tableHeader ? props.tableHeader : <div />}{" "}
          <SearchBar>
            {props.showSearch && (
              <SearchInput
                placeholder="Search"
                value={searchValue || ""}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                autoComplete={"off"}
              />
            )}
          </SearchBar>
        </TableToolbar>
        {props.infoRow}
        <StyledTable ref={tableRef}>
          {props.loading ? (
            <SpinnerWrap>
              <BarLoader width={80} color={"#a19e9e"} />
            </SpinnerWrap>
          ) : (
            <VirtualisationWrapper
              style={{
                width: "100%",
                position: "relative",
                height: `${filteredAndSortedData.length * newHeight}px`,
              }}
            >
              <VirtualisationWrapper
                style={{
                  position: "absolute",
                  top: `${offset}px`,
                }}
              >
                <tbody>
                  {props.showAddRow && (
                    <tr>{props.getInputRow && props.getInputRow()}</tr>
                  )}
                  {data.length > 0 ? data : <NoEntries>No Entries</NoEntries>}
                </tbody>
              </VirtualisationWrapper>
            </VirtualisationWrapper>
          )}
        </StyledTable>
      </TableContainer>
    </ThemeProvider>
  );
};
export default MobileTable;
MobileTable.defaultProps = defaultProps;

const VirtualisationWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  overflow-y: scroll;
  height: 80vh;

  tbody {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 10px;
    box-sizing: content-box;
  }

  tr {
    background-color: #e9e9e9;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 10px;
  }
  td {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: var(--grey-color);
    border-radius: 4px;
    padding: 10px;
    text-align: left;
    max-width: ${(props) => props.theme.breakpoint};
  }
  ${Cell} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  td button {
    width: 100%;
    justify-self: stretch;
  }
  td div input {
    border-radius: 4px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 12px;
`;

const TableToolbar = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  grid-gap: 10px;
  width: 100%;
  justify-self: start;
  margin-top: 10px;
  grid-template-columns: auto auto;
  @media only screen and (max-width: 470px) {
    grid-template-columns: 1fr;
  }
`;

const SearchBar = styled.div`
  & select {
    &:focus {
      border-color: rgba(0, 0, 0, 0.25);
      outline: 0;
    }
  }
`;

const NoEntries = styled.div`
  grid-column: 1/-1;
  display: grid;
  justify-items: center;
  justify-content: center;
  min-height: 40px;
  align-items: center;
  align-content: center;
`;

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1/-1;
  height: 40px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;

const Down = styled.img`
  width: 12px;
  margin-left: 10px;
`;

const Up = styled.img`
  width: 12px;
  margin-left: 10px;
  transform: rotate(180deg);
`;
