import { Link } from "react-router-dom";
import { DataItem } from "../../../types";
import { parseSearch } from "../../../../../utils/utils";
import styled from "styled-components";
import MenuLink from "../../link";

type BrandElementProps = {
  elements: DataItem[];
  header?: string;
  name: string;
};

const BrandElements = (props: BrandElementProps) => {
  const chunkSize = Math.ceil(props.elements.length / 6);
  const columns = [];
  for (let i = 0; i < 6; i++) {
    columns.push(props.elements.slice(i * chunkSize, (i + 1) * chunkSize));
  }

  return (
    <MenuItem data-menu={true}>
      {props.header && (
        <HeaderName>
          <Link
            data-menu={true}
            to={`${parseSearch(props.name)}/${parseSearch(props.header)}`}
          >
            {props.header}
          </Link>
        </HeaderName>
      )}
      <Columns data-menu={true}>
        {columns.map((column) => (
          <Column data-menu={true}>
            {column.map((e) => (
              <MenuLink name={props.name} dataItem={e} header={props.header} />
            ))}
          </Column>
        ))}
      </Columns>
    </MenuItem>
  );
};

export default BrandElements;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
`;

const Column = styled.div`
  display: grid;
  text-align: left;
  align-content: start;
  grid-gap: 5px;
`;

const HeaderName = styled.p`
  text-align: start;
  margin-bottom: 9px;
  border-bottom: solid white 2px;
  width: 100%;
  padding: 15px;

  box-sizing: border-box;
  font-size: 1.1em;
`;

const MenuItem = styled.div`
  width: 100%;
`;
