import React from "react";
import { Segment } from "../../types";
import { Link } from "react-router-dom";

const CarouselSegment = (props: Segment) => {
  const images = props.imageData.map((item) => {
    return (
      <div className="carousel-image">
        <Link to={`brands/${item.name}`}>
          {" "}
          <img src={item.src} alt={"brand logo"} />
        </Link>
      </div>
    );
  });

  return (
    <div
      id={"segment"}
      onTransitionEnd={(event) => {
        if (event.propertyName === "transform") {
          props.setRefresh(true);
        }
      }}
      className={props.class}
      style={{
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
        transform: props.style ? props.style : undefined,
      }}
    >
      {props.showElements && images}
    </div>
  );
};

/*
*   return (
    <div
      id={"segment"}
      onTransitionEnd={(event) => {
        if (event.propertyName === "transform") {
          props.setRefresh(true);
        }
      }}
      className={props.class}
      style={{
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
        transform: props.style ? props.style : undefined,
      }}
    >
      {props.showElements && images}
    </div>
  );
};*/

export default CarouselSegment;
