import { createOrder } from "../../../api/Orders";
import { Order } from "./index";
import { Product } from "../../../globaltypes";
import { getUser } from "../../../api/Account";

export const CreateOrder = async (
  product: Product,
  symbols: { symbol: string; currency: string }[],
  currencyIndex: number,
  openOrders: Order[],
  updateOrders?: () => void
) => {
  let orderId: number | null = null;
  const user = await getUser();
  let phoneNumber = "";
  if (user.ok) {
    phoneNumber = user.data.phone.number.toString();
  }
  //TODO: if order exists then use the current order details instead of initialising as empty strings.

  if (openOrders.length > 0) {
    orderId = Number(
      openOrders[0].orderNumber.substring(3, openOrders[0].orderNumber.length)
    );
  }
  let newOrder = {
    purchaseOrderNumber: 1,
    phoneNumber: phoneNumber,
    status: "OPEN",
    billingMethod: "STRIPE",
    macAddress: "",
    orderId: orderId,
    billingAddress: {
      deliveryContact: "",
      firstLine: "",
      secondLine: "",
      city: "",
      state: "",
      zipCode: "",
      vatCode: "",
    },
    deliveryAddress: {
      deliveryContact: "",
      firstLine: "",
      secondLine: "",
      city: "",
      state: "",
      zipCode: "",
      vatCode: "",
    },
    addOrderLineRequests: [
      {
        productId: product.id,
        quantity: 1,
        localValue: product.costs[currencyIndex].currencyValue.toString(),
        localCurrency: symbols[currencyIndex].currency,
      },
    ],
  };
  createOrder(newOrder).then(() => {
    if (updateOrders) {
      updateOrders();
    }
  });
};
