import styled from "styled-components";
import { Product } from "../../../../../../globaltypes";
import Item from "./Item";
type Props = {
  products: Product[];
};

const CartList = (props: Props) => {
  return (
    <Wrapper>
      {" "}
      {props.products.map((product) => {
        return <Item product={product} />;
      })}
    </Wrapper>
  );
};

export default CartList;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5px;
  @media only screen and (max-width: 920px) {
    grid-gap: 10px;
    max-height: unset;
  }
`;
