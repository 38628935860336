import logo from "../../../assets/images/header/logo.png";
import React from "react";

const ErrorWrapper = (props: { children: JSX.Element[] }) => {
  return (
    <div className={"error-page"}>
      <img src={logo} alt={"intrinsic logo"} />
      {props.children}
    </div>
  );
};
export default ErrorWrapper;
