import { Voucher } from "../index";
import styled from "styled-components";
import Window from "../../../Reusables/window";
import React, { useState } from "react";
import useInfo from "../../../Hooks/useInfo";
import VoucherDetailsSection from "./detail";

type Props = {
  voucher: Voucher;
  close: () => void;
};
const UpdateVoucherFormAdmin = (props: Props) => {
  const [edit, setEdit] = useState(true);
  const [fail, info, infoId, showInfo, resetInfo, updateInfo, index] =
    useInfo();

  return (
    <Window
      infoProps={{ message: info, hasFailed: fail, resetInfo: resetInfo }}
      close={props.close}
      showInfo={showInfo}
      noPlaceholder={true}
    >
      <Wrapper>
        <VoucherDetailsSection
          voucher={props.voucher}
          edit={edit}
          updateInfo={updateInfo}
          resetInfo={resetInfo}
          updateTable={() => {}}
        />
      </Wrapper>
    </Window>
  );
};

export default UpdateVoucherFormAdmin;

const Wrapper = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: relative;
  min-height: 30em;
  min-width: 44em;
  grid-template-rows: 0.2fr auto;
  padding-bottom: 1em;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
    min-width: unset;
  }
`;
