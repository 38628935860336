import CompanyInfo from "../companyInfo";
import Gallery from "../gallery";
import HomeContact from "../contact";
import LatestCarousel from "../../../../components/carousel/latestCarousel";
import "../../../../styles/carousel.css";
import styled from "styled-components";
import AboutBanner from "../../About/banner";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";
import { FullWidthSection } from "../../../../styles/styledComponents/document";
import useBreakpoint from "../../../../hooks/useBreakpoint";

const Home = () => {
  const { breakpoint } = useBreakpoint();

  return (
    <Wrapper className="content-wrapper">
      <Gallery />
      <ContentWrapper>
        <CompanyInfo />
      </ContentWrapper>
      <FullWidthSection bgColor={"#F6F4F2"}>
        <ContentWrapper>
          <CarouselPadding>
            <LatestCarousel />
          </CarouselPadding>
        </ContentWrapper>
      </FullWidthSection>
      {breakpoint > 600 && <AboutBanner />}
      <HomeContact />
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  display: grid;
  overflow-x: hidden;
  grid-column: 1/-1;
`;

const CarouselPadding = styled.div`
  padding-top: 5em;
  padding-bottom: 5em;
`;
