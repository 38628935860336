import React, { ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Subheading from "../../../../components/subheading";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { postDriverRequestForm } from "../../../../api/DriverRequest";
import LabeledInput from "../../../../components/form/components/labeledInput";
import { getUser } from "../../../../api/Account";
import { User } from "../../../Admin/Views/Users";
import TextAreaInput from "../../../../components/form/components/textareainput";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";
import DropdownInput from "../../../../components/form/components/dropdownInput";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../components/other/buttons/submit";

export interface DriverRequestForm {
  username: string;
  companyName: string;
  country: string;
  email: string;
  platform: string;
  userType: string;
  driverRequested: string;
  driverUsage: string;
  driverDetails: string;
}

const RequestForm = (): ReactElement => {
  const { platform } = useParams();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [submitStatus, setSubmitStatus] = useState<
    "Successful" | "Failed" | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const watchedDriverDetails = watch("driverDetails");
  const [userData, setUserData] = useState<User | null>(null);
  const [desc, setDesc] = useState<string>("");

  function handlePlatform(): void {}

  useEffect(() => {
    handlePlatform();
    if (auth.user) {
      getUser()
        .then((res) => res.ok && setUserData(res.data))
        .catch((err) => console.error(err));
    }
  }, [platform, auth]);

  useEffect(() => {
    if (userData?.username) {
      setValue("userName", userData.username);
    }
    if (userData?.companyName) {
      setValue("companyName", userData.companyName);
    }
    if (userData?.country) {
      setValue("country", userData.country);
    }
    if (userData?.email) {
      setValue("email", userData.email);
    }
  }, [userData]);

  function handleRequest(data: any): void {
    setLoading(true);
    postDriverRequestForm({
      ...data,
    })
      .then((res) => {
        if (res.ok) {
          setSubmitStatus("Successful");
          setTimeout(() => navigate("/"), 2000);
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitStatus("Failed");
      })
      .finally(() => setLoading(false));
  }

  return (
    <ContentWrapper withLoc={true}>
      <Wrapper>
        <Desc>
          {" "}
          <Title>
            <Subheading h3={"DRIVER"} h2={"REQUEST"} />
          </Title>
          <Description>{desc}</Description>
        </Desc>
        <Content>
          <form onSubmit={handleSubmit(handleRequest)}>
            <Entity>
              <Controller
                name="userName"
                control={control}
                defaultValue={""}
                rules={{ required: "Username is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name={"userName"}
                    value={field.value}
                    handlechange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={errors.name}
                    label={{
                      text: "Your Username",
                      for: "userName",
                      required: true,
                    }}
                  />
                )}
              />
              <Controller
                name="companyName"
                control={control}
                defaultValue=""
                rules={{ required: "Company Name is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name="companyName"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.companyName}
                    label={{
                      text: "Company Name",
                      for: "companyName",
                      required: true,
                    }}
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                defaultValue=""
                rules={{ required: "Country is required" }}
                render={({ field }) => (
                  <LabeledInput
                    disabled={!!userData?.country}
                    name="country"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.country}
                    label={{
                      text: "Country",
                      for: "country",
                      required: true,
                    }}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Email is required" }}
                disabled={!!userData?.email}
                render={({ field }) => (
                  <LabeledInput
                    name="email"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.email}
                    label={{
                      text: "Email",
                      for: "email",
                      required: true,
                    }}
                  />
                )}
              />

              <Controller
                name="userType"
                control={control}
                defaultValue=""
                rules={{ required: "User type is required" }}
                disabled={!!userData?.email}
                render={({ field }) => (
                  <DropdownInput
                    id={uuidv4()}
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    items={[
                      { value: "", displayValue: "Set User Type" },
                      { displayValue: "Manufacturer", value: "manufacturer" },
                      { displayValue: "Distributor", value: "distributor" },
                      { displayValue: "Rep", value: "rep" },
                      { displayValue: "End User", value: "end-user" },
                    ]}
                  />
                )}
              />
              <Controller
                name="platformName"
                control={control}
                defaultValue=""
                rules={{ required: "Platform Name is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name="platformName"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.platformName}
                    label={{
                      text: "Platform",
                      for: "platformName",
                      required: true,
                    }}
                  />
                )}
              />
              <Controller
                name="driverRequested"
                control={control}
                defaultValue=""
                rules={{ required: "Driver Requested is required" }}
                render={({ field }) => (
                  <LabeledInput
                    name="driverRequested"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.driverRequested}
                    label={{
                      text: "Driver Requested",
                      for: "driverRequested",
                      required: true,
                    }}
                  />
                )}
              />
              <Controller
                name="driverUsage"
                control={control}
                defaultValue=""
                rules={{ required: "Driver Usage is required" }}
                render={({ field }) => (
                  <DropdownInput
                    id={uuidv4()}
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    items={[
                      { value: "", displayValue: "Select Driver Usage" },
                      { displayValue: "1-5 licenses per year", value: "1-5" },
                      { displayValue: "6-10 licenses per year", value: "6-10" },
                      { displayValue: "11+ licenses per year", value: "10+" },
                      { displayValue: "Unknown", value: "unknown" },
                    ]}
                  />
                )}
              />
            </Entity>
            <Driver>
              <Controller
                name="driverDetails"
                control={control}
                defaultValue=""
                rules={{ required: "Driver Details are required" }}
                render={({ field }) => (
                  <TextAreaInput
                    style={{
                      minHeight: "100px",
                      maxHeight: "500px",
                      fontSize: "14px",
                    }}
                    name="driverDetails"
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    error={errors.driverDetails}
                    label={{
                      for: "Driver Project Details",
                      required: true,
                      text: "Driver Project Details",
                    }}
                  />
                )}
              />
              <SubmitWrapper>
                <Note>
                  Please provide as much information as possible regarding what
                  you would like the driver to do.
                </Note>
                <Note>
                  Minimum 50 characters{" "}
                  {watchedDriverDetails && watchedDriverDetails.length! < 50 ? (
                    <span>({50 - watchedDriverDetails.length} remaining)</span>
                  ) : (
                    <span>(passed)</span>
                  )}
                </Note>
                {submitStatus ? (
                  <div>Submission {submitStatus}</div>
                ) : (
                  <CustomButton
                    text={"Submit"}
                    loading={loading}
                    style={{ maxWidth: "305px", width: "100%" }}
                    type="submit"
                  />
                )}
              </SubmitWrapper>
            </Driver>
          </form>
        </Content>
      </Wrapper>
    </ContentWrapper>
  );
};

export default RequestForm;

const Desc = styled.div`
  grid-column: 1;
  justify-self: start;
  padding-left: 2em;
  padding-right: 2em;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 40px;
  @media only screen and (max-width: 1200px) {
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  display: grid;
  grid-column: 2/4;
  @media only screen and (max-width: 1200px) {
    grid-column: 1;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: 0.8em;
`;

const Description = styled.div`
  line-height: 22px;
  text-align: justify;
  font-size: 16px;
  margin-bottom: 40px;
`;

const Entity = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media only screen and (max-width: 920px) {
    justify-content: center;
    grid-template-columns: 1fr;
  }
`;

const Driver = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const Note = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;

const SubmitWrapper = styled.div`
  margin: 0 auto;
  grid-column: 2;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
`;
