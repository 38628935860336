import React, { useState } from "react";
import { User } from "../../../index";
import { editUserAdmin } from "../../../../../../../api/Admin";
import { MyResponse } from "../../../../../../../api/Request";
import { InputHandler } from "../../../../../../../components/form/types";

interface UserAdminContextInterface {
  userRoles: string[];
  rolesList: string[];
  rolesText: { [index: string]: string };
  updateRole: (role: string) => void;
  updateState: (role: string) => void;
  removeRole: (role: string) => void;
  resetState: () => void;
  userState: string;
  possibleStates: string[];
  submitChanges: () => any;
  adminPass: string;
  updatePass: InputHandler;
}

const initialState = {
  userRoles: [],
  rolesList: [],
  rolesText: {},
  updateRole: () => {},
  updateState: () => {},
  removeRole: () => {},
  submitChanges: () => {},
  resetState: () => {},
  possibleStates: [],
  userState: "",
  adminPass: "",
  updatePass: () => {},
};

const UserAdminContext =
  React.createContext<UserAdminContextInterface>(initialState);

const UserAdminContextProvider = (props: {
  children: JSX.Element;
  user: User;
}) => {
  const [userRoles, setUserRoles] = useState<string[]>(props.user.roles);
  const [userState, setUserState] = useState<string>(
    props.user.userState ? props.user.userState : "-"
  );
  const [adminPass, setAdminPass] = useState<string>("");

  const rolesList = [
    "ROLE_ADMIN",
    "ROLE_USER",
    "ROLE_MANUFACTURER",
    "ROLE_DEALER",
    "ROLE_DISTRIBUTOR",
    "ROLE_REP",
  ];
  const rolesText: { [index: string]: string } = {
    ROLE_ADMIN: "Admin",
    ROLE_USER: "User",
    ROLE_MANUFACTURER: "Manufacturer",
    ROLE_DEALER: "Dealer",
    ROLE_DISTRIBUTOR: "Distributor",
    ROLE_REP: "Rep",
  };

  const possibleStates = ["ACTIVE", "DEACTIVATED", "INVITED"];

  const submitChanges = async (): Promise<MyResponse> => {
    return await editUserAdmin({
      ...props.user,
      userState: userState,
      roles: userRoles,
      adminPassword: adminPass,
    });
  };

  const updateState = (text: string) => {
    setUserState(text);
  };

  const updateRole = (text: string) => {
    setUserRoles((prev) => {
      return [...prev, text];
    });
  };

  const removeRole = (role: string) => {
    setUserRoles((prev) => {
      return prev.filter((item) => {
        return rolesText[item] !== role;
      });
    });
  };

  const resetState = () => {
    setUserRoles(props.user.roles);
    setUserState(props.user.userState ? props.user.userState : "-");
    setAdminPass("");
  };

  const updatePass: InputHandler = (e) => {
    setAdminPass(e.target.value);
  };

  return (
    <UserAdminContext.Provider
      value={{
        rolesList,
        userRoles,
        rolesText,
        updateRole,
        updateState,
        removeRole,
        userState,
        possibleStates,
        submitChanges,
        resetState,
        adminPass,
        updatePass,
      }}
    >
      {props.children}
    </UserAdminContext.Provider>
  );
};

export { UserAdminContextProvider, UserAdminContext };
