import React, { useState, useEffect } from "react";
import styled from "styled-components";
import img1 from "../../../../assets/images/gallery/img1.jpg";
import img2 from "../../../../assets/images/gallery/img2.jpg";
import img3 from "../../../../assets/images/gallery/img3.jpg";
import img4 from "../../../../assets/images/gallery/img4.jpg";

const images = [img1, img2, img3, img4];
const imageInfo = [
  { desc: "OFFICIAL NAIM UNITI MEDIA DRIVER", for: "FOR CONTROL 4" },
  {
    desc: "APPLE TV 4K TVOS 13 & 14 DRIVER",
    for: "FOR CONTROL 4, CRESTRON, ELAN & RTI",
  },
  { desc: "DYNAUDIO MUSIC", for: "DRIVER FOR CRESTRON" },
  { desc: "AMAZON FIRE", for: "DRIVER FOR ELAN" },
];

const Gallery = () => {
  const [current, setCurrent] = useState(0);
  const [hovering, setHovering] = useState<boolean>(false);

  const handleClick = (direction: string) => {
    if (direction === "left") {
      setCurrent((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    } else {
      setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (!hovering) {
        setCurrent((prev) => (prev === images.length - 1 ? 0 : prev + 1));
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [hovering]);

  return (
    <Wrapper
      onMouseEnter={(e) => {
        setHovering(true);
        e.stopPropagation();
      }}
      onMouseLeave={(e) => {
        setHovering(false);
        e.stopPropagation();
      }}
    >
      <Arrow
        className="ri-arrow-left-s-line"
        onClick={() => handleClick("left")}
      ></Arrow>
      <ImageContainer>
        {[images[images.length - 1], ...images, images[0]].map((img, index) => (
          <Image
            key={index}
            src={img}
            alt={"advert"}
            style={{
              transform:
                index - 1 === current
                  ? "translateX(0)"
                  : index - 1 < current
                  ? "translateX(-100%)"
                  : "translateX(100%)",
              transition: "transform 1s",
            }}
          />
        ))}
      </ImageContainer>
      <Description>
        <h1>{imageInfo[current].desc}</h1>
        <p>{imageInfo[current].for}</p>
        <Button>BUY NOW</Button>
      </Description>
      <Arrow
        className="ri-arrow-right-s-line"
        onClick={() => handleClick("right")}
      ></Arrow>
    </Wrapper>
  );
};

export default Gallery;

const Wrapper = styled.div`
  width: 100vw;
  position: relative;
`;

const Arrow = styled.i`
  position: absolute;
  color: white;
  top: 50%;
  font-size: 2.5em;
  cursor: pointer;
  right: ${(props) =>
    props.className === "ri-arrow-right-s-line" ? "15px" : "auto"};
  left: ${(props) =>
    props.className === "ri-arrow-left-s-line" ? "15px" : "auto"};
  z-index: 8;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100vh - 8em);
  width: 100%;

  @media only screen and (max-width: 768px) {
    height: 50vh;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform 1s;
`;

const Description = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  color: white;
  display: grid;
  grid-template-rows: auto auto;
  align-content: center;
  justify-content: center;
  grid-gap: 1em;
  height: 100%;

  h1 {
    font-weight: 600;
    font-size: 4em;
    color: white;
  }

  p {
    font-size: 2em;
    color: white;
  }

  @media only screen and (max-width: 1400px) {
    h1 {
      font-size: 3em;
    }

    p {
      font-size: 1.5em;
    }
  }

  @media only screen and (max-width: 1000px) {
    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1em;
    }
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 1.6em;
    }

    p {
      font-size: 0.8em;
    }
  }
  @media only screen and (max-width: 400px) {
    h1 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.9em;
    }
  }
`;

const Button = styled.button`
  padding: 10px 25px;
  justify-self: center;
  border-radius: 30px;
  border: none;
  background-color: #ff9f00;
  color: white;
  font-size: 1em;
`;
