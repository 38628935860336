import React, { useEffect, useState } from "react";

interface ShowMenuContextInterface {
  isMenuOpen: (key: string) => boolean;
  toggleOn: (key: string) => void;
  toggleOff: (key: string) => void;
  reset: () => void;
}

const initialState: ShowMenuContextInterface = {
  isMenuOpen: () => false,
  toggleOn: () => {},
  toggleOff: () => {},
  reset: () => {},
};

const ShowMenuContext =
  React.createContext<ShowMenuContextInterface>(initialState);
const ShowMenuContextProvider = (props: { children: JSX.Element }) => {
  const [openMenuKey, setOpenMenuKey] = useState<string | null>(null);

  const isMenuOpen = (key: string): boolean => openMenuKey === key;

  useEffect(() => {
    const handleClick = (e: globalThis.MouseEvent): void => {
      if (e !== null && e.target instanceof HTMLElement) {
        const element = e.target as HTMLElement;
        if (!element.closest("[data-menu]")) {
          setOpenMenuKey(null);
        }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const toggleOn = (key: string): void => {
    setOpenMenuKey(key);
  };

  const toggleOff = (key: string): void => {
    if (openMenuKey === key) {
      setOpenMenuKey(null);
    }
  };

  const reset = (): void => {
    setOpenMenuKey(null);
  };

  return (
    <ShowMenuContext.Provider
      value={{
        isMenuOpen,
        toggleOn,
        toggleOff,
        reset,
      }}
    >
      {props.children}
    </ShowMenuContext.Provider>
  );
};

export { ShowMenuContext, ShowMenuContextProvider };
