import {
  AddressAction,
  AddressActionCase,
  AddressFormState,
  AddressValidationAction,
  AddressValidationCase,
  AddressValidityState,
} from "./addressTypes";

//EditModal

/*
const PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
*/
/*
    {
        "id": 196419,
        "vatCode": "vCode",
        "deliveryContact": "0785749489",
        "firstLine": "1st first Line",
        "secondLine": "2nd second Line",
        "city": "testing city",
        "state": "state testing",
        "country": "country TBD",
        "zipCode": "1234678",
        "addressType": "OTHER",
        "addressName": null
    }
*/

export const initialAddressState: AddressFormState = {
  vatCode: "",
  deliveryContact: "",
  firstLine: "",
  secondLine: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  addressType: "",
  addressName: "",
};

export const AddressReducer = (
  state: AddressFormState,
  action: AddressAction
): AddressFormState => {
  switch (action.case) {
    case AddressActionCase.EDIT:
      return action.value;
    case AddressActionCase.DISABLED:
      return { ...state };
    case AddressActionCase.ADDRESS_TYPE:
      return { ...state, addressType: action.value };
    default:
      return { ...state, [action.field]: action.value };
  }
};

//validity

export const checkAddressFormValidity = (
  state: AddressValidityState
): boolean => {
  return (
    state.vatCodeValid &&
    state.deliveryContactValid &&
    state.firstLineValid &&
    state.cityValid &&
    state.stateValid &&
    state.countryValid &&
    state.zipCodeValid &&
    state.addressNameValid
  );
};

const formValidIndividual = (state: AddressFormState) => {
  return {
    addressNameValid: state.addressName !== "",
    firstLineValid: state.firstLine !== "",
    secondLineValid: state.secondLine !== "",
    cityValid: state.city !== "",
    stateValid: state.state !== "",
    countryValid: state.country !== "",
    zipCodeValid: state.zipCode !== "",
    deliveryContactValid: state.deliveryContact !== "",
    vatCodeValid: state.vatCode !== "",
    addressTypeValid: state.addressType !== "",
  };
};

export const initialAddressValidityState: AddressValidityState = {
  vatCodeValid: false,
  deliveryContactValid: false,
  firstLineValid: false,
  secondLineValid: false,
  cityValid: false,
  stateValid: false,
  countryValid: false,
  zipCodeValid: false,
  addressTypeValid: false,
  addressNameValid: false,
  formValid: false,
};

export const AddressValidityReducer = (
  state: AddressValidityState,
  action: AddressValidationAction
): AddressValidityState => {
  let isValid = false;
  let updatedState: AddressValidityState;
  switch (action.case) {
    /*
            VALIDATE_ID = "VALIDATE_ID",
            VALIDATE_VAT_CODE = "VALIDATE_VAT_CODE",
            VALIDATE_DELIVERY_CONTACT = "VALIDATE_DELIVERY_CONTACT",
            VALIDATE_FIRST_LINE = "VALIDATE_FIRST_LINE",
            VALIDATE_SECOND_LINE = "VALIDATE_SECOND_LINE",
            VALIDATE_CITY = "VALIDATE_CITY",
            VALIDATE_STATE = "VALIDATE_STATE",
            VALIDATE_COUNTRY = "VALIDATE_COUNTRY",
            VALIDATE_ZIP_CODE = "VALIDATE_ZIP_CODE",
  */

    case AddressValidationCase.VALIDATE_ZIP_CODE:
    case AddressValidationCase.VALIDATE_VAT_CODE:
    case AddressValidationCase.VALIDATE_DELIVERY_CONTACT:
    case AddressValidationCase.VALIDATE_FIRST_LINE:
    case AddressValidationCase.VALIDATE_SECOND_LINE:
    case AddressValidationCase.VALIDATE_CITY:
    case AddressValidationCase.VALIDATE_STATE:
    case AddressValidationCase.VALIDATE_COUNTRY:
      isValid = action.value.length > 0;
      updatedState = {
        ...state,
        ...{
          [action.field]: isValid,
          formValid: state.formValid,
        },
      };
      return {
        ...updatedState,
        formValid: isValid && checkAddressFormValidity(updatedState),
      };

    case AddressValidationCase.VALIDATE_ADDRESS_NAME:
      isValid = action.value.length > 0;
      updatedState = {
        ...state,
        ...{
          addressNameValid: isValid,
          formValid: state.formValid,
        },
      };
      return {
        ...updatedState,
        formValid: isValid && checkAddressFormValidity(updatedState),
      };

    case AddressValidationCase.VALIDATE_ADDRESS_TYPE:
      isValid = action.value.length > 0;
      updatedState = {
        ...state,
        ...{
          addressNameValid: isValid,
          formValid: state.formValid,
        },
      };
      return {
        ...updatedState,
        formValid: isValid && checkAddressFormValidity(updatedState),
      };
    case AddressValidationCase.VALIDATE_FORM:
      let newState = { ...state, ...formValidIndividual(action.value) };
      return {
        ...newState,
        formValid: checkAddressFormValidity(newState),
      };
    default:
      return state;
  }
};
