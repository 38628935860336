import styled from "styled-components";
import { AddressResponse } from "../../../../../../components/form/types";
import Subheading from "../../../../../../components/subheading";
import AddressCard from "../card";
import React, { useEffect, useState } from "react";
import CancelButton from "../../../../../../components/other/buttons/cancel";
import EditButton from "../../../../../../components/other/buttons/edit";
import { getAddresses } from "../../../../../../api/Addresses";
import { MyResponse } from "../../../../../../api/Request";
import useBreakpoint from "../../../../../../hooks/useBreakpoint";

type addressWithTitle = {
  data: AddressResponse;
  title?: string;
};

interface Props {
  addresses?: addressWithTitle[];
  title?: string;
  editable?: boolean;
  updateAddresses?: (address: AddressResponse[]) => void;
  setShowAddresses?: (showAddresses: boolean) => void;
  setCloseType?: (closeType: string) => void;
  addressEditing?: boolean;
  setAddressEditing?: (edit: boolean | undefined) => void;
  showAddresses?: boolean | undefined;
}

const AddressPicker = (props: Props) => {
  const [editing, setEditing] = useState(false);
  const [updateMsg, setUpdateMsg] = useState("");
  const [userAddresses, setUserAddresses] = useState([]);
  const { breakpoint } = useBreakpoint();
  useEffect(() => {
    getAddresses()
      .then((res: MyResponse) => {
        if (res.ok) {
          setUserAddresses(res.data);
        } else {
          console.error("get addresses request failed");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    setEditing(false);
  }, [props.addressEditing]);

  const toggleEditing = (prev: boolean) => {
    setEditing((prev) => {
      return !prev;
    });
  };

  const changeUpdateMsg = (msg: string) => {
    setUpdateMsg(msg);
    setTimeout(() => {
      setUpdateMsg("");
    }, 2000);
  };

  return (
    <>
      <AddressPickerWrap
        style={{
          borderLeft:
            editing || breakpoint < 900 ? "transparent" : "var(--border-main)",
        }}
      >
        <Heading>
          <Subheading h3={"Manage"} h2={"Addresses"} />
          <ButtonWrapper>
            {editing ? (
              <>
                <CancelButton
                  onClick={() => {
                    setEditing(false);
                    if (props.setShowAddresses) {
                      props.setShowAddresses(false);
                    }
                    if (props.setCloseType) {
                      props.setCloseType("window");
                    }
                  }}
                  disabled={false}
                />
              </>
            ) : (
              props.editable && (
                <EditButton
                  onClick={() => {
                    setEditing(true);
                    if (props.setShowAddresses) {
                      props.setShowAddresses(true);
                      if (props.setCloseType) {
                        props.setCloseType("addresses");
                      }
                    }
                  }}
                />
              )
            )}
          </ButtonWrapper>
        </Heading>
        <CardList>
          {props.addresses && props.addresses.length > 0 ? (
            props.addresses.map((val: addressWithTitle, index) => {
              return (
                <AddressCard
                  key={index}
                  address={val.data}
                  title={val.title}
                  editable={editing}
                  toggleEditing={toggleEditing}
                  saveAddress={(address: AddressResponse) => {
                    let newAddress = props.addresses?.map(
                      (address) => address.data
                    );
                    if (newAddress) {
                      newAddress[index] = address;
                      props.updateAddresses &&
                        props.updateAddresses(newAddress);
                    }
                  }}
                  setShowAddresses={props.setShowAddresses}
                  changeUpdateMsg={changeUpdateMsg}
                  userAddresses={userAddresses}
                />
              );
            })
          ) : (
            <EmptyWrap>No Addresses</EmptyWrap>
          )}
          {updateMsg !== "" && <div>{updateMsg}</div>}
        </CardList>
      </AddressPickerWrap>
    </>
  );
};

export default AddressPicker;

const EmptyWrap = styled.p`
  justify-self: center;
`;

const CardList = styled.div`
  display: grid;
  align-items: start;
  align-content: start;
  align-self: start;
  grid-gap: 10px;
  padding: 10px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media only screen and (max-width: 920px) {
    grid-auto-flow: column;
    justify-items: start;
    justify-content: start;
    overflow-x: scroll;
  }
`;

const AddressPickerWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow-y: scroll;
  position: relative;
  max-height: 80vh;
  margin: 0 1.5em 2em 1.5em;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 1em;
  @media only screen and (max-width: 920px) {
    grid-auto-flow: column;
    grid-column: 1/-1;
  }
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: center;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 10px;
  margin-right: 14px;
  display: grid;
  grid-template-columns: 1fr auto;
  @media only screen and (max-width: 920px) {
    grid-row: 1;
    grid-column: 1/-1;
    margin-bottom: unset;
    margin-top: unset;
  }
`;

const ButtonWrapper = styled.div`
  justify-self: end;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  margin-right: 10px;
`;
