import Subheading from "../../../../components/subheading";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import UpdateSupportForm from "../Modal";
import { SupportTicket } from "../types";
import { DateTime } from "luxon";
import AddButton from "../../../../components/other/buttons/add";
import { useNavigate, useParams } from "react-router-dom";
import DropdownInput, {
  DropdownEvent,
  DropdownList,
  DropdownValue,
} from "../../../../components/form/components/dropdownInput";
import MobileTable from "../../../../components/table/MobileTable";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";
import { v4 as uuidv4 } from "uuid";
import { SupportContext } from "../../../../context/SupportContext";
import { capitalizeWords } from "../../../../utils/utils";

const emptyTicket = {
  id: "220",
  category: "ELAN TECHNICAL",
  status: "Unresolved",
  subject: "BROKEN TV",
  editDate: "2023-07-24T14:15:30Z",
};

const SupportRequests = () => {
  const { number } = useParams();
  const [supportTickets, setSupportTickets] = useState([
    emptyTicket,
    emptyTicket,
    emptyTicket,
    emptyTicket,
    emptyTicket,
  ]);
  const [filteredTickets, setFilteredTickets] = useState(supportTickets);
  const [supportTicketToEdit, setSupportTicketToEdit] =
    useState<SupportTicket | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint();
  const [filter, setFilter] = useState<DropdownValue | null>(null);
  const { statuses } = useContext(SupportContext);

  const statusesFormatted: DropdownList = statuses.map((status) => {
    return { displayValue: capitalizeWords(status.name), value: status.name };
  });

  useEffect(() => {
    const setInitialFilter = () => {
      const foundStatus = statuses.find(
        (status) => status.id.toString() === number
      );
      const newFilter = foundStatus ? foundStatus : statuses[0];
      setFilter({
        displayValue: capitalizeWords(newFilter.name),
        value: newFilter.name,
      });
    };

    if (statuses.length > 0) setInitialFilter();
  }, [number, statuses]);

  useEffect(() => {
    const newFilteredTickets = supportTickets.filter(
      (ticket) => ticket.status.toUpperCase() === filter?.value ?? ""
    );
    setFilteredTickets(newFilteredTickets);
  }, [filter, supportTickets]);

  useEffect(() => {
    switch (filter?.value) {
      case "UNRESOLVED":
        setFilteredTickets(
          supportTickets.filter((ticket) => ticket.status === "Unresolved")
        );
        break;
      case "RESOLVED":
        setFilteredTickets(
          supportTickets.filter((ticket) => ticket.status === "Resolved")
        );
        break;
      case "FOLLOW UP":
        setFilteredTickets(
          supportTickets.filter((ticket) => ticket.status === "Follow Up")
        );
        break;
      case "CLOSED":
        setFilteredTickets(
          supportTickets.filter((ticket) => ticket.status === "Closed")
        );
        break;
      default:
        setFilteredTickets([]);
        break;
    }
  }, [filter, supportTickets]);

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "ID",
        accessor: "id",
        sortBy: "id",
        columnSizing: { min: "100px", max: "120px" },
      },
      {
        text: "Status",
        accessor: "status",
        sortBy: "status",
      },
      {
        text: "Category",
        accessor: "category",
      },
      {
        text: "Subject",
        accessor: "subject",
      },
      {
        text: "Date Updated",
        accessor: "editDate",
        sortBy: "editDate",
        cb: (data: any, value: any) => {
          return <div>{DateTime.fromISO(value).toLocaleString()}</div>;
        },
      },
    ],
    []
  );

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        {filter !== null && (
          <DropdownInput
            id={uuidv4()}
            width={"120px"}
            value={filter.displayValue}
            handlechange={(e: DropdownEvent) => {
              setFilter({
                displayValue: e.target.displayName,
                value: e.target.value,
              });
            }}
            items={statusesFormatted}
          />
        )}
        <AddButton
          text={"New Ticket"}
          onClick={() => {
            navigate("/support/form");
          }}
        />
      </HeaderWrap>
    ),
    [filter, statusesFormatted]
  );

  const handleRowClick = (data: SupportTicket) => {
    if (data) {
      setSupportTicketToEdit(data);
      setShowForm(true);
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };
  return (
    <Wrapper>
      {supportTicketToEdit !== null && showForm && (
        <UpdateSupportForm support={supportTicketToEdit} close={handleClose} />
      )}
      <Heading>
        <Subheading h3={"Your"} h2={"Support Tickets"} />
      </Heading>
      {breakpoint < 700 ? (
        <MobileTable
          padding={40}
          data={filteredTickets}
          headerRow={setupTable}
          loading={loading}
          // editId={supportTicketToEdit && supportTicketToEdit}
          paginate={false}
          tableHeader={tableHeader}
        />
      ) : (
        <TableV2
          data={filteredTickets}
          headerRow={setupTable}
          loading={loading}
          tableHeader={tableHeader}
        />
      )}
    </Wrapper>
  );
};

export default SupportRequests;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  text-align: start;
  margin-top: 4em;

  @media only screen and (max-width: 700px) {
    margin-bottom: 5em;
    align-content: start;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Heading = styled.div`
  margin-left: 14px;
  margin-bottom: 2em;
`;

const HeaderWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
`;
