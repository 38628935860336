import Subheading from "../../../../../components/subheading";
import styled from "styled-components";

interface ContactHeadingProps {
  heading: string;
  children?: JSX.Element;
}

const ContactHeading = (props: ContactHeadingProps) => {
  return (
    <SidebarHeadingWrap>
      {" "}
      <Subheading h3={"CONTACT"} h2={props.heading} />
      {props.children && props.children}
    </SidebarHeadingWrap>
  );
};

export default ContactHeading;

const SidebarHeadingWrap = styled.div`
  text-align: left;
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: fit-content;
`;
