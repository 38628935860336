import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import { OpenOrdersContext } from "../../../context/OpenOrdersContext";
import { AuthContext } from "../../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useShowMenu from "../../../hooks/useShowMenu";
import Dropdown from "./Dropdown";

const Checkout = () => {
  const [openOrderInfo, setOpenOrderInfo] = useState({
    baseValue: 0.0,
    items: 0,
  });
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { openOrders } = useContext(OpenOrdersContext);
  const [showMenu, toggleOn, toggleOff, reset] = useShowMenu();
  const nav = useNavigate();

  //TODO set up to use orders? if so order needs to return an image of the product from the backend

  useEffect(() => {
    if (user !== null) {
      if (openOrders.length > 0) {
        let orderInfo = { baseValue: 0, items: 0 };
        orderInfo = {
          baseValue: openOrders[0].baseValue,
          items: 0,
        };
        openOrders[0].orderLines.forEach(() => {
          orderInfo = {
            ...orderInfo,
            items: orderInfo.items + 1,
          };
        });
        setOpenOrderInfo(orderInfo);
      }
    }
  }, [user, location, openOrders]);

  const product = {
    id: 665,
    name: "RTI AD-4 Driver for ELAN",
    description:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    addDate: "2022-10-12T16:20:26.743+00:00",
    updateDate: "2022-10-12T16:20:26.743+00:00",
    gbpPrice: 35,
    shortName: null,
    productUrl: "rti-ad4-driver-for-elan",
    supportedModels:
      "# IntrinsicDev driver  Test markdown **need to add descriptions** _and try the markdown_  - Maybe its OK",
    costs: [
      {
        currencyType: "GBP",
        currencyValue: 35,
      },
      {
        currencyType: "AUD",
        currencyValue: 68.6,
      },
      {
        currencyType: "CAD",
        currencyValue: 59.85,
      },
      {
        currencyType: "EUR",
        currencyValue: 40.95,
      },
      {
        currencyType: "USD",
        currencyValue: 44.1,
      },
    ],
    type: "PRODUCT",
    seoTitle: null,
    seoDescription: null,
    media: [
      {
        id: 1207,
        name: "",
        description: "",
        mediaType: "IMAGE",
        sequence: 1,
        addDate: "2022-11-08T07:31:29.928+00:00",
        updateDate: "2022-11-08T07:31:29.928+00:00",
        imageUrl:
          "https://www.intrinsicdev.com/wp-content/uploads/edd/2019/03/elan-rti-ad4-small.jpg",
      },
    ],
    downloadLink:
      "https://www.dropbox.com/sh/ec70fckk8a0t4k6/AAB_d_aQT6YVcBKC75S_9OxSa?dl=0",
    platformName: "ELAN",
  };
  const products = Array.from({ length: 5 }, () =>
    JSON.parse(JSON.stringify(product))
  );

  return (
    <Wrapper
      style={showMenu ? { borderBottom: "solid 1px #ff9d26" } : {}}
      data-menu={true}
      onMouseOver={(e: React.MouseEvent<HTMLElement>) => {
        toggleOn(e);
      }}
      onMouseOut={toggleOff}
      onClick={() => {
        nav("empty-cart");
      }}
    >
      <i className="ri-shopping-cart-2-fill"></i>
      <CartInfo>
        {openOrderInfo.items} items - £{openOrderInfo.baseValue.toFixed(2)}
      </CartInfo>
      {showMenu && <Dropdown close={toggleOff} products={products} />}
    </Wrapper>
  );
};

export default Checkout;

const Wrapper = styled.div`
  position: relative;
  i {
    font-size: 1.8em;
    color: var(--orange-color);
  }
  div {
    line-height: 1.1em;
  }
  display: grid;
  cursor: pointer;
  color: black;
  border-bottom: solid 1px rgba(255, 157, 38, 0);
  text-decoration: none;
  :hover {
    border-bottom: solid 1px #ff9d26;
  }
`;

const CartInfo = styled.div``;
