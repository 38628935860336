import styled from "styled-components";
import CurrencyButton from "./button";

interface CurrencyProps {
  updateCurrency: (index: number) => void;
  currentIndex: number;
}

const CurrencySelector = (props: CurrencyProps) => {
  return (
    <CurrencyWrapper>
      <CurrencyButton
        currencyType={"GBP"}
        active={props.currentIndex === 0}
        index={0}
        updateCurrency={props.updateCurrency}
      />
      <CurrencyButton
        currencyType={"AUD"}
        active={props.currentIndex === 1}
        index={1}
        updateCurrency={props.updateCurrency}
      />
      <CurrencyButton
        currencyType={"CAD"}
        active={props.currentIndex === 2}
        index={2}
        updateCurrency={props.updateCurrency}
      />
      <CurrencyButton
        currencyType={"EUR"}
        active={props.currentIndex === 3}
        index={3}
        updateCurrency={props.updateCurrency}
      />
      <CurrencyButton
        currencyType={"USD"}
        active={props.currentIndex === 4}
        index={4}
        updateCurrency={props.updateCurrency}
      />
    </CurrencyWrapper>
  );
};

export default CurrencySelector;

const CurrencyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  width: min-content;
  grid-gap: 5px;
  @media only screen and (max-width: 920px) {
    justify-items: center;
    justify-self: center;
  }
`;
