import Subheading from "../../../../components/subheading";
import React from "react";
import styled, { ThemeProvider } from "styled-components";

const ShowroomIntro = () => {
  const theme = {
    bottomMargin: "3em",
  };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Subheading h3={"SHOWROOM"} h2={"LICENCE"} />
        <Intro>
          <p>
            Intrinsic Dev are delighted to offer free licences for your showroom
            and demo gear. We will issue a full demo pack for your chosen
            control platform with all new drivers available to you (as released)
            in your account section of the website.
          </p>
          <p>
            Please fill in the form below and we’ll get back to you with licence
            key information as soon as possible.
          </p>
          <p>
            NB Showroom Licence Requests typically take 2 business days to be
            verified.
          </p>
        </Intro>
      </Wrapper>
    </ThemeProvider>
  );
};

export default ShowroomIntro;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: start;
  align-items: start;
  align-content: start;
  grid-gap: 1em;
  grid-column: 1;
`;

const Intro = styled.div`
  text-align: left;
  line-height: 22px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: auto 1fr;
  margin-right: 20px;
`;
