import Subheading from "../../../../components/subheading";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddButton from "../../../../components/other/buttons/add";
import { useNavigate } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";

const LicenseTransferRequests = () => {
  const [licenseTransferRequests, setLicenseTransferRequests] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    //TODO: Add License transfer endpoint when it is available.

    // getLicenseTransfers().then((res) => {
    //   if (res.ok) {
    //     setLicenseTransferRequests(res.data.objects);
    //     setLoading(false);
    //   }
    // });
    setLoading(false);
  }, []);
  //TODO: Change columns
  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Email",
        accessor: "email",
        sortBy: "email",
      },
      {
        text: "Name",
        accessor: "Name",
      },
      {
        text: "Old Mac Address",
        accessor: "oldMacAddress",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "New Mac Address",
        accessor: "newMacAddress",
        // columnSizing: { min: "120px", max: "1fr" },
      },
      {
        text: "Reason",
        accessor: "reason",
      },
    ],
    []
  );

  const tableHeader = React.useMemo<React.ReactNode>(
    () => (
      <HeaderWrap>
        <AddButton
          onClick={() => {
            navigate("/license-transfer/form");
          }}
        />
      </HeaderWrap>
    ),
    []
  );

  return (
    <Wrapper>
      <Heading>
        <Subheading h3={"Your"} h2={"License Transfers"} />
      </Heading>
      <TableV2
        data={licenseTransferRequests}
        headerRow={setupTable}
        loading={loading}
        tableHeader={tableHeader}
      />
    </Wrapper>
  );
};

export default LicenseTransferRequests;

const Wrapper = styled.div`
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  text-align: start;
`;

const Heading = styled.div`
  margin-left: 14px;
`;

const HeaderWrap = styled.div`
  justify-self: end;
`;
