import React, { useEffect, useState, useCallback } from "react";

interface CookieConsentContextInterface {
  cookieConsent: boolean | null;
  isCookieConsentLoading: boolean;
  acceptCookies: () => void;
  rejectCookies: () => void;
}

const initialState: CookieConsentContextInterface = {
  cookieConsent: null,
  isCookieConsentLoading: true,
  acceptCookies: () => {},
  rejectCookies: () => {},
};

const CookieConsentContext =
  React.createContext<CookieConsentContextInterface>(initialState);

const CookieConsentProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(null);
  const [isCookieConsentLoading, setIsCookieConsentLoading] =
    useState<boolean>(true);

  const updateConsentStatus = useCallback(() => {
    const storedConsent = localStorage.getItem("cookieConsent");
    setCookieConsent(
      storedConsent === "true" ? true : storedConsent === "false" ? false : null
    );
    setIsCookieConsentLoading(false);
  }, []);

  useEffect(() => {
    if (isCookieConsentLoading) {
      updateConsentStatus();
    }
  }, [isCookieConsentLoading, updateConsentStatus]);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setCookieConsent(true);
    console.log("accept");
  };

  const rejectCookies = () => {
    localStorage.setItem("cookieConsent", "false");
    setCookieConsent(false);
    console.log("reject");
  };

  return (
    <CookieConsentContext.Provider
      value={{
        cookieConsent,
        isCookieConsentLoading,
        acceptCookies,
        rejectCookies,
      }}
    >
      {props.children}
    </CookieConsentContext.Provider>
  );
};

export { CookieConsentContext, CookieConsentProvider };
