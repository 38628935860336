import styled from "styled-components";
import React from "react";
interface Props {
  heading: string;
  text: string | number;
  inputSetup?: React.InputHTMLAttributes<any>;
  editing?: boolean;
  case?: any;
}
const Card = (props: Props) => {
  return (
    <CardWrap>
      <Heading>{props.heading}</Heading>
      <CardInner>
        {props.inputSetup && props.editing ? (
          <input
            type="text"
            onChange={props.inputSetup.onChange}
            value={props.inputSetup.value}
            name={props.inputSetup.name}
            data-case={props.case && props.case}
          />
        ) : (
          <p>{props.text}</p>
        )}
      </CardInner>
    </CardWrap>
  );
};

export default Card;

const CardWrap = styled.div`
  border: var(--border-main);
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  align-content: center;
  align-items: center;
  grid-gap: 12px;
  grid-template-rows: auto 1fr;
  height: 3em;
`;

const CardInner = styled.div`
  word-break: keep-all;
  white-space: nowrap;

  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  input,
  p {
    all: unset;
  }

  input[type="text"] {
    border: solid 1px var(--orange-color);
    background-color: rgba(252, 151, 0, 0.26);
    box-sizing: border-box;
    width: -moz-available;
    padding: 6px;
  }
  p {
    border: solid 1px white;
  }

  font-size: 14px;
  margin-bottom: 10px;
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;
