import NavBarComponent from "./navbarcomponent";
import { ReactElement, useContext } from "react";

import { NavContext } from "../../../context/NavContext";
import Brands from "./brands";
import styled from "styled-components";
import DriverDevelopment from "./driverDevelopment";
import SupportMenu from "./support";

const NavBar = (): ReactElement => {
  const { loading, platforms, brands } = useContext(NavContext);
  const links = platforms.map((item, index) => {
    return <NavBarComponent platform={item} index={index} />;
  });

  return (
    <Wrapper>
      {" "}
      {!loading && (
        <>
          {links} <Brands brands={brands} />
          <DriverDevelopment />
          <SupportMenu />
        </>
      )}
    </Wrapper>
  );
};

export default NavBar;

const Wrapper = styled.div`
  background-color: var(--grey-color);
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(8, auto);
  align-items: center;
  color: white;
  z-index: 2;
  height: 100%;
  box-sizing: border-box;

  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
  a:hover {
    color: var(--orange-color);
  }
`;
