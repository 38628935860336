import styled from "styled-components";
import React, { useState } from "react";
import "../../../../components/form/styles/form.css";
import Form from "./form";
import SuccessScreen from "./successScreen";

const ResetPassword = () => {
  const [showForm, setShowForm] = useState<boolean>(true);

  const toggleForm = () => {
    setShowForm(false);
  };

  return (
    <PageWrap>
      {" "}
      {showForm ? <Form toggleForm={toggleForm} /> : <SuccessScreen />}
    </PageWrap>
  );
};
export default ResetPassword;

//

const PageWrap = styled.div`
  grid-row: 2;
  justify-self: center;
  align-self: center;
`;
