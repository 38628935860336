export interface OrderLineState {
  orderNumber: null;
  productId: number;
  productName: string;
  quantity: number;
  baseValue: number;
  baseCurrency: string;
  localValue: string;
  localCurrency: string;
}

export enum OrderActionCase {
  GENERAL = "GENERAL",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  EDIT = "EDIT",
}
export enum OrderValidationCase {
  VALIDATE_EDIT = "VALIDATE_EDIT",
  VALIDATE_PHONE_NUMBER = "VALIDATE_PHONE_NUMBER",
  VALIDATE_LICENCE_KEY = "VALIDATE_LICENCE_KEY",
  VALIDATE_PROJECT_NAME = "VALIDATE_PROJECT_NAME",
  VALIDATE_MAC_ADDRESS = "VALIDATE_MAC_ADDRESS",
  VALIDATE_PRODUCT_QUANTITY = "VALIDATE_PRODUCT_QUANTITY",
}
