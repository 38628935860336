import { RequestConfig, requestMiddleware } from "./Request";

export const getSupportTickets = () => {
  const config: RequestConfig = {
    url: "/api/support",
    method: "GET",
  };
  return requestMiddleware(config);
};

export const getCategories = () => {
  const config: RequestConfig = {
    url: "/api/support/category/all",
    method: "GET",
  };
  return requestMiddleware(config);
};

export const getAllStatuses = () => {
  const config: RequestConfig = {
    url: "/api/support/status/all",
    method: "GET",
  };
  return requestMiddleware(config);
};

// types

export type Category = {
  id: number;
  name: string;
  description: string;
  fields: { id: number; name: string }[];
};

export type Status = {
  id: number;
  name: string;
  description: string;
};
