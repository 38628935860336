import useInput from "../../../../hooks/useInput";
import styled from "styled-components";
import { parseSearch } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const SearchBox = () => {
  const [value, handleChange] = useInput();
  const nav = useNavigate();

  const searchNavigate = () => {
    if (value !== "") {
      nav(`products/${parseSearch(value)}`);
    }
  };

  return (
    <Wrapper>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Search..."
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            searchNavigate();
          }
        }}
      />
      <i
        className="ri-search-line"
        onClick={() => {
          searchNavigate();
        }}
      ></i>
    </Wrapper>
  );
};

export default SearchBox;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  width: 100%;
  grid-gap: 2px;
  input {
    padding-left: 1em;
    border: solid 1px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  i {
    color: white;
    background-color: var(--grey-color);
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
  }
`;
