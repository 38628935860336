import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function getCategories(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/categories",
    method: "GET",
  };

  return requestMiddleware(config);
}
