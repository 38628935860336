import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

export async function postShowroom(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/forms/showroom",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function postContact(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/forms/contact",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}

export async function postLicenseTransfer(data: any): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/forms/transfer",
    method: "POST",
    data,
  };
  return requestMiddleware(config);
}
