import React, { useEffect, useState } from "react";
import { InputProps } from "../forminput";
import Label from "../label";
import { FormInputProps, LabelInterface } from "../../types";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";

interface TextAreaInputProps extends FormInputProps, InputProps {
  label: LabelInterface;
}

const TextAreaInput = (props: TextAreaInputProps) => {
  const [displayError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (props.showerrors && !props.isvalid) {
      showError();
    }
  }, [props.showerrors, props.isvalid]);

  useEffect(() => {
    if (props.isvalid) {
      hideError();
    }
  }, [props.isvalid]);

  const showError = (): void => {
    setShowError(true);
  };

  const hideError = (): void => {
    setShowError(false);
  };

  useEffect(() => {
    hideError();
  }, []);

  const theme = {
    transform:
      props.value.length <= 0 ? "translateY(0px)" : "translateY(-18px)",
    opacity: props.value.length <= 0 ? 0 : 100,
    index: props.value.length <= 0 ? -1 : 1,
  };

  return (
    <ThemeProvider theme={theme}>
      <TextAreaInputWrap>
        {props.label && (
          <Label
            text={props.label.text}
            for={props.label.for}
            required={props.label.required}
          />
        )}

        <textarea
          {...props}
          placeholder={
            props.label.required ? props.label.text + "*" : props.label.text
          }
          className={` ${props.isvalid && displayError && "error-outline"}`}
          value={props.value}
          data-field={props.validationfield}
          data-case={props.case}
          data-validationtype={props.validationtype}
          onChange={(event) => {
            props.handlechange(event);
            if (props.handlevalidation) {
              props.handlevalidation(event);
              hideError();
            }
          }}
          onBlur={(event) => {
            if (props.handlevalidation) {
              props.handlevalidation(event);
              showError();
            }
          }}
        />
        {!props.isvalid && displayError && (
          <TextAreaError>
            {props.errormessage
              ? props.errormessage
              : "This field is required."}
          </TextAreaError>
        )}
      </TextAreaInputWrap>
    </ThemeProvider>
  );
};

export default TextAreaInput;

const TextAreaInputWrap = styled.div`
  display: grid;
  justify-items: start;
  border: solid 1px rgba(0, 0, 0, 0.23);
  // moved max-width to inline css to open override when implementing in other places with different width requirements
  //max-width: 32em;
  position: relative;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 4px;

  textarea {
    padding: 10px;
    font-family: inherit;
    width: 92.7%;
    height: 10.27em;
    resize: none;
    position: relative;
    outline: none;
    font-size: 0.8em;
  }
  label {
    position: absolute;
    top: 10px;
    left: 8px;
    background-color: white;
    font-size: 0.9em;
    transition: all ease-in-out 0.2s;
    transform: ${(props) => props.theme.transform};
    opacity: ${(props) => props.theme.opacity};
    z-index: ${(props) => props.theme.index};
    padding-left: 2px;
    padding-right: 2px;
  }
`;

const TextAreaError = styled.p`
  color: #990000;
  font-size: 0.7em;
  grid-row: -1;
  grid-column: 1/-1;
  justify-self: start;
  position: absolute;
  margin-top: 13.65em;

  @media only screen and (max-width: 992px) {
    margin-top: 13.6em;
  }
`;
