import {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import _ from "lodash";

type ItemType = any;

// High buffer values may cause cpu spikes, seems to be caused by react rendering too many things at once

const useVirtualization = (
  data: ItemType[],
  viewportRef: React.RefObject<HTMLElement>,
  rowRef: React.RefObject<HTMLElement>,
  buffer: number,
  defaultHeight: number,
  gap?: number | null
): [ItemType[], number, number, number, boolean] => {
  const GRID_GAP = gap === null ? 0 : gap ? gap : 10;
  const [items, setItems] = useState<ItemType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [firstIndex, setFirstIndex] = useState<number>(0);
  const heightRef = useRef<number>(
    rowRef.current
      ? rowRef.current.getBoundingClientRect().height + GRID_GAP
      : defaultHeight
  );

  useEffect(() => {
    if (rowRef.current) {
      if (
        rowRef.current.getBoundingClientRect().height !== heightRef.current &&
        !loaded
      )
        heightRef.current =
          rowRef.current.getBoundingClientRect().height + GRID_GAP;
      setLoaded(true);
    }
  }, [rowRef, items]);

  const getSiblingHeight = (
    ref: React.RefObject<HTMLElement>
  ): number | null => {
    const element = ref.current;
    const nextSibling = element?.nextSibling as HTMLElement;
    if (nextSibling) {
      return nextSibling.getBoundingClientRect().height;
    }
    const previousSibling = element?.previousSibling as HTMLElement;
    if (previousSibling) {
      return previousSibling.getBoundingClientRect().height;
    }
    return null;
  };

  const updateItems = useCallback(() => {
    const viewportElement = viewportRef.current;
    if (!viewportElement) return;
    if (viewportElement.scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
    let calcHeight = heightRef.current;
    const siblingHeight = getSiblingHeight(rowRef);
    const rowElement = rowRef.current;

    if (siblingHeight !== null) {
      if (
        rowElement &&
        rowElement.getBoundingClientRect().height &&
        siblingHeight !== rowElement.getBoundingClientRect().height
      ) {
        const commonHeight = siblingHeight;
        const oddOneOutHeight = rowElement.getBoundingClientRect().height;
        const totalItems = data.length;
        const totalItemsWithCommonHeight = totalItems - 1;
        if (oddOneOutHeight) {
          const totalHeight =
            commonHeight * totalItemsWithCommonHeight + oddOneOutHeight;
          const averageHeight = totalHeight / totalItems;
          calcHeight = averageHeight + GRID_GAP;
          heightRef.current = calcHeight;
        }
      } else {
        calcHeight = siblingHeight + GRID_GAP;
        heightRef.current = calcHeight;
      }
    }

    const { scrollTop, offsetHeight } = viewportElement;
    const visibleItems = Math.ceil(offsetHeight / calcHeight);
    const totalVisibleItems = visibleItems + 2 * buffer;

    const firstIndex = Math.max(0, Math.floor(scrollTop / calcHeight) - buffer);
    const lastIndex = Math.min(
      data.length - 1,
      firstIndex + totalVisibleItems - 1
    );

    const newItems = data.slice(firstIndex, lastIndex + 1);
    setItems(newItems);
    setOffset(firstIndex * calcHeight);
    setFirstIndex(firstIndex);
  }, [viewportRef, data, buffer, rowRef]);

  useEffect(() => {
    const viewportElement = viewportRef.current;
    if (viewportElement) {
      const handleScroll = _.throttle(updateItems, 300);
      viewportElement.addEventListener("scroll", handleScroll);
      return () => viewportElement.removeEventListener("scroll", handleScroll);
    }
  }, [viewportRef.current, updateItems]);

  useEffect(() => {
    updateItems();
  }, [data, updateItems]);

  useLayoutEffect(() => {
    if (!viewportRef.current) return;
    updateItems();
  }, [viewportRef.current]);

  return [items, offset, heightRef.current, firstIndex, isScrolled];
};

export default useVirtualization;
