import styled from "styled-components";
import {
  UpdateProductActionCase,
  UpdateProductFormState,
} from "../../../../../../components/form/setup/admin/product/updateProductTypes";
import React from "react";
import { InputHandler } from "../../../../../../components/form/types";
import { Platform } from "../../../Platforms";
import { Brand } from "../../../Brands";
import { Category } from "../../../Categories";
import Item from "../../../../Reusables/picker/item";
import Picker, { MenuItem } from "../../../../Reusables/picker";
import AdminDropDownMenu from "../../../../Reusables/dropdown";

interface Props {
  product: UpdateProductFormState;
  toggleEdit: () => void;
  edit: boolean;
  handleInputChange: InputHandler;
  handleDropdownInputChange: (
    value: Platform | Category | Brand | string,
    productCase?: UpdateProductActionCase | undefined
  ) => void;
  platforms: Platform[];
  brands: Brand[];
  categories: Category[];
  removeCategory: (category: Category) => void;
}

const ProductLinkingSection = (props: Props) => {
  const categoriesDisplay = (props.product.categories as Category[]).map(
    (item) => {
      return (
        <Item
          text={item.name}
          remove={() => {
            props.removeCategory(item);
          }}
          editMode={props.edit}
        />
      );
    }
  );

  const menuCategories: MenuItem[] = props.categories
    .filter((item) => {
      return !(props.product.categories as Category[]).includes(item);
    })
    .map((item) => {
      return { text: item.name, data: item };
    });
  return (
    <Inner>
      <AdminDropDownMenu
        heading={"Platform"}
        items={props.platforms.map((platform) => platform.name)}
        text={(props.product.driverPlatform as Platform).name}
        handleClick={(value) => {
          let newPlatform = props.platforms.find(
            (platform) => platform.name === value
          );
          props.handleDropdownInputChange(
            newPlatform ?? "",
            UpdateProductActionCase.UPDATE_DRIVER_PLATFORM
          );
        }}
        editMode={props.edit}
      />
      <AdminDropDownMenu
        heading={"Brand"}
        items={props.brands.map((brand) => brand.name)}
        text={(props.product.brand as Brand).name}
        handleClick={(value) => {
          let newBrand = props.brands.find((brand) => brand.name === value);
          props.handleDropdownInputChange(
            newBrand ?? "",
            UpdateProductActionCase.UPDATE_BRAND
          );
        }}
        editMode={props.edit}
      />
      <Picker
        heading={"Categories"}
        editMode={props.edit}
        handleClick={(value) => {
          props.handleDropdownInputChange(
            value ?? "",
            UpdateProductActionCase.UPDATE_CATEGORY
          );
        }}
        menuItems={menuCategories}
      >
        {props.product.categories.length > 0 ? categoriesDisplay : undefined}
      </Picker>
    </Inner>
  );
};

export default ProductLinkingSection;

const Inner = styled.div`
  display: grid;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
`;
