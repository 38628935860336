import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  heading: string;
  inputSetup?: React.InputHTMLAttributes<any>;
  editing?: boolean;
  case?: any;
}

const DateRangePicker = (props: Props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 365))
  );

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDate(new Date(event.target.value));
    setEndDate(
      new Date(
        new Date(event.target.value).setDate(
          new Date(event.target.value).getDate() + 365
        )
      )
    );
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(new Date(event.target.value));
  };

  return (
    <CardWrap>
      <Heading>Start Date</Heading>
      <CardInner>
        <input
          type={"date"}
          onChange={handleStartDateChange}
          value={startDate.toISOString().split("T")[0]}
          name={"start-date"}
          data-case={props.case && props.case}
        />
      </CardInner>
      <Heading>End Date</Heading>
      <CardInner>
        <input
          type={"date"}
          onChange={handleEndDateChange}
          value={endDate.toISOString().split("T")[0]}
          name={"end-date"}
          data-case={props.case && props.case}
        />
      </CardInner>
    </CardWrap>
  );
};

export default DateRangePicker;

const CardWrap = styled.div`
  border: var(--border-main);
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  align-content: center;
  align-items: center;
  grid-gap: 10px;
  padding-left: 12px;
  grid-template-columns: auto 1fr;
  height: 3em;
  width: 16em;
`;

const CardInner = styled.div`
  word-break: keep-all;
  white-space: nowrap;

  overflow-x: hidden;
  text-overflow: ellipsis;

  input,
  p {
    all: unset;
  }
`;

const Heading = styled.h3`
  color: var(--blue-color);
  font-size: 0.8em;
  font-weight: bold;
  align-self: center;
`;
