import styled, { ThemeProvider } from "styled-components";

interface Props {
  currencyType: string;
  active: boolean;
  index: number;
  updateCurrency: (index: number) => void;
}

const CurrencyButton = (props: Props) => {
  const buttonActive = {
    background: props.active ? "var(--orange-color)" : "var(--grey-color)",
    color: props.active ? "white" : "white",
  };
  return (
    <ThemeProvider theme={buttonActive}>
      <Button onClick={() => props.updateCurrency(props.index)}>
        {props.currencyType}
      </Button>
    </ThemeProvider>
  );
};

const Button = styled.button`
  width: max-content;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  :hover {
    background-color: var(--orange-color);
    color: var(--white-color);
  }
`;

export default CurrencyButton;
