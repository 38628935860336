import React, { ReactElement } from "react";
import styled from "styled-components";
import ButtonSpinner from "../spinner";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  text: string | ReactElement;
  spinnerColor?: string;
}

const CustomButton = (props: ButtonProps) => {
  return (
    <Button {...props}>
      {props.loading ? (
        <ButtonSpinner color={props.spinnerColor || "#4b4b4b"} />
      ) : (
        props.text
      )}
    </Button>
  );
};

export default CustomButton;

const Button = styled.button`
  width: max-content;
  height: 2.5em;
  padding: 12px;
  background-color: rgba(238, 238, 238, 0.51);
  display: grid;
  align-content: center;
  border: solid 1px rgba(100, 97, 97, 0.23);
  font-size: 0.9em;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  color: #4b4b4b;
  justify-items: center;
  justify-content: center;
  :disabled {
    border: solid 1px rgba(100, 97, 97, 0.08);
    box-shadow: none;
    color: rgba(42, 41, 41, 0.47);
  }
  :hover {
    background-color: rgba(189, 187, 187, 0.42);
  }
  :hover:disabled {
    background-color: rgba(238, 238, 238, 0.51);
  }
`;
