import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { DateTime } from "luxon";
import EditButton from "../../../../components/other/buttons/edit";
import UpdateOrderFormAdmin from "./modal";
import DownloadButton from "../../../../components/other/buttons/download";
import { getOrdersAdmin } from "../../../../api/Admin";
import { Order } from "../../../Main/Orders";
import { getOrdersById } from "../../../../api/Orders";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import MobileTable from "../../../../components/table/MobileTable";
import { useLocation } from "react-router-dom";
import TableV2 from "../../../../components/table/TableV2";
import { HeaderRowItem } from "../../../../components/table/Other/Types";
import { AuthContext } from "../../../../context/AuthContext";

type AdminOrder = {
  currency: string;
  id: number;
  macAddress: string | null;
  orderDate: string | null;
  primaryEmail: string | null;
  projectName: string | null;
  qty: number;
  status: string;
  total: number;
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [orderToEdit, setOrderToEdit] = useState<Order | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const { breakpoint } = useBreakpoint();
  const { isAdmin } = useContext(AuthContext);
  useEffect(() => {
    if (isAdmin) {
      getOrdersAdmin().then((res) => {
        if (res.ok) {
          setOrders(res.data.objects);
          setLoading(false);
        }
      });
    }
  }, [isAdmin]);

  const setupTable = React.useMemo<HeaderRowItem[]>(
    () => [
      {
        text: "Order ID",
        accessor: "id",
        sortBy: "id",
        columnSizing: { min: "90px", max: "90px" },
      },
      {
        text: "Primary Email",
        accessor: "primaryEmail",
        sortBy: "primaryEmail",
        columnSizing: { min: "300px", max: "1fr" },
      },
      {
        text: "Order Date",
        accessor: "orderDate",
        columnSizing: { min: "150px", max: "1fr" },
        cb: (data: any, value: any) => {
          return DateTime.fromISO(value).toLocaleString();
        },
      },
      {
        text: "Project Name",
        accessor: "projectName",
        columnSizing: { min: "200px", max: "1fr" },
      },
      {
        text: "MAC Address",
        accessor: "macAddress",
        columnSizing: { min: "200px", max: "200px" },
      },
      {
        text: "Total",
        accessor: "total",
        columnSizing: { min: "120px", max: "120px" },
        cb: (data: any, value: any, index: number | undefined) => {
          return value && value !== "-" ? `£${value}` : "-";
        },
      },
      {
        text: "Status",
        accessor: "status",
        sortBy: "status",
        columnSizing: { min: "120px", max: "120px" },
      },
      {
        text: "",
        columnSizing: { min: "120px", max: "120px" },
        cb: (data: any, value: any, index: number | undefined) => {
          return (
            <ButtonWrap>
              <EditButton
                onClick={() => {
                  handleRowClick(data);
                }}
              />
              <DownloadButton
                onClick={() => {
                  //Todo: Implement download invoice logic.
                }}
              />
            </ButtonWrap>
          );
        },
      },
    ],
    []
  );

  const handleRowClick = (data: AdminOrder) => {
    if (data) {
      getOrdersById(`ORD${data.id}`).then((res) => {
        if (res.ok) {
          setOrderToEdit(res.data);
          setShowForm(true);
        }
      });
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <Wrapper>
      {orderToEdit !== null && showForm && (
        <UpdateOrderFormAdmin order={orderToEdit} close={handleClose} />
      )}
      {breakpoint > 920 ? (
        <TableV2
          data={orders}
          headerRow={setupTable}
          loading={loading}
          editId={orderToEdit && orderToEdit.customerOrderId}
        />
      ) : (
        <MobileTable
          padding={40}
          data={orders}
          headerRow={setupTable}
          loading={loading}
        />
      )}
    </Wrapper>
  );
};

export default Orders;

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
