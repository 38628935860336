import styled, { ThemeProvider } from "styled-components";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

type Props = {
  changeTab: (e: React.MouseEvent<HTMLDivElement>) => void;
  current: string;
};

const TabBar = (props: Props) => {
  const tabsArr = ["Information", "Total", "Linking", "Media"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const tabs = tabsArr.map((item, index) => {
    return (
      <Tab
        changeTab={props.changeTab}
        name={item}
        index={currentIndex}
        setIndex={setCurrentIndex}
        tabIndex={index}
        length={tabsArr.length}
      />
    );
  });

  return <Wrapper>{tabs}</Wrapper>;
};

export default TabBar;

type TabProps = {
  changeTab: (e: React.MouseEvent<HTMLDivElement>) => void;
  name: string;
  index: number;
  setIndex: (index: number) => void;
  tabIndex: number;
  length: number;
};

const Tab = (props: TabProps) => {
  const tabActiveTheme = {
    color: props.tabIndex === props.index ? "#ffffff" : "#ebebeb",
    // boxShadow:
    //   props.tabIndex + 1 === props.index || props.tabIndex === props.index
    //     ? "rgba(0,0,0,0.16) 0px 0px 0px"
    //     : "4px 0 3px -3px rgba(0, 0, 0, 0.16), -4px 0 3px -3px rgba(0, 0, 0, 0.16)",
    borderRight:
      props.tabIndex + 1 === props.index ||
      props.tabIndex === props.index ||
      props.tabIndex === props.length - 1
        ? ""
        : "1px solid rgba(0, 0, 0, 0.1)",
    hoverColor: props.tabIndex === props.index ? "#ffffff" : "#efefef",
  };
  return (
    <ThemeProvider theme={tabActiveTheme} key={uuidv4()}>
      <TabWrapper
        onClick={(e) => {
          props.changeTab(e);
          props.setIndex(props.tabIndex);
        }}
      >
        {props.name}
      </TabWrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  grid-column: 1/-1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 2em;
  background-color: #ebebeb;
`;

const TabWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 2em;
  background-color: ${(props) => props.theme.color};
  //border-radius: 3px;
  cursor: pointer;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  // box-shadow: ${(props) => props.theme.boxShadow};
  border-right: ${(props) => props.theme.borderRight};
  // border-left: ${(props) => props.theme.borderLeft};

  :hover {
    background-color: ${(props) => props.theme.hoverColor};
  }
`;
