import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { HeaderRowItem } from "../Other/Types";
import Row, { Cell } from "./row/Row";
import { BarLoader } from "react-spinners";
import useVirtualisation from "../../../hooks/useVirtualisation";
import arrow from "../../../assets/images/other/arrow.svg";
import { useSearchAndFilter } from "../../../hooks/useSearchAndFilter";
import SearchInput from "../Other/SearchInput";

type Props = {
  data: any;
  headerRow: HeaderRowItem[];
  dataType?: string;
  handleRowClick?: (row: any) => void;
  loading: boolean;
  showAddRow?: boolean;
  showSearch?: boolean;
  infoRow?: React.ReactNode;
  tableHeader?: React.ReactNode;
  editId?: string | number | undefined | null;
  delId?: string | number | undefined | null;
  infoId?: string | number | undefined | null;
  getInputRow?: (setData?: (newData: any) => void) => ReactElement;
  getDelRow?: (setData?: (newData: any) => void) => ReactElement;
  setRowCallback?: (setData: (newData: any) => void) => void;
  searchCallback?: (searchValue: string) => any;
};

const defaultProps = {
  showSearch: true,
};

const TableV2 = (props: Props): ReactElement => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortIndex, setSortIndex] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("");
  const tableRef = useRef() as React.MutableRefObject<HTMLTableElement>;
  const rowRef = useRef<HTMLTableRowElement>(null);
  const arrowDown = <Down src={arrow} alt="" />;
  const arrowUp = <Up src={arrow} alt="" />;

  const filteredAndSortedData = useSearchAndFilter({
    data: props.data,
    headerRow: props.headerRow,
    searchValue,
    sortBy,
    sortIndex,
    searchCallback: props.searchCallback,
  });

  const [items, offset, newHeight, firstIndex, isScrolled] = useVirtualisation(
    filteredAndSortedData,
    tableRef,
    rowRef,
    50,
    50,
    null
  );

  const headerDisplay = React.useMemo(() => {
    return props.headerRow.map((item, index) => {
      const cursorTheme = {
        cursor: item.sortBy ? "pointer" : "default",
      };
      return (
        <th key={index}>
          <ThemeProvider theme={cursorTheme}>
            <HeaderText
              onClick={() => {
                if (!item.sortBy) {
                  return;
                }
                setSortIndex((prev) => {
                  if (item.sortBy && sortBy !== item.sortBy) {
                    setSortBy(item.sortBy);
                    return 1;
                  }
                  return prev === 2 ? 0 : prev + 1;
                });
              }}
            >
              {item.text}
            </HeaderText>
          </ThemeProvider>
          {sortIndex === 1 && item.sortBy === sortBy
            ? arrowUp
            : sortIndex === 2 && item.sortBy === sortBy && arrowDown}
        </th>
      );
    });
  }, [props.headerRow, sortBy, sortIndex, arrowUp, arrowDown]);

  const tableTheme = {
    columns: props.headerRow.map((item) => {
      return item.columnSizing
        ? `minmax(${item.columnSizing.min}, ${item.columnSizing.max} )`
        : "1fr ";
    }),
    cursor: props.handleRowClick && "pointer",
    hover: props.handleRowClick && "#D3D3D3",
    height: "75vh",
    isScrolled: isScrolled,
  };

  useEffect(() => {
    if (props.showAddRow) {
      tableRef.current.scrollTo(0, 0);
    }
  }, [props.showAddRow]);

  useEffect(() => {
    setSearchValue("");
  }, [props.loading]);

  useEffect(() => {
    tableRef.current.scrollTo(0, 0);
  }, [sortBy, searchValue]);

  const data = items.map((data: any, index: number) => {
    const showEditRow = props.editId === data.id;
    const showDeleteRow = props.delId === data.id;
    const showDelInfo = props.infoId === data.id && data.status === "DELETED";
    const showInfo = props.infoId === data.id && data.status !== "DELETED";

    return (
      <Row
        rowRef={rowRef}
        showEditRow={showEditRow}
        showInfoRow={showInfo}
        showDelInfo={showDelInfo}
        data={data}
        headerRow={props.headerRow}
        index={index}
        globalIndex={firstIndex + index}
        infoRow={props.infoRow}
        getInputRow={props.getInputRow}
        getDelRow={props.getDelRow}
        showDeleteRow={showDeleteRow}
      />
    );
  });

  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer>
        <TableToolbar>
          {props.tableHeader ? props.tableHeader : <div />}{" "}
          <SearchBar>
            {props.showSearch && (
              <SearchInput
                placeholder="Search"
                value={searchValue || ""}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                autoComplete={"off"}
              />
            )}
          </SearchBar>
        </TableToolbar>
        {props.infoRow}
        <StyledTable ref={tableRef}>
          {props.loading ? (
            <SpinnerWrap>
              <BarLoader width={80} color={"#a19e9e"} />
            </SpinnerWrap>
          ) : (
            <TableBody>
              <HeaderRow>{headerDisplay}</HeaderRow>
              <VirtualisationWrapper
                style={{
                  width: "100%",
                  position: "relative",
                  height: `${filteredAndSortedData.length * newHeight}px`,
                }}
              >
                <VirtualisationWrapper
                  style={{
                    position: "absolute",
                    top: `${offset}px`,
                  }}
                >
                  {props.showAddRow && (
                    <tr>{props.getInputRow && props.getInputRow()}</tr>
                  )}
                  {data.length > 0 ? data : <NoEntries>No Entries</NoEntries>}
                </VirtualisationWrapper>
              </VirtualisationWrapper>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
    </ThemeProvider>
  );
};

export default TableV2;
TableV2.defaultProps = defaultProps;

const HeaderRow = styled.tr`
  white-space: nowrap;
  border: solid 1px #e9e9e9;
  padding: 10px;
  display: grid;
  grid-template-columns: inherit;
  grid-gap: 10px;
  align-items: center;
  background: #e9e9e9;
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--grey-color);
  height: 25px;
  align-content: center;
  position: sticky;
  top: 0;
  z-index: 2;
  box-sizing: content-box;
  transition: all ease-in-out 0.1s;
  box-shadow: ${(props) =>
    props.theme.isScrolled ? "rgba(0, 0, 0, 0.16) 0px 1px 4px" : "none"};
  th {
    display: grid;
    grid-template-columns: min-content auto;
    justify-items: start;
    align-content: center;
    align-self: center;
  }
`;

const StyledTable = styled.table`
  display: grid;
  grid-auto-flow: row;
  overflow-y: scroll;
  height: 75vh;

  tr {
    border: solid 1px #e9e9e9;
    padding: 10px;
    display: grid;
    grid-template-columns: inherit;
    grid-column: 1/-1;
    align-items: center;
    grid-gap: 10px;
  }

  td {
    display: grid;
    color: var(--grey-color);
    border-radius: 4px;
    text-align: left;
  }

  ${Cell} {
    text-overflow: ellipsis; //cells that need this must have fixed column sizing (ie min: 300px, max:300px)
    overflow: hidden;
    white-space: nowrap;
  }
  td button {
    width: 100%;
    justify-self: stretch;
  }
  td div input {
    border-radius: 4px;
  }
`;

const TableBody = styled.tbody`
  grid-auto-flow: row;
  box-sizing: content-box;
  grid-template-columns: ${(props) => props.theme.columns};
`;

const VirtualisationWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  box-sizing: content-box;
  grid-template-columns: inherit;
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 12px;
`;

const TableToolbar = styled.div`
  display: grid;
  align-items: center;
  align-content: center;
  height: 50px;
  margin-bottom: 10px;
  grid-template-columns: 1fr auto;
  width: 100%;
  justify-content: end;
  grid-gap: 10px;
`;

const SearchBar = styled.div`
  & select {
    &:focus {
      border-color: rgba(0, 0, 0, 0.25);
      outline: 0;
    }
  }
`;

const Down = styled.img`
  width: 12px;
  margin-left: 3px;
`;

const Up = styled.img`
  width: 12px;
  margin-left: 3px;
  transform: rotate(180deg);
`;

const HeaderText = styled.p`
  :hover {
    cursor: ${(props) => props.theme.cursor};
  }
`;

const NoEntries = styled.div`
  grid-column: 1/-1;
  display: grid;
  justify-items: center;
  justify-content: center;
  min-height: 40px;
  align-items: center;
  align-content: center;
`;

const SpinnerWrap = styled.div`
  justify-self: center;
  align-self: center;
  grid-column: 1/-1;
  height: 40px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
`;
