import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import LabeledInput from "../../../../../components/form/components/labeledInput";
import { FormValues } from "../index";

interface ControlPlatformsCheckboxes {
  Control4: boolean;
  Crestron: boolean;
  Elan: boolean;
  RTI: boolean;
  Savant: boolean;
}

interface Props {
  selectedPlatforms: ControlPlatformsCheckboxes;
  control: Control<any>;
  handlePlatformChange: (platform: keyof ControlPlatformsCheckboxes) => void;
  errors: FieldErrors<FormValues>;
  watch: UseFormWatch<FormValues>;
  setError: UseFormSetError<FormValues>;
  clearErrors: UseFormClearErrors<FormValues>;
  getValues: UseFormGetValues<FormValues>;
}

const ControlAccountForm: React.FC<Props> = ({
  selectedPlatforms,
  control,
  handlePlatformChange,
  watch,
  errors,
  setError,
  clearErrors,
  getValues,
}) => {
  const [showError, setShowError] = useState(false);

  const control4 = watch("controlPlatforms").Control4;
  const elan = watch("controlPlatforms").Elan;
  const crestron = watch("controlPlatforms").Crestron;
  const rti = watch("controlPlatforms").RTI;
  const savant = watch("controlPlatforms").Savant;

  useEffect(() => {
    const validateAtLeastOneAccount = () => {
      const values = getValues();
      const anyAccountFilled = Object.values(values.controlPlatforms).some(
        (value) => !!value
      );
      if (!anyAccountFilled) {
        setError("controlPlatforms", {
          type: "manual",
          message: "At least one account number is required.",
        });
      } else {
        clearErrors("controlPlatforms");
      }
    };
    validateAtLeastOneAccount();
  }, [
    getValues,
    setError,
    clearErrors,
    errors,
    control4,
    savant,
    rti,
    crestron,
    elan,
  ]);

  return (
    <ControlAccountFormWrapper>
      <Wrapper style={{ marginBottom: showError ? "1em" : 0 }}>
        <p>Select Control Platform (tick all that apply)*</p>
        <ControlPlatformsWrapper>
          {Object.keys(selectedPlatforms).map((platform) => (
            <CheckboxWrapper key={platform}>
              <label style={{ fontSize: "16px" }}>
                <input
                  type="checkbox"
                  checked={
                    selectedPlatforms[
                      platform as keyof ControlPlatformsCheckboxes
                    ]
                  }
                  onChange={() =>
                    handlePlatformChange(
                      platform as keyof ControlPlatformsCheckboxes
                    )
                  }
                />
                {platform}
              </label>
            </CheckboxWrapper>
          ))}
        </ControlPlatformsWrapper>
      </Wrapper>
      {errors.controlPlatforms && (
        <ErrorP>A control platform needs to be selected</ErrorP>
      )}
      <ControlPlatformWrapper>
        {Object.keys(selectedPlatforms).map(
          (platform) =>
            selectedPlatforms[platform as keyof ControlPlatformsCheckboxes] && (
              <Controller
                key={platform}
                name={`controlPlatforms.${platform}`}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <LabeledInput
                    name={platform}
                    value={field.value}
                    handlechange={(e) => field.onChange(e.target.value)}
                    label={{
                      text: `${platform} Account Number`,
                      for: platform,
                      required: true,
                    }}
                  />
                )}
              />
            )
        )}
      </ControlPlatformWrapper>
    </ControlAccountFormWrapper>
  );
};

export default ControlAccountForm;

const ControlAccountFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  color: grey;
  box-sizing: border-box;
  width: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  grid-column: 1/-1;
  padding: 0 1em 0 1em;
  margin-bottom: 1em;
  p {
    grid-column: 1/-1;
    justify-self: center;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 920px) {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;
const ControlPlatformsWrapper = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
`;

const ErrorP = styled.div`
  color: red;
  justify-self: center;
  justify-items: center;
  margin-top: 20px;
`;

const ControlPlatformWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
  > :last-child {
    margin-bottom: 20px;
  }
`;
