import React from "react";
import styled from "styled-components";
import ShowroomIntro from "../intro";
import ShowroomForm from "../form";
import ContentWrapper from "../../../../components/reusables/ContentWrapper";

const ShowroomFormPage = () => {
  return (
    <ContentWrapper withLoc={true}>
      <Wrapper>
        <ShowroomIntro />
        <ShowroomForm />
      </Wrapper>
    </ContentWrapper>
  );
};
export default ShowroomFormPage;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
