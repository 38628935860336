import { AddressFormState } from "../components/form/setup/address/addressTypes";
import { MyResponse, RequestConfig, requestMiddleware } from "./Request";

// /addresses

export async function getAddresses(): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/addresses",
    method: "GET",
  };
  return requestMiddleware(config);
}

export async function postAddress(
  address: AddressFormState
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/addresses",
    method: "POST",
    data: address,
  };
  return requestMiddleware(config);
}

export async function deleteAddressById(id: number): Promise<MyResponse> {
  const config: RequestConfig = {
    url: "/api/addresses/" + id.toString(),
    method: "DELETE",
  };
  return requestMiddleware(config);
}
