import styled from "styled-components";
import React from "react";
import Markdown from "markdown-to-jsx";
interface Props {
  heading: string;
  text: string;
  inputSetup?: React.InputHTMLAttributes<any>;
  editing?: boolean;
  case?: any;
  markdown?: boolean;
}
const LongCard = (props: Props) => {
  return (
    <Wrapper>
      <CardWrap>
        <Heading>{props.heading}</Heading>
        <CardInner>
          {props.inputSetup && props.editing ? (
            <textarea
              onChange={props.inputSetup.onChange}
              value={props.inputSetup.value}
              name={props.inputSetup.name}
              data-case={props.case && props.case}
            />
          ) : props.markdown ? (
            <MarkdownWrapper>
              <Markdown>{props.text}</Markdown>
            </MarkdownWrapper>
          ) : (
            <p>{props.text}</p>
          )}
        </CardInner>
      </CardWrap>
    </Wrapper>
  );
};

export default LongCard;

const Wrapper = styled.div`
  grid-column: 1/-1;
`;
const CardWrap = styled.div`
  border: var(--border-main);
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px;
  display: grid;
  border-left: solid 4px var(--orange-color);
  align-content: center;
  align-items: center;
  grid-gap: 12px;
  padding-left: 12px;
  grid-template-rows: 1em;
`;

const CardInner = styled.div`
  overflow-x: hidden;

  p {
    font-size: 12px;
  }
  textarea {
    border: solid 1px var(--orange-color);
    background-color: rgba(252, 151, 0, 0.26);
    box-sizing: border-box;
    width: -moz-available;
    width: -webkit-fill-available;
    padding: 6px;
    white-space: break-spaces;
    min-height: 10em;
    font-size: 12px;
    max-width: 100%;
  }
`;

const Heading = styled.p`
  font-weight: 600;
  font-size: 0.9em;
`;

const MarkdownWrapper = styled.div`
  position: relative;
  padding: 5px;
  background-color: #f8f8f8;
  text-align: left;
  line-height: 1.3;
  display: grid;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: initial;
    padding-left: 2em;
    padding-right: 2em;
  }

  li {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  h2,
  h3 {
    font-weight: bold;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    font-size: 1.1em;
  }

  a {
    color: var(--orange-color);
  }
`;
