import Service from "./service";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link } from "react-router-dom";

const DriverDev = () => {
  return (
    <section>
      <Services />
    </section>
  );
};

export default DriverDev;

const BulletList = styled.ul`
  list-style: disc;
  line-height: 30px;
  margin-left: 20px;
`;

const Services: any = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    }
  }, [user]);

  const data = [
    {
      name: "driver",
      subHeading: { h3: "INTEGRATION", h2: "DRIVERS" },
      mainText: (
        <div>
          <p>
            Intrinsic Dev develop, retail and support drivers for all the
            leading control platforms. Working alongside leading manufacturers,
            we are able to offer you reliable, field tested drivers, backed up
            with unparalleled, industry leading support.
          </p>
          <p>
            If you have a need for, or would like to suggest a driver to us,
            please fill out our{" "}
            {isLoggedIn ? (
              <Link to={"/driver-request"}>Driver Request Form</Link>
            ) : (
              <Link to={"/login"}>Driver Request Form</Link>
            )}
          </p>
        </div>
      ),
    },
    {
      name: "support",
      subHeading: { h3: "TECHNICAL", h2: "SUPPORT" },
      mainText: (
        <div>
          <p>
            We recognise that our drivers form the backbone of most automation
            installs and so support is a big priority for us.
          </p>
          <p>
            With a team constructed of global industry leading experts, we’re
            able to offer premium tech support services whether for our drivers,
            3rd party drivers or platform support.
          </p>
          <p>
            {isLoggedIn ? (
              <>
                <span>
                  Our <Link to={"/support/form"}>Online Ticketing System</Link>{" "}
                  makes things easy, so you’re always up to speed.
                </span>
              </>
            ) : (
              <>
                <span>
                  Our Online Ticketing System makes things easy, so you’re
                  always up to speed.{" "}
                  <Link to={"/login"}>
                    Please login to see our Online Ticketing System
                  </Link>
                </span>
              </>
            )}
          </p>
        </div>
      ),
    },
    {
      name: "white-label-support",
      subHeading: { h3: "SERVICES", h2: "WHITE LABEL SUPPORT" },
      mainText: (
        <div>
          <p>Sometimes, you just need a hand…</p>
          <p>
            At Intrinsic Dev, our aim is to support the Custom Install industry
            and so we offer a range of White Label or Branded Support Services.
            There’s often a conflict of interests when you need a helping hand,
            as pulling in support from another installation company can be
            risky. However, when it comes to Intrinsic Dev, we do not compete
            against you – we only offer support and drivers, so you’ll never
            find us quoting against you.
          </p>
          <p>
            We offer on site sales, support and installation under our white
            label service. We can attend site in your uniform, under your brand
            and help support your client. Client information is confidential and
            is backed up by our exclusive NDA agreement.
          </p>
        </div>
      ),
    },
    {
      name: "distributors-dealers",
      subHeading: { h3: "TRAINING FOR", h2: "DISTRIBUTORS & DEALERS" },
      mainText: (
        <div>
          <p>
            Having a unique insight in to how the leading automation platforms
            are put together, coupled with a wealth of on site experience, means
            that our team at Intrinsic Dev are perfectly positioned to offer
            training either on behalf of distributors, or directly to dealers.
          </p>
          <p>
            Our training team is comprised of site-experienced trainers, with
            accolades testifying to their ability, including having been
            acknowledged as the leading global trainers for key brands.
          </p>
          <p>
            We are happy to travel globally to provide you with, what we
            consider to be, the world’s best training programmes.
          </p>
          <p>
            If you’re interested, just drop us a message on our contact page
          </p>
        </div>
      ),
    },
    {
      name: "smart-home",
      subHeading: { h3: "SERVICES", h2: "SMART HOME CONSULTANCY" },
      mainText: (
        <div>
          <p>
            Intrinsic Dev offer a comprehensive range of consultancy services,
            backed up by years of experience. Our range of services includes:
          </p>
          <BulletList className="service-bullet-list">
            <li>Planning Support</li>
            <li>Tender Design</li>
            <li>Mediation and Litigation (via our in house legal team)</li>
            <li>CAD Design</li>
          </BulletList>
          <p>
            Please <Link to={"/contact"}>contact</Link> us for more information.
          </p>
        </div>
      ),
    },
  ];

  const elements = data.map((s: any, i: any) => {
    return (
      <Service name={s.name} subHeading={s.subHeading} mainText={s.mainText} />
    );
  });
  return <>{elements}</>;
};
