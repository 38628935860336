import styled from "styled-components";

export enum AddressTypeCases {
  "BILLING" = "BILLING",
  "CONTACT" = "CONTACT",
}

interface Props {
  selected: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  disabled?: boolean;
}
const AddressTypeSelect = (props: Props): JSX.Element => {
  let addressOptions: JSX.Element[] = [];

  for (const type in AddressTypeCases) {
    addressOptions.push(
      <option key={type} value={type}>
        {type}
      </option>
    );
  }

  return (
    <>
      <SelectWrap>
        <select
          disabled={props.disabled}
          value={props.selected}
          onChange={(e) => props.onChange(e)}
        >
          {addressOptions}
        </select>
      </SelectWrap>
    </>
  );
};

export default AddressTypeSelect;

const SelectWrap = styled.div`
  display: contents;
  select {
    border: var(--border-main);
    width: 100%;
    padding: 10px 0 10px 10px;
    height: 35px;
    margin: 0;
  }
`;
