import "./index.css";
import styled from "styled-components";
import SidebarLink from "../../../../SidebarLink";

function DropdownItem(props: {
  name: string;
  url: string;
  prefix?: string;
  suffix?: string;
}) {
  return (
    <MenuItem>
      <SidebarLink {...props} />
    </MenuItem>
  );
}

export default DropdownItem;

const MenuItem = styled.div`
  width: 100%;
  p {
    word-break: keep-all;
    word-wrap: normal;
    white-space: nowrap;
    padding-left: 30px;
    padding-right: 1em;
  }
  text-align: left;
`;
