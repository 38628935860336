import React, { useEffect, useState } from "react";
import { Order } from "../views/Main/Orders";
import { getOrders } from "../api/Orders";

interface OpenOrdersContextInterface {
  openOrders: Order[];
  openOrdersLoading: boolean;
  updateOrders: () => void;
}

const initialState = {
  openOrders: [],
  openOrdersLoading: true,
  updateOrders: () => {},
};

const OpenOrdersContext =
  React.createContext<OpenOrdersContextInterface>(initialState);

const OpenOrdersContextProvider = (props: { children: JSX.Element }) => {
  const [openOrders, setOpenOrders] = useState<Order[]>([]);
  const [openOrdersLoading, setOpenOrdersLoading] = useState<boolean>(true);

  const updateOrders = () => {
    setOpenOrdersLoading(true);
  };

  useEffect(() => {
    const fetchOpenOrders = async () => {
      getOrders().then((res) => {
        if (res.ok) {
          let openOrders = res.data.objects.filter(
            (order: Order) => order.orderStatus === "OPEN"
          );
          setOpenOrders(openOrders);
        }
      });
    };
    if (openOrdersLoading) {
      fetchOpenOrders().then(() => {
        setOpenOrdersLoading(false);
      });
    }
  }, [openOrdersLoading]);

  return (
    <OpenOrdersContext.Provider
      value={{ openOrders, openOrdersLoading, updateOrders }}
    >
      {props.children}
    </OpenOrdersContext.Provider>
  );
};

export { OpenOrdersContext, OpenOrdersContextProvider };
