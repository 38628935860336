import React from "react";
import styled from "styled-components";
import Subheading from "../../../../../../components/subheading";
import Products from "../../../../../Main/Orders/modal/details/products";
import Card from "../../../../Reusables/card";
import { Order } from "../../../../../Main/Orders";

interface Props {
  order: Order;
  showProducts: boolean;
  setShowProducts: (showProducts: boolean) => void;
}

const OrdersDetailsSection = (props: Props) => {
  return (
    <Wrapper>
      <Heading>
        <Subheading h3={"Update"} h2={"Order"} />
      </Heading>
      <Inner>
        <>
          <Card
            heading={"Order Number"}
            text={props.order.orderNumber ? props.order.orderNumber : "-"}
          />
          <Card
            heading={"Order Date"}
            text={props.order.orderDate ? props.order.orderDate : "-"}
          />
          <Card
            heading={"Phone Number"}
            text={props.order.phoneNumber ? props.order.phoneNumber : "-"}
          />
          <Card
            heading={"Voucher"}
            text={props.order.voucher ? props.order.voucher : "-"}
          />
          <Card
            heading={"Project Name"}
            text={props.order.projectName ? props.order.projectName : "-"}
          />
          <Card
            heading={"MAC Address"}
            text={props.order.macAddress ? props.order.macAddress : "-"}
          />
          <Card
            heading={"Order Email"}
            text={props.order.orderEmail ? props.order.orderEmail : "-"}
          />
        </>
      </Inner>
      <Products orderLines={props.order.orderLines} />
    </Wrapper>
  );
};

export default OrdersDetailsSection;

const Wrapper = styled.div`
  display: grid;
  position: relative;
  margin: 0 1.5em 2em 1.5em;
  grid-template-rows: auto 1fr;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Heading = styled.div`
  font-weight: 600;
  justify-content: start;
  grid-column: 1;
  font-size: 0.7em;
  justify-self: start;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 1em;
  display: grid;
  grid-template-columns: 1fr auto;
  box-sizing: border-box;
  height: fit-content;
  padding-right: 1em;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 3px;
  align-content: start;
  grid-gap: 20px;
  padding: 10px;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }
`;
