import styled, { ThemeProvider } from "styled-components";

type Props = {
  number: number;
  isCurrent: boolean;
};

const Number = (props: Props): JSX.Element => {
  const theme = {
    background: props.isCurrent
      ? "var(--orange-color)"
      : "var(--grey-color-light)",
  };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>{props.number}</Wrapper>
    </ThemeProvider>
  );
};

export default Number;

const Wrapper = styled.div`
  height: 25px;
  width: 25px;
  display: grid;
  align-items: center;
  background-color: ${(props) => props.theme.background};
  color: white;
  border-radius: 4px;
`;
