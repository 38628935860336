import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
  email: string;
}

const SuccessScreen = (props: Props) => {
  return (
    <SuccessWrap>
      <i className="ri-checkbox-circle-line"></i>
      <TextWrap>
        <h2>Password Reset Email Sent</h2>
        <p>
          An email has been sent to:
          <OrangeText>{" " + props.email}</OrangeText>.
        </p>
        <p>Follow the instructions in the email to reset your password.</p>
        <p>
          Return <Link to={"/"}>Home</Link>
        </p>
      </TextWrap>
    </SuccessWrap>
  );
};

export default SuccessScreen;

const SuccessWrap = styled.div`
  i {
    color: var(--orange-color);
    font-size: 6em;
  }
`;

const TextWrap = styled.div`
  display: grid;
  grid-gap: 10px;
  h2 {
    font-size: 1.6em;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    color: var(--orange-color);
  }
`;
const OrangeText = styled.text`
  color: var(--orange-color);
`;
