import Markdown from "markdown-to-jsx";
import { Product } from "../../../../../../../globaltypes";

interface PanelProps {
  product: Product;
}
const innerDescription = (props: PanelProps) => {
  return (
    <div className={"inner-description"}>
      {<Markdown>{props.product.description}</Markdown>}
    </div>
  );
};

export default innerDescription;
