import LabeledInput from "../../../../../components/form/components/labeledInput";
import React, { useEffect, useState } from "react";
import SaveButton from "../../../../../components/other/buttons/save";
import CancelButton from "../../../../../components/other/buttons/cancel";
import { DateTime } from "luxon";
import DropdownInput from "../../../../../components/form/components/dropdownInput";
import styled from "styled-components";
import { InputRowProps } from "../../../../../components/table/Other/Types";
import { v4 as uuidv4 } from "uuid";
import { Controller, useForm } from "react-hook-form";
import { addCategory, editCategory } from "../../../../../api/Admin";

const InputRow = (props: InputRowProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
  });

  const possibleTypes = [
    { displayValue: "NORMAL", value: "NORMAL", fieldName: "type" },
    { displayValue: "SUPPORT", value: "SUPPORT", fieldName: "type" },
  ];

  useEffect(() => {
    if (props.data) {
      const { type, name, description } = props.data;
      setValue("name", name ? name : "");
      setValue("description", description ? description : "");
      setValue("type", type ? type : "");
    }
  }, [props.data, setValue]);

  const handleSave = async (data: any, id?: number) => {
    if (id) {
      await editCategory({ ...data, id: id });
    } else {
      await addCategory({ ...data });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (!loading) {
        setLoading(true);
        await handleSave(data, props.data ? props.data.id : undefined);
        props.refresh();
        props.close();
        props.updateInfo(
          `${props.data ? props.data.name : "Category"} ${
            props.data ? "updated" : "added"
          } successfully`,
          props.data ? props.data.id : null,
          false
        );
      }
    } catch (e: any) {
      props.updateInfo(
        e.response?.data?.message || "Something went wrong, please try again",
        props.data ? props.data.id : null,
        true
      );
    } finally {
      setLoading(false);
    }
  };

  const manualSubmit = handleSubmit(onSubmit);

  return (
    <>
      <td>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{ text: "Name", for: "name", required: true }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.name}
              disabled={props.buttonsDisabled}
              name="name"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{ required: "Description is required" }}
          render={({ field }) => (
            <LabeledInput
              label={{
                text: "Description",
                for: "description",
                required: true,
              }}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              error={errors.description}
              disabled={props.buttonsDisabled}
              name="description"
            />
          )}
        />
      </td>
      <td>
        <Controller
          name="type"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DropdownInput
              id={uuidv4()}
              value={field.value}
              handlechange={(e) => field.onChange(e.target.value)}
              items={possibleTypes}
            />
          )}
        />
      </td>
      <td>
        {props.mobile && "Product Count: "}
        {props.data && props.data.productCount ? props.data.productCount : "-"}
      </td>
      <td>
        {props.mobile && "Add Date: "}
        {props.data && props.data.addDate
          ? DateTime.fromISO(props.data.addDate).toLocaleString()
          : "-"}
      </td>
      <td>
        <ButtonWrap>
          <SaveButton
            onClick={() => {
              manualSubmit();
            }}
            loading={loading}
            disabled={props.buttonsDisabled}
          />
          <CancelButton
            onClick={props.close}
            disabled={props.buttonsDisabled}
          />
        </ButtonWrap>
      </td>
    </>
  );
};

export default InputRow;

const ButtonWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
`;
