import Subheading from "../../../../components/subheading";
import React from "react";
import styled, { ThemeProvider } from "styled-components";

const RegisterIntro = () => {
  const theme = {
    bottomMargin: "3em",
  };
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Subheading h3={"User"} h2={"Registration"} />
        <Intro>
          <p>
            Please use the below form to register your company account. Once
            your company account is created you will be able to add additional
            users and email addresses of employees as required. Registration is
            only open to integration professionals and all account requests are
            verified by Intrinsic Dev staff prior to acceptance.
          </p>
        </Intro>
      </Wrapper>
    </ThemeProvider>
  );
};

export default RegisterIntro;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: start;
  align-items: start;
  align-content: start;
  grid-gap: 1em;
  grid-column: 1;
`;

const Intro = styled.div`
  text-align: left;
  line-height: 22px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: auto 1fr;
  margin-right: 20px;
  @media only screen and (max-width: 920px) {
    margin-right: 0;
  }
`;
