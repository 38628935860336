import styled from "styled-components";

interface PageControllerProps {
  pages: number;
  currentPage: number;
  updateCurrentPage: (pageNum: number) => void;
}

const PageController = (props: PageControllerProps) => {
  const numberOfButtons = 4;
  let start =
    Math.floor((props.currentPage - 1) / numberOfButtons) * numberOfButtons;
  let pageNumbers = [];

  for (let x = start + 1; x < start + numberOfButtons + 1; x++) {
    if (x <= props.pages) {
      pageNumbers.push(x);
    }
  }

  const pageButtons = pageNumbers.map((num) => {
    const PageButton = styled.div`
      width: 3em;
      height: 3em;
      color: white;
      display: grid;
      text-align: center;
      align-content: center;
      align-items: center;
      background-color: ${props.currentPage === num
        ? "var(--orange-color)"
        : "black"};
      cursor: pointer;
    `;
    return (
      <PageButton onClick={() => props.updateCurrentPage(num)}>
        {" "}
        <p>{num}</p>
      </PageButton>
    );
  });

  return (
    <Wrapper>
      <i
        style={{
          alignSelf: "center",
          cursor: "pointer",
          opacity: props.currentPage > 1 ? 100 : 0,
        }}
        onClick={() => {
          if (props.currentPage > 1) {
            props.updateCurrentPage(props.currentPage - 1);
          }
        }}
        className="ri-arrow-left-s-line"
      ></i>
      {pageButtons}

      <i
        style={{
          alignSelf: "center",
          cursor: "pointer",
          opacity: props.currentPage < props.pages ? 100 : 0,
        }}
        onClick={() => {
          if (props.currentPage < props.pages) {
            props.updateCurrentPage(props.currentPage + 1);
          }
        }}
        className="ri-arrow-right-s-line"
      ></i>
    </Wrapper>
  );
};

export default PageController;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  grid-column: 1/-1;
  margin-top: 1em;
`;
